import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import { SlideInFromLeft } from "styled/common.styled";

export const MobileMenuWrapMask = styled.div`
	position: fixed;
	z-index: 1050;
	height: 100%;
	width: 100%;
	top: 66px;
	left: 0;
`;
export const MobileMenuMask = styled.div`
	position: absolute;
	z-index: 1050;
	width: 100%;
	height: 100%;
	left: 0rem;
	background: #fff;
	opacity: 0.9;
	cursor: pointer;
`;

export const MobileMenuContentWrapper = styled.div`
	position: fixed;
	height: 100%;
	z-index: 1050;
	top: 115px;
	left: 0px;
	padding: 0rem;
	background-color: white;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 16rem;
	box-shadow: 1px 1px 11px 1px #505050;
	animation: ${SlideInFromLeft} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	border-bottom: solid 1px #a8a8a8;
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
`;
export const MobileMenuContentWrapperNft = styled.div`
	position: fixed;
	height: 100%;
	z-index: 1050;
	top: 65px;
	left: 0px;
	padding: 0rem;
	background-color: white;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 16rem;
	box-shadow: 1px 1px 11px 1px #505050;
	animation: ${SlideInFromLeft} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	border-bottom: solid 1px #a8a8a8;
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
`;

export const MobileMenuContentClose = styled(Icon)`
	color: black;
	font-size: 25px;
	float: right;
`;

const MobileMenuContentStyle = css`
	color: black;

	margin: 0;
	font: normal normal 800 14px/26px Lato !important;
	letter-spacing: 0.35px;
	color: #000000;
	text-transform: uppercase;
	&:hover {
		color: #d2502d;
	}
`;

export const MobileMenuContentLink = styled.div`
	display: block;
	padding: 1rem;
	cursor: pointer;
	${MobileMenuContentStyle}
	${(props) => `padding-top: ${props.pt}rem`};
	${(props) =>
		// Subcategories
		props.level === 3 &&
		css`
			padding: 0px 5px 10px 2rem;
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			font: normal normal normal 14px/38px Lato !important;
			letter-spacing: 0.35px;
			color: #828282;
			opacity: 1;
		`};
	${(props) =>
		// Artist menu items
		props.level === 4 &&
		css`
			padding: 7px 5px 7px 2rem;
			font-size: 14px;
			font-weight: 400;
		`};
`;
export const MobileMenuContentLinkTest = styled.a`
	display: block;
	padding: 1rem;
	cursor: pointer;
	${MobileMenuContentStyle}
	${(props) => `padding-top: ${props.pt}rem`};
	${(props) =>
		// Subcategories
		props.level === 3 &&
		css`
			padding: 0px 5px 10px 2rem;
			font-size: 14px;
			font-weight: 400;
		`};
	${(props) =>
		// Artist menu items
		props.level === 4 &&
		css`
			padding: 7px 5px 7px 2rem;
			font-size: 14px;
			font-weight: 400;
		`};
`;

export const MobileMenuContentDrop = styled.div`
	${MobileMenuContentStyle};

	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	cursor: pointer;
	a {
		${MobileMenuContentStyle};
		/* Categories */
		${(props) =>
			props.level === 2 &&
			css`
				font-size: 14px;
				font-weight: 400;
			`}
	}
	${(props) =>
		//  Categories wrap
		props.level === 2 &&
		css`
			padding: 10px 1rem;
			text-align: left;
			font: normal normal 600 14px/26px Lato !important;
			letter-spacing: 0.35px;
			color: #000000;
			border-bottom: solid 0.2px #f0f0f0;
		`};
`;
export const SingleMobileMenu = styled.div`
	${MobileMenuContentStyle};

	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	cursor: pointer;
	padding: 10px 1rem;
	text-align: left;
	font: normal normal 600 14px/26px Lato !important;
	letter-spacing: 0.35px;
	color: #000000;
	border-bottom: solid 0.2px #f0f0f0;
	span {
		font: normal normal 900 14px/28px Lato !important;
		letter-spacing: 0.14px;
		color: #000000;
	}
`;
export const MobileMenuLevels = styled.div`
	${(props) =>
		// main menu
		props.level === 1
			? css`
					padding-left: 0;
			  `
			: // Categories
			props.level === 2
			? css`
					margin-left: 1rem;
					margin-right: 3px;
			  `
			: css`
					padding-left: 1rem;
			  `};
	${(props) =>
		props.open &&
		css`
			background-color: ${props.level === 1 ? "white" : "white"};
			border-radius: 15px;
			margin-bottom: 4px;
			/* main menu */
			${props.level === 1 &&
			css`
				padding-bottom: 5px;
			`}
		`}
`;

export const MobileMenuArrow = styled(Icon)`
	color: black;
	font-size: 15px;
	cursor: pointer;
	${(props) =>
		props.dorotate === "true"
			? css`
					transform: rotate(180deg);
					transition: transform 0.3s linear;
			  `
			: null}
`;
export const ProfileSectionMobile = styled.div`
	background-color: black;
	width: 16rem;
	height: 60px;

	img {
		width: 18px;
		height: 18px;
	}
`;
export const ProfileTextSection = styled.div`
	display: flex;
	height: 50px;
	align-items: center;
	width: ${(props) => `${props.width ? "100%" : "150px"}`};
	justify-content: space-evenly;
	span {
		text-align: left;
		font: normal normal 600 14px/24px Lato !important;
		letter-spacing: 0.14px;
		color: #ffffff;
		opacity: 1;
	}
	.vertical-line {
		border: solid 1px #ffffff;
		height: 10px;
	}
`;

export const ProfileNameContainerMobile = styled.div`
	display: flex;
	justify-content: space-between;
	width: 190px;
	img {
		width: 20px;
		height: 22px;
	}
	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

export const SocialMediaIconMobile = styled.div`
	display: flex;
	height: 70px;
	position: fixed;
	background-color: white;
	bottom: 0px;
	z-index: 9999;
	width: 16rem;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
	border-top: solid 1px #dddddd;
	img {
		width: 20px;
		height: 20px;
	}
	span {
		text-align: left;
		font: normal normal normal 14px/26px Lato !important;
		letter-spacing: 0.35px;
		color: black;
	}
`;
