import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class CommentsService {
  static API = APIUrlConstants.API_URLS;
  static service = new BaseService();

  // Get Comment List
  static async getComments(pageNum, pageSize, parentId) {
    const params = { pageNum, pageSize, parentId };
    return this.service.execute(this.API.getAllComments, HTTP_METHOD.GET, {
      params
    });
  }

  // Add Comment
  static async addComment(comment, parentId) {
    const body = {
      Comment: comment,
      ParentId: parentId
    };
    return this.service.execute(this.API.addComment, HTTP_METHOD.POST, {
      body
    });
  }

  // Update Comment
  static async updateComment(comment, parentId) {
    const body = {
      Comment: comment,
      ProductId: parentId
    };
    return this.service.execute(this.API.updateComment, HTTP_METHOD.PUT, {
      body
    });
  }
  // Delete Comment
  static async deleteComment(commentId) {
    const urlDirectParams = `/${commentId}`;
    return this.service.execute(this.API.deleteComment, HTTP_METHOD.DELETE, {
      urlDirectParams
    });
  }
}
