import { UserManagementService } from "../services/UserManagementService";
import * as types from "./ActionsTypes";

// -------------- Login -------------- //
export const login = credentials => {
	return {
		actionType: types.LOGIN_REQUEST,
		callAPI: () => UserManagementService.doLogin(credentials),
		stateObject: "userLoginData"
	};
};


// -------------- Login -------------- //
export const walletLogin = address => {
	return {
		actionType: types.LOGIN_REQUEST,
		callAPI: () => UserManagementService.doWalletLogin(address),
		stateObject: "userLoginData"
	};
};


// ========================================== Get User Info ========================================== //
export const getUserInfoAction = () => {
	return {
		actionType: types.GET_USER_INFO_REQUEST,
		callAPI: () => UserManagementService.getUserInfo(),
		stateObject: "profileData"
	};
};

// ========================================== Get User Info for mobile ========================================== //
export const getUserInfoForMobileAction = () => {
	return {
		actionType: types.GET_USER_INFO_FOR_MOBILE_REQUEST,
		callAPI: () => UserManagementService.getUserInfoForMobile(),
		stateObject: "profileMobileData"
	};
};

// ========================================== User Login with Google ========================================== //
export const googleLoginAction = userToken => {
	return {
		actionType: types.LOGIN_WITH_GOOGLE_REQUEST,
		callAPI: () => UserManagementService.googleLogin(userToken),
		stateObject: "googleLoginData"
	};
};

// ========================================== User Login with Facebook ========================================== //
export const facebookLoginAction = userToken => {
	return {
		actionType: types.LOGIN_WITH_FACEBOOK_REQUEST,
		callAPI: () => UserManagementService.facebookLogin(userToken),
		stateObject: "facebookLoginData"
	};
};

// ========================================== User Login with Refresh Token ========================================== //
export const loginRefreshTokenAction = refreshToken => {
	return {
		actionType: types.LOGIN_WITH_REFRESH_TOKEN_REQUEST,
		callAPI: () => UserManagementService.loginRefreshToken(refreshToken),
		stateObject: "refreshLoginData"
	};
};

// ========================================== User Logout ========================================== //
export const logoutUserAction = refreshToken => {
	return {
		actionType: types.LOGOUT_REQUEST,
		callAPI: () => UserManagementService.logoutUser(refreshToken),
		stateObject: "logoutData",
		isTransient: true
	};
};

// ========================================== Toggle Two Factor Authentication ========================================== //
export const toggleTwoFactorAction = status => {
	return {
		actionType: types.TOGGLE_TWO_FACTOR_REQUEST,
		callAPI: () => UserManagementService.toggleTwoFactor(status),
		stateObject: "toggleData",
		isTransient: true
	};
};

// ========================================== Change password ========================================== //
export const changePasswordAction = (oldPassword, newPassword) => {
	return {
		actionType: types.CHANGE_PASSWORD_REQUEST,
		callAPI: () => UserManagementService.changePassword(oldPassword, newPassword),
		stateObject: "changePasswordData",
		isTransient: true
	};
};

// ========================================== Two Factor Authentication ========================================== //
export const twoFactorAuth = (email, otp) => {
	return {
		actionType: types.TWO_FACTOR_AUTH_REQUEST,
		callAPI: () => UserManagementService.validate2FOtp(email, otp),
		stateObject: "twoFactorResult",
		isTransient: true
	};
};

// ========================================== Update User Info ========================================== //
export const updateUserInfoAction = userData => {
	return {
		actionType: types.UPDATE_USER_INFO_REQUEST,
		callAPI: () => UserManagementService.updateUserInfo(userData),
		stateObject: "updateUserData",
		isTransient: true
	};
};

// ========================================== Choose User Role Action ========================================== //
export const chooseUserRole = role => {
	return {
		actionType: types.CHOOSE_USER_ROLE_REQUEST,
		callAPI: () => UserManagementService.updateUserRole(role),
		stateObject: "chooseUserRoleResult",
		isTransient: true
	};
};

// ========================================== Toggle Push Notification Action ========================================== //
export const togglePushNotificationAction = status => {
	return {
		actionType: types.TOGGLE_PUSH_NOTIFICATION_REQUEST,
		callAPI: () => UserManagementService.togglePushNotification(status),
		stateObject: "togglePushNotificationResult",
		isTransient: true
	};
};

// ========================================== Reset User Account ========================================== //
export const resetUserAccountAction = () => {
	return {
		actionType: types.RESET_USER_ACCOUNT_REQUEST,
		callAPI: () => UserManagementService.resetUserAccount(),
		stateObject: "resetAccountResult",
		isTransient: true
	};
};

// ========================================== Initial Update Action ========================================== //
export const initialUpdateAction = initialData => {
	return {
		actionType: types.INITIAL_UPDATE_REQUEST,
		callAPI: () => UserManagementService.initialUpdate(initialData),
		stateObject: "initialUpdateResult",
		isTransient: true
	};
};

// ========================================== Follow User ========================================== //
export const followUserAction = userId => {
	return {
		actionType: types.FOLLOW_USER_REQUEST,
		callAPI: () => UserManagementService.followUser(userId),
		stateObject: "followUser",
		isTransient: true
	};
};

// ========================================== Unfollow User ========================================== //
export const unfollowUserAction = userId => {
	return {
		actionType: types.UNFOLLOW_USER_REQUEST,
		callAPI: () => UserManagementService.unfollowUser(userId),
		stateObject: "unfollowUser",
		isTransient: true
	};
};

// ========================================== Remove Follower ========================================== //
export const removeFollower = userId => {
	return {
		actionType: types.REMOVE_FOLLOWER_REQUEST,
		callAPI: () => UserManagementService.removeFollower(userId),
		stateObject: "removeFollower",
		isTransient: true
	};
};

// ========================================== Get Notifications ========================================== //
export const getNotifications = () => {
	return {
		actionType: types.GET_NOTIFICATIONS_REQUEST,
		callAPI: () => UserManagementService.getNotifications(),
		stateObject: "notifications"
	};
};

// ========================================== Get Country List ========================================== //
export const getCountryListAction = () => {
	return {
		actionType: types.GET_COUNTRY_LIST,
		callAPI: () => UserManagementService.getCountryList(),
		stateObject: "countryList"
	};
};

// ========================================== Create New Artist ========================================== //
export const createNewArtistAction = (body) => {
	return {
		actionType: types.CREATE_NEW_ARTIST,
		callAPI: () => UserManagementService.createNewArtist(body),
		stateObject: "createNewArtist",
		isTransient: true
	};
};

// ========================================== User Login with Apple ========================================== //
export const appleLoginAction = userToken => {
	return {
		actionType: types.LOGIN_WITH_APPLE_REQUEST,
		callAPI: () => UserManagementService.appleLogin(userToken),
		stateObject: "appleLoginData"
	};
};


// ========================================== Email OTP Verification ========================================== //
export const emailOTPVerificationAction = (userName, otp) => {
	return {
		actionType: types.EMAIL_OTP_VERIFICATION_REQUEST,
		callAPI: () => UserManagementService.emailOTPVerification(userName, otp),
		stateObject: "emailOtpData",
		isTransient: true
	};
};

// ========================================== Send Contact Message ========================================== //
export const sendContactUsAction = data => {
	return {
		actionType: types.CONTACT_US_REQUEST,
		callAPI: () => UserManagementService.sendContactUs(data),
		stateObject: "contactUsResult",
		isTransient: true
	};
};

// ========================================== Toggle Blockchain Anonymous Action ========================================== //
export const toggleBlockchainAnonymousAction = status => {
	return {
		actionType: types.TOGGLE_BLOCKCHAIN_ANONYMOUS_REQUEST,
		callAPI: () => UserManagementService.toggleBlockchainAnonymous(status),
		stateObject: "toggleBlockchainResult",
		isTransient: true
	};
};
