import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twoFactorAuth } from "../actions/UserManagementAction";

const useTwoFactorAuth = () => {
    const twoFactorAuthInfo = (username, otp) => {
        dispatch(twoFactorAuth(username, otp));
    };
    const twoFactorResult = useSelector(state => state.userManagement.twoFactorResult);
    const dispatch = useDispatch();
    return [twoFactorAuthInfo, twoFactorResult.data, twoFactorResult.error, twoFactorResult.loading];
};
export default useTwoFactorAuth;
