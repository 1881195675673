import styled from "styled-components";
import { Input } from "antd";

export const ArtistSectionContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;
export const SearchSectionTitle = styled.h3`
	text-align: left;
	font: normal normal 300 21px/59px Lato !important;
	letter-spacing: 0.53px;
	color: #000000;
	text-transform: uppercase;
	opacity: 1;
	margin: 5px 0;
`;
export const ArtistCardWrapper = styled.div`
	margin: 5px;
`;
export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	hr {
		width: 100%;
		margin-left: 10px;
		border: 0.5px solid #e5e5e5;
		margin-top: 20px;
	}
`;

export const ViewMoreWrapper = styled.div`
	background: #000000;
	width: 180px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Lato !important;
	font-style: normal !important;
	font-weight: 900 !important;
	font-size: 13px !important;
	line-height: 136%;
	/* identical to box height, or 18px */
	text-align: center;
	letter-spacing: 0.165em;
	color: #ffffff;
	margin: 25px 0;
	cursor: pointer;
	@media only screen and (max-device-width: 768px) {
		margin: 15px 0;
	}
	@media only screen and (max-device-width: 480px) {
		margin: 15px 0;
	}
`;
export const ViewMoreMainDiv = styled.div`
	display: flex;
	justify-content: center;
`;
