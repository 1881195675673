// import React from "react";
// import { Wrapper, Img, Logo, Avathar, Content, Row, Title, TextRow, DateText, IconSet } from "./SelectedCollectionsNew.styled";
// import assets from "assets";
// import { useHistory } from "react-router";

// const SelectedCollectionsNew = ({ data }) => {
//     const history = useHistory();
//     const updatedDate = new Date(data.lastUpdatedDate).toDateString();
//     return (
//         <Wrapper
//             cover={
//                 <Img
//                     alt={data.collectionName}
//                     src={data.collectionThumbnail}
//                     fallback={data.collectionThumbnailUc}
//                     onClick={() => history.push("/collections/" + data.slugId)}
//                     imageloadingclass="selected-collections-loading-img"
//                     imageerrorclass="selected-collections-error-img"
//                 />
//             }
//         >
//             {/* <Row>
//                 <Avathar>
//                     <Logo src={assets.images.Logo} alt="admin" />
//                 </Avathar>
//                 <Content>
//                     <Title>{data.collectionName}</Title>
//                     <TextRow>
//                         <IconSet type="clock-circle" theme="filled" />
//                         <DateText>Updated on {updatedDate}</DateText>
//                     </TextRow>
//                 </Content>
//             </Row> */}
//         </Wrapper>
//     );
// };

// export default SelectedCollectionsNew;


import React from "react";
import { MainWrapper, TextSpan, TitleText, IconSpan, ArrowIcon, NewsCardImageWrapper, ShopNowButton } from "./SelectedCollectionsNew.styled";
import { Link } from "react-router-dom";
import assets from "assets";

const SelectedCollectionsNew = ({ data = {}, ...props }) => {
	const { imageGreyPlace, noImageWhite } = assets.images;

	return (
		<Link to={"/collections/" + data.slugId}>
			<MainWrapper {...props}>
				{data.collectionThumbnail ?
					<NewsCardImageWrapper src={data.collectionThumbnail || noImageWhite} fallback={data.collectionThumbnailUc || noImageWhite} alt={`RtistiQ Art Collection:${data && data.collectionName}${data && data.ownerName}`}
					/>
					:
					<NewsCardImageWrapper src={noImageWhite} alt={`RtistiQ Art Collection`}
					/>
				}
				<TextSpan>
					<TitleText>{data.collectionName}</TitleText>


					<ShopNowButton to={"/collections/" + data.slugId}>
						SHOP NOW
                    </ShopNowButton>
				</TextSpan>

			</MainWrapper>
		</Link>
	);
};

export default SelectedCollectionsNew;

