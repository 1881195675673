import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class ArtistUserTypesService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Get User ArtistTypes
	static async getMyArtistTypes() {
		return this.service.execute(this.API.getMyArtistTypes, HTTP_METHOD.GET, {});
	}

	// Get User ArtistTypes Using SlugId
	static async getArtistTypesBySlugId(SlugId) {
		let params = { SlugId };
		return this.service.execute(this.API.getArtistTypesBySlugId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}
	// Update User ArtistTypes
	static async updateArtistUserTypes(body) {
		return this.service.execute(this.API.updateArtistUserTypes, HTTP_METHOD.MULTIPART_PUT, {
			body
		});
	}
}
