import * as types from "./ActionsTypes";
import { useSelector } from "react-redux";


// ==========================================Connect Wallet ========================================== //
export const connectWallet = (payload) => {
    return async dispatch => {
        await dispatch({
            type: types.CONNECT_WALLET, payload
        })
    }
};
// ==========================================Get Wallet ========================================== //

export const getWalletDetails = () => {
    return async dispatch => {

        await dispatch({
            type: types.GET_WALLET_DETAILS
        })
    }
};
// ==========================================Disconnect Wallet ========================================== //
export const disconnectWallet = () => {
    return async dispatch => {
        await dispatch({
            type: types.DISCONNECT_WALLET
        })
    }
};