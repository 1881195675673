import { useDispatch, useSelector } from "react-redux";
import { connectWallet, disconnectWallet, getWalletDetails } from "../actions/WalletAction";


const useWalletContainer = () => {
    const dispatch = useDispatch();
    const onConnectWallet = (data) => {
        dispatch(connectWallet(data));
    };
    const getCurrentConnectedWalletDetails = () => {
        dispatch(getWalletDetails());
    }
    const disconnetCurrentWallet = () => {
        dispatch(disconnectWallet());
    }
    const {
        walletName,
        provider,
        walletAddress,
        web3,
        isConnected
    } = useSelector(state => state.walletReducer);
    return {
        onConnectWallet,
        getCurrentConnectedWalletDetails,
        disconnetCurrentWallet,
        walletName,
        provider,
        walletAddress,
        web3,
        isConnected
    }
}

export default useWalletContainer;