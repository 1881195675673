import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class GalleryService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Get My Gallery Profile
	static async getMyGalleryProfile() {
		return this.service.execute(this.API.getMyGalleryProfile, HTTP_METHOD.GET, {});
	}
	// Get Gallery Profile By Id
	static async getGalleryProfileById(SlugId, loginStatus) {
		let params = { SlugId };
		if (loginStatus === true) {
			return this.service.execute(this.API.getGalleryProfileById, HTTP_METHOD.GET, {
				params
			});
		} else {
			return this.service.execute(this.API.getGalleryProfileById, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
		}
	}
	// Update Gallery Profile
	static async updateGalleryProfile(body) {
		return this.service.execute(this.API.updateGalleryProfile, HTTP_METHOD.PUT, {
			body
		});
	}
	// Update Gallery ProfileImages
	static async updateGalleryProfileImages(body) {
		return this.service.execute(this.API.updateGalleryProfileImages, HTTP_METHOD.MULTIPART_PUT, {
			body
		});
	}
	// Get My Gallery Following
	static async getMyGalleryFollowing() {
		return this.service.execute(this.API.getMyGalleryFollowing, HTTP_METHOD.GET, {});
	}
	// Get My Followers
	static async getMyFollowers() {
		return this.service.execute(this.API.getMyFollowers, HTTP_METHOD.GET, {});
	}
	// Get My Following
	static async getMyFollowing() {
		return this.service.execute(this.API.getMyFollowing, HTTP_METHOD.GET, {});
	}
	// Get Artist List By Gallery
	static async getArtistListByGallery(PageNum, PageSize) {
		let params = { PageNum, PageSize };
		return this.service.execute(this.API.getArtistListByGallery, HTTP_METHOD.GET, {
			params
		});
	}
	// Get Artist List By User Id
	static async getArtistListByUserId(PageNum, PageSize, UserId) {
		let params = { PageNum, PageSize, UserId };
		return this.service.execute(this.API.getArtistListByUserId, HTTP_METHOD.GET, {
			params
		});
	}
	// Add New Artist To Gallery
	static async addNewArtistToGallery(artistIds) {
		let urlDirectParams = "?" + artistIds;
		return this.service.execute(this.API.addNewArtistToGallery, HTTP_METHOD.POST, {
			urlDirectParams
		});
	}
	// Delete Artist from Gallery
	static async deleteArtistFromGallery(artistId) {
		let params = { artistId };
		return this.service.execute(this.API.deleteArtistFromGallery, HTTP_METHOD.DELETE, {
			params
		});
	}

	//Get Event List
	static async getEventList() {
		return this.service.execute(this.API.getEventListGallery, HTTP_METHOD.GET);
	}

	//Get Event List by UserId
	static async getEventListByUserId(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getEventListByUserIdGallery, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Event
	static async addNewEvent(body) {
		return this.service.execute(this.API.addNewEventGallery, HTTP_METHOD.MULTIPART_POST, {
			body
		});
	}

	// Update Event
	static async updateEvent(body, eventId) {
		const urlDirectParams = `/${eventId}`;
		return this.service.execute(this.API.updateEventGallery, HTTP_METHOD.MULTIPART_PUT, {
			body,
			urlDirectParams
		});
	}

	// Delete From Event
	static async deleteFromEvent(_id) {
		const urlDirectParams = `/${_id}`;
		return this.service.execute(this.API.deleteFromEventGallery, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	//Get Award List
	static async getAwardList() {
		return this.service.execute(this.API.getAwardListGallery, HTTP_METHOD.GET);
	}

	//Get Award List by UserId
	static async getAwardListByUserId(userId) {
		let params = { userId };
		return this.service.execute(this.API.getAwardListByUserIdGallery, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Award
	static async addNewAward(Year, Award) {
		let body = { Year, Award };
		return this.service.execute(this.API.addNewAwardGallery, HTTP_METHOD.POST, { body });
	}

	// Update Award
	static async updateAward(Year, Award, awardId) {
		let urlDirectParams = `/${awardId}`;
		let body = { Year, Award };
		return this.service.execute(this.API.updateAwardGallery, HTTP_METHOD.PUT, { body, urlDirectParams });
	}

	// Delete Award
	static async deleteAward(awardId) {
		const urlDirectParams = `/${awardId}`;
		return this.service.execute(this.API.deleteAwardGallery, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	//Get Historical Data List
	static async getHistoricalDataList() {
		return this.service.execute(this.API.getHistoricalDataListGallery, HTTP_METHOD.GET, {});
	}

	//Get Historical Data List by UserId
	static async getHistoricalDataListByUserId(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getHistoricalDataListByUserIdGallery, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Historical Data
	static async addNewHistorical(Year, Events) {
		let body = { Year, Events };
		return this.service.execute(this.API.addNewHistoricalGallery, HTTP_METHOD.POST, { body });
	}

	// Update Historical Data
	static async updateHistoricalData(Year, Events, historicalId) {
		let urlDirectParams = `/${historicalId}`;
		let body = { Year, Events };
		return this.service.execute(this.API.updateHistoricalDataGallery, HTTP_METHOD.PUT, { body, urlDirectParams });
	}

	// Delete Historical Data
	static async deleteHistoricalData(historicalId) {
		const urlDirectParams = `/${historicalId}`;
		return this.service.execute(this.API.deleteHistoricalDataGallery, HTTP_METHOD.DELETE, { urlDirectParams });
	}
	// Filter Galleries
	static async filterGalleries(PageNum, PageSize, SearchKey, alphabetSearch) {
		let params = { PageNum, PageSize, SearchKey, alphabetSearch };
		return this.service.execute(this.API.filterGalleries, HTTP_METHOD.GET, { params });
	}

	//Update Gallery Address
	static async updateGalleryAddress(address) {
		const body = { ...address };
		return this.service.execute(this.API.updateGalleryAddress, HTTP_METHOD.PUT, { body });
	}

	// Search For Artists
	static async searchArtistsAction(name) {
		const params = { name };
		return this.service.execute(this.API.searchForArtists, HTTP_METHOD.GET, { params });
	}
}
