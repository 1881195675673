class Util {
	// To convert an JSON Object into formdata
	static objectToFormData = function(obj, form, namespace) {
		var fd = form || new FormData();
		let formKey;
		for (let property in obj) {
			if (
				(obj.hasOwnProperty(property) && obj[property]) ||
				// When value is equal to 0 [number], Function ignores it - To prevent that
				(obj.hasOwnProperty(property) && obj[property] === 0)
			) {
				if (namespace) {
					formKey = namespace + "[" + property + "]";
				} else {
					formKey = property;
				}
				// if the property is an object, but not a File, use recursivity.
				if (obj[property] instanceof Date) {
					fd.append(formKey, obj[property].toISOString());
				} else if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
					Util.objectToFormData(obj[property], fd, formKey);
				} else {
					// if it's a string or a File object
					fd.append(formKey, obj[property]);
				}
			}
		}

		return fd;
	};

	// To convert string array into string list for query url
	static arrayToStringList = (array, param) => {
		// Eg: param: RFID => response will be &RFID=arrayItem
		// return reduce(array, (str, i) => str + `&${param}=${i}`, "");
		return array.reduce((str, i) => str + `&${param}=${i}`, "");
	};
}
export default Util;
