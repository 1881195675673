import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class HomeService {
    static API = APIUrlConstants.API_URLS;
    static service = new BaseService();

    // Get latest Artists
    static async getLatestArtists(pageNum = 1, pageSize = "") {
        let params = { pageNum, pageSize };
        return this.service.execute(this.API.getLatestArtists, HTTP_METHOD.GET, {
            params
        });
    }

    // Get popular Artists
    static async getPopularArtists(pageNum = 1, pageSize = "") {
        let params = { pageNum, pageSize };
        return this.service.execute(this.API.getPopularArtists, HTTP_METHOD.GET, {
            params
        });
    }

    // Get HomeScreenBanner
    static async getHomeScreenBanner() {
        return this.service.execute(this.API.getHomeScreenBanner, HTTP_METHOD.GET);
    }

    // Get HeaderCount
    static async getHeaderCount() {
        return this.service.execute(this.API.getHeaderCount, HTTP_METHOD.GET);
    }

    // Get Blogs By Id
    static async getBlogsById(SlugId) {
        let params = { SlugId };
        return this.service.execute(this.API.getBlogsById, HTTP_METHOD.GET, {
            params
        });
    }

    // Get Blogs List
    static async getBlogsList(pageNum, pageSize) {
        let params = { pageNum, pageSize };
        return this.service.execute(this.API.getBlogsList, HTTP_METHOD.GET, {
            params
        });
    }

    // Get News List
    static async getNewsList(pageNum, pageSize) {
        let params = { pageNum, pageSize };
        return this.service.execute(this.API.getNewsList, HTTP_METHOD.GET, {
            params
        });
    }

    // Get News By Id
    static async getNewsById(NewsId) {
        let params = { NewsId };
        return this.service.execute(this.API.getNewsById, HTTP_METHOD.GET, {
            params
        });
    }

    // Get News By SlugId
    static async getNewsBySlugId(SlugId) {
        let params = { SlugId };
        return this.service.execute(this.API.getNewsBySlugId, HTTP_METHOD.GET, {
            params
        });
    }
    //Get Selected Categories
    static async getSelectedCollections() {
        return this.service.execute(this.API.getSelectedCollections, HTTP_METHOD.GET);
    }

    // Get Search List
    static async getSearchList(Keyword) {
        let params = { Keyword };
        return this.service.execute(this.API.getSearchResult, HTTP_METHOD.GET, {
            params
        });
    }

    // Save Search
    static async saveSearch(body) {
        return this.service.execute(this.API.saveSearch, HTTP_METHOD.MULTIPART_POST, {
            body
        });
    }
}
