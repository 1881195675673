import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class OrderManagementService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get Order History
	static async getOrderHistory() {
		return this.service.execute(this.API.getOrderHistory, HTTP_METHOD.GET);
	}

	// Get Order Details
	static async getOrderDetails(OrderDetailsId) {
		let params = { OrderDetailsId };
		return this.service.execute(this.API.getOrderDetails, HTTP_METHOD.GET, { params });
	}

	// Get Seller Order List
	static async getPlacedOrders(pageNum, pageSize, status) {
		if (status || status === 0) {
			let params = { pageNum, pageSize, status };
			return this.service.execute(this.API.getPlacedOrders, HTTP_METHOD.GET, { params });
		} else {
			let params = { pageNum, pageSize };
			return this.service.execute(this.API.getPlacedOrders, HTTP_METHOD.GET, { params });
		}
	}

	// Update Order Status
	static async updateOrderStatus(orderStatus, UpdateText, orderDetailsId) {
		let params = { orderStatus, UpdateText, orderDetailsId };
		return this.service.execute(this.API.updateOrderStatus, HTTP_METHOD.POST, { params });
	}

	//Checkout Cart
	static async checkoutCart(ShippingAddressId, BillingAddressId, Currency, BuyerEthAddress, CouponCode) {
		const body = { ShippingAddressId, BillingAddressId, BuyerEthAddress, Currency, CouponCode };
		return this.service.execute(this.API.checkoutCart, HTTP_METHOD.POST, { body });
	}

	// Cancel Order
	static async cancelOrder(OrderDetailsId, Reason) {
		let params = { OrderDetailsId, Reason };
		return this.service.execute(this.API.cancelOrder, HTTP_METHOD.PUT, { params });
	}

	// Return Order
	static async returnOrder(OrderDetailsId, Reason, PickupAddressId, ReturnPickupTimeStamp) {
		let params = { OrderDetailsId, Reason, PickupAddressId, ReturnPickupTimeStamp };
		return this.service.execute(this.API.returnOrder, HTTP_METHOD.PUT, { params });
	}

	//Invoice Generation
	static async orderInvoice(OrderDetailsId) {
		let params = { OrderDetailsId };
		let fileName = "RtistIQ Invoice";
		return this.service.execute(this.API.orderInvoice, HTTP_METHOD.PDFDOWNLOAD, { params, fileName });
	}

	// Get OrderDetails By Id [Payment Page]
	static async getOrderDetailsById(OrderId) {
		let params = { OrderId };
		return this.service.execute(this.API.getOrderDetailsById, HTTP_METHOD.GET, { params });
	}

	// Get all signature pending orders
	static async getSignaturePendingOrders(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getSignaturePendingOrders, HTTP_METHOD.GET, { params });
	}

	// Get order summary
	static async getOrderSummary(ShippingAddressId, BillingAddressId, Currency, CouponCode) {
		let params = { ShippingAddressId, BillingAddressId, Currency, CouponCode };
		return this.service.execute(this.API.getOrderSummary, HTTP_METHOD.GET, { params });
	}

	//Need Help
	static async needHelp(body) {
		return this.service.execute(this.API.needHelp, HTTP_METHOD.POST, { body });
	}

	//create Shipment
	static async createShipment(body) {
		return this.service.execute(this.API.createShipment, HTTP_METHOD.POST, { body });
	}

	//Download the AWB number
	static async getWaybillNumber(OrderDetailsId) {
		let params = { OrderDetailsId };
		let fileName = "WayBillNumber";
		return this.service.execute(this.API.getWaybillNumber, HTTP_METHOD.PDFDOWNLOAD, { params, fileName });
	}
	//pickup Details
	static async getPickupDetails(OrderDetailId) {
		let params = { OrderDetailId };
		return this.service.execute(this.API.pickupDetails, HTTP_METHOD.GET, { params });
	}
}
