const login = "loginModal";
const register = "registerModal";
const resetPassword = "resetPassword";
const sendOtp = "sendOtp";
const otpVerification = "otpVerification";
const twoFactorAuth = "twoFactorAuth";
const userRoles = "userRoles";
const youAreHere = "youAreHere";
const categoryOfInterest = "categoryOfInterest";
const eventDialog = "eventDialog";
const sellYourArt = "sellYourArt";
const styleOfCreation = "styleOfCreation";
const searchProducts = "searchProducts";
const awardHistory = "awardHistory";
const collections = "collections";
const addInterest = "addInterest";
const addSocialMedia = "addSocialMedia";
const createBlockChainAccount = "createBlockChainAccount";
const certificate = "certificate";
const blockchainVerification = "blockchainVerification";
const sellAddArtworkModal = "sellAddArtworkModal";
const resendEmailModal = "resendEmailModal";
const blockchainPendingModal = "blockchainPendingModal";
const TwoFactorPopup = "TwoFactorPopup";
const addGalleryAddress = "addGalleryAddress";
const overviewModal = "overviewModal";
const forgotPassword = "forgotPassword";
const needHelpModal = "needHelpModal";
const chooseCountryModal = "chooseCountryModal";
const successResponseModal = "successResponseModal";
const responseRedirectionModal = "responseRedirectionModal";
const roomViewModal = "roomViewModal";
const imageUploadingModal = "imageUploadingModal";
const artworkSuccessModal = "artworkSuccessModal";
const registerEmailOtp = "registerEmailOtp";
const sellerAgreement = "sellerAgreement";
const nfcSkipModal = "nfcSkipModal";
const ownershipModal = "ownershipModal";
const kycDocument = "kycDocument";
const sellingPriceModal = "sellingPriceModal";
const AuctionSignInModal = "AuctionSignInModal";
const AuctionEmptyAddressModal = "AuctionEmptyAddressModal";
const AuctionCreateAddressModal = "AuctionCreateAddressModal";
const AuctionSelectAddressModal = "AuctionSelectAddressModal";
const AuctionCryptoPaymentModal = "AuctionCryptoPaymentModal";
const BlockChainPrivateKeyShowModal = "BlockChainPrivateKeyShowModal";
const AuctionBiddingStepsModal = "AuctionBiddingStepsModal";
const ArtworkLogDetailsModal = "ArtworkLogDetailsModal";
const ProxyBiddingWarningModal = "ProxyBiddingWarningModal";
const AuctionDirectBidHelperModal = "AuctionDirectBidHelperModal";
const AuctionProxyBidHelperModal = "AuctionProxyBidHelperModal";
export default {
	login,
	register,
	resetPassword,
	sendOtp,
	otpVerification,
	twoFactorAuth,
	sellYourArt,
	userRoles,
	youAreHere,
	categoryOfInterest,
	styleOfCreation,
	eventDialog,
	searchProducts,
	awardHistory,
	collections,
	addInterest,
	addSocialMedia,
	createBlockChainAccount,
	certificate,
	blockchainVerification,
	sellAddArtworkModal,
	resendEmailModal,
	blockchainPendingModal,
	TwoFactorPopup,
	addGalleryAddress,
	overviewModal,
	forgotPassword,
	needHelpModal,
	chooseCountryModal,
	successResponseModal,
	responseRedirectionModal,
	roomViewModal,
	imageUploadingModal,
	artworkSuccessModal,
	registerEmailOtp,
	sellerAgreement,
	nfcSkipModal,
	ownershipModal,
	kycDocument,
	sellingPriceModal,
	AuctionSignInModal,
	AuctionEmptyAddressModal,
	AuctionCreateAddressModal,
	AuctionSelectAddressModal,
	AuctionCryptoPaymentModal,
	BlockChainPrivateKeyShowModal,
	AuctionBiddingStepsModal,
	ArtworkLogDetailsModal,
	ProxyBiddingWarningModal,
	AuctionDirectBidHelperModal,
	AuctionProxyBidHelperModal
};
