import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyArtistTypes, getArtistTypesBySlugIdAction, updateArtistUserTypes } from "../actions/ArtistUserTypesAction";

export const useGetUserArtist = () => {
	const dispatch = useDispatch();
	const getUserArtist = SlugId => {
		//-----------------Get Artist Types Using SlugId----------------//
		if (SlugId) dispatch(getArtistTypesBySlugIdAction(SlugId));
		else dispatch(getMyArtistTypes());
	};
	const result = useSelector(state => state.artistUserTypes.getUserArtist);

	return [getUserArtist, result.data, result.error, result.loading];
};

export const useUpdateUserArtist = () => {
	const dispatch = useDispatch();
	const updateUserArtist = userData => {
		dispatch(updateArtistUserTypes(userData));
	};
	const result = useSelector(state => state.artistUserTypes.updateUserArtist);
	return [updateUserArtist, result.data, result.error, result.loading];
};
