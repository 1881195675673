import React from "react";
import { DialogModal } from "./DialogBox.styled";

const DialogBox = ({ open, title, width, handleClose, closable = true, maskClosable = false, children, ...props }) => {
	return (
		<DialogModal
			centered
			title={title}
			width={width}
			visible={open}
			onCancel={handleClose}
			footer={null}
			closable={closable}
			maskClosable={maskClosable}
			{...props}
		>
			{children}
		</DialogModal>
	);
};
export default DialogBox;
