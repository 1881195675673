import assets from 'assets';
import MainContainer from 'common/MainContainer';
import React from 'react';
import { useHistory } from "react-router-dom";
import './pageNotFound.style.scss';

const PageNotFound = () => {
    let history = useHistory();

    return (
        <MainContainer>
            <section id="pagenotfound">
                <div className="pnf-container">
                    <p className="pnf-title">Sorry the page you are looking for does not exist in the URL</p>
                    <img src={assets.images.pageNotFound} className="pnf-img" alt="page-not-found" />
                    <br />
                    <p className="pnf-contactlink">Please contact <span className="contact-link"> artworks@rtistiq.com </span>  if you have any queries.</p>
                    <div className="pnf-btn-wrap">
                        <button onClick={() => history.push("/")} className="pnf-btn">HOME PAGE</button>
                        <button onClick={() => history.push("/about-us")} className="pnf-btn">ABOUT US</button>
                    </div>
                </div>
            </section>
        </MainContainer>
    );
};

export default PageNotFound;