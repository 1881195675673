import React from "react";

import FloatingLabeledInput from "common/FloatingLabeledInput";
const FormikInput = ({ field, form, ...props }) => {
  const error = Boolean(form.errors[field.name] && form.touched[field.name]);
  const touched = form.touched[field.name];
  const errorMessage = form.errors[field.name];
  return (
    <FloatingLabeledInput
      {...field}
      {...props}
      error={error}
      touched={touched}
      errorMessage={errorMessage}
    />
  );
};

export default FormikInput;
