import styled from "styled-components";
import { Link } from "react-router-dom";

export const PrivacyLink = styled(Link)`
    cursor: pointer;
    color: #ffffff;
    text-decoration: underline;
    :hover{
        color: #ffffff;
        text-decoration: underline;
    }
`;
