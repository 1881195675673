import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
  userRegisterResult: {},
  getMailResult: {},
  resetPasswordResult: {},
  otpSendtoMailResult: {},
  validateOtpResult: {}
};

const authenticationReducer = createApiReducer(initialState, [
  types.USER_REGISTER_REQUEST,
  types.GET_MAIL_REQUEST,
  types.RESET_PASSWORD_REQUEST,
  types.SEND_OTP_MAIL_REQUEST,
  types.VALIDATE_OTP_REQUEST
]);
export default authenticationReducer;
