import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	galleryProfile: {},
	updateGalleryProfile: {},
	updateGalleryProfileImages: {},
	myGalleryFollowing: {},
	myFollowers: {},
	myFollowing: {},
	artistList: {},
	galleryArtistList: {},
	addArtistToGalleryResult: {},
	deleteArtistFromGalleryResult: {},
	// Get Event List
	getEventListResult: {},
	addNewEventResult: {},
	updateEventResult: {},
	deleteFromEventResult: {},

	// Get Award List
	awardListResult: {},
	addAwardResult: {},
	updateAwardResult: {},
	deleteAwardResult: {},

	// Get HistoricalData List
	historicalDataListResult: {},
	addHistoricalDataResult: {},
	updateHistoricalDataResult: {},
	deleteHistoricalDataResult: {},

	galleryList: {},
	updateGalleryAddress: {},
	searchArtists: {}
};

const GalleryReducer = createApiReducer(initialState, [
	types.GET_GALLERY_PROFILE_REQUEST,
	types.UPDATE_GALLERY_PROFILE_REQUEST,
	types.UPDATE_GALLERY_PROFILE_IMAGES_REQUEST,
	types.GET_MY_GALLERY_FOLLOWING_REQUEST,
	types.GET_MY_FOLLOWERS_REQUEST,
	types.GET_MY_FOLLOWING_REQUEST,
	types.GET_ARTIST_LIST_REQUEST,
	types.GET_GALLERY_ARTIST_LIST_REQUEST,
	types.ADD_NEW_ARTIST_TO_GALLERY_REQUEST,
	types.DELETE_ARTIST_FROM_GALLERY_REQUEST,
	types.GET_EVENT_LIST_GALLERY_REQUEST,
	types.ADD_NEW_EVENT_GALLERY_REQUEST,
	types.UPDATE_EVENT_GALLERY_REQUEST,
	types.DELETE_FROM_EVENT_GALLERY_REQUEST,
	types.GET_AWARD_LIST_GALLERY_REQUEST,
	types.ADD_AWARD_GALLERY_REQUEST,
	types.UPDATE_AWARD_GALLERY_REQUEST,
	types.DELETE_AWARD_GALLERY_REQUEST,
	types.GET_HISTORICAL_DATA_LIST_GALLERY_REQUEST,
	types.ADD_HISTORICAL_DATA_GALLERY_REQUEST,
	types.UPDATE_HISTORICAL_DATA_GALLERY_REQUEST,
	types.DELETE_HISTORICAL_DATA_GALLERY_REQUEST,
	types.FILTER_GALLERIES_REQUEST,
	types.UPDATE_GALLERY_ADDRESS_REQUEST,
	types.SEARCH_FOR_ARTISTS
]);

export default GalleryReducer;
