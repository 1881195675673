/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Col, Icon, Pagination, Divider } from "antd";
import {
	AddCollectionImageInput,
	AddCollectionInputsWrapper,
	AddPhotoImg,
	AddCollectionTextarea,
	AddCollectionInputCol,
	ArtworkPopupWrapper,
	AddArtworkInnerWrapper,
	CollectionModal,
	FixedAddButton,
	AddNewButton,
	HR,
	AddCollectionModalWrapper,
	ArtistName,
	ArtPrice,
	StyledSearch,
	ProductName,
	ViewMore,
	ProductRow,
	Img,
	InvisibleInput,
	SelectedArtworkPopupWrapper,
} from "./AddNewCollection.styled";
import assets from "assets";
import { Hr, HeadingCharcoalGrey } from "styled/common.styled";
import FormikInput from "common/FormikInput";
import { Formik, Form, Field } from "formik";
import toast from "common/Toast";
import useModal from "utils/useModal";
import ModalTypes from "utils/ModalTypes";
import { artistCollectionHooks, categoryHooks, userManagementHooks } from "ecommerce-sdk/src/index";
import lodash, { isEmpty, size } from "lodash";
import ArtworkCheckbox from "common/ArtworkCheckbox";
import { errorToast, toastMessage, warningMessage } from "utils/toastMessages";
import { useTranslation } from "react-i18next";

const AddNewCollection = ({ setToShowBlank }) => {
	const { useAddCollectionByArtist, useUpdateCollection, useGetCollectionByArtist, useGetCollection } = artistCollectionHooks;
	const [isShown, modalData, , onCancel] = useModal(ModalTypes.collections);
	const attachCollectionBanner = useRef(null);
	const [values, setValues] = useState(modalData);
	const [productList, setProductList] = useState([]);
	const [searchText, setSearchText] = useState("");
	const { useGetProductSearch } = categoryHooks;
	const { useGetUserInfo } = userManagementHooks;
	const [getUserInfo, userInfo, , gettingUserInfo] = useGetUserInfo();
	const [filterFunction, filteredData, filteredError, filteringData] = useGetProductSearch();
	const [list, setList] = useState([]);
	const [add, addedCollectionData, addedCollectionError, adding] = useAddCollectionByArtist();
	const [update, updatedCollectionData, updatedCollectionError, updating] = useUpdateCollection();
	const [getCollection] = useGetCollectionByArtist();
	const [getCollectionById, collection, , collectionLoading] = useGetCollection();

	const [media, setMedia] = useState();
	const [mediaThumb, setMediaThumb] = useState();
	const nonWhiteSpacesRegex = /.*\S.*/;
	const [viewMore, setViewMore] = useState(false);
	const [pageNum, setPageNum] = useState(1);
	const body = { PageNum: 1, PageSize: 11, OwnerId: userInfo && userInfo.userId };
	const all = { OwnerId: userInfo && userInfo.userId };
	const [gotAllAvailableArtworks, setGotAllAvailableArtworks] = useState(false); //Flag to check whether GET was called without pagination
	const [filteredCount, setFilteredCount] = useState(0);
	const { t } = useTranslation();

	const [addNewCollectionBody, setAddNewCollectionBody] = useState({
		collectionName: "",
		collectionSlug: "",
		description: "",
		curatorName: null,
		curatorInfo: null,
	});
	useEffect(() => {
		setValues(modalData);
		if (modalData) {
			if (isShown) {
				getCollectionById(modalData.slugId);
			}
		}

		if (modalData && modalData.collectionItems) setList(modalData.collectionItems);
		else setList([]);
	}, [modalData]);

	useEffect(() => {
		if (collection) {
			setList(collection.collectionItems);
		}
	}, [collection]);

	useEffect(() => {
		let tempArray;
		if (searchText) {
			// if (productList) {
			// 	tempArray = productList.filter(product => {
			// 		return product.productName.toLowerCase().includes(searchText.toLowerCase());
			// 	});
			// 	if (tempArray) setFilteredCount(size(tempArray));
			// }
			const body = { OwnerId: userInfo && userInfo.userId, ProductName: searchText };
			filterFunction(body);
		} else {
			if (filteredCount !== 0) setFilteredCount(0);
		}
	}, [searchText]);

	useEffect(() => {
		if (!userInfo && !gettingUserInfo) {
			getUserInfo();
		}
		if (userInfo && !filteringData) {
			filterFunction(body);
		}
	}, [userInfo]);

	useEffect(() => {
		if (addedCollectionData) {
			onCancel();
			setMedia(null);
			setMediaThumb(null);
			setViewMore(false);
			getCollection();
			toast(t(toastMessage.addSuccess), "success");
		} else if (addedCollectionError) errorToast(addedCollectionError, "addError");
	}, [addedCollectionData, addedCollectionError]);

	useEffect(() => {
		if (updatedCollectionData) {
			onCancel();
			setMedia(null);
			setMediaThumb(null);
			setViewMore(false);
			getCollection();
			toast(t(toastMessage.updateSuccess), "success");
		} else if (updatedCollectionError) errorToast(updatedCollectionError, "updateError");
	}, [updatedCollectionData, updatedCollectionError]);

	useEffect(() => {
		if (filteredData) {
			setProductList(filteredData.productList);
			setToShowBlank(filteredData.productList);
			setFilteredCount(filteredData.count);
		} else if (filteredError) toast(toastMessage.fetchError, "error");
	}, [filteredData, filteredError]);

	const handleViewMore = () => {
		if (!viewMore) {
			if (!gotAllAvailableArtworks) {
				filterFunction(all);
				setGotAllAvailableArtworks(true);
			}
			const body = { OwnerId: userInfo && userInfo.userId, PageNum: 1, PageSize: filteredCount !== 0 ? filteredCount : 10 };
			filterFunction(body);
			setViewMore(true);
		} else {
			const body = { OwnerId: userInfo && userInfo.userId, PageNum: 1, PageSize: 10 };
			filterFunction(body);
			setViewMore(false);
		}
	};

	const onImageSelect = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		if (file) {
			reader.onloadend = () => {
				setMedia(file);
				setMediaThumb(reader.result);
			};
			reader.readAsDataURL(file);
		} else {
			setMediaThumb(null);
		}
	};
	const onClickProduct = (item) => {
		let tempList = [...list];
		if (isEmpty(tempList)) {
			tempList.push(item);
		} else {
			let alreadySelectedOrNot = lodash.findIndex(tempList, item);
			if (alreadySelectedOrNot > -1) {
				let test = tempList.filter((data) => data._id !== item._id);
				tempList = test;
			} else {
				tempList.push(item);
			}
		}
		setList(tempList);
	};

	useEffect(() => {
		if (values)
			setAddNewCollectionBody({
				collectionName: values.collectionName ? values.collectionName : "",
				collectionSlug: values.slugId ? values.slugId : "",
				description: values.description ? values.description : "",
				curatorName: values.curatorName ? values.curatorName : null,
				curatorInfo: values.curatorInfo ? values.curatorInfo : null,
			});
	}, [values]);

	const sendData = (formikValues) => {
		if (!formikValues.collectionName.match(nonWhiteSpacesRegex)) {
			toast(t(warningMessage.formCollName), "error");
			return;
		} else if (!formikValues.collectionSlug.match(nonWhiteSpacesRegex)) {
			toast(warningMessage.formCollSlug, "error");
			return;
		} else if (!formikValues.description.match(nonWhiteSpacesRegex)) {
			toast(t(warningMessage.formCollDesc), "error");
			return;
		}
		// else if (!formikValues.curatorName.match(nonWhiteSpacesRegex)) {
		// 	toast("Please enter curator name", "error");
		// 	return;
		// } else if (!formikValues.curatorInfo.match(nonWhiteSpacesRegex)) {
		// 	toast("Please enter curator information", "error");
		// 	return;
		// }
		else if (!media && !(values && values.collectionBanner)) {
			toast(t(warningMessage.formCollBann), "error");
			return;
		} else if (isEmpty(list)) {
			toast(t(warningMessage.formCollArt), "error");
			return;
		}

		const collectionIds = list.map((item) => {
			return item._id;
		});

		let data = {
			// OneLiner: formikValues.oneLiner,
			CollectionName: formikValues.collectionName,
			Description: formikValues.description,
			Collection: collectionIds,
			CollectionType: "ProductCollection",
			SlugId: formikValues.collectionSlug,
			CuratorName: formikValues.curatorName,
			CuratorInfo: formikValues.curatorInfo,
			CollectionStatus: 2,
		};

		if (media) data["CollectionBanner"] = media;
		values ? update(data, values._id) : add(data);
	};
	const convertToSlug = (Text) => {
		return Text.toLowerCase()
			.replace(/[^\w ]+/g, "")
			.replace(/ +/g, "-");
	};

	//---------Update fields of API params-----------//
	const updateAddNewOllectionBody = (value) => {
		setAddNewCollectionBody({ ...addNewCollectionBody, ...value });
	};

	let submitFormikForm;

	return (
		<CollectionModal
			open={isShown}
			width="90%"
			// height="550px"
			handleClose={() => {
				onCancel();
				setMedia(null);
				setMediaThumb(null);
				setViewMore(false);
			}}
		>
			<AddCollectionModalWrapper>
				<HeadingCharcoalGrey align="center">Collection</HeadingCharcoalGrey>
				<Formik
					enableReinitialize={true}
					initialValues={{
						collectionName: addNewCollectionBody ? addNewCollectionBody.collectionName : "",
						collectionSlug: addNewCollectionBody ? addNewCollectionBody.collectionSlug : "",
						description: addNewCollectionBody ? addNewCollectionBody.description : "",
						curatorName: addNewCollectionBody.curatorName ? addNewCollectionBody.curatorName : "",
						curatorInfo: addNewCollectionBody.curatorInfo ? addNewCollectionBody.curatorInfo : "",
					}}
					onSubmit={(values) => {
						sendData(values);
					}}
				>
					{({ errors, touched, handleSubmit, isSubmitting, resetForm, submitForm, setFieldValue }) => {
						submitFormikForm = submitForm;
						return (
							<Form name="artistProfileCollections" method="post" onSubmit={handleSubmit}>
								<br />
								<AddCollectionInputsWrapper type="flex" justify="center" align="top" gutter={16}>
									<Col>
										<AddCollectionImageInput onClick={(e) => attachCollectionBanner.current.click()}>
											{mediaThumb || (values && values.collectionBanner) ? (
												<AddPhotoImg
													srcWebp={mediaThumb ? mediaThumb : values && values.collectionBanner}
													fallback={mediaThumb ? mediaThumb : values && values.collectionBannerUc}
													alt={values && values.collectionName}
												/>
											) : (
												<img src={assets.images.addPhoto} alt="" />
											)}

											<InvisibleInput
												type="file"
												onChange={(e) => onImageSelect(e)}
												accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
												ref={attachCollectionBanner}
											/>
										</AddCollectionImageInput>
									</Col>

									<AddCollectionInputCol span={16}>
										<div className="form-control">
											<Field
												name="collectionName"
												component={FormikInput}
												type="text"
												label={t("Collection Name")}
												value={addNewCollectionBody.collectionName || ""}
												onChange={(e) => {
													updateAddNewOllectionBody({
														collectionName: e.target.value,
														collectionSlug: convertToSlug(e.target.value),
													});
												}}
											/>
										</div>
										<div className="form-control">
											<Field
												name="collectionSlug"
												component={FormikInput}
												type="text"
												label="Collection SludId"
												value={addNewCollectionBody.collectionSlug || ""}
												onChange={(e) => {
													updateAddNewOllectionBody({ collectionSlug: e.target.value });
												}}
											/>
										</div>
										<div className="form-control">
											<label className="description">{t("Description")}</label>
											<AddCollectionTextarea
												name="description"
												type="text"
												component="textarea"
												maxLength={1000}
												value={addNewCollectionBody.description || ""}
												onChange={(e) => {
													updateAddNewOllectionBody({ description: e.target.value });
												}}
											/>
										</div>
										{/* <div className="form-control">
											<Field name="curatorName" component={FormikInput} type="text" label={"Curator Name"}
												value={addNewCollectionBody.curatorName || ""}
												onChange={e => {
													updateAddNewOllectionBody({ curatorName: e.target.value });
												}}
											/>
										</div>
										<div className="form-control">
											<label className="description">{"Curator Info"}</label>
											<AddCollectionTextarea name="curatorInfo" type="text" component="textarea" maxLength={1000}
												value={addNewCollectionBody.curatorInfo || ""}
												onChange={e => {
													updateAddNewOllectionBody({ curatorInfo: e.target.value });
												}}
											/>
										</div> */}
									</AddCollectionInputCol>
								</AddCollectionInputsWrapper>
							</Form>
						);
					}}
				</Formik>
				<Hr width="2px" />

				<ArtworkPopupWrapper>
					<StyledSearch
						autoFocus
						placeholder={t("Search")}
						onSearch={(value) => setSearchText(value)}
						onChange={(event) => setSearchText(event.target.value)}
					/>

					<AddArtworkInnerWrapper>
						{!isEmpty(list) && (
							<>
								<Divider
									orientation="left"
									style={{
										marginBottom: 10,
									}}
								>
									Selected Artworks
								</Divider>
								{!isEmpty(list) &&
									list.map((productItem) => {
										return (
											<div key={productItem._id}>
												<ArtworkCheckbox
													isChecked={
														lodash.findIndex(list, (item) => {
															return item._id === productItem._id;
														}) >= 0
													}
													image={productItem.displayPicture}
													imageUC={productItem.displayPictureUC}
													productId={productItem._id}
													productName={productItem.productName}
													onClickProduct={() => {
														onClickProduct(productItem);
													}}
												/>
												<ProductName>{productItem.productName}</ProductName>
												<ProductRow>
													<Col span={2}>
														<Img src={assets.images.artistIcon} alt="" />
													</Col>
													<Col span={12}>
														<ArtistName>{productItem.artistName}</ArtistName>
													</Col>
													<Col span={10}>
														{!productItem.isHidePrice && (
															<ArtPrice>
																{productItem.currency}
																{productItem && productItem.price && productItem.price.toLocaleString()}
															</ArtPrice>
														)}
													</Col>
												</ProductRow>
											</div>
										);
									})}
							</>
						)}

						<Divider orientation="left">All Artworks</Divider>

						{!isEmpty(productList) &&
							productList.map((product) => {
								if (
									!lodash.findIndex(list, (item) => {
										return item._id === product._id;
									}) >= 0
								) {
									return (
										<div key={product._id}>
											<ArtworkCheckbox
												isChecked={
													lodash.findIndex(list, (item) => {
														return item._id === product._id;
													}) >= 0
												}
												image={product.displayPicture}
												imageUC={product.displayPictureUC}
												productId={product._id}
												productName={product.productName}
												onClickProduct={() => {
													onClickProduct(product);
												}}
											/>
											<ProductName>{product.productName}</ProductName>
											<ProductRow>
												<Col span={2}>
													<Img src={assets.images.artistIcon} alt="" />
												</Col>
												<Col span={12}>
													<ArtistName>{product.artistName}</ArtistName>
												</Col>
												<Col span={10}>
													{!product.isHidePrice && (
														<ArtPrice>
															{product.currency}
															{product && product.price && product.price.toLocaleString()}
														</ArtPrice>
													)}
												</Col>
											</ProductRow>
										</div>
									);
								}
							})}
					</AddArtworkInnerWrapper>

					{((size(productList) > 10 && searchText === "") || filteredCount > 10) && (
						<ViewMore
							onClick={() => {
								handleViewMore();
							}}
						>
							{viewMore ? t("See less") + " " : "See all "}
							{viewMore ? <Icon type="arrow-up" /> : <Icon type="arrow-down" />}
						</ViewMore>
					)}
				</ArtworkPopupWrapper>
			</AddCollectionModalWrapper>
			<HR />
			<FixedAddButton>
				<AddNewButton
					variant="invert"
					width="10rem"
					onClick={() => {
						submitFormikForm();
					}}
					loading={values ? updating : adding}
					disabled={values ? updating : adding}
				>
					{t("Add to Collection")}
				</AddNewButton>
			</FixedAddButton>
		</CollectionModal>
	);
};

export default AddNewCollection;
