import React, { useEffect, useState, useRef } from "react";
import { NavbarSearchContainer, NavbarSearchPopover, NavbarSearchListItem, NavbarSearchInput } from "./Search.styled";
import { ArtistSectionContainer, SearchSectionTitle, ArtistCardWrapper, TitleWrapper, ViewMoreMainDiv, ViewMoreWrapper } from "./NewSearch.styled";
import { Empty, Spin, Icon } from "antd";
import { homeHooks } from "ecommerce-sdk/src/index";
import { useHistory } from "react-router-dom";
import { errorToast } from "utils/toastMessages";
import { isEmpty, debounce } from "lodash";
import { useTranslation } from "react-i18next";
import ImageSlider from "common/ImageSlider";
import { ArtistInnerWrapper } from "pages/Artist/Artist.styled";
import LatestArtWork from "common/LatestArtWork";
import ArtWorkCardForSearch from "common/LatestArtWork/ArtWorkCardForSearch";
import SelectedCollectionsNew from "common/SelectedCollectionsNew";
import BlogDetails from "common/BlogListContainer/BlogDetails/BlogDetails";
import BlogCard from "common/BlogCard";
import BlogCardForSearch from "common/BlogCard/BlogCardForSearch";
import ImageSliderForSearch from "common/ImageSlider/ImageSliderForSearch";
import SelectedCollectionsSearch from "common/SelectedCollectionsNew/SelectedCollectionsSearch";
import ArtWorkCardForGlobalSearch from "common/SearchArtWorkCard/ArtWorkCardForGlobalSearch";
import useArtWorkCard from "common/ArtworkCard/useArtWorkCard";
import { MoeTrackEvent } from "utils/moEngageHooks";

const Search = ({ searchTextValue }) => {
	const history = useHistory();
	const { useSearch, useSaveSearch } = homeHooks;
	const [getSearch, searchResult, searchError, searching] = useSearch();
	const [saveSearch] = useSaveSearch();
	const [filterProductsList, filterProductsListAction, searchArtworks, filterProductsError, filterProductsLoader] = useArtWorkCard(useSearch);

	const [searchText, setSearchText] = useState(searchTextValue);
	const [searchData, setSearchData] = useState([]);
	const [isHoldingAPICall, setIsHoldingAPICall] = useState(false);
	const [artworkViewMore, setArtworkViewMore] = useState(false);
	const { t } = useTranslation();

	// On handle change , Debounce request until user stops typing in field
	// To avoid continuous API calls

	useEffect(() => {
		setSearchText(searchTextValue);
		setIsHoldingAPICall(true);
		getSearch(searchTextValue);
		// delayedQuery(searchTextValue);
		// document.body.style.overflow = searchTextValue !== "" ? 'hidden' : 'auto';
	}, [searchTextValue]);
	const delayedQuery = useRef(
		debounce((val) => {
			getSearch(val);
			setIsHoldingAPICall(false);
		}, 700)
	).current;
	const handleChange = (event) => {
		const val = event.target.value || "";
		setSearchText(val);
		setIsHoldingAPICall(true);
		delayedQuery(val);
	};

	useEffect(() => {
		if (searchResult) {
			MoeTrackEvent("Search Attempt", {
				"keyword": searchTextValue,
				"IsResultFound": "Yes",
				"CategoryNameMapping": "Null",
				"CategoryIdMapping": "Null",
				"RefinedSearch": "Null"
			})
			setSearchData(searchResult);
			if (searchResult.productList && searchResult.productList.length > 6) {
				setArtworkViewMore(true);
			} else {
				setArtworkViewMore(false);
			}
		}
		if (searchError) errorToast(searchError);
	}, [searchResult, searchError]);

	useEffect(() => {
		if (searchResult) setSearchData(searchResult);
		if (searchError) errorToast(searchError);
	}, [searchResult, searchError]);

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			// history.push(`/search?ProductName=${event.target.value}`);
		}
	};
	const handleBlur = () => {
		console.log("Blur");
	};
	const onClickViewMore = () => {
		setArtworkViewMore(false);
	};
	return (
		<NavbarSearchContainer>
			{/* <NavbarSearchInput
				placeholder={t("Search")}
				autoFocus
				prefix={<Icon type="search" />}
				suffix={<Icon type="close" onClick={() => handleBlur(searchText)} />}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/> */}

			{searching ? (
				<NavbarSearchPopover>
					<Spin />
				</NavbarSearchPopover>
			) : isEmpty(searchData.artistsList) &&
				isEmpty(searchData.productList) &&
				isEmpty(searchData.collectionList) &&
				isEmpty(searchData.blogList) ? (
				!searching && (
					<NavbarSearchPopover>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</NavbarSearchPopover>
				)
			) : (
				<NavbarSearchPopover>
					{/* Artwork Section */}
					{searchData.productList && searchData.productList.length > 0 && (
						<>
							<TitleWrapper>
								<SearchSectionTitle> {t("Artworks")}</SearchSectionTitle>
								<hr></hr>
							</TitleWrapper>
							{artworkViewMore ? (
								<>
									<ArtistSectionContainer>
										{searchData.productList.slice(0, 4).map((data, index) => (
											<ArtWorkCardForGlobalSearch data={data} key={index} onAction={filterProductsListAction} />
										))}
									</ArtistSectionContainer>
									<ViewMoreMainDiv>
										<ViewMoreWrapper onClick={() => onClickViewMore()}>
											<span>View More Artworks</span>
										</ViewMoreWrapper>{" "}
									</ViewMoreMainDiv>
								</>
							) : (
								<ArtistSectionContainer>
									{searchData.productList.map((data, index) => (
										<ArtWorkCardForGlobalSearch data={data} key={index} onAction={filterProductsListAction} />
									))}
								</ArtistSectionContainer>
							)}
						</>
					)}
					{searchData.artistsList && searchData.artistsList.length > 0 && (
						<>
							<SearchSectionTitle>{t("Artists")}</SearchSectionTitle>

							<ArtistSectionContainer>
								{searchData.artistsList.map((data, index) => (
									<ArtistCardWrapper>
										<ImageSliderForSearch data={data} />
									</ArtistCardWrapper>
								))}
							</ArtistSectionContainer>
						</>
					)}
					{/* Blog List  */}
					{searchData.blogList && searchData.blogList.length > 0 && (
						<>
							<TitleWrapper>
								<SearchSectionTitle> {t("Articles")}</SearchSectionTitle>
								<hr></hr>
							</TitleWrapper>

							<ArtistSectionContainer>
								{searchData.blogList.map((data, index) => (
									<ArtistCardWrapper>
										<BlogCardForSearch key={index} cardItem={data} />
									</ArtistCardWrapper>
								))}
							</ArtistSectionContainer>
						</>
					)}

					{/* Curated Collections */}
					{searchData.collectionList && searchData.collectionList.length > 0 && (
						<>
							<TitleWrapper>
								<SearchSectionTitle> {t("Collections")}</SearchSectionTitle>
								<hr></hr>
							</TitleWrapper>

							<ArtistSectionContainer>
								{searchData.collectionList.map((data, index) => (
									<ArtistCardWrapper>
										<SelectedCollectionsSearch data={data} />
									</ArtistCardWrapper>
								))}
							</ArtistSectionContainer>
						</>
					)}
				</NavbarSearchPopover>
			)}
		</NavbarSearchContainer>
	);
};

export default Search;
