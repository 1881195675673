import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";


const initialState = {
	followArtist: {},
	followingList: {},
	followersList: {},
	unfollowArtist: {},
	myArtistFollowing: {}
};

const ArtistFollowReducer = createApiReducer(initialState,[
    types.FOLLOW_USER_REQUEST,
    types.GET_FOLLOWING_LIST_REQUEST,
    types.GET_FOLLOWERS_LIST_REQUEST,
    types.UNFOLLOW_USER_REQUEST,
    types.GET_MY_ARTIST_FOLLOWING_REQUEST
])

export default ArtistFollowReducer;
