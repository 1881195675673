import styled from "styled-components";

export const FrameWrapper = styled.div`
	width: 100%;
	font-weight: 500;
	P{
		font-size: 18px !important;
		margin-top: 32px;
		margin-bottom: 32px;
	}
	img{
		@media (max-width: 768px) {
			
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
}
        }
	}
	
`;
