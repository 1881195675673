import styled from "styled-components";
import { Button, Result } from "antd";

export const PaymentCheckoutContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 2rem;
`;

export const PaymentCheckoutTitle = styled.h2`
	font-size: 23px;
	margin-top: 2rem;
	font-weight: 700;
`;

export const PaymentCheckoutWrapper = styled.div`
	display: block;
	width: 30rem;
	margin-top: 1rem;

	@media (max-width: 320px) {
		width: 19rem;
	}
`;

export const PaypalButtonWrapper = styled.div`
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	margin-top: 20px;
	${(props) => (props.loading ? "pointer-events: none;" : "pointer-events: default;")};
`;

export const ORDivider = styled.div`
	display: flex;
	align-items: center;
	margin: 10px 0;
	font-size: 14px;
	font-weight: 500;
	${(props) => props.customWidth && `width: ${props.customWidth}`}
	span.text {
		padding: 0 3%;
	}
	span.hr {
		background-color: #e5e5e5;
		height: 1px;
		width: 45%;
	}
`;

export const PaymentCheckoutSubTitle = styled.h3`
	text-align: center;
	margin-top: 1rem;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 1rem;
`;

export const PaymentCheckoutCardImages = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		&:first-of-type {
			margin-right: 10px;
		}
	}
`;
export const ConnectWallectConatiner = styled(Button)`
	min-width: 200px;
	max-width: 750px;
	height: 45px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #e5e5e5;
	cursor: pointer;
	background: ${(props) => (props.isConnected ? "white" : "black")};
	p {
		margin: 0px 20px;
		text-align: left;
		font: normal normal normal 15px/25px Lato !important;
		letter-spacing: 0.38px;
		color: ${(props) => (props.isConnected ? "black" : "white")};
	}
	img {
		width: 30px;
		height: 30px;
	}
`;
export const PaymentCheckoutFooterWrapper = styled.div`
	display: flex;
	background-color: #58514b;
	padding-left: calc(120px + 2rem);
	padding-right: 2rem;
	justify-content: space-between;
	align-items: center;
	height: 9rem;
	margin-bottom: 3rem;
	width: 100%;
	@media screen and (max-width: 660px) {
		padding-left: 2rem;
	}
	.text-price-title {
		color: #fff;
		font-size: 14px;
		margin-bottom: 3px;
	}
	.text-price {
		color: #ad9972;
		font-size: 22px;
		font-weight: 700;
		margin: 0;

		@media (max-width: 320px) {
			font-size: 16px;
		}
	}
`;

export const PaymentResult = styled(Result)`
	border: 1px solid rgba(0, 0, 0, 0.15);
	width: 86%;
	border-radius: 3px;
	margin-bottom: 2rem;
	max-width: 40rem;
`;
