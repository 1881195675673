import React from "react";
import {
	TextSpan,
	TitleText,
	IconSpan,
	BlogMainWrapper,
	BlogTitleSearch,
	BlogSubWrapperSearch,
	BlogImageWrapperNextGenSearch,
	IconSpanSearch,
} from "./BlogCard.styled";
import { useHistory } from "react-router-dom";
import assets from "assets";
import { useTranslation } from "react-i18next";
import moment from "moment";

const BlogCardForSearch = ({ cardItem = {} }) => {
	const { image, title, blogDate, imageUc, _id } = cardItem;
	const { artistIcon } = assets.images;

	let history = useHistory();
	const { t } = useTranslation();

	return (
		<BlogMainWrapper key={_id}>
			<BlogSubWrapperSearch>
				<div
					onClick={() => {
						history.push(`/blog/${cardItem.slugId}`);
					}}
				>
					<BlogImageWrapperNextGenSearch srcWebp={image} fallback={imageUc} alt={`RtistiQ Art Blog:${title}`} />
				</div>
				<IconSpanSearch>
					{/* <ArrowIcon type="line" /> */}
					<BlogTitleSearch>{title}</BlogTitleSearch>
				</IconSpanSearch>
			</BlogSubWrapperSearch>
		</BlogMainWrapper>
	);
};
export default BlogCardForSearch;
