/* eslint-disable no-unused-vars */
import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// CSS References [Prioritywise]
import "antd/dist/antd.css";
import "swiper/css/swiper.css";
import "./App.scss";
import SDK, { userManagementHooks } from "ecommerce-sdk/src/index";
import modal from "redux/reducers/ModalReducer";
import ReactGA from "react-ga";
import { v4 as uuidv4 } from "uuid";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import { isEmpty, isUndefined } from "lodash";

// Components
import PrivateRoute from "routes/PrivateRoute";
import ScrollToTop from "common/ScrollToTop";
import FullScreenLoader from "common/FullScreenLoader";
import CookieComponent from "common/CookieComponent";
import packageJson from "../package.json";
import { getBuildDate } from "./utils/getBuild";
import withClearCache from "./ClearCache";
import ArtistDashBoard from "pages/ArtistDashboard/ArtistDashBoard";
import { localStorageGetItem } from "utils/storage";
import AppleLoginRedirect from "pages/login/AppleLoginRedirect";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import OrderConfirm from "pages/OrderConfirm";
import RePayment from "pages/CheckOut/RePayment";

const BuyerProfile = lazy(() => import("pages/BuyerProfile"));
const RegistrationAuthPage = lazy(() => import("pages/Authentication/RegistrationAuthPage"));
const BuyerRegistrationAuthPage = lazy(() => import("pages/TransferOwnershipBuyerReg/BuyerRegistrationAuthPage"));

const LoginAuthPage = lazy(() => import("pages/Authentication/LoginAuthPage"));
const ForgetPassword = lazy(() => import("pages/Authentication/ForgetPassword"));

const NftArtworkList = lazy(() => import("pages/NftDrop/NftArtworkList"));
const NFTAuction3DView = lazy(() => import("pages/NftDrop/NFTAuction3DView"));

const NftDrop = lazy(() => import("pages/NftDrop/NftDrop"));
const AuctionList = lazy(() => import("pages/Auction/AuctionList/AuctionList"));

const AppleRedirect = lazy(() => import("pages/Authentication"));
const AuctionSinglePage = lazy(() => import("pages/Auction/AuctionSiglePage/AuctionSinglePage"));
const BidArtwork = lazy(() => import("pages/Auction/BidArtwork"));
const BidSettings = lazy(() => import("pages/Auction/BidSettings/BidSettings"));

// import SearchPage from "pages/GlobalSearch";
const Home = lazy(() => import("pages/Home"));
const Profile = lazy(() => import("pages/Profile"));
const Cart = lazy(() => import("pages/Cart"));
const AddProduct = lazy(() => import("pages/AddProduct"));
const AddDigitalProduct = lazy(() => import("pages/AddDigitalProduct"));
const AuctionSubscription = lazy(() => import("pages/Auction/AuctionSubscription/AuctionSubscription"));

const ProductDetails = lazy(() => import("pages/ProductDetails"));
const EventContainer = lazy(() => import("pages/Artist/EventContainer/EventContainer"));
const ArtistArticles = lazy(() => import("pages/ArtistArticles"));
const Artist = lazy(() => import("pages/Artist"));
const ArtistNew = lazy(() => import("pages/Artist/ArtistNew.js"));
const ArtistProfile = lazy(() => import("pages/ArtistProfile"));
const SearchArtworks = lazy(() => import("pages/SearchArtworks"));

const CategoryArtworks = lazy(() => import("pages/CategoryArtworks"));
const ChooseAddress = lazy(() => import("pages/ChooseAddress"));
const ArtistList = lazy(() => import("common/ArtistList"));
const ArtistListNewLink = lazy(() => import("common/ArtistList/ArtistListNewLink_old"));

const Gallery = lazy(() => import("pages/Gallery"));
const BlogListContainer = lazy(() => import("common/BlogListContainer/BlogListContainer"));
const ArticlesListContainer = lazy(() => import("common/ArticlesListContainer/ArticlesListContainer"));
const Notifications = lazy(() => import("pages/Notifications"));
const BlogDetails = lazy(() => import("common/BlogListContainer/BlogDetails/BlogDetails"));
const BlockchainAddress = lazy(() => import("common/BlockchainAddress"));
const ArticleDetails = lazy(() => import("common/ArticlesListContainer/ArticleDetails/ArticleDetails"));
const NewsDetails = lazy(() => import("pages/NewsDetails"));
const News = lazy(() => import("pages/News"));
const CollectionPage = lazy(() => import("pages/CollectionPage"));
const CollectionList = lazy(() => import("pages/CollectionList"));
const LegalNotices = lazy(() => import("pages/LegalNotices"));
const Faq = lazy(() => import("pages/Faq"));
const TermofUse = lazy(() => import("pages/TermofUse"));
const AboutUs = lazy(() => import("pages/AboutUs"));
const TheTeam = lazy(() => import("pages/TheTeam"));
const UpdateProductFeatures = lazy(() => import("pages/UpdateProduct/UpdateProductFeatures"));
const UpdateProductImages = lazy(() => import("pages/UpdateProduct/UpdateProductImages"));
const UpdateProductPricing = lazy(() => import("pages/UpdateProduct/UpdateProductPricing"));
const UpdateProductBasic = lazy(() => import("pages/UpdateProduct/UpdateProductBasic"));
const UpdateProductLogistics = lazy(() => import("pages/UpdateProduct/UpdateProductLogistics"));

const UpdateDigitalProductFeatures = lazy(() => import("pages/UpdateDigitalProduct/UpdateProductFeatures"));
const UpdateDigitalProductImages = lazy(() => import("pages/UpdateDigitalProduct/UpdateProductImages"));
const UpdateDigitalProductPricing = lazy(() => import("pages/UpdateDigitalProduct/UpdateProductPricing"));
const UpdateDigitalProductBasic = lazy(() => import("pages/UpdateDigitalProduct/UpdateProductBasic"));

const GalleryList = lazy(() => import("pages/GalleryList"));
const MyStripeProvider = lazy(() => import("pages/Payment/StripeProvider"));
const PaymentCheckout = lazy(() => import("pages/Payment/PaymentCheckout"));
const CheckOut = lazy(() => import("pages/CheckOut"));
const ChooseBillingAddress = lazy(() => import("pages/ChooseBillingAddress"));
const AddProductComplete = lazy(() => import("pages/AddProduct/AddProductComplete"));
const BulkAddProduct = lazy(() => import("common/BulkUpload"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const CategorySearchArtworks = lazy(() => import("pages/CategorySearchArtworks"));
const NewCategorySearchArtwork = lazy(() => import("pages/NewCategorySearchArtwork"));
const Buyers = lazy(() => import("pages/Buyers"));
const Sellers = lazy(() => import("pages/Sellers"));
const ArtWorkInventory = lazy(() => import("pages/ArtistProfile/ArtWorkInventory/ArtWorkInventory"));
const CollectionListByUser = lazy(() => import("pages/CollectionList/CollectionListByUser.js"));
const Sitemap = lazy(() => import("pages/SiteMap/SiteMap"));
const SearchPage = lazy(() => import("pages/GlobalSearch"));

const DigitalCOA = lazy(() => import("pages/DigitalCOA/DigitalCOA"));

const reducers = [{ name: "modal", reducer: modal }];
reducers.forEach((reducer) => {});
SDK.ReduxStore.reducerManager.add("modal", modal);

function usePageViews() {
	ReactGA.initialize("UA-170223104-1");
	ReactGA.pageview(window.location.pathname + window.location.search);
	const options = {
		autoConfig: true, // set pixel's autoConfig
		debug: false, // enable
	};
}

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	const { useGetUserInfo } = userManagementHooks;
	const [getUserInfo, userStates, userInfoLoading] = useGetUserInfo();
	usePageViews();
	//To fetch userInfo API
	useEffect(() => {
		window.$crisp = [];
		window.CRISP_TOKEN_ID = uuidv4();
		window.CRISP_WEBSITE_ID = "5f56d644-8fba-4351-8509-88539616a151";
		(function () {
			let d = document;
			let s = d.createElement("script");
			s.src = "https://client.crisp.chat/l.js";
			s.async = 1;
			d.getElementsByTagName("head")[0].appendChild(s);
		})();

		window.$crisp.push(["safe", true]);

		const fetchUserInfo = async () => {
			const isLoggedIn = await SDK.UserManagement.isLoggedIn();
			if (isEmpty(userStates) && isLoggedIn && !userInfoLoading) {
				getUserInfo();
				let token = await SDK.UserManagement.getAuthToken();
				window.CRISP_TOKEN_ID = token;
			} else {
				window.$crisp.push(["do", "session:reset"]);
				window.CRISP_TOKEN_ID = uuidv4();
			}
		};
		fetchUserInfo();
	}, []);

	useEffect(() => {
		if (userStates && userStates.email) {
			window.$crisp.push(["set", "user:email", [userStates.email]]);
		}
		if (userStates && userStates.name) {
			window.$crisp.push(["set", "user:nickname", [userStates.name]]);
		}
	}, [userStates]);
	// if (process.env.REACT_APP_ENVIORNMENT === "PROD") console.log = function no_console() {};
	return (
		<Router basename={`/${localStorageGetItem("i18nextLng") === "en-US" ? "en" : localStorageGetItem("i18nextLng")}`}>
			<ScrollToTop />
			{/* <CookieComponent /> */}
			<SmartBanner title={"RtistiQ"} daysHidden={0} daysReminder={0} />
			<Suspense fallback={<FullScreenLoader />}>
				<Switch>
					<Route path="/forget-password" component={ForgetPassword} />

					<Route path="/user/login" component={LoginAuthPage} />
					<Route path="/user/register/:path?/:subpath?" component={RegistrationAuthPage} />
					<Route path="/buyer/register/:path?/:subpath?" component={BuyerRegistrationAuthPage} />

					{/* <Route path="/apple/login" component={AppleLoginRedirect} /> */}
					<Route path="/nftauction" component={NftDrop} />

					<Route path="/nftdrop/raja-ravi-varma-nft-auction" exact component={NftArtworkList} />
					<Route path="/auction/raja-ravi-varma-nft-drop2/virtualroom" exact component={NFTAuction3DView} />

					{/* <Route path="/nftdrop" component={NftDrop} /> */}
					<Route path="/nftdrop" component={AuctionList} />
					<Route path="/appleredirect" component={AppleRedirect} />
					<Route path="/auction-list" component={AuctionList} />
					<Route path="/auction/:slugId?/:tab?/:pageNum?" component={AuctionSinglePage} />
					<Route path="/auctionsubscription" component={AuctionSubscription} />

					<Route path="/digital-cerificate/:slugId?/:stock?" component={DigitalCOA} />

					{/* <Route path="/auction-details/:medium/:slug?/:lot?" component={AuctionDetails} /> */}
					{/* <Route path="/auction-bidding/:lot/:bid/:billingAddress/:shippingAddress?" component={BidSettings} /> */}
					<Route path="/auction-bidding/:lot/:slug/:prodType/:userId/:bid/:bidType" component={BidSettings} />

					<Redirect from="/profile" exact to="/artist/dashboard" />
					<PrivateRoute path="/profile/:path?/:subpath?" exact component={Profile} />
					<PrivateRoute path="/notifications" exact component={Notifications} />
					<PrivateRoute path="/dashboard/add-artwork/complete/:slugId" exact component={AddProductComplete} />
					<PrivateRoute path="/dashboard/add-artwork" component={AddProduct} />

					<PrivateRoute path="/dashboard/add-digital-artwork" component={AddDigitalProduct} />
					<Route
						exact
						path="/edit-artwork/:slugId"
						render={(props) => <Redirect to={`/edit-artwork/basic/${props.match.params.slugId}`} />}
					/>
					<PrivateRoute path="/edit-artwork/basic/:slugId/pending" exact component={UpdateProductBasic} />
					<PrivateRoute path="/edit-artwork/basic/:slugId" component={UpdateProductBasic} />
					<PrivateRoute path="/edit-artwork/artist-details/:slugId" component={UpdateProductFeatures} />
					<PrivateRoute path="/edit-artwork/pricing/:slugId" component={UpdateProductPricing} />
					<PrivateRoute path="/edit-artwork/images/:slugId" component={UpdateProductImages} />
					<PrivateRoute path="/edit-artwork/logistics/:slugId" component={UpdateProductLogistics} />

					<PrivateRoute path="/edit-digital-artwork/basic/:slugId/pending" exact component={UpdateDigitalProductBasic} />
					<PrivateRoute path="/edit-digital-artwork/basic/:slugId" component={UpdateDigitalProductBasic} />
					<PrivateRoute path="/edit-digital-artwork/artist-details/:slugId" component={UpdateDigitalProductFeatures} />
					<PrivateRoute path="/edit-digital-artwork/pricing/:slugId" component={UpdateDigitalProductPricing} />
					<PrivateRoute path="/edit-digital-artwork/images/:slugId" component={UpdateDigitalProductImages} />

					<PrivateRoute path="/pages/artist-articles" exact component={ArtistArticles} />
					<Redirect from="/artist" exact to="/artists/1" />
					<PrivateRoute path="/dashboard" exact component={ArtistDashBoard} />
					<PrivateRoute path="/artist/dashboard" exact component={ArtistDashBoard} />
					<PrivateRoute path="/buyer/dashboard" exact component={BuyerProfile} />
					<PrivateRoute path="/artist/me" exact component={ArtistDashBoard} />
					<PrivateRoute path="/dashboard/edit/:path?" exact component={ArtistProfile} />
					<PrivateRoute path="/dashboard/artworks-inventory" exact component={ArtWorkInventory} />
					<Route path="/artist/:slug" exact component={ArtistNew} />
					{/* <Route path="/dashboard/:slug" exact component={ArtistNew} /> */}

					<Route path="/artists/:filterType?/:fliterValue?/:pageNo?" exact component={ArtistList} />
					<Route path="/artists/all/:filterType?/:fliterValue?/:pageNo?" exact component={ArtistList} />
					<Route path="/artists/new/:filterType?/:fliterValue?/:pageNo?" exact component={ArtistList} />
					<Route path="/artists/popular/:filterType?/:fliterValue?/:pageNo?" exact component={ArtistList} />
					<Route path="/artists/featured/:filterType?/:fliterValue?/:pageNo?" exact component={ArtistList} />

					{/* <Route path="/artists/new-artists/:pageNo?" exact component={ArtistList} />
					<Route path="/artists/popular-artists/:pageNo?" exact component={ArtistList} />
					<Route path="/artists/featured-artists/:pageNo?" exact component={ArtistList} /> */}
					{/* <Route path="/artists/:pageNo?" exact component={ArtistList} /> */}
					<PrivateRoute path="/galleries/me" exact component={Gallery} />
					<Route path="/galleries/:slug" exact component={Gallery} />
					<Route path="/galleries" exact component={GalleryList} />
					<Route path="/collections/:slug" exact component={CollectionPage} />
					<Route path="/collections" component={CollectionList} />
					<Route path="/artist/dashboard/user-collections" component={CollectionListByUser} />
					<Route path="/news/:slug" exact component={NewsDetails} />
					<Route path="/news" exact component={News} />
					<Route path="/search" component={SearchPage} />
					<Route path="/artworks/:slug?/:first?/:second?/:third?/:forth?/:fifth?" exact component={NewCategorySearchArtwork} />
					{/* <Route path="/art/:slug?/:first?/:second?/:third?/:forth?/:fifth?" exact component={NewCategorySearchArtwork} /> */}
					<Route path="/eventList" component={EventContainer} />
					<Route path="/articles-list/:userId" component={ArticlesListContainer} />
					<Route path="/article-details/:slug" component={ArticleDetails} />
					<Route path="/blog/:slug" component={BlogDetails} />
					<Route path="/blog" exact component={BlogListContainer} />
					<Route path="/legal-notices" component={LegalNotices} />
					<Route path="/faq" component={Faq} />
					<Route path="/term-of-use" component={TermofUse} />
					<Route path="/about-us" component={AboutUs} />
					<Route path="/the-team" component={TheTeam} />
					<Route path="/bulk-upload" component={BulkAddProduct} />
					<Route path="/contact-us" component={ContactUs} />
					<Route path="/collector" component={Buyers} />
					<Route path="/sellers" component={Sellers} />

					{/* Payment Flow */}
					<PrivateRoute path="/cart" exact component={Cart} />
					<PrivateRoute path="/checkout" exact component={CheckOut} />
					<PrivateRoute path="/order-confirm/:orderId" exact component={OrderConfirm} />
					<PrivateRoute path="/repayment/:orderId" exact component={RePayment} />

					<PrivateRoute path="/cart/checkout/choose-delivery-address" exact component={ChooseAddress} />
					<PrivateRoute path="/cart/checkout/choose-billing-address" exact component={ChooseBillingAddress} />
					{/* <PrivateRoute path="/cart/check-out/blockchain-address" exact component={BlockchainAddress} />
					<PrivateRoute path="/payment-gateway/:orderId?" component={PaymentCheckout} />  */}
					<Route path="/art/:medium/:slug?/:stock?/:sold?" exact component={ProductDetails} />
					<Route path="/art/">
						<Redirect to="/artworks" />
					</Route>
					<Route path="/site-map" component={Sitemap} />
					<Route path="/page-not-found" component={PageNotFound} />
					<Route exact path="/" component={Home} />
					<Redirect from="*" exact={true} to="/page-not-found" />
				</Switch>
			</Suspense>
		</Router>
	);
}
export default App;
