import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { unregister } from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { BaseTheme } from "utils/theme";
import SDK from "ecommerce-sdk/src/index";
import modal from "redux/reducers/ModalReducer";
import general from "redux/reducers/GeneralReducer";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const reducers = [
	{ name: "general", reducer: general },
	{ name: "modal", reducer: modal },
];

reducers.forEach((reducer) => {
	SDK.ReduxStore.reducerManager.add(reducer.name, reducer.reducer);
});
ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<ThemeProvider theme={BaseTheme}>
			<Provider store={SDK.ReduxStore}>
				<App />
			</Provider>
		</ThemeProvider>
	</I18nextProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can changee
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
