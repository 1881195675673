import * as types from "../actions/ActionsTypes";

const initialState = {
	// Basic/General Info

	categoryArray: [],
	categoryId: "",
	productName: "",
	artistId: "",
	materialsUsed: "",
	yearofCreation: "",
	originalArtworkId: "",
	editionPublisher: "",
	isLimitedEdition: false,
	isBasicSaved: false,
	productType: "",

	//Artist Details Tab
	isAbstract: false,
	style: [],
	subject: [],
	tagsArray: [],
	tags: "",
	oneLineDescription: "",
	description: "",
	conditionReport: "",
	signature: "",
	isArtistSaved: false,

	// Pricing
	price: "",
	isHidePrice: false,
	currency: null,
	isPSSaved: false,
	autoApproveQuote: false,
	approvePercentage: 0,
	autoRejectQuote: false,
	rejectPercentage: 0,

	//Logistics
	framed: false,
	panel: false,
	height: "",
	width: "",
	depth: "",
	weight: "",
	packageHeight: "",
	packageWidth: "",
	packageDepth: "",
	packaging: "",
	isLogisticsSaved: false,

	// Images and Videos
	isIVSaved: false,
	imagesArray: [],
	// roomImageArray: [],
	displayPictureArray: null,

	// Features
	isFeaturesSaved: false,

	// Signing
	isSigningSaved: false,
	ownerEthAddress: "",
	blockchainPrivateKey: "",
	getPropertyLoader: false,

	createProductLoader: false,
	successToast: null,
	errorToast: null,
	productResponse: null,

	//OTHER
	stockManagement: true,
	stock: 1,
	medium: "",
	productProperties: [],
};

const AddProductReducer = (state = initialState, { type, payload, error }) => {
	switch (type) {
		// ========= Save Each Stage Data to Reducer ========= //
		case types.SET_BASIC_DETAILS:
			state = {
				...state,
				// TODO: Convert with spread
				categoryArray: payload.categoryArray,
				categoryId: payload.categoryId,
				productName: payload.productName,
				artistId: payload.artistId,
				materialsUsed: payload.materialsUsed,
				yearofCreation: payload.yearofCreation,
				originalArtworkId: payload.originalArtworkId,
				editionPublisher: payload.publisher,
				isLimitedEdition: payload.isLimitedEdition,
				stock: payload.stock,
				medium: payload.medium,
				isBasicSaved: true,
				height: payload.height,
				width: payload.width,
				depth: payload.depth,
				productType: payload.productType,
			};
			return state;
		case types.SET_ARTIST_DETAILS:
			state = {
				...state,
				isAbstract: payload.isAbstract,
				style: payload.style,
				subject: payload.subject,
				tagsArray: payload.tagsArray,
				tags: payload.tags,
				description: payload.description,
				oneLineDescription: payload.oneLineDescription,
				seriesName: payload.seriesName,
				conditionReport: payload.conditionReport,
				signature: payload.signature,
				isArtistSaved: true,
			};
			return state;
		case types.SET_PRICING_DETAILS:
			state = {
				...state,
				// TODO: Convert with spread
				price: payload.price,
				isHidePrice: payload.isHidePrice,
				currency: payload.currency,
				isPSSaved: true,
				autoApproveQuote: payload.autoApproveQuote,
				approvePercentage: payload.approvePercentage,
				autoRejectQuote: payload.autoRejectQuote,
				rejectPercentage: payload.rejectPercentage,
			};
			return state;
		case types.SET_LOGISTIC_DETAILS:
			state = {
				...state,
				panel: payload.panel,
				framed: payload.framed,
				// height: payload.height,
				// width: payload.width,
				// depth: payload.depth,
				weight: payload.weight,
				packageHeight: payload.packageHeight,
				packageWidth: payload.packageWidth,
				packageDepth: payload.packageDepth,
				packaging: payload.packaging,
				isLogisticsSaved: true,
			};
			return state;

		case types.SET_IV_DETAILS:
			state = {
				...state,
				imagesArray: payload.imagesArray,
				// roomImageArray: payload.roomImageArray || [],
				displayPictureArray: payload.displayPictureArray,
				isIVSaved: true,
			};
			return state;

		case types.SET_FEATURES_DETAILS:
			state = {
				...state,
				productProperties: [],

				isFeaturesSaved: true,
			};
			return state;

		case types.SET_BLOCKCHAIN_DETAILS:
			state = {
				...state,
				isSigningSaved: true,
				ownerEthAddress: payload.ownerEthAddress,
				blockchainPrivateKey: payload.blockchainPrivateKey,
			};
			return state;

		// ======= Get category properties ====== //
		case types.GET_CATEGORY_PROPERTIES_REQUEST:
			state = {
				...state,
				getPropertyLoader: true,
				productProperties: [],
			};
			return state;
		case types.GET_CATEGORY_PROPERTIES_SUCCESS:
			state = {
				...state,
				getPropertyLoader: false,
				productProperties: payload,
			};
			return state;
		case types.GET_CATEGORY_PROPERTIES_FAILURE:
			state = {
				...state,
				getPropertyLoader: false,
				productProperties: [],
			};
			return state;

		// ========== Add Product ========== //
		case types.CREATE_PRODUCT_REQUEST:
			state = {
				...state,
				createProductLoader: true,
				successToast: null,
				errorToast: null,
				productResponse: null,
			};
			return state;

		case types.CREATE_PRODUCT_SUCCESS:
			state = {
				...state,
				createProductLoader: false,
				successToast: "Successfully created a product!",
				productResponse: payload,
				errorToast: null,
			};
			return state;

		case types.CREATE_PRODUCT_FAILURE:
			state = {
				...state,
				createProductLoader: false,
				successToast: null,
				productResponse: error.productResponse || null,
				errorToast: (error && error.message) || error.data || error.statusText || "Something went wrong",
			};
			return state;

		// Clear states
		case types.CLEAR_TOASTS:
			state = {
				...state,
				successToast: null,
				errorToast: null,
			};
			return state;

		case types.CLEAR_ADD_PRODUCT_REDUCER_STATES:
			state = {
				...initialState,
			};
			return state;

		default:
			return state;
	}
};

export default AddProductReducer;
