import Storage from "react-native-storage";
// import AsyncStorage from "@react-native-community/async-storage";
// let storageBackend = AsyncStorage; // TODO: For App

// let storageBackend = window.localStorage; // TODO: For web
let storageBackend = "";
try {
	storageBackend = window.localStorage; // TODO: For web
} catch (e) {
	console.log("trycatch-localstorage", e)
}
const webStorage = new Storage({
	size: 1000,
	storageBackend,
	defaultExpires: null,
	enableCache: true,
	sync: {}
});

class LocalStorage {
	// Save item
	static saveItem(key, data) {
		webStorage.save({
			key: key,
			data: data
		});
	}

	// Get Item
	static getItem = async key => {
		return await webStorage
			.load({
				key: key,
				autoSync: true,
				syncInBackground: true
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				switch (err.name) {
					case "NotFoundError":
						break;
					case "ExpiredError":
						break;
				}
				return null;
			});
	};

	// Remove a single record
	static removeItem(key) {
		webStorage.remove({ key });
	}
}
export default LocalStorage;
