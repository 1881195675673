import createApiReducer from "../util/createApiReducer";
import * as types from "../actions/ActionsTypes";

const initialState = {
    getArtistTypesById: {},
    getArtistTypes: {},
    addArtistTypes: {},
    updateArtistTypes: {},
    deleteArtistTypes: {},
    filterArtistTypes: {},
    filterAdminArtistByTypes: {}
};

const ArtistTypesReducer = createApiReducer(initialState, [
    types.GET_ARTIST_TYPES_BY_ID_REQUEST,
    types.GET_ARTIST_TYPES_REQUEST,
    types.ADD_ARTIST_TYPES_REQUEST,
    types.UPDATE_ARTIST_TYPES_REQUEST,
    types.DELETE_ARTIST_TYPES_REQUEST,
    types.FILTER_ARTIST_BY_TYPES_REQUEST,
    types.FILTER_ADMIN_ARTIST_BY_TYPES_REQUEST,
])
export default ArtistTypesReducer;
