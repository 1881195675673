import { ArtistCollectionService } from "../services/ArtistCollectionService";
import * as types from "./ActionsTypes";

// ========================================== GetArtistCollections ========================================== //
export const getCollectionByArtist = () => {
	return {
		actionType: types.GET_COLLECTION_BY_ARTIST_REQUEST,
		callAPI: () => ArtistCollectionService.getCollectionByArtist(),
		stateObject: "getCollectionByArtist"
	};
};

// ========================================== GetArtistCollections using UserId ========================================== //
export const getCollectionListByUserIdAction = userId => {
	return {
		actionType: types.GET_COLLECTION_BY_ARTIST_REQUEST,
		callAPI: () => ArtistCollectionService.getCollectionListByUserId(userId),
		stateObject: "getCollectionByArtist"
	};
};

// ========================================== AddArtistCollections ========================================== //
export const addCollectionByArtist = collectionData => {
	return {
		actionType: types.ADD_COLLECTION_BY_ARTIST_REQUEST,
		callAPI: () => ArtistCollectionService.addCollectionByArtist(collectionData),
		stateObject: "addCollectionByArtist",
		isTransient: true
	};
};

// ========================================== UpdateArtistCollections ========================================== //
export const updateCollection = (collectionData, collectionId) => {
	return {
		actionType: types.UPDATE_COLLECTION_REQUEST,
		callAPI: () => ArtistCollectionService.updateCollection(collectionData, collectionId),
		stateObject: "updateCollection",
		isTransient: true
	};
};
// ========================================== DeleteArtistCollections ========================================== //
export const deleteCollection = collectionId => {
	return {
		actionType: types.DELETE_COLLECTION_REQUEST,
		callAPI: () => ArtistCollectionService.deleteCollection(collectionId),
		stateObject: "deleteCollection",
		isTransient: true
	};
};
// ========================================== getProductListByArtist ========================================== //
export const filterMyProduct = searchData => {
	return {
		actionType: types.FILTER_MY_PRODUCT_REQUEST,
		callAPI: () => ArtistCollectionService.filterMyProduct(searchData),
		stateObject: "filterMyProduct"
	};
};
// ========================================== getCollection By Id ========================================== //
export const getCollectionById = slugId => {
	return {
		actionType: types.GET_COLLECTION_REQUEST,
		callAPI: () => ArtistCollectionService.getCollection(slugId),
		stateObject: "collection"
	};
};
// ========================================== deleteCollectionItem ========================================== //
export const deleteCollectionItem = (collectionId, collectionItemId) => {
	return {
		actionType: types.DELETE_COLLECTION_ITEM_REQUEST,
		callAPI: () => ArtistCollectionService.deleteCollectionItem(collectionId, collectionItemId),
		stateObject: "deleteCollectionItem",
		isTransient: true
	};
};
// ========================================== Get All Collections ========================================== //
export const getAllCollections = (pageNum, pageSize) => {
	return {
		actionType: types.GET_ALL_COLLECTIONS_REQUEST,
		callAPI: () => ArtistCollectionService.getAllCollections(pageNum, pageSize),
		stateObject: "allCollections"
	};
};
