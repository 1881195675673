import { useDispatch, useSelector } from "react-redux";
import {
	getUserInfoAction,
	followUserAction,
	unfollowUserAction,
	removeFollower,
	getNotifications,
	getCountryListAction,
	createNewArtistAction,
	getUserInfoForMobileAction,
	emailOTPVerificationAction,
	sendContactUsAction,
	toggleBlockchainAnonymousAction
} from "../actions/UserManagementAction";

// userManagementHooks

// ========================================== User Info ========================================== //
export const useGetUserInfo = () => {
	const dispatch = useDispatch();

	// Get user info []
	const getUserInfo = () => {
		dispatch(getUserInfoAction());
	};

	// Reducer states
	const profileData = useSelector(state => state.userManagement.profileData);

	return [getUserInfo, profileData.data, profileData.error, profileData.loading];
};

// ========================================== User Info for mobile ========================================== //
export const useGetUserInfoMobile = () => {
	const dispatch = useDispatch();

	// Get user info []
	const getUserInfoForMobile = () => {
		dispatch(getUserInfoForMobileAction());
	};

	// Reducer states
	const profileMobileData = useSelector(state => state.userManagement.profileMobileData);

	return [getUserInfoForMobile, profileMobileData.data, profileMobileData.error, profileMobileData.loading];
};

// ========================================== Follow User ========================================== //
export const useFollowUser = () => {
	const dispatch = useDispatch();

	const followUser = userId => {
		dispatch(followUserAction(userId));
	};

	const { data, error } = useSelector(state => state.userManagement.followUser);

	return [followUser, data, error];
};

// ========================================== Unfollow user ========================================== //
export const useUnfollowUser = () => {
	const dispatch = useDispatch();

	const unfollowUser = userId => {
		dispatch(unfollowUserAction(userId));
	};

	const { data, error } = useSelector(state => state.userManagement.unfollowUser);

	return [unfollowUser, data, error];
};
// ========================================== Remove Follower ========================================== //
export const useRemoveFollower = () => {
	const dispatch = useDispatch();

	const remove = userId => {
		dispatch(removeFollower(userId));
	};

	const result = useSelector(state => state.userManagement.removeFollower);

	return [remove, result.data, result.error, result.loading];
};

// ========================================== Notifications ========================================== //
export const useGetNotifications = () => {
	const dispatch = useDispatch();

	// Get Notifications
	const beNotified = () => {
		dispatch(getNotifications());
	};

	// Reducer states
	const result = useSelector(state => state.userManagement.notifications);

	return [beNotified, result.data, result.error, result.loading];
};

// ========================================== Country List ========================================== //
export const useGetCountryList = () => {
	const dispatch = useDispatch();

	const getCountryList = () => {
		dispatch(getCountryListAction());
	};
	const { data, error, loading } = useSelector(state => state.userManagement.countryList);

	return [getCountryList, data, error, loading];
};

// ========================================== Create New Artist ========================================== //
export const useCreateNewArtist = () => {
	const dispatch = useDispatch();

	const createNewArtist = body => {
		dispatch(createNewArtistAction(body));
	};
	const { data, error, loading } = useSelector(state => state.userManagement.createNewArtist);

	return [createNewArtist, data, error, loading];
};

// ========================================== Email Otp Verification ========================================== //
export const useEmailOtpVerification = () => {
	const dispatch = useDispatch();

	const emailOtp = (userName, Otp) => {
		dispatch(emailOTPVerificationAction(userName, Otp));
	};
	const { data, error, loading } = useSelector(state => state.userManagement.emailOtpData);

	return [emailOtp, data, error, loading];
};

// ========================================== Send Contactus Message ========================================== //
export const useContactUs = () => {
	const dispatch = useDispatch();

	const contactUs = (data) => {
		dispatch(sendContactUsAction(data));
	};
	const { data, error, loading } = useSelector(state => state.userManagement.contactUsResult);

	return [contactUs, data, error, loading];
};

// ========================================== Toggle Blockchain Anonymous ========================================== //
export const useToggleBlockchainAnonymous = () => {
	const dispatch = useDispatch();

	const toggleBlockchain = (status) => {
		dispatch(toggleBlockchainAnonymousAction(status));
	};
	const { data, error, loading } = useSelector(state => state.userManagement.toggleBlockchainResult);

	return [toggleBlockchain, data, error, loading];
};
