/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { addRoute, removeRoute } from "redux/actions/GeneralActions";
import lodash from "lodash";
const useRoutes = () => {
  const generalReducer = useSelector(state => state.general);
  const dispatch = useDispatch();
  const location = useLocation()
  const add = useCallback((title, path) => {
    console.log("🚀 ~ file: BreadCrumbNav.js ~ line 12 ~ add ~ path", path)
    console.log("🚀 ~ file: BreadCrumbNav.js ~ line 12 ~ add ~ title", title)
    dispatch(addRoute(path, title));
  }, []);

  const remove = (path = location.pathname) => {
    dispatch(removeRoute(path));
  };

  const routes = lodash.get(generalReducer, "routes", {});

  return [routes, add, remove];
};

export default useRoutes;
