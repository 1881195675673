import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	getCategoryList: {},
	productSearch: {},
	artworkPropertyValuesList: {},
	getCategoryDetailsById: {}
};

const CategoryManagementReducer = createApiReducer(initialState, [types.CATEGORY_LIST_REQUEST, types.GET_PRODUCT_SEARCH_REQUEST, types.GET_PROPERTY_VALUES, types.GET_CATEGORY_BY_ID_SLUG_SUCCESS]);

export default CategoryManagementReducer;
