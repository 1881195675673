import React from 'react';
const ImgNextGenBanner = ({
    srcWebp,
    fallback,
    alt,
    ...props
}) => {
    return (
        <>
            <link rel="preload" as="image" href={fallback} />
            <link rel="preload" as="image" href={srcWebp} />

            <picture>
                <source srcSet={srcWebp} type="image/webp" />
                <source srcSet={fallback} type="image/jpeg" />
                <img src={fallback} alt={alt} title={alt} {...props} width="1152" height="648" />
            </picture>
        </>
    );
};
export default ImgNextGenBanner;
