import styled, { css, keyframes } from "styled-components";
import Image from "common/Image";

const FlipHorizontalBottomKeyframe = keyframes`
    0%{transform:rotateX(-90deg)}100%{transform:rotateX(0)};
`;

const FlipHorizontalBottom = css`
	& {
		animation: ${FlipHorizontalBottomKeyframe} 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	}
`;

const ArtworkCardImageWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	background: #f6f6f6 0% 0% no-repeat padding-box;
   width:370px;
   .sold-artwork-style{
	width: 40px;
    height: 22px;
    position: absolute;
    left: -8px;
    top: 15px;
   }
   @media (max-width: 576px) {
		max-width: 100%;
		/* max-width:280px; */
	}
	@media screen and (max-width: 769px) {
		max-width: 100%;
		/* max-width:280px; */
	}
}
`;
const ArtworkCardImageWrapperForArtistList = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	background: #f6f6f6 0% 0% no-repeat padding-box;
	max-width:280px;
   @media (max-width: 576px) {
		/* max-width: 100%; */
		max-width:280px;
	}
	@media screen and (max-width: 769px) {
		/* max-width: 100%; */
		max-width:280px;
	}
}
`;

const ArtworkCardImagePlaceholder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 160px;
	width: 15rem;
	background-color: #eaeaea;
	border-radius: 4px;
	img {
		width: 5rem;
		height: 5rem;
	}
`;

const ArtworkCardImage = styled(Image)`
	position: relative;
	height: 305px;
	max-width: 350px;
	cursor: pointer;
	margin: 30px;
	object-fit: contain;
	/* box-shadow: 11px 11px 6px #00000025; */
	@media (max-width: 576px) {
		/* max-width: 100%; */
		max-width: 300px;
		object-fit: contain;
	}
`;
const ArtworkCardImageNew = styled(Image)`
	width: 90%;
	height: 300px;
	object-fit: contain;
	margin: 5px 15px 10px 0px;
	/* &:hover {
		object-fit: cover;
	} */

	/* position: relative;
	height: 305px;
	max-width: 350px;
	cursor: pointer;
	margin: 30px;
	object-fit: contain; */
	/* box-shadow: 11px 11px 6px #00000025; */
	@media (max-width: 576px) {
		/* max-width: 100%; */
		max-width: 300px;
		object-fit: contain;
	}
`;

const ArtworkCardImageForArtistList = styled(Image)`
	position: relative;
	height: 250px;
	width: 240px;
	cursor: pointer;
	margin: 30px;
	object-fit: contain;
	/* box-shadow: 11px 11px 6px #00000025; */
	@media (max-width: 576px) {
		/* max-width: 100%; */
		max-width: 300px;
		object-fit: contain;
	}
`;

const ArtworkCardOffer = styled.div`
	position: absolute;
	right: 3px;
	top: 3px;
	background-color: #ff0000;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	padding: 1px 7px;
	border-radius: 2px;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
`;

const ArtworkCardActions = styled.div`
	position: absolute;
	display: none;
	height: 26px;
	align-items: center;
	background-color: #fff;
	border-radius: 2rem;
	right: -5px;
	bottom: -25px;
	/* padding: 0 5px; */
	/* box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45); */
	img {
		width: 35px !important;
		/* padding: 0 8.5px; */
		/* border-right: 1px solid #bfbfbf; */
		cursor: pointer;
		&:last-of-type {
			border: none;
		}
	}
`;

const ArtwordCardTitle = styled.p`
	font-size: 15px !important;
	font-weight: 800 !important;
	color: #000000;
	width: ${(props) => {
		return props.customWidth ? props.customWidth + "px" : "70%";
	}};
	margin-top: 6px;
	margin-bottom: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const ArtwordCardDiscount = styled.p`
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #ff0000 !important;

	/* margin-left: 15px; */
	margin-bottom: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
	margin: 2px 5px;
`;

const ArtworkCardFooter = styled.div`
	/* display: flex;
	justify-content: space-between;
	height: 30px;
    align-items: center;
	justify-content: flex-end; */

	display: flex;
	justify-content: space-between;
	height: 30px;
	align-items: center;
	justify-content: space-between;
`;
const ArtworkCardFooterPrice = styled.div`
	display: flex;
	/* justify-content: space-between; */
	height: 30px;
	justify-content: flex-start;
`;
const ArtworkCardArtist = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: inherit;
	img {
		width: 13px !important;
		height: 13px;
		margin-right: 3px;
		position: relative;
		left: -1.5px;
	}
	p {
		font-size: 14px !important;
		color: black;
		font-weight: 200;
		margin-bottom: 0;
		${FlipHorizontalBottom}
		overflow: hidden;
		letter-spacing: 0px;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 180px;
		min-width: 100px;
		width: 10rem;
		font-weight: normal !important;
		font-family: "Lato" !important;
	}
`;

const ProfileIcon = styled.img`
	object-fit: unset;
`;

const ArtworkCardPrice = styled.p`
	font-size: 13px !important;
	color: #000;
	font-weight: 400 !important;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100px;
`;

const FollowButtonWrapper = styled.div`
	display: none;
`;
const FollowButton = styled.button`
	/* display: none; */
	border: none;
	background: none;
	/* font-size: 12px; */
	color: #0071d4;
	/* box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45); */
	font: normal normal 200 13px/59px Cormorant Garamond;
	border-radius: 10px;
	padding: 3px auto;
	cursor: pointer;
	outline: none;
	justify-content: center;
	.anticon {
		margin-right: 5px;
	}
`;

const ArtworkCardWrapper = styled.div`
	max-width: 385px;
	cursor: default;
	margin: ${(props) => props.margin || 0};
	&:hover {
		${ArtwordCardTitle} {
			color: #d2502d;
		}
		${ArtworkCardActions} {
			display: flex;
			${FlipHorizontalBottom}
		}
		/* ${ArtworkCardArtist} {
			p {
				display: ${(props) => (props.hideNameOnHover ? "none" : "inline")};
			}
		} */
		${FollowButtonWrapper} {
			display: flex;
			justify-content: flex-start;
			width: 200px;
			height: 100%;
			max-width: 180px;
			min-width: 100px;
		}
		${FollowButton} {
			display: flex;
			align-items: center;
			${FlipHorizontalBottom}
		}
	}
	@media screen and (max-width: 917px) {
		width: 330px;
	}
`;
const ArtworkCardWrapperForArtistList = styled.div`
	max-width: 280px;
	max-height: 280px;
	cursor: default;
	margin: ${(props) => props.margin || 0};
	&:hover {
		${ArtwordCardTitle} {
			color: #d2502d;
		}
		${ArtworkCardActions} {
			display: flex;
			${FlipHorizontalBottom}
		}
		/* ${ArtworkCardArtist} {
			p {
				display: ${(props) => (props.hideNameOnHover ? "none" : "inline")};
			}
		} */
		${FollowButtonWrapper} {
			display: flex;
			justify-content: flex-start;
			width: 200px;
			height: 100%;
			max-width: 180px;
			min-width: 100px;
		}
		${FollowButton} {
			display: flex;
			align-items: center;
			${FlipHorizontalBottom}
		}
	}
	@media screen and (max-width: 917px) {
		width: 330px;
	}
`;

export {
	ArtworkCardWrapper,
	ArtworkCardImageWrapper,
	ArtworkCardImagePlaceholder,
	ArtworkCardImage,
	ArtworkCardOffer,
	ArtworkCardActions,
	ArtwordCardTitle,
	ArtworkCardFooter,
	ArtworkCardArtist,
	ArtworkCardPrice,
	FollowButtonWrapper,
	FollowButton,
	ProfileIcon,
	ArtwordCardDiscount,
	ArtworkCardFooterPrice,
	ArtworkCardImageForArtistList,
	ArtworkCardImageWrapperForArtistList,
	ArtworkCardWrapperForArtistList,
	ArtworkCardImageNew,
};
