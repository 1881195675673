import axios from "axios";
import { APIUrlConstants } from "./APIConstants";
import LocalStorage from "./LocalStorageUtility";
import UserManagement from "./UserManagement";
import createAuthRefreshInterceptor from "./RefresTokenInterceptor";
import { requestInterceptor } from "../containers/RequestInterceptor";
// import { UserManagementService } from "../services/UserManagementService";

const refreshAuthLogic = async failedRequest => {
	console.log("Refresh token failedRequest: ");
	const refreshToken = await UserManagement.getRefreshToken();
	let response = null;

	const params = { refreshToken: refreshToken };
	// const headers = { Authorization: `Bearer ${await LocalStorage.getItem("authToken")}` };
	return axios
		.post(`${APIUrlConstants.BASE_WEB_URL}UserManagment/api/UserManagement/LoginRefreshToken`, {}, { params })
		.then(tokenRefreshResponse => {
			console.log("Refresh token response: ", tokenRefreshResponse);
			const token = tokenRefreshResponse.data.data.access_token;
			const refreshToken = tokenRefreshResponse.data.data.refresh_token;
			console.log("token: ", token);
			UserManagement.saveAuthToken(token);
			UserManagement.refreshToken(refreshToken);
			failedRequest.response.config.headers["Authentication"] = "Bearer " + token;
			return Promise.resolve();
		});
};

// createAuthRefreshInterceptor(axios, refreshAuthLogic);
// requestInterceptor(axios);
//Add a response interceptor


axios.interceptors.response.use((response) => {
	console.log("axios.interceptors.response-response-35", response)
	return response
},
	async function (error) {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {

			originalRequest._retry = true;
			const refreshToken = await UserManagement.getRefreshToken();
			const params = { refreshToken: refreshToken };
			console.log("refreshAuthLogic -> refreshToken->ApiService-ln49", refreshToken)
			let response = null;
			return axios.post(`${APIUrlConstants.BASE_WEB_URL + APIUrlConstants.API_URLS.loginRefreshToken}`, {}, { params }).then((tokenRefreshResponse) => {
				console.log("tokenRefreshResponse", tokenRefreshResponse);
				console.log("tokenRefreshResponse.data.result", tokenRefreshResponse.data.result)

				if (tokenRefreshResponse.data.result === 200) {
					// 1) put token to LocalStorage
					const token = tokenRefreshResponse.data.data.access_token;
					const refreshToken = tokenRefreshResponse.data.data.refresh_token;
					UserManagement.saveAuthToken(token);
					UserManagement.refreshToken(refreshToken);
					//Firts Time Set Grace period
					UserManagement.saveGracePeriod();

					// 2) Change Authorization header
					// axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

					originalRequest.headers["Authorization"] = 'Bearer ' + token;

					// 3) return originalRequest object with Axios.
					return axios(originalRequest);
				} else if (tokenRefreshResponse.data.result === 401) {
					UserManagement.logout();
					return Promise.reject(error);
				}
			}).catch(error => {
				console.log("axios.interceptors.response->error-ln71", error)

			});
		}
	});
export class APIService {
	/**
	 * NOTES
	 * @param urlConstant String used to refer the endpoint value
	 * @param body Object that takes the request body as json/formdata
	 * @param params Object that contains the parameters to pass through URL
	 * @param urlDirectParams String to append after url
	 * @param headers Object that contains the headers of request
	 * @param isAuthorizedAPI Boolean that indicate if request should pass authorization token or not
	 * @returns Object {success: true|false, status, statusText, data}
	 */

	// ------------------------------------- GET ------------------------------------- //
	static async doGet(urlConstant, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fullUrl) {
		let url = APIUrlConstants.getApiUrl(urlConstant) + urlDirectParams;
		if (fullUrl) {
			url = fullUrl;
		}

		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		try {
			console.log("headers", headers);
			let response = await axios.get(url, {
				params, headers,
				validateStatus: (status) => {
					return status < 500; // Reject only if the status code is greater than or equal to 500
				}
			});
			const { status, statusText, data } = response;
			return { success: true, status, statusText, data };
		} catch (error) {
			if (error && error.response) {
				const { status, statusText, data } = error.response;
				return { success: false, status, statusText, data };
			} else {
				return {
					success: false,
					status: 500,
					statusText: "Something went wrong",
					data: null
				};
			}
		}
	}

	static async Get({ fullUrl, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		return APIService.doGet("", params, urlDirectParams, headers, isAuthorizedAPI, url);
	}

	// ------------------------------------- POST ------------------------------------- //
	static async doPost(urlConstant, body, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fullUrl) {
		let url = APIUrlConstants.getApiUrl(urlConstant) + urlDirectParams;
		if (fullUrl) {
			url = fullUrl;
		}
		console.log("Post url", url);
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;

		try {
			console.log("headers", headers);
			let response = await axios.post(url, body, {
				params: params,
				headers: headers
			});
			const { status, statusText, data } = response;
			return { success: true, status, statusText, data };
		} catch (error) {
			console.log("Post error", error);
			if (error && error.response) {
				const { status, statusText, data } = error.response;

				return { success: false, status, statusText, data };
			} else {
				return {
					success: false,
					status: 500,
					statusText: "Something went wrong",
					data: null
				};
			}
		}
	}

	static async Post({ fullUrl, body = {}, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		return APIService.doPost("", body, params, urlDirectParams, headers, isAuthorizedAPI, url);
	}

	// ------------------------------------- PUT ------------------------------------- //
	static async doPut(urlConstant, body, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fullUrl) {
		let url = APIUrlConstants.getApiUrl(urlConstant) + urlDirectParams;
		if (fullUrl) {
			url = fullUrl;
		}
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		try {
			console.log("headers", headers);
			let response = await axios.put(url, body, { params, headers });

			const { status, statusText, data } = response;
			return { success: true, status, statusText, data };
		} catch (error) {
			if (error && error.response) {
				const { status, statusText, data } = error.response;

				return { success: false, status, statusText, data };
			} else {
				return {
					success: false,
					status: 500,
					statusText: "Something went wrong",
					data: null
				};
			}
		}
	}

	static async Put({ fullUrl, body = {}, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		return APIService.doPut("", body, params, urlDirectParams, headers, isAuthorizedAPI, url);
	}

	// ------------------------------------- DELETE ------------------------------------- //
	static async doDelete(urlConstant, body, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fullUrl) {
		let url = APIUrlConstants.getApiUrl(urlConstant) + urlDirectParams;
		if (fullUrl) {
			url = fullUrl;
		}
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		try {
			console.log("headers", headers);
			let response = await axios.delete(url, { params, headers, data: body });

			const { status, statusText, data } = response;
			return { success: true, status, statusText, data };
		} catch (error) {
			if (error && error.response) {
				const { status, statusText, data } = error.response;

				return { success: false, status, statusText, data };
			} else {
				return {
					success: false,
					status: 500,
					statusText: "Something went wrong",
					data: null
				};
			}
		}
	}

	static async Delete({ fullUrl, body = {}, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		return APIService.doDelete("", body, params, urlDirectParams, headers, isAuthorizedAPI, url);
	}

	// ------------------------------------- MULTIPLE ------------------------------------- //
	/**
	 * MULTIPLE CONCURRENT REQUESTS
	 * @param requestsArray Array of requests to call concurrently
	 */
	static doMultipleConcurrentRequests(requestsArray) {
		axios.all(requestsArray);
	}

	// ------------------------------------- POST [FORMDATA] ------------------------------------- //
	static async doPostMultipart(urlConstant, body, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fullUrl) {
		let url = APIUrlConstants.getApiUrl(urlConstant) + urlDirectParams;
		if (fullUrl) {
			url = fullUrl;
		}
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		try {
			console.log("headers", headers);
			let response = await axios({
				method: "post",
				url,
				data: body,
				params,
				headers: { ...headers, "Content-Types": "multipart/form-data" }
			});

			const { status, statusText, data } = response;
			return { success: true, status, statusText, data };
		} catch (error) {
			if (error && error.response) {
				const { status, statusText, data } = error.response;

				return { success: false, status, statusText, data };
			} else {
				return {
					success: false,
					status: 500,
					statusText: "Something went wrong",
					data: null
				};
			}
		}
	}

	static async PostMultipart({ fullUrl, body = {}, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		return APIService.doPostMultipart("", body, params, urlDirectParams, headers, isAuthorizedAPI, url);
	}
	// ------------------------------------- PUT [FORMDATA] ------------------------------------- //
	static async doPutMultipart(urlConstant, body, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fullUrl) {
		let url = APIUrlConstants.getApiUrl(urlConstant) + urlDirectParams;
		if (fullUrl) {
			url = fullUrl;
		}
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		try {
			console.log("headers", headers);
			let response = await axios({
				method: "put",
				url,
				data: body,
				params,
				headers: { ...headers, "Content-Types": "multipart/form-data" }
			});

			const { status, statusText, data } = response;
			return { success: true, status, statusText, data };
		} catch (error) {
			if (error && error.response) {
				const { status, statusText, data } = error.response;

				return { success: false, status, statusText, data };
			} else {
				return {
					success: false,
					status: 500,
					statusText: "Something went wrong",
					data: null
				};
			}
		}
	}

	static async PutMultipart({ fullUrl, body = {}, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		return APIService.doPutMultipart("", body, params, urlDirectParams, headers, isAuthorizedAPI, url);
	}

	// ------------------------------------- DOWNLOAD ------------------------------------- //
	static async doDownload({ fullUrl, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		axios({
			method: "GET",
			url,
			params,
			headers: { ...headers },
			responseType: "blob"
		}).then(
			resp => {
				const url = window.URL.createObjectURL(new Blob([resp.data]));
				const link = document.createElement("a");
				link.href = url;
				link.download = "Bulk_Upload_Form_Download.xlsx";
				// link.setAttribute("download", "file.pdf");
				document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
				link.click();
				link.remove(); //afterwards we remove the element again

				// RESPONSE
				const { status, statusText, data } = resp;
				return { success: true, status, statusText, data };
			},
			error => {
				if (error && error.response) {
					const { status, statusText, data } = error.response;

					return { success: false, status, statusText, data };
				} else {
					return {
						success: false,
						status: 500,
						statusText: "Something went wrong",
						data: null
					};
				}
			}
		);
	}
	// ------------------------------------- PDFDOWNLOAD ------------------------------------- //
	static async doPDFDownload({ fullUrl, params = {}, urlDirectParams = "", headers = {}, isAuthorizedAPI = true, fileName }) {
		let url = APIUrlConstants.getApiFullUrl(fullUrl, urlDirectParams);
		if (isAuthorizedAPI)
			if (await LocalStorage.getItem("authToken"))
				headers["Authorization"] = `Bearer ${await LocalStorage.getItem("authToken")}`;
		axios({
			method: "GET",
			url,
			params,
			headers: { ...headers },
			responseType: "blob"
		}).then(
			resp => {
				const url = window.URL.createObjectURL(new Blob([resp.data]));
				const link = document.createElement("a");
				link.href = url;
				link.download = `${fileName}_${new Date().toLocaleString()}.pdf`;
				// link.setAttribute("download", "file.pdf");
				document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
				link.click();
				link.remove(); //afterwards we remove the element again

				// RESPONSE
				const { status, statusText, data } = resp;
				return { success: true, status, statusText, data };
			},
			error => {
				if (error && error.response) {
					const { status, statusText, data } = error.response;

					return { success: false, status, statusText, data };
				} else {
					return {
						success: false,
						status: 500,
						statusText: "Something went wrong",
						data: null
					};
				}
			}
		);
	}
	// --------------------- ERROR NOTES --------------------- //
	// NOTE: async/await is part of ECMAScript 2017 and is not supported in Internet Explorer and older browsers, so use with caution.

	// 1xx – informational
	// 2xx – successful
	// 3xx – redirection
	// 4xx – client error
	// 5xx – server error

	// 400	Bad Request
	// 401	Not Authorized
	// 403	Forbidden
	// 404	Page/ Resource Not Found
	// 405	Method Not Allowed
	// 415	Unsupported Media Type
	// 500	Internal Server Error
}
