import styled from "styled-components";
import { Result } from "antd";


const ProfileDropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 0px;

	a {
		cursor: pointer;
		font-weight: 500;
		padding: 10px 0 10px 0;
		color: #fff;
		margin: 0 16px;
		border-bottom: 1px solid #6d6d6d;
	}

	a:last-child {
		background-color: #e8e8e8;
		color: #000;
		margin: 0;
		padding: 10px 16px 10px 16px;
		border-bottom: none;
	}

	a:hover {
		color: #ad9972;
	}
`;
const LogoutPopWrapper = styled(Result)`
&.ant-result {
    padding: 30px 30px;
}
.ant-result-title {
    font-size: 20px;
    line-height: unset;
    text-align: center;
    font-weight: 600;
}
.ant-result-icon {
    margin-bottom: 15px;
    text-align: center;
}
.ant-result-icon > .anticon {
    font-size: 50px;
}
.ant-result-extra {
    margin-top: 15px;
    text-align: center;
}
.ant-btn{
	margin: 0 5px;
}
`;

export { ProfileDropdownWrapper, LogoutPopWrapper };
