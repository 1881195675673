import { KYCDocumentService } from "../services/KYCDocumentService";
import * as types from "./ActionsTypes";

// ========================================== Update Or Add Kyc Document ========================================== //
export const UpdateKycDocumentAction = body => {
	return {
		actionType: types.UPDATE_KYC_DOCUMENT,
		callAPI: () => KYCDocumentService.updateKycDocument(body),
        stateObject: "updateKycDocumentsResult",
        isTransient: true
	};
};




// ========================================== GET OWN KYC ========================================== //
export const GetOwnKycDocuments = () => {
	return {
		actionType: types.GET_OWN_KYC,
		callAPI: () => KYCDocumentService.getOwnKycDocument(),
        stateObject: "ownKycDocumentResult",
        isTransient: true
	};
};