import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryListAction, getProductSearchAction, getAllArtWorkPropertyAction, getCategoryBySlugAndIdAction } from "../actions/CategoryManagementAction";

// ========================================== Get Category List ========================================== //
export const useGetCategory = () => {
	const dispatch = useDispatch();

	// Get Category List
	const getCategoryList = parentId => {
		dispatch(getCategoryListAction(parentId));
	};

	// Reducer states
	const { data, error, loading } = useSelector(state => state.CategoryManagement.getCategoryList);
	return [getCategoryList, data, error, loading];
};


export const useGetCategoryById = () => {
	const dispatch = useDispatch();
	const getCategoryById = (slugId, categoryId) => {
		dispatch(getCategoryBySlugAndIdAction(slugId, categoryId))
	}
	const { data, error, loading } = useSelector(state => state.CategoryManagement.getCategoryDetailsById);
	return [getCategoryById, data, error, loading];

}
// ========================================== Search Product ========================================== //
export const useGetProductSearch = () => {
	const dispatch = useDispatch();

	// Get Product List
	// Body is a json object
	const getProductSearch = body => {
		dispatch(getProductSearchAction(body));
	};

	// Reducer states
	const { data, error, loading } = useSelector(state => state.CategoryManagement.productSearch);
	return [getProductSearch, data, error, loading];
};


// ========================================== Get Category List ========================================== //
export const useGetArtworkPropertyValues = () => {
	const dispatch = useDispatch();

	// Get  List
	const getArtworkPropertyValues = body => {
		dispatch(getAllArtWorkPropertyAction(body));
	};

	// Reducer states
	const { data, error, loading } = useSelector(state => state.CategoryManagement.artworkPropertyValuesList);
	return [getArtworkPropertyValues, data, error, loading];
};