import Web3 from "web3";
import Web3Modal from "web3modal";
import Torus from "@toruslabs/torus-embed";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import toast from "common/Toast";
import { isNil } from "lodash";
import { toastMessage } from "./toastMessages";

let web3;
let address;
let provider;
//let walletBalance;

const providerOptions = {
	// portis: {
	// 	package: Portis, // required
	// 	options: {
	// 		id: "607c5a9b-d346-4706-93ad-23f83b6dfdf8", // required
	// 	},
	// },
	// fortmatic: {
	// 	package: Fortmatic, // required
	// 	options: {
	// 		key: "pk_live_3F2699AA8CD2B203", // required
	// 	},
	// },
	// walletconnect: {
	// 	package: WalletConnectProvider, // required
	// 	options: {
	// 		infuraId: "546c9905f15944eb99ebca13b2025270", // required
	// 	},
	// },
	// torus: {
	// 	package: Torus,
	// },
};

export const connectWallet = async () => {
	try {
		if (isNil(window.ethereum)) {
			toast(toastMessage.metamaskNotInstalled, "error");
			return;
		} else if (!window.ethereum.isMetaMask) {
			toast(toastMessage.metamaskNotInstalled, "error");
			return;
		}
		provider = await web3Modal.connect();

		console.log("🚀 ~ file: walletCommonFunctions.js ~ line 35 ~ connectWal ~ provider", provider);
		web3 = new Web3(provider);
		let accounts = await web3.eth.getAccounts();
		address = accounts[0];

		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log("Account" + accounts);
			address = accounts[0];
			localStorage.setItem("WALLET_ADDRESS", address);
		});

		// walletBalance = await web3.eth.getBalance(accounts[0]);
		// localStorage.setItem('blockchainAddress', address)
		// localStorage.setItem('walletBalance', walletBalance);
		// console.log(web3.utils.fromWei(walletBalance, "ether"));

		return address;
	} catch (err) {
		console.log("🚀 ~ file: walletCommonFunction.js ~ line 48 ~ connectWal ~ err", err);
	}
};
export const getWeb3 = async () => {
	if (web3 !== null) {
		return web3;
	} else {
		return null;
	}
};
export const getProvider = async () => {
	if (provider !== null) {
		return provider;
	} else {
		return null;
	}
};
export const getWalletName = () => {
	if (localStorage.getItem("isCustomeWalletConnected") === "true") {
		return "Torus";
	} else if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
		let walletName = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER");
		walletName = walletName.replace(/['"]+/g, "");
		switch (walletName) {
			case "injected":
				return "Metamask";
				break;
			case "torus":
				return "Torus";
				break;
			case "portis":
				return "Portis";
				break;
			case "fortmatic":
				return "Fortmatic";
				break;
			case "walletconnect":
				return provider.walletMeta ? provider.walletMeta.description : "Wallet Connect";
				break;
			default:
				return "";
		}
	} else {
		return "No Wallet Connected";
	}
};
export const disconnectWallet = async () => {
	if (provider != null && provider.close) {
		await provider.close();
	}

	await web3Modal.clearCachedProvider();
	provider = null;
	web3 = null;

	console.log(web3Modal);
	localStorage.removeItem("blockchainAddress");
	console.log("Disconnected...");
};

export const signWithWallet = async (message) => {
	console.log("🚀 ~ file: walletCommonFunction.js ~ line 63 ~ signWithWal ~ message", message);
	try {
		if (web3 === null || web3 === undefined) {
			console.log("Connecting Wallet..");
			await connectWallet();
		}
		console.log("Connecting Wallet not required...");

		// encode message (hex)
		const hexMsg = message;
		//let address;

		const result = await web3.eth.personal.sign(hexMsg, address);
		console.log(result);
		return result;
	} catch (error) {
		console.log("🚀 ~ file: walletCommonFunction.js ~ line 86 ~ signWithWal ~ error", error);

		if (error.code) {
			console.log("error code+", error.code);
			return error;
		} else {
			console.log("error object");
			let errObject = {
				code: 4001,
				message: String(error),
			};
			return errObject;
		}
	}
};

export const hashForAddArtwork = async (artId) => {
	if (web3 === null || web3 === undefined) {
		await connectWallet();
	}
	let messageHash = web3.utils.soliditySha3(artId);
	return { messageHash: messageHash };
};

export const hashForOwnershipTransfer = async (tokenId, address) => {
	if (web3 === null || web3 === undefined) {
		await connectWallet();
	}
	let messageHash = web3.utils.soliditySha3(tokenId, address);
	return messageHash;
};

export const paddingForOwnershipTransfer = async (rfid) => {
	if (web3 === null || web3 === undefined) {
		await connectWallet();
	}
	// let rfIdStringPadd = web3.utils.rightPad(rfid, 32, " ");
	//let messageHash = web3.utils.soliditySha3(rfIdStringPadd);
	// return rfIdStringPadd;
	let rfIdStringPadd = web3.utils.rightPad(rfid, 32, " ");
	//let messageHash = web3.utils.soliditySha3(rfIdStringPadd);
	return rfIdStringPadd;
};

export const validateSignature = async (expectedEthAddress, message, signature) => {
	const signerAddress = await web3.eth.personal.ecRecover(message, signature);
	if (signerAddress === expectedEthAddress.toLowerCase()) {
		return true;
	}
	return false;
};

export const web3Modal = new Web3Modal({
	network: "mainnet", // optional
	cacheProvider: true, // optional
	providerOptions, // requiredd
});
