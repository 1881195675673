import * as types from "./ActionsTypes";
import { APIService } from "../util/APIService";
import UserManagement from "../util/UserManagement";

const refreshAuthLogic = async () => {
    const resp = await APIService.doGet("validateToken");
    if (resp) {
        return resp;
    }
};

// ============================= Refresh token action ============================= //
export const refreshTokenAction = () => {
    return async dispatch => {
        dispatch(request());
        await refreshAuthLogic().then(
            response => {
                if (response.status === 200) {
                    dispatch(success(response));
                } else if (response.status === 401) {
                    dispatch(failure(response));
                    UserManagement.logout();
                }
            },
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: types.REFRESH_TOKEN_DATA_REQUEST };
    }
    function success(payload) {
        return { type: types.REFRESH_TOKEN_DATA_SUCCESS, payload };
    }
    function failure(error) {
        return { type: types.REFRESH_TOKEN_DATA_FAILURE, error };
    }
};

export const requestErrorInterception = (err = null) => {
    return dispatch => {
        dispatch(request(err));
        dispatch(request(null));
    };
    function request(error) {
        return { type: types.REQUEST_ERROR_INTERCEPT, error };
    }
}
