import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MainTopWrapper, LanguageWrapper, SocialMediaWrapper, NotificationWrapper, AccountIconWrapper, FullWidthRow, SBadge } from "./TopMenu.styled";
import { Col } from "antd";
import assets from "assets";
import "./TopMenu.style.css";
import { Link } from "react-router-dom";
import SDK, { LoginHooks, userManagementHooks } from "ecommerce-sdk/src/index";
import { Popover } from "antd";
import { isEmpty, isUndefined } from "lodash";
import toast from "common/Toast";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";
import { useTranslation } from "react-i18next";
import lodash from "lodash";

export const TopMenu = ({ onIconClick, loggedData, isLoggedIn, handleVisibleChange }) => {
	const { useGetUserInfo } = userManagementHooks;
	const { useLogout } = LoginHooks;

	const [getUserInfo, userStates] = useGetUserInfo();
	const [logoutUser, logoutData, logoutError] = useLogout();
	const [sellerType, setSellerType] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (isEmpty(userStates) && isLoggedIn) {
			getUserInfo();
		}
		SDK.UserManagement.getUserRoles((result) => {
			const isSeller = lodash.includes(result, "GALLERY") || lodash.includes(result, "ARTIST");
			if (isSeller) {
				setSellerType(lodash.includes(result, "ARTIST") ? "ARTIST" : "GALLERY");
			}
		});
	}, []);

	useEffect(() => {
		if (logoutError) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		} else if (logoutData) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		}
	}, [logoutData, logoutError]);
	const onLogout = async () => {
		let token = await SDK.UserManagement.getAuthToken();

		logoutUser(token);
		toast(t(toastMessage.loggedOut), "success");
	};
	return (
		<MainTopWrapper>
			<FullWidthRow>
				<div className="top-first-content-wrap">
					<span>$ USD</span>
					<LanguageWrapper>
						<img src={assets.images.language} alt="Language Icon" title="Language Icon" width="19" height="19" />
						<span>ENG</span>
					</LanguageWrapper>

					<span> Follow us on</span>
					<SocialMediaWrapper>
						<a href="https://www.facebook.com/RtistIQ.ART/" rel="noopener noreferrer" target="_blank">
							{" "}
							<img src={assets.images.fb_white} alt="Social Media Icon" title="Facebook" width="15" height="15" />
						</a>
						<a href="https://www.instagram.com/rtistiq.art/" rel="noopener noreferrer" target="_blank">
							{" "}
							<img src={assets.images.insta_white} alt="Social Media Icon" title="Instagram" width="15" height="15" />
						</a>
						<a href="https://twitter.com/rtistiq_art" rel="noopener noreferrer" target="_blank">
							{" "}
							<img src={assets.images.twiter_white} alt="Social Media Icon" title="Twitter" width="15" height="15" />
						</a>
						<a href="https://www.linkedin.com/company/rtistiq" rel="noopener noreferrer" target="_blank">
							{" "}
							<img src={assets.images.linkedin_white} alt="Social Media Icon" title="Linkein" width="15" height="15" />
						</a>
						<a href="https://www.reddit.com/r/rtistiq/" rel="noopener noreferrer" target="_blank">
							{" "}
							<img src={assets.images.redit_white} alt="Social Media Icon" title="Reddit" width="15" height="15" />
						</a>
						<a href="https://discord.com/invite/WPyYZcvdCs" rel="noopener noreferrer" target="_blank">
							{" "}
							<img src={assets.images.dicrod_white} alt="Social Media Icon" title="Discord" width="15" height="15" />
						</a>
					</SocialMediaWrapper>
				</div>
				<div className="top-second-content-wrap">
					<NotificationWrapper>
						<Link to="/sellers" className="buyer-link">
							Artist
						</Link>
						<Link to="/collector" className="buyer-link">
							Collector
						</Link>
						<Link to="/about-us" className="buyer-link">
							About Us
						</Link>
					</NotificationWrapper>
					<AccountIconWrapper width={isLoggedIn ? 140 : 145}>
						<img src={assets.images.profile_white} alt="Profile Icon" title="Profile Icon" width="25" height="15" />
						{isLoggedIn ? (
							<>
								<Link to={sellerType === "ARTIST" ? "/artist/dashboard" : "/profile/genarel"}>
									<span>{userStates && userStates.name}</span>
								</Link>
								<img
									src={assets.images.logout}
									alt="Logout btn"
									className="logout-btn"
									width="15"
									height="15"
									onClick={() => onLogout()}
								/>
								{/* <Popover
									content={loggedData.popoverContent}
									trigger="hover"
									overlayClassName="profile-popover"
									placement="bottom"
									onClick={() => onIconClick("profile")}
									visible={loggedData.isVisible}
									defaultVisible={false}
									onVisibleChange={handleVisibleChange}
								>
									<span>{userStates && userStates.name}</span>
									<img src={assets.images.down_arrow_white} className="profile-down-arrow" alt="arrowdown" />
								</Popover> */}
							</>
						) : (
							<>
								<Link to="#" onClick={() => onIconClick("login")}>
									{" "}
									<span>Login</span>
								</Link>
								<div className="vertical-line"></div>
								<Link to="#" onClick={() => onIconClick("register")}>
									{" "}
									<span>Register</span>
								</Link>
							</>
						)}
					</AccountIconWrapper>
				</div>
			</FullWidthRow>
		</MainTopWrapper>
	);
};

export default TopMenu;
