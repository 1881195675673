import { BulkUploadService } from "../services/BulkUploadService";
import * as types from "./ActionsTypes";

// ========================================== Bulk Upload ========================================== //
export const bulkUploadAction = (body) => {
	return {
		actionType: types.BULK_UPLOAD_REQUEST,
		callAPI: () => BulkUploadService.bulkUpload(body),
		stateObject: "bulkUploadResult",
		isTransient: true,
	};
};

// ========================================== Bulk Form Download ========================================== //
export const bulkFormDownloadAction = () => {
	return {
		actionType: types.BULK_FORM_DOWNLOAD_REQUEST,
		callAPI: () => BulkUploadService.bulkFormDownload(),
		stateObject: "bulkFormDownload",
		isTransient: true,
	};
};
