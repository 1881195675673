import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getCartItems,
	onDeleteFromCart,
	onAddToCart
} from "../actions/CartManagementAction";

// ========================================== Get Cart Items ========================================== //
export const useGetCart = () => {
	const dispatch = useDispatch();
	// Get user info []
	const getCartList = (currency) => {
		dispatch(getCartItems(currency));
	};

	// Reducer states
	const { data, error, loading } = useSelector(
		state => state.cartManagement.getCartItem
	);

	return [getCartList, data, error, loading];
};

// ========================================== Delete Cart Item ========================================== //
export const useDeleteCart = () => {
	const dispatch = useDispatch();
	const doDeleteFromCart = id => {
		dispatch(onDeleteFromCart(id));
	};

	// Reducer states
	const { data, error } = useSelector(state => state.cartManagement.deleteCart);
	return [doDeleteFromCart, data, error];
};

// ========================================== Add to Cart ========================================== //
export const useAddToCart = () => {
	const dispatch = useDispatch();
	// Add item to Cart
	const addToCart = body => {
		dispatch(onAddToCart(body));
	};

	// Reducer states
	const { data, error, loading } = useSelector(state => state.cartManagement.addToCart);
	return [addToCart, data, error, loading];
};
