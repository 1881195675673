import React, { useState, useEffect } from "react";
import { Dropdown, Icon } from "antd";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { DropdownContent } from "styled/home.styled";
import {
	MenuItem,
	ArtworkDropdownWrapper,
	ArtworkDropdownMainWrapper,
	ArtworkDropdownSubWrapper,
	ArtworkDropdownLink,
	ArtworkDropdownMainItem,
	ArtworkDropdownSubItem,
	ArtworkDropdownSubItemTitle,
	MainArtworkDropdownLink,
	MainArtworkDropdownMainTitile,
} from "common/Navbar/NavbarMenu/NavbarMenu.styled";
import { categoryHooks } from "ecommerce-sdk/src/index";
import { useTranslation } from "react-i18next";

const ArtworkDropDown = ({ history }) => {
	const { useGetCategory } = categoryHooks;
	const { t } = useTranslation();
	const [getCategoryList, getCategoryListResult, getCategoryListError, getCategoryListLoader] = useGetCategory();

	const [mainCategoryList, setMainCategoryList] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [subCategoryTitle, setSubCategoryTitle] = useState("");
	const [mainCategoryPath, setMainCategoryPath] = useState(null);

	useEffect(() => {
		// if (isEmpty(getCategoryListResult) && !getCategoryListLoader) {
		// 	getCategoryList();
		// }
	}, []);

	// =========================== Create category array =========================== //
	useEffect(() => {
		if (getCategoryListResult) {
			let list = getCategoryListResult.map((data) => {
				return {
					_id: data._id,
					slugPath: data.slugPath,
					categoryName: data.categoryName,
					subCategories: data.subCategories,
					description: data.description,
					filterURL: data.filterURL,
					filterObject: data.filterObject,
				};
			});

			setMainCategoryList(list);
		}
	}, [getCategoryListResult, getCategoryListError]);

	// =========================== On click/hover item =========================== //
	const onHoverCategoryItem = (data) => {
		setSubCategoryList(data.subCategories);
		setSubCategoryTitle(data.categoryName);
		setMainCategoryPath(data.slugPath);
	};

	const onClickCategoryItem = (data) => {
		console.log("🚀 ~ file: ArtworkDropDown.js ~ line 66 ~ ArtworkDropDown ~ data", data);
		if (data.slugPath)
			// window.location.href = `/artworks/${data.filterURL !== null ? data.filterURL : data.slugPath}`;
			history.push({
				pathname: `/artworks/${data.slugPath}`,
				state: { filterObject: data.filterObject },
			});
	};

	// =========================== Main Category =========================== //
	const renderMainCategoryList = mainCategoryList.map((item, index) => (
		<ArtworkDropdownMainItem
			key={index}
			onMouseOver={() => {
				onHoverCategoryItem(item);
			}}
			onClick={() => {
				onClickCategoryItem(item);
			}}
		>
			{item.categoryName}
		</ArtworkDropdownMainItem>
	));

	// =========================== Sub category =========================== //
	const renderSubCategoryList =
		!isEmpty(subCategoryList) &&
		subCategoryList.map((item, index) => (
			<ArtworkDropdownSubItem
				key={index}
				onClick={() => {
					onClickCategoryItem(item);
				}}
			>
				{item.categoryName}
			</ArtworkDropdownSubItem>
		));

	// =========================== Overlay =========================== //
	const menu = (
		<DropdownContent width="auto">
			<ArtworkDropdownWrapper>
				{mainCategoryList.map((item, index) => (
					<ArtworkDropdownSubWrapper>
						<MainArtworkDropdownMainTitile>{item.categoryName}</MainArtworkDropdownMainTitile>
						{/* <ArtworkDropdownSubItem
							key={index}
							onClick={() => {
								onClickCategoryItem(item);
							}}
						>
							{`Most Popuplar ${item.categoryName}`}
						</ArtworkDropdownSubItem> */}

						{item.subCategories.map((item, index) => (
							<ArtworkDropdownSubItem
								key={index}
								onClick={() => {
									onClickCategoryItem(item);
								}}
							>
								{item.categoryName}
							</ArtworkDropdownSubItem>
						))}

						<ArtworkDropdownLink to={`/artworks/${item.slugPath}`}>View All</ArtworkDropdownLink>
					</ArtworkDropdownSubWrapper>
				))}
				{/* <ArtworkDropdownMainWrapper>
					<MainArtworkDropdownLink to="/artworks">All Artworks</MainArtworkDropdownLink>
					{renderMainCategoryList}
				</ArtworkDropdownMainWrapper>
				<ArtworkDropdownSubWrapper>
					<ArtworkDropdownSubItemTitle>{subCategoryTitle && `Most Popular ${subCategoryTitle}`}</ArtworkDropdownSubItemTitle>
					{renderSubCategoryList}
					{mainCategoryPath &&
						<ArtworkDropdownLink to={`/artworks/${mainCategoryPath}`}>View All</ArtworkDropdownLink>
					}
				</ArtworkDropdownSubWrapper>
				<ArtworkDropdownSubWrapper>
					<ArtworkDropdownSubItemTitle>{subCategoryTitle && `Most Popular ${subCategoryTitle}`}</ArtworkDropdownSubItemTitle>
					{renderSubCategoryList}
					{mainCategoryPath &&
						<ArtworkDropdownLink to={`/artworks/${mainCategoryPath}`}>View All</ArtworkDropdownLink>
					}
				</ArtworkDropdownSubWrapper> */}
			</ArtworkDropdownWrapper>
		</DropdownContent>
	);

	return (
		<Dropdown overlay={menu}>
			<MenuItem>
				{t("Artworks")}
				<Icon type="down" />
			</MenuItem>
		</Dropdown>
	);
};

export default withRouter(ArtworkDropDown);
