/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MainContainer from "common/MainContainer";
import { LoginHooks, userManagementHooks } from "ecommerce-sdk/src/index";
import toast from "common/Toast";
import { Link } from "react-router-dom";
import { Modal } from "antd";

import { withRouter } from "react-router";
import SDK, { artistBasicProfileHooks, useRefreshTokenContainer, productDetailHooks, blockchainHooks } from "ecommerce-sdk/src/index";
import lodash, { isNull } from "lodash";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import assets from "assets";
import ModalTypes from "utils/ModalTypes";
import useModal from "utils/useModal";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";
import { isEmpty } from "lodash";

import "./ArtistDashBoard.scss";
import AddNewCollection from "../Artist/AddNewCollection/AddNewCollection";
import { authorizedGet } from "utils/apiConfig";
import useCustomWalletConnect from "utils/useCustomWalletConnect";
import { ArtistNewSideMenu } from "pages/Artist/ArtistNewSideMenu";
import { ArtistMainBannerImg } from "./ArtistDashBoard.styled";
import { unAuthorizedGet } from "utils/apiConfig";

const ArtistDashBoard = ({ match, history }) => {
	const {
		useGetArtistProducts,
		useGetGalleryView,
		useUpdateProductStatus,
		useCheckAddProductEligibility,
		useGetOwnedArtworks,
		useGetInventory,
		useUpdatePrice,
	} = productDetailHooks;

	const { useLogout } = LoginHooks;
	const { useGetBasicDescription } = artistBasicProfileHooks;
	const [getDescription, gotDescriptionData, , getLoading] = useGetBasicDescription();

	const { useGetUserInfo } = userManagementHooks;
	const [logoutUser, data, error] = useLogout();
	const [getUserInfo, userStates] = useGetUserInfo();
	const { t } = useTranslation();
	const [, , showModal] = useModal(ModalTypes.kycDocument);
	const [getCheckAddProductEligibility, checkAddProductEligibilityData] = useCheckAddProductEligibility();
	const { walletAddress, isWalletConnected, walletNameLocal } = useCustomWalletConnect();
	const [toShowBlank, setToShowBlank] = useState();
	const { refreshToken, refreshTokenData } = useRefreshTokenContainer();

	const [profileInfo, setProfileInfo] = useState();
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		if (refreshTokenData && refreshTokenData.status === 401) {
			SDK.UserManagement.logout();
			history.push("/");
			window.location.reload();
		}
	}, [refreshTokenData]);

	useEffect(() => {
		if (error) {
			SDK.UserManagement.logout();
			history.push("/");
			window.location.reload();
		} else if (data) {
			toast(t(toastMessage.loggedOut), "success");
			SDK.UserManagement.logout();
			history.push("/");
			window.location.reload();
		}
	}, [data, error]);

	useEffect(() => {
		if (isEmpty(userStates)) {
			getUserInfo();
		}
		getDescription();
		SDK.UserManagement.getUserRoles((result) => {
			const isSeller = lodash.includes(result, "GALLERY") || lodash.includes(result, "ARTIST");
		});

		getCheckAddProductEligibility();
	}, []);
	useEffect(() => {
		if (gotDescriptionData) {
			setUserId(gotDescriptionData.userId);
		}
	}, [gotDescriptionData]);
	useEffect(() => {
		if (userId) {
			onLoadData();
		}
	}, [userId]);

	const onLoadData = () => {
		//Get Info
		unAuthorizedGet(`UserManagment/api/Artists/GetArtistsAbout?userId=${userId}`).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setProfileInfo(responseJson.response.data);
			}
		});
	};

	return (
		<MainContainer>
			<div className="artist-dashboard-main-wrappper">
				<ArtistNewSideMenu />
				<div className="artist-content-container">
					<ArtistMainBannerImg
						bgImg={gotDescriptionData && gotDescriptionData.coverPhoto ? gotDescriptionData.coverPhoto : assets.images.emptyBanner}
					>
						<div>
							<h1>{profileInfo && profileInfo.faetureQuote !== null ? `${profileInfo.faetureQuote.substring(0, 90)}...` : ""}</h1>
							{profileInfo && profileInfo.faetureQuote !== null && profileInfo.faetureQuote !== "" && (
								<img src={assets.images.qouteWhite} alt="Qoute White" />
							)}
						</div>
						<div className="profile-new-container">
							<img
								className="profile-photo"
								src={
									gotDescriptionData && gotDescriptionData.profilePhoto
										? gotDescriptionData.profilePhoto
										: assets.images.emptyArtistDpNew
								}
								alt="profile photo"
							/>
							<h1>
								{gotDescriptionData && gotDescriptionData.alaisName && gotDescriptionData.alaisName !== null
									? gotDescriptionData.alaisName
									: gotDescriptionData && gotDescriptionData.name && gotDescriptionData.name !== null
									? gotDescriptionData.name
									: "Unknown"}
							</h1>
							<span className="place-text">{`${
								profileInfo && profileInfo.birthYear !== null ? "B. " + profileInfo.birthYear + " , " : ""
							}${gotDescriptionData && gotDescriptionData.place}`}</span>
						</div>
					</ArtistMainBannerImg>
				</div>
			</div>

			{/* <div className="main-wrapper">
				<h1 className="main-welcome-head">Welcome</h1>
				<p className="artist-name">
					{gotDescriptionData && gotDescriptionData.alaisName
						? gotDescriptionData.alaisName
						: gotDescriptionData && gotDescriptionData.name
						? gotDescriptionData.name
						: "Unknown"}
				</p>
				{gotDescriptionData && gotDescriptionData.status !== 1 && (
					<div className="warning-wrapper">
						<img src={assets.images.warningIcon} alt="warning" />
						<p>Pending Admin Approval</p>
					</div>
				)}
				<div className="card-main-wrapper">
					<div className="card-wrapper">
						<div className="card-img-icon">
							<img src={assets.images.dashProfile} alt="dashprofile"></img>
						</div>
						<div className="card-link-wrapper">
							<h1>Profile</h1>
							<Link to={`/dashboard/edit`}>{t("Edit Profile Details ")}</Link>
							<Link to={`/dashboard/${gotDescriptionData && gotDescriptionData.slugId}`}>{t("View Public Profile")}</Link>
						</div>
					</div>

					<div className="card-wrapper">
						<div className="card-img-icon">
							<img src={assets.images.dashInventory} alt="dashin" />
						</div>
						<div className="card-link-wrapper">
							<h1>Manage Inventory</h1>
							<Link to={`/dashboard/artworks-inventory`}>{t("View Inventory")}</Link>
							<Link to="#" onClick={() => onAddArtwork()}>
								{t("Add Artwork")}
							</Link>
							<Link
								to="#"
								onClick={() => {
									handleAddNew();
								}}
							>
								{t("Add Collections")}
							</Link>

							<Link to="/artist/dashboard/user-collections">{t("View Collections")}</Link>
						</div>
					</div>

					<div className="card-wrapper">
						<div className="card-img-icon">
							<img src={assets.images.dashOrders} alt="dashin" />
						</div>
						<div className="card-link-wrapper">
							<h1>Quotes & Orders</h1>
							<Link to={`/profile/quotes`}>{t("Manage Quotes")}</Link>
							<Link to={`/profile/received-orders`}>{t("Manage Orders")}</Link>
						</div>
					</div>

					<div className="card-wrapper">
						<div className="card-img-icon">
							<img src={assets.images.dashAddress} alt="dashin" />
						</div>
						<div className="card-link-wrapper">
							<h1>Address</h1>
							<Link to={`/profile/address`}>{t("Manage your Address")}</Link>
						</div>
					</div>

					<div className="card-wrapper">
						<div className="card-img-icon">
							<img src={assets.images.dashBlockChain} alt="dashin" />
						</div>
						<div className="card-link-wrapper">
							<h1>Blockchain</h1>
							<Link to={`/profile/blockchain-account`}>{t("Manage Blockchain")}</Link>
							<Link to={`/profile/signature-pending-artworks`}>{t("Signature Pending Artworks")}</Link>
						</div>
					</div>

					<div className="card-wrapper">
						<div className="card-img-icon">
							<img src={assets.images.dashKyc} alt="dashin" />
						</div>
						<div className="card-link-wrapper">
							<h1>KYC</h1>
							{gotDescriptionData && gotDescriptionData.kycStatus === 0 ? (
								<Link
									to="#"
									onClick={() => {
										showModal(ModalTypes.kycDocument, {
											location: "dropdown",
										});
									}}
								>
									{t("Manage KYC")}
								</Link>
							) : (
								<Link
									to="#"
									onClick={() => {
										onLoadKYC();
									}}
								>
									{isLoadingKyc ? "Loading KYC Please wait.." : t("View Uploaded KYC Document ")}
								</Link>
							)}
						</div>
					</div>
				</div>
			</div> */}

			<div
				style={{
					display: "none",
				}}
			>
				<AddNewCollection setToShowBlank={setToShowBlank} />
			</div>
		</MainContainer>
	);
};

export default withRouter(ArtistDashBoard);
