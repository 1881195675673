import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlockchainAccountAction, createBlockchainAccountAction, buyArtWorkTransactionAction, addSignMessageAction, getBlockchainCertificate, metaDataSignatureUpdateAction } from "../actions/BlockchainAction";
import Util from "../util/Util";

// ========================================== Get Blockchain Account list ========================================== //
export const useGetBlockchainAccount = () => {
    const dispatch = useDispatch();
    const getBlockchainAccount = (pageNum, pageSize) => {
        dispatch(getBlockchainAccountAction(pageNum, pageSize));
    };

    // Reducer states
    const { data, error, loading } = useSelector(state => state.blockchainReducer.getBlockchainAccountList);
    return [getBlockchainAccount, data, error, loading];
};

// ========================================== Create Blockchain Account ========================================== //
export const useCreateBlockchainAccount = () => {
    const dispatch = useDispatch();
    const createBlockchainAccount = data => {
        const formData = Util.objectToFormData(data);
        dispatch(createBlockchainAccountAction(formData));
    };

    // Reducer states
    const { data, error, loading } = useSelector(state => state.blockchainReducer.createBlockchainAccount);
    return [createBlockchainAccount, data, error, loading];
};

// ========================================== Buy Artwork transaction =========================================== //
export const useBuyArtWorkTransaction = () => {
    const dispatch = useDispatch();
    const buyArtworkTxn = data => {
        dispatch(buyArtWorkTransactionAction(data));
    };

    // Reducer states
    const { data, error, loading } = useSelector(state => state.blockchainReducer.buyArtworkTransaction);
    return [buyArtworkTxn, data, error, loading];
};

// ========================================== Add Signed Message =========================================== //
export const useAddSignedMessage = () => {
    const dispatch = useDispatch();
    const addSign = data => {
        dispatch(addSignMessageAction(data));
    };

    // Reducer states
    const { data, error, loading } = useSelector(state => state.blockchainReducer.addSignedMessage);
    return [addSign, data, error, loading];
};

// ========================================== Add Meta Data Signature Updates =========================================== //
export const useAddMetaDataUpdateSignature = () => {
    const dispatch = useDispatch();
    const metaDataSignatureUpdate = data => {
        dispatch(metaDataSignatureUpdateAction(data));
    };

    // Reducer states
    const { data, error, loading } = useSelector(state => state.blockchainReducer.metaDataSignature);
    return [metaDataSignatureUpdate, data, error, loading];
};

// ========================================== Get Blockchain Certificate ========================================== //
export const useGetCertificate = () => {
    const dispatch = useDispatch();
    const getCertificate = (rfid) => {
        dispatch(getBlockchainCertificate(rfid));
    };

    // Reducer states
    const { data, error, loading } = useSelector(state => state.blockchainReducer.getCertificate);
    return [getCertificate, data, error, loading];
};
