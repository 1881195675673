import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	getUserArtist: {},
	updateUserArtist: {}
};

const ArtistUserTypeReducer = createApiReducer(initialState, [types.GET_ARTIST_USER_TYPES_REQUEST, types.UPDATE_ARTIST_USER_TYPES_REQUEST]);
export default ArtistUserTypeReducer;
