import React, { useState } from "react";
import { ImageLoading, MainImage, ImageError } from "./Image.styled";
import PropTypes from "prop-types";
import assets from "assets";

const Image = ({ src, fallback, shouldAddExtraWrap = false, extraWrapClassName = "", ...props }) => {
	const { imageloadingclass, imageerrorclass } = props;

	// TODO : Add custom placeholders

	const [imageStatus, setImageStatus] = useState("LOAD");

	// Image loaded
	const handleImageLoaded = () => {
		setImageStatus("SUCCESS");
	};

	// Image Error
	const handleImageErrored = () => {
		setImageStatus("ERROR");
	};

	return (
		<>
			<MainImage
				srcWebp={src}
				fallback={fallback}
				hideimage={imageStatus !== "SUCCESS" ? "true" : "false"}
				alt="unavailable"
				onLoad={() => handleImageLoaded()}
				onError={() => handleImageErrored()}
				{...props}
			/>
			{shouldAddExtraWrap ? (
				<>
					{imageStatus === "LOAD" && (
						<div className={extraWrapClassName}>
							<ImageLoading src={assets.images.imagePlace} className={imageloadingclass} alt="loading" />
						</div>
					)}
					{imageStatus === "ERROR" && (
						<div className={extraWrapClassName}>
							<ImageError src={assets.images.imageNoPlace} className={imageerrorclass} alt="error" />
						</div>
					)}
				</>
			) : (
					<>
						{imageStatus === "LOAD" && <ImageLoading src={assets.images.imagePlace} className={imageloadingclass} alt="loading" />}
						{imageStatus === "ERROR" && <ImageError src={assets.images.imageNoPlace} className={imageerrorclass} alt="error" />}
					</>
				)}
			{}
		</>
	);
};

Image.propTypes = {
	src: PropTypes.string.isRequired
};

export default Image;
