import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	getFilterCategoryProperties: {},
	filterProducts: {},
	getBreadcrumbs: {}
};

const FilterArtworksReducer = createApiReducer(initialState, [
	types.GET_FILTER_CATEGORY_PROPERTIES_REQUEST,
	types.GET_FILTER_ARTWORKS_REQUEST,
	types.GET_BREADCRUMBS_REQUEST
]);

export default FilterArtworksReducer;
