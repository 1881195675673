import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	getCollectionByArtist: {},
	addCollectionByArtist: {},
	updateCollection: {},
	deleteCollection: {},
	filterMyProduct: {},
	collection:{},
	deleteCollectionItem:{},
	allCollections:{}
};

const ArtistCollectionReducer = createApiReducer(initialState, [
	types.GET_COLLECTION_BY_ARTIST_REQUEST,
	types.ADD_COLLECTION_BY_ARTIST_REQUEST,
	types.UPDATE_COLLECTION_REQUEST,
	types.DELETE_COLLECTION_REQUEST,
	types.FILTER_MY_PRODUCT_REQUEST,
	types.GET_COLLECTION_REQUEST,
	types.DELETE_COLLECTION_ITEM_REQUEST,
	types.GET_ALL_COLLECTIONS_REQUEST
]);
export default ArtistCollectionReducer;
