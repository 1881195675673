import urls from "./urlConfig";

const baseUrl = process.env.REACT_APP_API_URI;

export function authorizePostFormData(endPoint, reqData, token) {
	return fetch(baseUrl + endPoint, {
		method: "POST",
		headers: {
			// 'Content-Type': 'application/json'
			"Content-Type": "multipart/form-data",
			Authorization: "Bearer " + token,
		},
		body: reqData,
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.resultCode === 1) {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 0 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 0 };
		});
}
export function authorizePost(endPoint, reqData, token) {
	return fetch(baseUrl + endPoint, {
		method: "POST",
		headers: {
			// 'Content-Type': 'application/json'
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(reqData),
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 2 };
		});
}
export function authorizePostForTorus(endPoint, reqData, token) {
	return fetch(baseUrl + endPoint, {
		method: "POST",
		headers: {
			// 'Content-Type': 'application/json'
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(reqData),
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 0 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 0 };
		});
}
export function authorizePostReg(endPoint, reqData, token) {
	return fetch(baseUrl + endPoint, {
		method: "POST",
		headers: {
			// 'Content-Type': 'application/json'
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(reqData),
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.result === 200) {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 0 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 0 };
		});
}
export function unthorizePost(endPoint, reqData) {
	return fetch(baseUrl + endPoint, {
		method: "POST",
		headers: {
			// 'Content-Type': 'application/json'
			"Content-Type": "application/json",
		},
		body: JSON.stringify(reqData),
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 0 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 0 };
		});
}
export function authorizePut(endPoint, reqData, token) {
	return fetch(baseUrl + endPoint, {
		method: "PUT",
		headers: {
			// 'Content-Type': 'application/json'
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(reqData),
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 0 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 0 };
		});
}
export function postMultiPart(endPoint, reqData) {
	return fetch(baseUrl + endPoint, {
		method: "POST",
		headers: {
			"Content-Type": "multipart/form-data",
		},
		body: reqData,
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (typeof responseJson.access === "undefined") {
				return { error: responseJson, resultCode: 2 };
			} else {
				return { error: responseJson, response: responseJson, resultCode: 1 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 3 };
		});
}
export function putFormData(endPoint, reqData, token) {
	return fetch(baseUrl + endPoint, {
		method: "PUT",
		headers: {
			Authorization: "Bearer " + token,
		},
		body: reqData,
	})
		.then((response) => response.json())
		.then((responseJson) => {
			// // console.log("🚀 ~ file: apiConfig.js ~ line 80 ~ putFormData ~ responseJson", responseJson);
			if (responseJson.message === "Success") {
				return { error: responseJson, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 3 };
		});
}
export function postFormData(endPoint, reqData, token) {
	var myHeaders = new Headers();
	if (token === null) {
		var requestOptions = {
			method: "POST",
			body: reqData,
			redirect: "follow",
		};
	} else {
		myHeaders.append("Authorization", "Bearer " + token);
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: reqData,
			redirect: "follow",
		};
	}

	for (var pair of reqData.entries()) {
		console.log("form data", pair[0] + ", " + JSON.stringify(pair[1]));
	}
	return fetch(baseUrl + endPoint, requestOptions)
		.then((response) => response.json())
		.then((responseJson) => {
			// // console.log("🚀 ~ file: apiConfig.js ~ line 80 ~ putFormData ~ responseJson", responseJson);
			if (responseJson.message === "Success") {
				return { error: responseJson, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 3 };
		});
}

export function authorizedGet(endPoint, token) {
	console.log("apiURL", endPoint);
	return fetch(baseUrl + endPoint, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, response: responseJson, resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 3 };
		});
}

export function deleteAPi(endPoint, token) {
	return fetch(baseUrl + endPoint, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, response: responseJson, resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 3 };
		});
}

export function unAuthorizedGet(endPoint) {
	console.log("apiURL", endPoint);
	return fetch(baseUrl + endPoint, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.message === "Success") {
				return { error: null, response: responseJson, resultCode: 1 };
			} else {
				return { error: responseJson, response: responseJson, resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 3 };
		});
}

export function activeCampaignAPI(email, name, location) {
	return fetch(`${baseUrl}eCommerce/api/General/ActiveCampaign?email=${email}&name=${name}&SubscriptionLocation=${location}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (responseJson.result === 400) {
				return { msg: "You are already part of the community!", resultCode: 2 };
			} else if (responseJson.result === 200) {
				return { msg: "Thank you for subscribing!", resultCode: 1 };
			} else if (responseJson.result === 404) {
				return { msg: "Some error occured.Please try again later", resultCode: 2 };
			}
		})
		.catch(function (error) {
			return { error: error, resultCode: 0 };
		});
}
