import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	followArtist,
	getMyFollowingListAction,
	getMyFollowersListAction,
	getFollowingList,
	getFollowersList,
	unfollowArtist,
	getMyArtistFollowing
} from "../actions/ArtistFollowAction";

export const useFollowArtist = () => {
	const dispatch = useDispatch();

	// Follow Artist
	const follow = followerData => {
		dispatch(followArtist(followerData));
	};
	// Reducer states
	const result = useSelector(state => state.artistFollow.followArtist);

	return [follow, result.data, result.error, result.loading];
};

export const useGetFollowingList = () => {
	const dispatch = useDispatch();

	// Get FollowingList
	const getFollowing = userId => {
		if (userId) dispatch(getFollowingList(userId));
		else dispatch(getMyFollowingListAction());
	};
	// Reducer states
	const result = useSelector(state => state.artistFollow.followingList);

	return [getFollowing, result.data, result.error, result.loading];
};

export const useGetFollowersList = () => {
	const dispatch = useDispatch();

	// Get FollowersList
	const getFollowers = userId => {
		if (userId) dispatch(getFollowersList(userId));
		else dispatch(getMyFollowersListAction());
	};
	// Reducer states
	const result = useSelector(state => state.artistFollow.followersList);

	return [getFollowers, result.data, result.error, result.loading];
};

export const useUnfollowArtist = () => {
	const dispatch = useDispatch();

	// Unfollow Artist
	const unfollow = followerData => {
		dispatch(unfollowArtist(followerData));
	};
	// Reducer states
	const result = useSelector(state => state.artistFollow.unfollowArtist);

	return [unfollow, result.data, result.error, result.loading];
};

export const useGetMyArtistFollowing = () => {
	const dispatch = useDispatch();

	// Get My Following Artist List
	const getMyFollowing = () => {
		dispatch(getMyArtistFollowing());
	};
	// Reducer states
	const result = useSelector(state => state.artistFollow.myArtistFollowing);

	return [getMyFollowing, result.data, result.error, result.loading];
};
