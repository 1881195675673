import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	similarProducts: {},
	suggestedArtworks: {},
	continueYourSearch: {},
	recommendedCategories: {},
	trendingCategories: {},
	artworksOnOffer: {},
	featuredArtists: {},
	getFeaturedGallery: {},
	getOriginalArtworks: {},
	getLatestArtworks: {},
	getLikeAndWishList: {},
	getPopularArtworks: {},
	getFeaturedArtworks: {}
};

const RecommendationsReducer = createApiReducer(initialState, [
	types.GET_SIMILAR_PRODUCTS_REQUEST,
	types.GET_SUGGESTED_ARTWORKS_REQUEST,
	types.GET_CONTINUE_YOUR_SEARCH_REQUEST,
	types.GET_RECOMMENDED_CATEGORIES_REQUEST,
	types.GET_TRENDING_CATEGORIES_REQUEST,
	types.GET_ARTWORKS_ON_OFFER_REQUEST,
	types.GET_FEATURED_ARTISTS_REQUEST,
	types.GET_FEATURED_GALLERY_REQUEST,
	types.GET_ORIGINAL_ARTWORKS_REQUEST,
	types.GET_LATEST_ARTWORKS_REQUEST,
	types.GET_LIKE_WISHLIST_REQUEST,
	types.GET_POPULAR_ARTWORKS_REQUEST,
	types.GET_FEATURED_ARTWORKS_REQUEST
]);

export default RecommendationsReducer;
