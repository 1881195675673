import styled from "styled-components";
import { Spin } from "antd";
import { BaseTheme } from "utils/theme";

const StyledRawButton = styled.button`
	background-color: ${props => {
		switch (props.variant) {
			case "primary":
				return BaseTheme.buttonWhite.bg;
			case "invert":
				return BaseTheme.buttonGrey.bg;
			default:
				return BaseTheme.buttonWhite.bg;
		}
	}};
	color: ${props => {
		switch (props.variant) {
			case "primary":
				return BaseTheme.buttonGrey.bg;
			case "invert":
				return BaseTheme.buttonWhite.bg;
			default:
				return BaseTheme.buttonGrey.bg;
		}
	}};
	min-width: ${props => {
		return props.width || "8rem";
	}};
	height: 35px;
	padding: 3px 10px;
	border: 1px solid #58514b;
	outline: none;
	font-size: 13px;
	font-weight: 500;

	text-transform: ${props => {
		return props.textTransform || "uppercase";
	}};
	transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	&:hover,
	&:active,
	&:focus {
		box-shadow: 2px 2px 7px 1px #e8e8e8;
	}
	&:disabled {
		background-color: #d0d5dc;
		border: 1px solid #d0d5dc;
		color: #fff;
		cursor: not-allowed;
		pointer-events: none;
		&:hover {
			transition: none;
		}
	}

	@media (max-width: 320px) {
		min-width: 8rem;
	}
`;

const SpinLoader = styled(Spin)`
  margin-right: 10px;
`;

export { SpinLoader, StyledRawButton };

