import React, { useEffect, useState } from "react";
import assets from "assets";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import ConfirmPopup from "common/ConfirmPopup";
import { userManagementHooks } from "ecommerce-sdk/src/index";
import SDK from "ecommerce-sdk/src/index";
import { useDispatch } from "react-redux";
import useModal from "utils/useModal";
import ModalTypes from "utils/ModalTypes";
import { ceil } from "lodash";
import { useTranslation } from "react-i18next";
import "./NewArtworkCard.style.scss";
import { Icon, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import useArtWorkCard from "common/ArtworkCard/useArtWorkCard";
import moment from "moment";
import CountDownDateTimer from "common/CountDownDateTimer";
import useAuctionList from "../../pages/Auction/useAuctionList";
import {
	ArtworkCardWrapper,
	ArtworkCardImageWrapper,
	ArtworkCardImagePlaceholder,
	ArtworkCardImageNew,
	ArtworkCardActions,
	ArtwordCardTitle,
	ArtworkCardFooter,
	ArtworkCardArtist,
	ArtworkCardPrice,
	FollowButton,
	ArtworkCardOffer,
	ProfileIcon,
	FollowButtonWrapper,
	ArtwordCardDiscount,
	ArtworkCardFooterPrice,
} from "./SearchArtWorkCard.styled";
/**
 * NOTES
 * @param data Object Each data item to display
 * @param containerWidth Width of title / To set title overflow
 * @param margin String Margin for each card [By default 0]
 * @param type String To show which actions to show [By default like/favourite/cart]
 * @param hideFollowButton Boolean To show follow button for artists [By default shows follow button on hover]
 * @param showOffer Boolean To show/hide offer tag [By default false]
 * @param showAction Boolean To show/hide action icons [By default true]
 * @param sold Sold/Not
 * @function onClickEditButton Click to the edit button of action
 * @function onClickDeleteButton Click to the delete button of action
 * @
 */
const ArtWorkCardForGlobalSearch = ({ history, data = {}, onAction = () => {}, containerWidth, ...props }) => {
	const { useGetUserInfo } = userManagementHooks;
	const [, userStates] = useGetUserInfo();
	const { t } = useTranslation();
	const { margin, type, hideFollowButton = false, showOffer = false, showAction = true, sold = false } = props;
	// Functions
	const { onClickEditButton, onClickDeleteButton } = props;
	// Values used from data prop
	const {
		artistName,
		artistCountry,
		artistProfileImageUC,
		artistId,
		displayPicture,
		displayPictureUc,
		isLiked,
		isFavorite,
		isFollowing,
		oneLineDescription,
		productName,
		sellingPrice,
		price,
		currency,
		discountPercentage,
		slugId,
		yearofCreation,
		medium,
		materialsUsed,
		categoryName,
		dimensions,
		stockId,
		status,
		productType,
		artistSlugId,
		auctionLot,
		edition,
	} = data;
	const { imageGreyPlace, likeInactive, likeActive, whishlistInactive, whishlistActive, artistIcon, editIcon, signIcon, deleteIcon } =
		assets.images;
	const discountValue = +discountPercentage && ceil(+discountPercentage) > 1 ? ceil(discountPercentage) : null;
	const [isLikedLocal, setIsLikedLocal] = useState(isLiked);
	const [isFavLoacal, setIsFavLoacal] = useState(isFavorite);
	//login check
	const [, , showModal] = useModal();
	const dispatch = useDispatch();
	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		const checkLoggedIn = async () => {
			const isLoggedInResult = await SDK.UserManagement.isLoggedIn();
			setLoggedIn(isLoggedInResult);
		};
		checkLoggedIn();
	}, []);
	// TODO : Different currencies
	// TODO : Get image placeholder for no image
	// loading above count
	// ================= LIKE ================= //
	const onClickLikeButton = async () => {
		if (loggedIn) {
			setIsLikedLocal(!isLikedLocal);
			onAction("like", data);
		} else {
			dispatch(showModal(ModalTypes.login));
		}
	};
	// ================= FAVOURITE ================= //
	const onClickFavouriteButton = async () => {
		if (loggedIn) {
			setIsFavLoacal(!isFavLoacal);
			onAction("favourite", data);
		} else {
			dispatch(showModal(ModalTypes.login));
		}
	};
	// ================= FOLLOW ================= //
	const onClickFollowButton = async () => {
		loggedIn ? onAction("follow", data) : dispatch(showModal(ModalTypes.login));
	};
	// ================= On click artwork ================= //
	const onClickArtwork = () => {
		sold === true
			? history.push(`/art/${medium && medium.slugId}/${slugId}/${stockId}`)
			: history.push(`/art/${medium && medium.slugId}/${slugId}`);
	};
	// ================= ACTIONS ================= //
	const artworkCardActionsSwitcher = () => {
		switch (type) {
			// EDIT DELETE
			case "edit-delete":
				return (
					<ArtworkCardActions>
						<Tooltip title={"Edit"}>
							<img src={editIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data)} />
						</Tooltip>
						<Tooltip title={t("Delete")}>
							<ConfirmPopup
								text={t("Confirm Deletion") + "?"}
								image={assets.images.deleteIcon}
								buttonText={t("Delete")}
								data={data}
								confirmAction={() => onClickDeleteButton(data)}
							>
								<img src={deleteIcon} alt="Delete" />
							</ConfirmPopup>
						</Tooltip>
					</ArtworkCardActions>
				);
			case "delete":
				return (
					<ArtworkCardActions>
						<Tooltip title={t("Delete")}>
							<ConfirmPopup
								text={t("Confirm Deletion") + "?"}
								image={assets.images.deleteIcon}
								buttonText={t("Delete")}
								confirmAction={() => onClickDeleteButton(data)}
							>
								<img src={deleteIcon} alt="Delete" />
							</ConfirmPopup>
						</Tooltip>
					</ArtworkCardActions>
				);
			case "edit":
				return (
					<ArtworkCardActions>
						<Tooltip title={"Edit"}>
							<img src={editIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data)} />
						</Tooltip>
					</ArtworkCardActions>
				);
			case "sign":
				return (
					<ArtworkCardActions>
						<Tooltip title={t("Update Signature")}>
							<img src={signIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data)} />
						</Tooltip>
					</ArtworkCardActions>
				);
			case "orderSign":
				return (
					<ArtworkCardActions>
						<Tooltip title={t("Update Signature")}>
							<img src={signIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data, props.orderData)} />
						</Tooltip>
					</ArtworkCardActions>
				);
			// LIKE, FAVOURITE, CART actions
			default:
				return (
					<ArtworkCardActions className="artwork-card-action">
						{/* <Tooltip title={isLiked ? "Dislike artwork" : "Like Artwork"}> */}
						<img src={loggedIn && isLikedLocal ? likeActive : likeInactive} alt="🖒Like" onClick={(e) => onClickLikeButton()} />
						{/* </Tooltip> */}
						{/* <Tooltip title={isFavorite ? "Remove from favourites" : "Mark as Favourite"}> */}
						<img
							src={loggedIn && isFavLoacal ? whishlistActive : whishlistInactive}
							alt="❤Favorite"
							onClick={(e) => onClickFavouriteButton()}
						/>
						{/* </Tooltip> */}
					</ArtworkCardActions>
				);
		}
	};
	// To check if the artwork card is of same user // To hide follow button
	const checkIfThisUserProfile = () => {
		if (artistId && !hideFollowButton) {
			const id = (userStates && userStates.userId) || "";
			if (artistId === id) return false;
			else return true;
		} else return true;
	};
	const navigateArtistProfile = () => {
		history.push(`/artist/${artistSlugId}`);
	};

	/**  Auction Realated Changes */

	const { getCurrentTimeZoneName } = useAuctionList();
	const auctionStartsInRenderText = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return <></>;
		} else {
			// Render a countdown
			return (
				<p>
					{days}d:&nbsp;{hours}h:&nbsp;{minutes}m:&nbsp;{seconds}s
				</p>
			);
		}
	};
	const renderTimer = () => {
		if (auctionLot) {
			if (auctionLot.status === 1) {
				return (
					<div className="date-area">
						<span className="lot-label">Auction Starts in</span>
						<CountDownDateTimer countDate={auctionLot.startDate} renderer={auctionStartsInRenderText} />
					</div>
				);
			} else if (auctionLot.status === 2) {
				return (
					<div className="date-area">
						<span className="lot-label">Auction Ends in</span>
						<CountDownDateTimer countDate={auctionLot.endDate} renderer={auctionStartsInRenderText} />
					</div>
				);
			} else if (auctionLot.status === 3) {
				return (
					<div className="date-area">
						<span className="lot-label">Auction Completed on</span>
						<p>{`${moment(auctionLot.endDate).format("MMMM DD , YYYY HH:mm:ss")} ${getCurrentTimeZoneName()}`}</p>
					</div>
				);
			}
		}
	};
	const renderPrice = () => {
		if (auctionLot) {
			if (auctionLot.status === 1) {
				return (
					<div className="price-area">
						<span className="lot-label">Starting Bid</span>
						<p>{`USD ${auctionLot.startBidPrice}`}</p>
					</div>
				);
			} else if (auctionLot.status === 2) {
				return (
					<div className="price-area">
						<span className="lot-label">{auctionLot.highestBid === 0 ? "Starting Bid" : "Current Bid"}</span>
						<p>{`USD ${auctionLot.highestBid === 0 ? auctionLot.startBidPrice : auctionLot.highestBid}`}</p>
					</div>
				);
			} else if (auctionLot.status === 3) {
				return (
					<div className="price-area">
						<span className="lot-label">{auctionLot.highestBid === 0 ? "Starting Bid" : "Highest Bid"}</span>
						<p>{`USD ${auctionLot.highestBid === 0 ? auctionLot.startBidPrice : auctionLot.highestBid}`}</p>
					</div>
				);
			}
		}
	};
	return (
		// <Col xs={12} sm={12} md={3} lg={3} xl={3} className="new-artwork-card">
		<div className="new-artwork-card">
			<div className="aution-artist-info">
				<img src={artistProfileImageUC !== null ? artistProfileImageUC : assets.images.emptyArtistDp} alt="Artist Name" />
				<div onClick={() => navigateArtistProfile()}>
					<h3>{artistName || "Unknown"}</h3>
					<p>{artistCountry}</p>
				</div>
				{artistId && !hideFollowButton && checkIfThisUserProfile() && (
					<div className="follow-btn" onClick={() => onClickFollowButton()}>
						{isFollowing ? t("Unfollow") : t("Follow")}
					</div>
				)}
			</div>
			{displayPicture ? (
				<ArtworkCardImageNew
					shouldAddExtraWrap
					extraWrapClassName="artwork-card-empty-image"
					className="new-main-img"
					onClick={(e) => onClickArtwork(e)}
					src={displayPicture || imageGreyPlace}
					fallback={displayPictureUc || imageGreyPlace}
					alt={`${categoryName}:${artistName}${productName}${yearofCreation}${medium}${materialsUsed}`}
				/>
			) : (
				<ArtworkCardImagePlaceholder>
					<img src={imageGreyPlace} onClick={(e) => onClickArtwork(e)} alt="" />
				</ArtworkCardImagePlaceholder>
			)}
			{/* <img src="https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image5c.webp" alt="ARtwork Name" className="new-main-img" /> */}
			<div className="new-artwork-details">
				<div className="new-artwork-title">
					<h3>{productName}</h3>
					<p>
						{productType === 2
							? `${Math.round(dimensions.height)}H x ${Math.round(dimensions.width)}W Pixel`
							: `${Math.round(dimensions.height)}H x ${Math.round(dimensions.width)}W Inches`}
					</p>
				</div>
				<div className="new-artwork-icon">
					{/* <img src={assets.images.nftArtwokIcon} alt="Artwork Type " /> */}
					{productType === 2 ? (
						<img src={assets.images.digitlaArtworkIcon} alt="Digital Artwork Icon" />
					) : (
						<img src={assets.images.physicalArtworkIcon} alt="Digital Artwork Icon" />
					)}
				</div>
				{showAction && artworkCardActionsSwitcher()}
			</div>
			{status === 16 || status === 17 || status === 18 ? (
				<div className="auction-lot-pricing-container">
					{renderPrice()}
					{renderTimer()}

					{!showAction && edition && edition !== null && (
						<div>
							<span className="lot-label">Edition</span>
							<p>{edition}</p>
						</div>
					)}
				</div>
			) : (
				<div className="new-price-details-wrapper">
					<div className="aution-price-section price-div-1">
						<h3>{(sellingPrice || price) && `USD ${sellingPrice.toLocaleString() || price.toLocaleString()}`}</h3>
						{showOffer && discountValue && <ArtwordCardDiscount>{discountValue}% off</ArtwordCardDiscount>}
					</div>
				</div>
			)}
			{/* </Col> */}
		</div>
	);
};
export default withRouter(ArtWorkCardForGlobalSearch);
