import React, { useState, useEffect } from "react";
import { newArtistDashboardMenus, newGalleryDashBoardMenu, newUserDashBoardMenu } from "utils/constants";
import { useHistory, useLocation } from "react-router";
import SDK, { artistBasicProfileHooks, LoginHooks } from "ecommerce-sdk/src/index";
import ModalTypes from "utils/ModalTypes";
import useModal from "utils/useModal";
import toast from "common/Toast";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import AddNewCollection from "../AddNewCollection/AddNewCollection";
import { Modal } from "antd";
import { KYCDocumentView } from "common/KYCDocumentModal/KYCDocumentView";
import { authorizedGet } from "utils/apiConfig";
import lodash from "lodash";

import "./ArtistNewSideMenu.style.scss";
import { ArtistSignature } from "common/Modals";

const ArtistNewSideMenu = () => {
	const { useGetBasicDescription } = artistBasicProfileHooks;
	const { useLogout } = LoginHooks;

	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	const [, , showModal] = useModal(ModalTypes.kycDocument);

	const [getDescription, gotDescriptionData, , getLoading] = useGetBasicDescription();
	const [logoutUser, logoutData, logoutError] = useLogout();

	//UseStates
	const [activeMenu, setactiveMenu] = useState("");
	const [activeSubMenu, setActiveSubMenu] = useState("");
	const [toShowBlank, setToShowBlank] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [kycData, setKycData] = useState();
	const [isLoadingKyc, setIsLoadingKyc] = useState(false);
	const [userToken, setUserToken] = useState();
	const [sellerType, setSellerType] = useState(false);
	const [signatureModal, setSignatureModal] = useState(false);
	const [artistSignatureData, setArtistSignatureData] = useState([]);

	let menus = sellerType
		? sellerType === "ARTIST"
			? newArtistDashboardMenus
			: sellerType === "GALLERY"
			? newGalleryDashBoardMenu
			: newUserDashBoardMenu
		: newUserDashBoardMenu;

	useEffect(() => {
		async function fetchUserToken() {
			let token = await SDK.UserManagement.getAuthToken();
			setUserToken(token);
		}
		fetchUserToken();

		SDK.UserManagement.getUserRoles((result) => {
			const isSeller = lodash.includes(result, "GALLERY") || lodash.includes(result, "ARTIST");
			if (isSeller) {
				setSellerType(lodash.includes(result, "ARTIST") ? "ARTIST" : "GALLERY");
			}
		});
	}, []);

	useEffect(() => {
		if (logoutError) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		} else if (logoutData) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		}
	}, [logoutData, logoutError]);

	useEffect(() => {
		if (location) {
			//Set Main Menu
			if (location.pathname === "/artist/dashboard" || location.pathname === "/dashboard" || location.pathname.includes("dashboard/edit/")) {
				setactiveMenu("artist_profile_menu");
			} else if (
				location.pathname === "/dashboard/artworks-inventory" ||
				location.pathname === "/artist/dashboard/user-collections" ||
				location.pathname === "/profile/signature-pending-artworks"
			) {
				setactiveMenu("inventory_menu");
			} else if (
				location.pathname === "/profile/followers" ||
				location.pathname === "/profile/artworks" ||
				location.pathname === "/profile/following-galleries" ||
				location.pathname === "/profile/following-artists"
			) {
				setactiveMenu("fav_menu");
			} else if (
				location.pathname === "/profile/my-quotes" ||
				location.pathname === "/profile/orders" ||
				location.pathname === "/profile/owned-artwork"
			) {
				setactiveMenu("my_purchases");
			} else if (
				location.pathname === "/profile/quotes" ||
				location.pathname === "/profile/received-orders" ||
				location.pathname === "/profile/group-requests"
			) {
				setactiveMenu("offer_menu");
			} else if (location.pathname === "/profile/blockchain-account") {
				setactiveMenu("blockchain_menu");
			} else if (location.pathname === "/profile/address") {
				setactiveMenu("address_menu");
			} else if (location.pathname === "/profile/address") {
				setactiveMenu("address_menu");
			} else if (location.pathname === "/profile/general") {
				setactiveMenu("general");
			}

			//Set selected sub menus
			if (location.pathname === "/dashboard/artworks-inventory") {
				setActiveSubMenu("manage-inventory");
			} else if (location.pathname === "/artist/dashboard/user-collections") {
				setActiveSubMenu("manage-collection");
			} else if (location.pathname === "/profile/signature-pending-artworks") {
				setActiveSubMenu("signature-pending-artworks");
			} else if (location.pathname === "/profile/blockchain-account") {
				setActiveSubMenu("blockchain-account");
			} else if (location.pathname === "/profile/received-orders") {
				setActiveSubMenu("received-orders");
			} else if (location.pathname === "/profile/artworks") {
				setActiveSubMenu("artworks");
			} else if (location.pathname === "/profile/following") {
				setActiveSubMenu("following");
			} else if (location.pathname === "/profile/followers") {
				setActiveSubMenu("followers");
			} else if (location.pathname === "/profile/my-quotes") {
				setActiveSubMenu("my-quotes");
			} else if (location.pathname === "/profile/orders") {
				setActiveSubMenu("orders");
			} else if (location.pathname === "/profile/address") {
				setActiveSubMenu("manage-address");
			}
		}
		//Open key
	}, [location.pathname]);

	const onClickMaineMenu = (menu) => {
		if (menu === "terms_menu") {
			window.open("https://art.rtistiq.com/en/term-of-use", "_blank");
		} else if (menu === "blockchain_menu") {
			history.push("/profile/blockchain-account");
		} else if (menu === "signature_menu") {
			onLoadArtistSignature();
		} else if (menu === "logout_menu") {
			onLogout();
		} else {
			setactiveMenu(menu);
		}
	};
	const handleAddNewCollection = () => {
		toShowBlank && !isEmpty(toShowBlank) ? showModal(ModalTypes.collections) : toast(t(warningMessage.createCollectionWarn), "warning");
	};

	const onToggleModel = () => {
		setIsModalVisible(!isModalVisible);
	};
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onLoadKYC = () => {
		//Get Info
		setIsLoadingKyc(true);
		authorizedGet("UserManagment/api/UserManagement/getMyKYC", userToken).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				if (responseJson.response.data && responseJson.response.data._id) {
					setKycData(responseJson.response.data);
					setIsLoadingKyc(false);
					onToggleModel();
				} else {
					showModal(ModalTypes.kycDocument, {
						data: "dropdown",
					});
				}
			}
		});
	};
	const onLoadArtistSignature = () => {
		authorizedGet("UserManagment//api/UserManagement/Signature", userToken).then((res) => {
			if (res.resultCode === 1) {
				setArtistSignatureData(res.response && res.response.data);
				setSignatureModal(true);
			} else if (res.resultCode === 2) {
				toast(res.error && res.error.message, "error");
			}
		});
	};
	const onLogout = () => {
		logoutUser(userToken);
		toast(t(toastMessage.loggedOut), "success");
	};
	//Sub Menu Click Functionality

	const onClickSubMenu = (menu) => {
		setActiveSubMenu(menu);
		if (menu === "edit-profile" || menu === "manage-events" || menu === "awards" || menu === "media-publication") {
			history.push(`/dashboard/edit/${menu}`);
		} else if (menu === "add-artwork") {
			onAddArtwork();
		} else if (menu === "view-profile") {
			history.push(`/artist/${gotDescriptionData && gotDescriptionData.slugId}`);
		} else if (menu === "edit-profile") {
			history.push("/dashboard/edit/edit-profile");
		} else if (menu === "manage-inventory") {
			history.push("/dashboard/artworks-inventory");
		} else if (menu === "manage-collection") {
			history.push("/artist/dashboard/user-collections");
		} else if (menu === "add-collection") {
			handleAddNewCollection();
		} else if (
			menu === "saved-artworks" ||
			menu === "artworks" ||
			menu === "followers" ||
			menu === "following-artists" ||
			menu === "following-galleries" ||
			menu === "signature-pending-artworks" ||
			menu === "blockchain-account" ||
			menu === "my-quotes" ||
			menu === "orders" ||
			menu === "received-orders" ||
			menu === "general" ||
			menu === "quotes" ||
			menu === "group-requests" ||
			menu === "owned-artwork"
		) {
			history.push(`/profile/${menu}`);
		} else if (menu === "manage-address" || menu === "add-new-address") {
			history.push(`/profile/address`);
		} else if (menu === "about-us") {
			window.open("https://art.rtistiq.com/en/about-us", "_blank");
		} else if (menu === "faq") {
			window.open("https://help.rtistiq.com/en/", "_blank");
		} else if (menu === "contact-us") {
			window.open("https://art.rtistiq.com/en/contact-us", "_blank");
		} else if (menu === "view_kyc") {
			onLoadKYC();
		}
	};

	const onAddArtwork = () => {
		let display = true;
		showModal(ModalTypes.sellAddArtworkModal, display);
	};

	return (
		<div className="artist-menu-main-wrapper">
			<div className="artist-new-menu-main-container">
				{menus.map((mainMenu, index) => (
					<div className="artist-menu-sub-main-caontainer" key={index} onClick={() => onClickMaineMenu(mainMenu.menuId)}>
						<div className="artist-menu-header-div">
							<img src={mainMenu.icon} alt={mainMenu.title} />
							<span>{mainMenu.title}</span>
						</div>
						{activeMenu === mainMenu.menuId && (
							<div className="artist-menu-child-menu-div">
								{mainMenu.subMenu.map((subMenuItm, subMenuIndex) => (
									<span
										className={activeSubMenu === subMenuItm.route ? "active-sub-menu" : ""}
										onClick={() => onClickSubMenu(subMenuItm.route)}
										key={subMenuIndex}
									>
										{subMenuItm.title}
									</span>
								))}
							</div>
						)}
					</div>
				))}
			</div>
			<div className="vertical-line"></div>
			<AddNewCollection setToShowBlank={setToShowBlank} />

			<Modal title={`KYC Documents`} width={700} footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<KYCDocumentView data={kycData} onCancel={handleCancel} />
			</Modal>
			<Modal
				title={`Artist Signature`}
				width={700}
				footer={null}
				visible={signatureModal}
				onOk={() => setSignatureModal(false)}
				onCancel={() => setSignatureModal(false)}
			>
				<ArtistSignature closeModal={() => setSignatureModal(false)} userToken={userToken} signatureData={artistSignatureData} />
			</Modal>
		</div>
	);
};

export default ArtistNewSideMenu;
