import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	userLoginData: {},
	profileData: {},
	googleLoginData: {},
	facebookLoginData: {},
	refreshLoginData: {},
	logoutData: {},
	toggleData: {},
	changePasswordData: {},
	twoFactorResult: {},
	chooseUserRoleResult: {},
	updateUserData: {},
	togglePushNotificationResult: {},
	resetAccountResult: {},
	initialUpdateResult: {},
	followUser: {},
	unfollowUser: {},
	removeFollower: {},
	notifications: {},
	countryList: {},
	createNewArtist: {},
	profileMobileData: {},
	appleLoginData: {},
	emailOtpData: {},
	contactUsResult: {},
	toggleBlockchainResult: {}
};
const UserManagementReducer = createApiReducer(initialState, [
	types.LOGIN_REQUEST,
	types.GET_USER_INFO_REQUEST,
	types.LOGIN_WITH_GOOGLE_REQUEST,
	types.LOGIN_WITH_FACEBOOK_REQUEST,
	types.LOGIN_WITH_REFRESH_TOKEN_REQUEST,
	types.LOGOUT_REQUEST,
	types.TOGGLE_TWO_FACTOR_REQUEST,
	types.TWO_FACTOR_AUTH_REQUEST,
	types.CHANGE_PASSWORD_REQUEST,
	types.CHOOSE_USER_ROLE_REQUEST,
	types.UPDATE_USER_INFO_REQUEST,
	types.TOGGLE_PUSH_NOTIFICATION_REQUEST,
	types.RESET_USER_ACCOUNT_REQUEST,
	types.INITIAL_UPDATE_REQUEST,
	types.FOLLOW_USER_REQUEST,
	types.UNFOLLOW_USER_REQUEST,
	types.REMOVE_FOLLOWER_REQUEST,
	types.GET_NOTIFICATIONS_REQUEST,
	types.GET_COUNTRY_LIST,
	types.CREATE_NEW_ARTIST,
	types.GET_USER_INFO_FOR_MOBILE_REQUEST,
	types.LOGIN_WITH_APPLE_REQUEST,
	types.EMAIL_OTP_VERIFICATION_REQUEST,
	types.CONTACT_US_REQUEST,
	types.TOGGLE_BLOCKCHAIN_ANONYMOUS_REQUEST
]);

export default UserManagementReducer;
