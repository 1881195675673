import { useDispatch, useSelector } from "react-redux";
import {
  getComments,
  addComment,
  updateComment,
  deleteComment
} from "../actions/CommentsAction";

//Get Comments
export const useGetComments = () => {
  const dispatch = useDispatch();
  const getAllComments = (pageNum, pageSize, parentId) => {
    dispatch(getComments(pageNum, pageSize, parentId));
  };
  // Reducer states
  const result = useSelector(state => state.comments.getCommentsData);

  return [getAllComments, result.data, result.error, result.loading];
};

//Add Comments
export const useAddComments = () => {
  const dispatch = useDispatch();
  const addAComment = (comment, parentId) => {
    dispatch(addComment(comment, parentId));
  };
  // Reducer states
  const result = useSelector(state => state.comments.addCommentsData);

  return [addAComment, result.data, result.error, result.loading];
};

//Update Comments
export const useUpdateComments = () => {
  const dispatch = useDispatch();
  const updateAComment = (comment, parentId) => {
    dispatch(updateComment(comment, parentId));
  };
  // Reducer states
  const result = useSelector(state => state.comments.updateCommentsData);

  return [updateAComment, result.data, result.error, result.loading];
};

//Delete Comments
export const useDeleteComments = () => {
  const dispatch = useDispatch();
  const deleteAComment = commentId => {
    dispatch(deleteComment(commentId));
  };
  // Reducer states
  const result = useSelector(state => state.comments.deleteCommentsData);

  return [deleteAComment, result.data, result.error, result.loading];
};
