import { ArtistUserTypesService } from "../services/ArtistUserTypesService";
import * as types from "./ActionsTypes";

// ========================================== Get User ArtistTypes ========================================== //
export const getMyArtistTypes = () => {
	return {
		actionType: types.GET_ARTIST_USER_TYPES_REQUEST,
		callAPI: () => ArtistUserTypesService.getMyArtistTypes(),
		stateObject: "getUserArtist"
	};
};

// ========================================== Get User ArtistTypes Using SlugId ========================================== //
export const getArtistTypesBySlugIdAction = SlugId => {
	return {
		actionType: types.GET_ARTIST_USER_TYPES_REQUEST,
		callAPI: () => ArtistUserTypesService.getArtistTypesBySlugId(SlugId),
		stateObject: "getUserArtist"
	};
};
// ========================================== Update User Artist Types ========================================== //
export const updateArtistUserTypes = formData => {
	return {
		actionType: types.UPDATE_ARTIST_USER_TYPES_REQUEST,
		callAPI: () => ArtistUserTypesService.updateArtistUserTypes(formData),
		stateObject: "updateUserArtist",
		isTransient: true
	};
};
