import styled from "styled-components";
import { Result } from "antd";

export const NfcResultWrapper = styled(Result)`
&.ant-result {
    padding: 30px 30px;
}
.ant-result-title {
    font-size: 20px;
    line-height: unset;
    text-align: center;
    font-weight: 600;
}
.ant-result-icon {
    margin-bottom: 15px;
    text-align: center;
}
.ant-result-icon > .anticon {
    font-size: 50px;
}
.ant-result-extra {
    margin-top: 15px;
    text-align: center;
}
`;
