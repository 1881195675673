//HomeManagement
export const GET_LATEST_ARTISTS_REQUEST = "GET_LATEST_ARTISTS_REQUEST";
export const GET_POPULAR_ARTISTS_REQUEST = "GET_POPULAR_ARTISTS_REQUEST";
export const GET_NEWS_LIST_REQUEST = "GET_NEWS_LIST_REQUEST";
export const GET_NEWS_BY_ID_REQUEST = "GET_NEWS_BY_ID_REQUEST";
export const GET_NEWS_BY_SLUG_ID_REQUEST = "GET_NEWS_BY_SLUG_ID_REQUEST";
export const GET_BLOGS_BY_ID_REQUEST = "GET_BLOGS_BY_ID_REQUEST";
export const GET_BLOGS_LIST_REQUEST = "GET_BLOGS_LIST_REQUEST";

// Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";

//Registration
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const GET_MAIL_REQUEST = "GET_MAIL_REQUEST";
export const GET_MAIL_SUCCESS = "GET_MAIL_SUCCESS";
export const GET_MAIL_FAILURE = "GET_MAIL_FAILURE";

//Send OTP to Mail
export const SEND_OTP_MAIL_REQUEST = "SEND_OTP_MAIL_REQUEST";
export const SEND_OTP_MAIL_SUCCESS = "SEND_OTP_MAIL_SUCCESS";
export const SEND_OTP_MAIL_FAILURE = "SEND_OTP_MAIL_FAILURE";

//Validate OTP
export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

//Reset Password
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// User management
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_FOR_MOBILE_REQUEST = "GET_USER_INFO_FOR_MOBILE_REQUEST";
export const LOGIN_WITH_GOOGLE_REQUEST = "LOGIN_WITH_GOOGLE_REQUEST";
export const LOGIN_WITH_FACEBOOK_REQUEST = "LOGIN_WITH_FACEBOOK_REQUEST";
export const LOGIN_WITH_REFRESH_TOKEN_REQUEST = "LOGIN_WITH_REFRESH_TOKEN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const TOGGLE_TWO_FACTOR_REQUEST = "TOGGLE_TWO_FACTOR_REQUEST";
export const TWO_FACTOR_AUTH_REQUEST = "TWO_FACTOR_AUTH_REQUEST";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHOOSE_USER_ROLE_REQUEST = "CHOOSE_USER_ROLE_REQUEST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const CREATE_NEW_ARTIST = "CREATE_NEW_ARTIST";
export const GET_SIGNATURE_PENDING_ARTWORKS = "GET_SIGNATURE_PENDING_ARTWORKS";
export const LOGIN_WITH_APPLE_REQUEST = "LOGIN_WITH_APPLE_REQUEST";
export const EMAIL_OTP_VERIFICATION_REQUEST = "EMAIL_OTP_VERIFICATION_REQUEST";
export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";

// Update UserInfo
export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST";

//Comments/Reviews
export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAILURE = "GET_COMMENTS_FAILURE";

export const ADD_COMMENTS_REQUEST = "ADD_COMMENTS_REQUEST";
export const ADD_COMMENTS_SUCCESS = "ADD_COMMENTS_SUCCESS";
export const ADD_COMMENTS_FAILURE = "ADD_COMMENTS_FAILURE";

export const UPDATE_COMMENTS_REQUEST = "UPDATE_COMMENTS_REQUEST";
export const UPDATE_COMMENTS_SUCCESS = "UPDATE_COMMENTS_SUCCESS";
export const UPDATE_COMMENTS_FAILURE = "UPDATE_COMMENTS_FAILURE";

export const DELETE_COMMENTS_REQUEST = "DELETE_COMMENTS_REQUEST";
export const DELETE_COMMENTS_SUCCESS = "DELETE_COMMENTS_SUCCESS";
export const DELETE_COMMENTS_FAILURE = "DELETE_COMMENTS_FAILURE";

// Cart Management
export const GET_CART_LIST_REQUEST = "GET_CART_LIST_REQUEST";
export const GET_CART_LIST_SUCCESS = "GET_CART_LIST_SUCCESS";
export const GET_CART_LIST_FAILURE = "GET_CART_LIST_FAILURE";

//Shipment
export const GET_SHIPPING_ADDRESS_REQUEST = "GET_SHIPPING_ADDRESS_REQUEST";
export const ADD_SHIPPING_ADDRESS_REQUEST = "ADD_SHIPPING_ADDRESS_REQUEST";
export const UPDATE_SHIPPING_ADDRESS_REQUEST = "UPDATE_SHIPPING_ADDRESS_REQUEST";
export const DELETE_SHIPPING_ADDRESS_REQUEST = "DELETE_SHIPPING_ADDRESS_REQUEST";
export const SET_DEFAULT_SHIPPING_ADDRESS_REQUEST = "SET_DEFAULT_SHIPPING_ADDRESS_REQUEST";

//Billing
export const GET_BILLING_ADDRESS_REQUEST = "GET_BILLING_ADDRESS_REQUEST";
export const ADD_BILLING_ADDRESS_REQUEST = "ADD_BILLING_ADDRESS_REQUEST";
export const UPDATE_BILLING_ADDRESS_REQUEST = "UPDATE_BILLING_ADDRESS_REQUEST";
export const DELETE_BILLING_ADDRESS_REQUEST = "DELETE_BILLING_ADDRESS_REQUEST";
export const SET_DEFAULT_BILLING_ADDRESS_REQUEST = "SET_DEFAULT_BILLING_ADDRESS_REQUEST";

//PickUp Address
export const GET_PICKUP_ADDRESS_REQUEST = "GET_PICKUP_ADDRESS_REQUEST";
export const ADD_PICKUP_ADDRESS_REQUEST = "ADD_PICKUP_ADDRESS_REQUEST";
export const UPDATE_PICKUP_ADDRESS_REQUEST = "UPDATE_PICKUP_ADDRESS_REQUEST";
export const DELETE_PICKUP_ADDRESS_REQUEST = "DELETE_PICKUP_ADDRESS_REQUEST";
export const SET_DEFAULT_PICKUP_ADDRESS_REQUEST = "SET_DEFAULT_PICKUP_ADDRESS_REQUEST";

//Cart Managment
export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

//Toggle Push Notification
export const TOGGLE_PUSH_NOTIFICATION_REQUEST = "TOGGLE_PUSH_NOTIFICATION_REQUEST";

//Toggle Blockchain Anonymous
export const TOGGLE_BLOCKCHAIN_ANONYMOUS_REQUEST = "TOGGLE_BLOCKCHAIN_ANONYMOUS_REQUEST";

// Reset User Account
export const RESET_USER_ACCOUNT_REQUEST = "RESET_USER_ACCOUNT_REQUEST";

// Product Details Management
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";
export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST";
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_FAILURE = "GET_PRODUCT_DETAIL_FAILURE";
export const SET_UPDATE_PRODUCT_REDUCER_STATES = "SET_UPDATE_PRODUCT_REDUCER_STATES";
export const GET_SELLER_QUOTES_REQUEST = "GET_SELLER_QUOTES_REQUEST";
export const UPDATE_SELLER_QUOTE_STATUS_REQUEST = "UPDATE_SELLER_QUOTE_STATUS_REQUEST";
export const GET_SELLER_PRICE_REQUEST_REQUEST = "GET_SELLER_PRICE_REQUEST_REQUEST";
export const UPDATE_SELLER_PRICE_STATUS_REQUEST = "UPDATE_SELLER_PRICE_STATUS_REQUEST";

// Get Product By Slug
export const GET_PRODUCT_BY_SLUG_REQUEST = "GET_PRODUCT_BY_SLUG_REQUEST";
export const GET_SOLD_ARTWORK_DETAILS_REQUEST = "GET_SOLD_ARTWORK_DETAILS_REQUEST";

// Get product ownership history
export const GET_PRODUCT_OWNERSHIP_HISTORY_REQUEST = "GET_PRODUCT_OWNERSHIP_HISTORY_REQUEST";

// Product List Management
export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const PRODUCT_LIKE_REQUEST = "PRODUCT_LIKE_REQUEST";
export const PRODUCT_LIKE_SUCCESS = "PRODUCT_LIKE_SUCCESS";
export const PRODUCT_LIKE_FAILURE = "PRODUCT_LIKE_FAILURE";

export const PRODUCT_DISLIKE_REQUEST = "PRODUCT_DISLIKE_REQUEST";
export const PRODUCT_DISLIKE_SUCCESS = "PRODUCT_DISLIKE_SUCCESS";
export const PRODUCT_DISLIKE_FAILURE = "PRODUCT_DISLIKE_FAILURE";

export const GET_FILTER_ARTWORKS_REQUEST = "GET_FILTER_ARTWORKS_REQUEST";
export const GET_FILTER_ARTWORKS_SUCCESS = "GET_FILTER_ARTWORKS_SUCCESS";
export const GET_FILTER_ARTWORKS_FAILURE = "GET_FILTER_ARTWORKS_FAILURE";

export const GET_SELLER_PRODUCTS_REQUEST = "GET_SELLER_PRODUCTS_REQUEST";
export const GET_SELLER_ARTWORKS_REQUEST = "GET_SELLER_ARTWORKS_REQUEST";

export const UPDATE_PRODUCT_STATUS_REQUEST = "UPDATE_PRODUCT_STATUS_REQUEST";

export const GET_GALLERY_ADDED_PRODUCTS = "GET_GALLERY_ADDED_PRODUCTS";

export const GET_ARTIST_PRODUCTS = "GET_ARTIST_PRODUCTS";
export const GET_GALLERY_VIEW = "GET_GALLERY_VIEW";
export const GET_INVENTORY = "GET_INVENTORY";
export const GET_OWNED_ARTWORKS = "GET_OWNED_ARTWORKS";
export const UPDATE_IMAGES_REQUEST = "UPDATE_IMAGES_REQUEST";
export const NFC_BYPASS_REQUEST = "NFC_BYPASS_REQUEST";
export const UPDATE_PRICE_REQUEST = "UPDATE_PRICE_REQUEST";


//Bulk Upload
export const BULK_UPLOAD_REQUEST = "BULK_UPLOAD_REQUEST";
export const BULK_FORM_DOWNLOAD_REQUEST = "BULK_FORM_DOWNLOAD_REQUEST";

// Order Management
export const GET_ORDER_HISTORY_REQUEST = "GET_ORDER_HISTORY_REQUEST";
export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_SELLER_ORDER_LIST_REQUEST = "GET_SELLER_ORDER_LIST_REQUEST";
export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const CHECK_OUT_CART_REQUEST = "CHECK_OUT_CART_REQUEST";
export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const RETURN_ORDER_REQUEST = "RETURN_ORDER_REQUEST";
export const ORDER_INVOICE_REQUEST = "ORDER_INVOICE_REQUEST";
export const GET_ORDER_DETAILS_BY_ID = "GET_ORDER_DETAILS_BY_ID";
export const GET_SIGNATURE_PENDING_ORDERS = "GET_SIGNATURE_PENDING_ORDERS";
export const GET_ORDER_SUMMARY = "GET_ORDER_SUMMARY";
export const NEED_HELP_REQUEST = "NEED_HELP_REQUEST";
export const CREATE_SHIPMENT_REQUEST = "CREATE_SHIPMENT_REQUEST";
export const WAY_BILL_REQUEST = "WAY_BILL_REQUEST";
export const PICKUP_DETAILS_REQUEST = "PICKUP_DETAILS_REQUEST";
// Add to WishList
export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";

//Get Wishlist
export const GET_WISHLIST_REQUEST = "GET_WISHLIST_REQUEST";

// Delete from  WishList
export const DELETE_FROM_WISHLIST_REQUEST = "DELETE_FROM_WISHLIST_REQUEST";

// Disclose Price
export const DISCLOSE_PRICE_REQUEST = "DISCLOSE_PRICE_REQUEST";

// Quote Rate
export const QUOTE_RATE_REQUEST = "QUOTE_RATE_REQUEST";

//Initial Update Managemet
export const INITIAL_UPDATE_REQUEST = "INITIAL_UPDATE_REQUEST";

//Add Product
export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";
export const CLEAR_TOASTS = "CLEAR_TOASTS";
export const CHECK_RFID_EXISTS = "CHECK_RFID_EXISTS";
export const SET_ADD_PRODUCT_REDUCER_STATES = "SET_ADD_PRODUCT_REDUCER_STATES";
export const CHECK_ADD_PRODUCT_ELIGIBILITY = "CHECK_ADD_PRODUCT_ELIGIBILITY";



export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";

export const SET_BASIC_DETAILS = "SET_BASIC_DETAILS";
export const SET_PS_DETAILS = "SET_PS_DETAILS";
export const SET_IV_DETAILS = "SET_IV_DETAILS";
export const SET_FEATURES_DETAILS = "SET_FEATURES_DETAILS";
export const SET_ARTIST_DETAILS = "SET_ARTIST_DETAILS";
export const SET_PRICING_DETAILS = "SET_PRICING_DETAILS";
export const SET_LOGISTIC_DETAILS = "SET_LOGISTIC_DETAILS";
export const SET_BLOCKCHAIN_DETAILS = "SET_BLOCKCHAIN_DETAILS";
//Update Product
export const SAVE_BASIC_DETAILS = "SAVE_BASIC_DETAILS";
export const SAVE_ARTIST_DETAILS = "SAVE_ARTIST_DETAILS";
export const SAVE_PRICING_DETAILS = "SAVE_PRICING_DETAILS";
export const SAVE_LOGISTIC_DETAILS = "SAVE_LOGISTIC_DETAILS";
export const SAVE_PS_DETAILS = "SAVE_PS_DETAILS";

export const SAVE_IV_DETAILS = "SAVE_IV_DETAILS";
export const SAVE_FEATURES_DETAILS = "SAVE_FEATURES_DETAILS";
export const SAVE_BLOCKCHAIN_DETAILS = "SAVE_BLOCKCHAIN_DETAILS";
export const CLEAR_ADD_PRODUCT_REDUCER_STATES = "CLEAR_ADD_PRODUCT_REDUCER_STATES";
export const CLEAR_UPDATE_PRODUCT_REDUCER_STATES = "CLEAR_UPDATE_PRODUCT_REDUCER_STATES";

export const GET_PARENTS_BY_CATEGORY_REQUEST = "GET_PARENTS_BY_CATEGORY_REQUEST";
export const GET_PARENTS_BY_CATEGORY_SUCCESS = "GET_PARENTS_BY_CATEGORY_SUCCESS";
export const GET_PARENTS_BY_CATEGORY_FAILURE = "GET_PARENTS_BY_CATEGORY_FAILURE";

export const GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST = "GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST";
export const GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS = "GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS";
export const GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE = "GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE";

//Get Add Artwork Property's

export const GET_PROPERTY_VALUES = "GET_PROPERTY_VALUES";


// Search Artworks
export const SET_FILTER_SEARCH_ARTWORKS_REDUCER_STATES = "SET_FILTER_SEARCH_ARTWORKS_REDUCER_STATES";

export const GET_FILTER_CATEGORY_PROPERTIES_REQUEST = "GET_FILTER_CATEGORY_PROPERTIES_REQUEST";
export const GET_FILTER_CATEGORY_PROPERTIES_SUCCESS = "GET_FILTER_CATEGORY_PROPERTIES_SUCCESS";
export const GET_FILTER_CATEGORY_PROPERTIES_FAILURE = "GET_FILTER_CATEGORY_PROPERTIES_FAILURE";

export const GET_BREADCRUMBS_REQUEST = "GET_BREADCRUMBS_REQUEST";
export const GET_BREADCRUMBS_SUCCESS = "GET_BREADCRUMBS_SUCCESS";
export const GET_BREADCRUMBS_FAILURE = "GET_BREADCRUMBS_FAILURE";

export const CATEGORY_ARTWORKS_LIST_REQUEST = "CATEGORY_ARTWORKS_LIST_REQUEST";
export const CATEGORY_ARTWORKS_LIST_SUCCESS = "CATEGORY_ARTWORKS_LIST_SUCCESS";
export const CATEGORY_ARTWORKS_LIST_FAILURE = "CATEGORY_ARTWORKS_LIST_FAILURE";
export const CATEGORY_ARTWORKS_LIST_CLEAR = "CATEGORY_ARTWORKS_LIST_CLEAR";

//Category Management
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const GET_PRODUCT_SEARCH_REQUEST = "GET_PRODUCT_SEARCH_REQUEST";

export const GET_CATEGORY_PROPERTIES_REQUEST = "GET_CATEGORY_PROPERTIES_REQUEST";
export const GET_CATEGORY_PROPERTIES_SUCCESS = "GET_CATEGORY_PROPERTIES_SUCCESS";
export const GET_CATEGORY_PROPERTIES_FAILURE = "GET_CATEGORY_PROPERTIES_FAILURE";

export const GET_CATEGORY_BY_ID_SLUG_REQUEST = "GET_CATEGORY_BY_ID_SLUG_REQUEST";
export const GET_CATEGORY_BY_ID_SLUG_SUCCESS = "GET_CATEGORY_BY_ID_SLUG_SUCCESS";
export const GET_CATEGORY_BY_ID_SLUG_FAILURE = "GET_CATEGORY_BY_ID_SLUG_FAILURE";

export const REFRESH_TOKEN_DATA_REQUEST = "REFRESH_TOKEN_DATA_REQUEST";
export const REFRESH_TOKEN_DATA_SUCCESS = "REFRESH_TOKEN_DATA_SUCCESS";
export const REFRESH_TOKEN_DATA_FAILURE = "REFRESH_TOKEN_DATA_FAILURE";

export const REQUEST_ERROR_INTERCEPT = "REQUEST_ERROR_INTERCEPT";

//Artist Profile
export const GET_ARTIST_TYPES_BY_ID_REQUEST = "GET_ARTIST_TYPES_BY_ID_REQUEST";

export const GET_ARTIST_TYPES_REQUEST = "GET_ARTIST_TYPES_REQUEST";

export const ADD_ARTIST_TYPES_REQUEST = "ADD_ARTIST_TYPES_REQUEST";

export const UPDATE_ARTIST_TYPES_REQUEST = "UPDATE_ARTIST_TYPES_REQUEST";

export const DELETE_ARTIST_TYPES_REQUEST = "DELETE_ARTIST_TYPES_REQUEST";

export const GET_ARTIST_BASIC_DESCRIPTION_REQUEST = "GET_ARTIST_BASIC_DESCRIPTION_REQUEST";

export const UPDATE_ARTIST_PROFILE_REQUEST = "UPDATE_ARTIST_PROFILE_REQUEST";

export const UPDATE_SOCIAL_LINKS_REQUEST = "UPDATE_SOCIAL_LINKS_REQUEST";

export const UPDATE_ARTIST_PROFILE_IMAGES_REQUEST = "UPDATE_ARTIST_PROFILE_IMAGES_REQUEST";

export const GET_MY_ARTIST_FOLLOWING_REQUEST = "GET_MY_ARTIST_FOLLOWING_REQUEST";

export const FOLLOW_USER_REQUEST = "FOLLOW_USER_REQUEST";

export const UNFOLLOW_USER_REQUEST = "UNFOLLOW_USER_REQUEST";

export const REMOVE_FOLLOWER_REQUEST = "REMOVE_FOLLOWER_REQUEST";

export const GET_FOLLOWING_LIST_REQUEST = "GET_FOLLOWING_LIST_REQUEST";

export const GET_FOLLOWERS_LIST_REQUEST = "GET_FOLLOWERS_LIST_REQUEST";

export const GET_MY_INTERESTS_REQUEST = "GET_MY_INTERESTS_REQUEST";

export const UPDATE_ARTIST_INTERESTS_REQUEST = "UPDATE_ARTIST_INTERESTS_REQUEST";

export const GET_ARTIST_USER_TYPES_BY_USER_ID_REQUEST = "GET_ARTIST_USER_TYPES_BY_USER_ID_REQUEST";

export const GET_ARTIST_USER_TYPES_REQUEST = "GET_ARTIST_USER_TYPES_REQUEST";

export const UPDATE_ARTIST_USER_TYPES_REQUEST = "UPDATE_ARTIST_USER_TYPES_REQUEST";

export const GET_MY_SOLD_PRODUCTS = "GET_MY_SOLD_PRODUCTS";

export const GET_SOLD_PRODUCTS = "GET_SOLD_PRODUCTS";

// Artist List
export const GET_ARTISTS_LIST_REQUEST = "GET_ARTISTS_LIST_REQUEST";

export const GET_GALLERY_MEMBERSHIP_REQUESTS = "GET_GALLERY_MEMBERSHIP_REQUESTS";
export const UPDATE_GALLERY_MEMBERSHIP_REQUEST = "UPDATE_GALLERY_MEMBERSHIP_REQUEST";

//Filter Artist List
export const FILTER_ARTIST_BY_TYPES_REQUEST = "FILTER_ARTIST_BY_TYPES_REQUEST";

export const FILTER_ADMIN_ARTIST_BY_TYPES_REQUEST = "FILTER_ADMIN_ARTIST_BY_TYPES_REQUEST";

// Artist Gallery
export const GET_GALLERY_LIST_REQUEST = "GET_GALLERY_LIST_REQUEST";
export const ADD_NEW_GALLERY_REQUEST = "ADD_NEW_GALLERY_REQUEST";
export const UPDATE_GALLERY_REQUEST = "UPDATE_GALLERY_REQUEST";
export const DELETE_FROM_GALLERY_REQUEST = "DELETE_FROM_GALLERY_REQUEST";

// Artist Event
export const GET_EVENT_LIST_REQUEST = "GET_EVENT_LIST_REQUEST";
export const ADD_NEW_EVENT_REQUEST = "ADD_NEW_EVENT_REQUEST";
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const DELETE_FROM_EVENT_REQUEST = "DELETE_FROM_EVENT_REQUEST";

// Artist Award
export const GET_AWARD_LIST_REQUEST = "GET_AWARD_LIST_REQUEST";
export const ADD_AWARD_REQUEST = "ADD_AWARD_REQUEST";
export const UPDATE_AWARD_REQUEST = "UPDATE_AWARD_REQUEST";
export const DELETE_AWARD_REQUEST = "DELETE_AWARD_REQUEST";

// Artist Historical Data
export const GET_HISTORICAL_DATA_LIST_REQUEST = "GET_HISTORICAL_DATA_LIST_REQUEST";
export const ADD_HISTORICAL_DATA_REQUEST = "ADD_HISTORICAL_DATA_REQUEST";
export const UPDATE_HISTORICAL_DATA_REQUEST = "UPDATE_HISTORICAL_DATA_REQUEST";
export const DELETE_HISTORICAL_DATA_REQUEST = "DELETE_HISTORICAL_DATA_REQUEST";

// Artist Blogs
export const GET_MY_BLOGS_REQUEST = "GET_MY_BLOGS_REQUEST";
export const GET_BLOGS_BY_USER_REQUEST = "GET_BLOGS_BY_USER_REQUEST";
export const ADD_BLOG_REQUEST = "ADD_BLOG_REQUEST";
export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST";
export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";

//Artist Collections
export const GET_COLLECTION_BY_ARTIST_REQUEST = "GET_COLLECTION_BY_ARTIST_REQUEST";

export const ADD_COLLECTION_BY_ARTIST_REQUEST = "ADD_COLLECTION_BY_ARTIST_REQUEST";

export const UPDATE_COLLECTION_REQUEST = "UPDATE_COLLECTION_REQUEST";

export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";

export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST";

export const DELETE_COLLECTION_ITEM_REQUEST = "DELETE_COLLECTION_ITEM_REQUEST";

export const GET_ALL_COLLECTIONS_REQUEST = "GET_ALL_COLLECTIONS_REQUEST";
//-------------------------------------------------------------------
export const FILTER_MY_PRODUCT_REQUEST = "FILTER_MY_PRODUCT_REQUEST";

// HomeScreen Banner
export const GET_HOME_BANNER_REQUEST = "GET_HOME_BANNER_REQUEST";

// HeaderCount
export const GET_HEADER_COUNT_REQUEST = "GET_HEADER_COUNT_REQUEST";

export const GET_SUGGESTED_ARTWORKS_REQUEST = "GET_SUGGESTED_ARTWORKS_REQUEST";
export const GET_CONTINUE_YOUR_SEARCH_REQUEST = "GET_CONTINUE_YOUR_SEARCH_REQUEST";

//Recommended & Trending Categories
export const GET_RECOMMENDED_CATEGORIES_REQUEST = "GET_RECOMMENDED_CATEGORIES_REQUEST";
export const GET_TRENDING_CATEGORIES_REQUEST = "GET_TRENDING_CATEGORIES_REQUEST";
export const GET_ARTWORKS_ON_OFFER_REQUEST = "GET_ARTWORKS_ON_OFFER_REQUEST";

// Recommendations
export const GET_SIMILAR_PRODUCTS_REQUEST = "GET_SIMILAR_PRODUCTS_REQUEST";
export const GET_FEATURED_ARTISTS_REQUEST = "GET_FEATURED_ARTISTS_REQUEST";
export const GET_LATEST_ARTWORKS_REQUEST = "GET_LATEST_ARTWORKS_REQUEST";
export const GET_LIKE_WISHLIST_REQUEST = "GET_LIKE_WISHLIST_REQUEST";
export const GET_POPULAR_ARTWORKS_REQUEST = "GET_POPULAR_ARTWORKS_REQUEST";
export const GET_FEATURED_ARTWORKS_REQUEST = "GET_FEATURED_ARTWORKS_REQUEST";

//Selected Collections
export const GET_SELECTED_COLLECTION_REQUEST = "GET_SELECTED_COLLECTION_REQUEST";
export const GET_SELECTED_COLLECTION_SUCCESS = "GET_SELECTED_COLLECTION_SUCCESS";
export const GET_SELECTED_COLLECTION_FAILURE = "GET_SELECTED_COLLECTION_FAILURE";

//Search Result
export const GET_SEARCH_REQUEST = "GET_SEARCH_REQUEST";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_FAILURE = "GET_SEARCH_FAILURE";

//Save Search
export const SAVE_SEARCH_REQUEST = "SAVE_SEARCH_REQUEST";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH_FAILURE = "SAVE_SEARCH_FAILURE";

// Featured Gallery List
export const GET_FEATURED_GALLERY_REQUEST = "GET_FEATURED_GALLERY_REQUEST";

//Get Original Artworks
export const GET_ORIGINAL_ARTWORKS_REQUEST = "GET_ORIGINAL_ARTWORKS_REQUEST";

//Gallery Page
export const GET_GALLERY_PROFILE_REQUEST = "GET_GALLERY_PROFILE_REQUEST";
export const UPDATE_GALLERY_PROFILE_REQUEST = "UPDATE_GALLERY_PROFILE_REQUEST";
export const UPDATE_GALLERY_PROFILE_IMAGES_REQUEST = "UPDATE_GALLERY_PROFILE_IMAGES_REQUEST";
export const GET_MY_GALLERY_FOLLOWING_REQUEST = "GET_MY_GALLERY_FOLLOWING_REQUEST";
export const GET_MY_FOLLOWERS_REQUEST = "GET_MY_FOLLOWERS_REQUEST";
export const GET_MY_FOLLOWING_REQUEST = "GET_MY_FOLLOWING_REQUEST";
export const GET_ARTIST_LIST_REQUEST = "GET_ARTIST_LIST_REQUEST";
export const GET_GALLERY_ARTIST_LIST_REQUEST = "GET_GALLERY_ARTIST_LIST_REQUEST";
export const ADD_NEW_ARTIST_TO_GALLERY_REQUEST = "ADD_NEW_ARTIST_TO_GALLERY_REQUEST";
export const DELETE_ARTIST_FROM_GALLERY_REQUEST = "DELETE_ARTIST_FROM_GALLERY_REQUEST";

export const FILTER_GALLERIES_REQUEST = "FILTER_GALLERIES_REQUEST";

export const UPDATE_GALLERY_ADDRESS_REQUEST = "UPDATE_GALLERY_ADDRESS_REQUEST";
export const SEARCH_FOR_ARTISTS = "SEARCH_FOR_ARTISTS";

// Gallery Event
export const GET_EVENT_LIST_GALLERY_REQUEST = "GET_EVENT_LIST_GALLERY_REQUEST";
export const ADD_NEW_EVENT_GALLERY_REQUEST = "ADD_NEW_EVENT_GALLERY_REQUEST";
export const UPDATE_EVENT_GALLERY_REQUEST = "UPDATE_EVENT_GALLERY_REQUEST";
export const DELETE_FROM_EVENT_GALLERY_REQUEST = "DELETE_FROM_EVENT_GALLERY_REQUEST";

// Gallery Award
export const GET_AWARD_LIST_GALLERY_REQUEST = "GET_AWARD_LIST_GALLERY_REQUEST";
export const ADD_AWARD_GALLERY_REQUEST = "ADD_AWARD_GALLERY_REQUEST";
export const UPDATE_AWARD_GALLERY_REQUEST = "UPDATE_AWARD_GALLERY_REQUEST";
export const DELETE_AWARD_GALLERY_REQUEST = "DELETE_AWARD_GALLERY_REQUEST";

// Gallery Historical Data
export const GET_HISTORICAL_DATA_LIST_GALLERY_REQUEST = "GET_HISTORICAL_DATA_LIST_GALLERY_REQUEST";
export const ADD_HISTORICAL_DATA_GALLERY_REQUEST = "ADD_HISTORICAL_DATA_GALLERY_REQUEST";
export const UPDATE_HISTORICAL_DATA_GALLERY_REQUEST = "UPDATE_HISTORICAL_DATA_GALLERY_REQUEST";
export const DELETE_HISTORICAL_DATA_GALLERY_REQUEST = "DELETE_HISTORICAL_DATA_GALLERY_REQUEST";

//Notifications
export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";

//BlockChain
export const GET_BLOCKCHAIN_ACCOUNTS_REQUEST = "GET_BLOCKCHAIN_ACCOUNTS_REQUEST";
export const CREATE_BLOCKCHAIN_ACCOUNT_REQUEST = "CREATE_BLOCKCHAIN_ACCOUNT_REQUEST";
export const BUY_ARTWORK_TRANSACTION_REQUEST = "BUY_ARTWORK_TRANSACTION_REQUEST";
export const ADD_SIGNED_MESSAGE_REQUEST = "ADD_SIGNED_MESSAGE_REQUEST";
export const GET_CERTIFICATE_REQUEST = "GET_CERTIFICATE_REQUEST";
export const ADD_SIGNATURE_METADATA_UPDATE = "ADD_SIGNATURE_METADATA_UPDATE"

//Payment
export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const CONVERT_CURRENCY_REQUEST = "CONVERT_CURRENCY_REQUEST";
export const STRIPE_PAYMENT_REQUEST = "STRIPE_PAYMENT_REQUEST";
export const STRIPE_PAYMENT_INTENT = "STRIPE_PAYMENT_INTENT";
export const STRIPE_PAYMENT_CONFIRMATION = "STRIPE_PAYMENT_CONFIRMATION";
export const GET_PAYPAL_ID_REQUEST = "GET_PAYPAL_ID_REQUEST";
export const PAYPAL_PAYMENT_REQUEST = "PAYPAL_PAYMENT_REQUEST";
export const PAYPAL_PAYMENT_FAILURE_REQUEST = "PAYPAL_PAYMENT_FAILURE_REQUEST";

//My Quote
export const GET_MY_QUOTE_REQUEST = "GET_MY_QUOTE_REQUEST";
export const DELETE_MY_QUOTE_REQUEST = "DELETE_MY_QUOTE_REQUEST";



//KYC Document Section

export const UPDATE_KYC_DOCUMENT = "UPDATE_KYC_DOCUMENT";
export const GET_OWN_KYC = "GET_OWN_KYC";



//Wallet ACtion types

export const CONNECT_WALLET = "CONNECT_WALLET";
export const DISCONNECT_WALLET = "DISCONNECT_WALLET";
export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";