import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
import Util from "../util/Util";

export class BlockchainService {
    static API = APIUrlConstants.API_URLS;
    static service = new BaseService();

    //Get Blockchain Accounts List
    static async getBlockchainAccount(pageNum, pageSize) {
        const params = { pageNum, pageSize };
        return this.service.execute(this.API.getBlockchainAccount, HTTP_METHOD.GET, {
            params
        });
    }

    //Create Blockchain Account
    static async createBlockchainAccount(body) {
        return this.service.execute(this.API.createBlockchainAccount, HTTP_METHOD.POST, {
            body
        });
    }

    //Change Artwork Transaction
    static async buyArtWorksTransaction(body) {
        return this.service.execute(this.API.buyArtWorksTransaction, HTTP_METHOD.POST, {
            body
        });
    }

    //Add Signed Message
    static async addSignedMessage(body) {
        return this.service.execute(this.API.addSignedMessage, HTTP_METHOD.POST, {
            body
        });
    }

    //Get Blockchain Certificate
    static async getCertificate(RFID) {
        const params = { RFID };
        return this.service.execute(this.API.getCertificate, HTTP_METHOD.GET, {
            params
        });
    }

    static async metaDataSignatureUpdates(body) {
        return this.service.execute(this.API.metaDataUpdates, HTTP_METHOD.POST, {
            body
        });
    }
}
