import React, { useEffect } from "react";
import SDK, { orderHooks, paymentHooks } from "ecommerce-sdk/src/index";
import assets from "assets";
import { useTranslation } from "react-i18next";
import {
	PaymentCheckoutContainer,
	PaymentCheckoutWrapper,
	ORDivider,
	PaymentCheckoutTitle,
	PaymentCheckoutSubTitle,
	PaymentCheckoutCardImages,
	PaymentResult,
	ConnectWallectConatiner,
} from "../Payment/PaymentCheckout.styled";
import { StyledRawButton } from "common/RawButton/RawButton.styled";
import MainContainer from "common/MainContainer";
import ReactGA from "react-ga";
import { isArray, isNil } from "lodash";
import { MoeTrackEvent } from "utils/moEngageHooks";
import TagManager from "react-gtm-module";

export default function OrderConfirm({ history, match }) {
	const { useGetOrderDetailsById } = orderHooks;
	const { t } = useTranslation();

	const [getOrderDetails, getOrderDetailsSuccess, , getOrderDetailsLoading] = useGetOrderDetailsById();

	useEffect(() => {
		if (match.params.orderId) {
			getOrderDetails(match.params.orderId);
		}
	}, [match.params.orderId]);

	useEffect(() => {
		if (getOrderDetailsSuccess) {
			let productItem = [];
			getOrderDetailsSuccess &&
				getOrderDetailsSuccess.productList &&
				getOrderDetailsSuccess.productList.map((data, index) => {
					let obj = {
						id: data._id,
						name: data.productName,

						artist: data.artistName,

						quantity: 1,
						price: data.price,
					};
					productItem.push(obj);
				});
			//React Tag Manager Implementaion
			const tagManagerArgs = {
				gtmId: "GTM-PT6MSSQ",
				events: {
					purchase: {
						transaction_id: getOrderDetailsSuccess._id,
						affiliation: "Rtistiq Product Purchase",
						value: getOrderDetailsSuccess.orderTotal,
						currency: getOrderDetailsSuccess.currency,
						tax: getOrderDetailsSuccess.totalOrderTax,
						shipping: getOrderDetailsSuccess.totalShippingChargeInBase,
						items: productItem,
					},
				},
			};

			TagManager.initialize(tagManagerArgs);

			ReactGA.plugin.require("ec");
			ReactGA.plugin.execute("ec", "setAction", "purchase", {
				transaction_id: getOrderDetailsSuccess._id,
				affiliation: "Rtistiq Product Purchase",
				value: getOrderDetailsSuccess.orderTotal,
				currency: getOrderDetailsSuccess.currency,
				tax: getOrderDetailsSuccess.totalOrderTax,
				shipping: getOrderDetailsSuccess.totalShippingChargeInBase,
				items: productItem,
			});

			ReactGA.plugin.execute("ec", "clear");
			triggerMoeCheckoutCompletedEvent(getOrderDetailsSuccess);
		}
	}, [getOrderDetailsSuccess]);

	const triggerMoeCheckoutCompletedEvent = (getOrderDetailsSuccess) => {
		if (!isNil(getOrderDetailsSuccess)) {
			let productName = getOrderDetailsSuccess.productList.map((s) => s.productName);
			let productId = getOrderDetailsSuccess.productList.map((s) => s._id);
			let productUrl = getOrderDetailsSuccess.productList.map(
				(s) => `${window.location.origin}/en/art/${s.medium.slugId}/${s.slugId}/${s.stockId}`
			);
			let productPrice = getOrderDetailsSuccess.productList.map((s) => s.sellingPrice);
			let productImageUrl = getOrderDetailsSuccess.productList.map((s) => s.displayPicture);
			let category = getOrderDetailsSuccess.productList.map((s) => s.medium && s.medium.name);
			let categoryId = getOrderDetailsSuccess.productList.map((s) => s.medium && s.medium._id);
			MoeTrackEvent("Purchase Completed", {
				ProductName: isArray(productName) ? productName.join(",") : "",
				ProductId: isArray(productId) ? productId.join(",") : "",
				ProductUrl: isArray(productUrl) ? productUrl.join(",") : "",
				Currency: getOrderDetailsSuccess.currency,
				ProductPrice: isArray(productPrice) ? productPrice.join(",") : "",
				Category: isArray(category) ? category.join(",") : "",
				CategoryId: isArray(categoryId) ? categoryId.join(",") : "",
				ProductImageUrl: isArray(productImageUrl) ? productImageUrl.join(",") : "",
				TotalQuantity: getOrderDetailsSuccess.productList && getOrderDetailsSuccess.productList.length,
				TotalItemPrice: getOrderDetailsSuccess.subTotal,
				ShippingCharge: getOrderDetailsSuccess.totalShippingCharge,
				TotalPrice: getOrderDetailsSuccess.orderTotal,
				PaymentType: getOrderDetailsSuccess.paymentType,
				OrderId: getOrderDetailsSuccess._id,
			});
		}
	};

	const fetchResult = (
		<PaymentResult
			title={t("Processing") + "..."}
			subTitle={t("Fetching order details")}
			extra={
				[
					// <StyledRawButton key="cart" onClick={() => history.push("/cart")}>
					// 	Go to Cart
					// </StyledRawButton>,
					// <StyledRawButton key="status" onClick={() => getOrderDetails(match.params.orderId)}>
					// 	Check order status
					// </StyledRawButton>
				]
			}
		/>
	);

	const successResult = (
		<PaymentResult
			status="success"
			title={t("Successfully completed the payment") + "!"}
			subTitle={`${t("Order number")}: ${getOrderDetailsSuccess && getOrderDetailsSuccess._id}`}
			extra={[
				<StyledRawButton key="orders" onClick={() => history.push("/profile/orders")}>
					{t("Go to your orders")}
				</StyledRawButton>,
			]}
		/>
	);

	const failureResult = (
		<PaymentResult
			status="error"
			title={t("Payment Failed") + "!"}
			subTitle={`${t("Order number")}: ${match.params.orderId}`}
			extra={[
				<StyledRawButton key="cart" onClick={() => history.push("/cart")}>
					{t("Go to Cart")}
				</StyledRawButton>,
				<StyledRawButton key="status" onClick={() => getOrderDetails(match.params.orderId)}>
					Check order status
				</StyledRawButton>,
			]}
		/>
	);

	const expiredResult = (
		<PaymentResult
			status="warning"
			title="Expired"
			subTitle="Order details not found. Please try with a valid order."
			extra={[
				<StyledRawButton key="cart" onClick={() => history.push("/cart")}>
					{t("Go to Cart")}
				</StyledRawButton>,
			]}
		/>
	);
	const renderSwitchCase = () => {
		switch (getOrderDetailsSuccess && getOrderDetailsSuccess.orderStatus) {
			case 0:
				// [SUCCESS PAYMENT]
				return fetchResult;
			case 1:
				// [SUCCESS PAYMENT]
				return successResult;
			case 2:
				// [FAILURE PAYMENT]
				return failureResult;
			case 3:
				// [HOLD PAYMENT]
				return successResult;
			default:
				break;
		}
	};
	return (
		<MainContainer hideBreadcrumbsBar hideNavbarOptions>
			<PaymentCheckoutContainer>
				<PaymentCheckoutTitle>{t("Order Status")}</PaymentCheckoutTitle>
				{/* <p>{t("Please DO NOT close the browser or refresh the page.")}</p> */}
				{!getOrderDetailsLoading && getOrderDetailsSuccess ? renderSwitchCase() : fetchResult}
			</PaymentCheckoutContainer>
		</MainContainer>
	);
}
