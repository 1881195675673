import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteProductAction
} from "../actions/DeleteProductAction";


 const useDeleteProduct = () => {
	const dispatch = useDispatch();
	const result = useSelector(state => state.deleteProductManagement.deleteProduct);

	const deleteProduct = stockId => {
		dispatch(deleteProductAction(stockId));
	};
	return [deleteProduct, result.data, result.error, result.loading];
};
export default useDeleteProduct;
