import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
    billingAddress: {},
    addBillingAddress: {},
    updateBillingAddress: {},
    deleteBillingAddress: {},
    defaultBillingAddress: {}
};

const BillingAddressReducer = createApiReducer(initialState, [
    types.GET_BILLING_ADDRESS_REQUEST,
    types.ADD_BILLING_ADDRESS_REQUEST,
    types.UPDATE_BILLING_ADDRESS_REQUEST,
    types.DELETE_BILLING_ADDRESS_REQUEST,
    types.SET_DEFAULT_BILLING_ADDRESS_REQUEST
]);

export default BillingAddressReducer;
