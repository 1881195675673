import React from "react";
import { ArtworkCheckboxWrapper, CardImg, SelectButton, ImgContainerDiv, CheckIcon } from "./ArtworkCheckbox.styled";

const ArtworkCheckbox = ({ image, imageUC, productId, productName, onClickProduct, isChecked }) => {
	return (
		<ArtworkCheckboxWrapper>
			<ImgContainerDiv
				onClick={() => {
					onClickProduct({ productId, image, productName });
				}}
			>
				<CardImg srcWebp={image} fallback={imageUC} alt={productName} />
				<SelectButton isChecked={isChecked}>
					<CheckIcon type="check" />
				</SelectButton>
			</ImgContainerDiv>
		</ArtworkCheckboxWrapper>
	);
};

export default ArtworkCheckbox;
