import React from 'react'
import { connect } from 'react-redux'
import './KycView.scss';
import ModalTypes from "utils/ModalTypes";
import useModal from "utils/useModal";
import assets from 'assets';
import { includes, isEmpty, toLower } from "lodash";
import { MainSubmitButton } from "pages/ArtistProfile/ArtistProfile.styled";

export const KYCDocumentView = ({ data, onCancel, props }) => {

    const [, , showModal] = useModal(ModalTypes.kycDocument);

    const onClickPdf = (data) => {
        const raw = window.atob(data);
        const rawLength = raw.length;
        const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            blobArray[i] = raw.charCodeAt(i);
        }

        const blob = new Blob([blobArray], { type: 'application/pdf' });

        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
    }

    return (
        <div className="add-kyc-content">
            <div className="add-kyc-featured-box">
                <div className="section">
                    <h6>Full Name</h6>
                    <h4>{data && data.fullName}</h4>
                </div>
                <div className="hr-line"></div>
                <div className="section-image">
                    <div className="image-container" >
                        <h4>Identity proof with photo</h4>
                        {includes(toLower(data.idProofFileName), ".pdf") ?
                            <div className="viewpdf" onClick={() => onClickPdf(data.idProof)}>View PDF</div>

                            :
                            <img
                                src={data && data.idProof ? `data:image/jpg;base64,${data.idProof}` : assets.images.calendarIcon} alt="kycimage"

                            />
                        }
                    </div>
                    <div className="image-container" >
                        <h4>Address Proof (Bill)</h4>
                        {includes(toLower(data.addressProofFileName), ".pdf") ?
                            <div className="viewpdf" onClick={() => onClickPdf(data.addressProof)}>View PDF</div>

                            :
                            <img src={data && data.addressProof ? `data:image/jpg;base64,${data.addressProof}` : assets.images.calendarIcon} alt="kycimage" />
                        }
                    </div>
                </div>

                <div className="section">
                    <h6>Comments</h6>
                    <h5>{data && data.comments}</h5>
                </div>
                <br />
                <div className="section">
                    <MainSubmitButton onClick={(e) => {
                        onCancel();
                        showModal(ModalTypes.kycDocument, {
                            data: "dropdown"
                        });
                    }} >
                        Update KYC
                                </MainSubmitButton>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(KYCDocumentView)
