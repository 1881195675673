import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	productList: {},
	productDetail: {},
	soldArtworkDetail: {},
	productLike: {},
	productDisLike: {},
	quoteRate: {},
	disclosePrice: {},
	addToWishlist: {},
	wishlist: {},
	deleteFromWishlist: {},
	filterArtWorks: {},
	filterProducts: {},
	sellerProducts: {},
	sellerArtworks: {},
	mySoldProducts: {},
	soldProducts: {},
	productOwnershipHistory: {},
	sellerQuotes: {},
	updateSellerQuoteStatus: {},
	sellerPriceRequest: {},
	updateSellerPriceStatus: {},
	myQuoteList: {},
	deleteMyQuote: {},
	getSignaturePendingArtworks: {},
	doesRFIDExist: {},
	updateProductStatus: {},
	checkAddProductEligibilityData: {},
	galleryAddedProducts: {},
	artistProducts: {},
	getGalleryView: {},
	getInventory: {},
	getOwnedArtworks: {},
	updateImages: {},
	NFCBypassData: {},
	updatePriceData: {}
};

const ProductManagementReducer = createApiReducer(initialState, [
	types.GET_PRODUCT_DETAIL_REQUEST,
	types.GET_PRODUCT_LIST_REQUEST,
	types.GET_PRODUCT_BY_SLUG_REQUEST,
	types.GET_SOLD_ARTWORK_DETAILS_REQUEST,
	types.GET_PRODUCT_OWNERSHIP_HISTORY_REQUEST,
	types.PRODUCT_LIKE_REQUEST,
	types.PRODUCT_DISLIKE_REQUEST,
	types.QUOTE_RATE_REQUEST,
	types.DISCLOSE_PRICE_REQUEST,
	types.ADD_TO_WISHLIST_REQUEST,
	types.GET_WISHLIST_REQUEST,
	types.DELETE_FROM_WISHLIST_REQUEST,
	types.GET_FILTER_ARTWORKS_REQUEST,
	types.GET_SELLER_PRODUCTS_REQUEST,
	types.GET_SELLER_ARTWORKS_REQUEST,
	types.GET_MY_SOLD_PRODUCTS,
	types.GET_SOLD_PRODUCTS,
	types.GET_SELLER_QUOTES_REQUEST,
	types.UPDATE_SELLER_QUOTE_STATUS_REQUEST,
	types.GET_SELLER_PRICE_REQUEST_REQUEST,
	types.UPDATE_SELLER_PRICE_STATUS_REQUEST,
	types.GET_MY_QUOTE_REQUEST,
	types.DELETE_MY_QUOTE_REQUEST,
	types.GET_SIGNATURE_PENDING_ARTWORKS,
	types.CHECK_RFID_EXISTS,
	types.UPDATE_PRODUCT_STATUS_REQUEST,
	types.CHECK_ADD_PRODUCT_ELIGIBILITY,
	types.GET_GALLERY_ADDED_PRODUCTS,
	types.GET_ARTIST_PRODUCTS,
	types.GET_GALLERY_VIEW,
	types.GET_INVENTORY,
	types.GET_OWNED_ARTWORKS,
	types.UPDATE_IMAGES_REQUEST,
	types.NFC_BYPASS_REQUEST,
	types.UPDATE_PRICE_REQUEST
]);

export default ProductManagementReducer;
