import createApiReducer from "../util/createApiReducer";
import * as types from "../actions/ActionsTypes";

const initialState = {
    updateKycDocumentsResult: {},
    ownKycDocumentResult:{}
	
};

const KycDocumentReducer = createApiReducer(
    initialState, [
    types.UPDATE_KYC_DOCUMENT,
    types.GET_OWN_KYC]);

export default KycDocumentReducer;
