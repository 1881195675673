import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class BillingAddressService {
    static API = APIUrlConstants.API_URLS;
    static service = new BaseService();
    // Get BillingAddress
    static async getBillingAddress() {
        return this.service.execute(
            this.API.getBillingAddress,
            HTTP_METHOD.GET,
            {}
        );
    }
    // Add BillingAddress
    static async addBillingAddress(addressToBeAdded) {
        let body = { ...addressToBeAdded };
        return this.service.execute(this.API.addBillingAddress, HTTP_METHOD.POST, {
            body
        });
    }
    // Update BillingAddress
    static async updateBillingAddress(addressToBeUpdated, addressId) {
        let urlDirectParams = `/${addressId}`;
        const body = { ...addressToBeUpdated };

        return this.service.execute(this.API.updateBillingAddress, HTTP_METHOD.PUT, {
            body,
            urlDirectParams
        });
    }
    // Delete BillingAddress
    static async deleteBillingAddress(addressId) {
        let params = { addressId };
        return this.service.execute(this.API.deleteBillingAddress, HTTP_METHOD.DELETE, {
            params
        });
    }
    // Set Default BillingAddress
    static async setDefaultBillingAddress(addressId) {
        let urlDirectParams = `/${addressId}`;
        return this.service.execute(this.API.setDefaultBillingAddress, HTTP_METHOD.PUT, {
            urlDirectParams
        });
    }
}
