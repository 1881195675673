import toast from "common/Toast";

// errorToast is an error toast for API responses
// Param 1 : err : error response from API
// Param 2 : fallbackErr : OBJECT KEY : If there is no msg from API, then use the msg in toastMessage Obj
export const errorToast = (err = null, fallbackErr = "error") => toast(err && err.message ? err.message : toastMessage[fallbackErr], "error");

// Messages
export const toastMessage = {
	updateSuccess: "Successfully updated",
	addSuccess: "Successfully added",
	updateError: "Something went wrong while updating. Please try again",
	addError: "Something went wrong while adding. Please try again",
	uploadSuccess: "Successfully uploaded",
	uploadError: "Something went wrong while uploading",
	deleteSuccess: "Successfully deleted",
	deleteError: "Something went wrong while deleting",
	createSuccess: "Successfully Created",
	createError: "Something went wrong while creating",
	submitSuccess: "Successfully submitted",
	submitError: "Something went wrong. Submit failed",
	doLogin: "Please login",
	tryAgain: "Something went wrong. Please try again",
	success: "Success",
	error: "Something went wrong",
	brokenURL: "Please check your URL. Your link might be broken. Try again.",
	shippingBilling: "Please select your shipping & billing address",

	loggedOut: "Successfully logged out",
	fetchError: "Some error occured while fetching data",
	blockchainAccountCreationError: "Something went wrong. Please log out and login again.",

	// Custom
	walletVerify: "Wallet verified successfully. Please continue with checkout",
	walletError: "Wallet verification failed.",
	registerSuccess: "Account verified successfully",
	checkoutSuccess: "Successfully checked out",
	checkoutError: "Failed to checkout from cart",
	privateKeyMatch: "Keystore file matching with the selected account",
	privateKeyNotMatch: "Keystore file not matching with the selected account",
	getBlockchainAccount: "Couldn't fetch blockchain accounts. Try refreshing the page",
	blockchainVerifyError: "Verification failed, please recheck the file uploaded / password",
	noBlockchain: "No blockchain account created",
	createBlockchain: "Please create a blockchain account to add artwork",
	artistDetailsUnavailable: "Selected artist details are not available. Try again or register",
	verificationLinkSent: "Verification link has been sent your email account",
	roleChangeSuccess: "Registration request as 'Artist'/'Gallery' confirmed. Profile will be visible to public only after Admin approval",
	invalidCredentials: "Invalid Credentials",
	otpSent: "OTP has been send to your mail",
	userBlocked: "User is blocked",
	alreadyRegisterUser: "User is already registered with this Email id or Metamask Id, Please go to the sign in page.",
	walletBlocked: "Your wallet login is blocked",
	noUserFound: "Could not find any user with this MetaMask account",
	emailNotVerified: "Email not verified",
	emailNotVerifyAndContinue: "Email not verified.Please verify and continue",
	registrationPurpose: "Purpose of registration saved",
	artworkAdd: "Successfully created the artwork",
	artworkUpdate: "Successfully updated the artwork",
	artworkAddError: "Something went wrong while creating artwork. Please make sure your images and signatures are successfully updated",
	followSuccess: "Successfully followed",
	unfollowSuccess: "Successfully unfollowed",
	followError: "Some error occured while following",
	unfollowError: "Some error occured while unfollowing",
	markedSold: "Successfully marked as Sold",
	defaultAddressSuccess: "Selected default address",
	defaultAddressError: "Something went wrong while selecting default address",
	getCartError: "Not able to fetch cart items. Try refreshing the page",
	accountVerified: "Your Account has been successfully verified",
	orderCancelSuccess: "Successfully cancelled order",
	orderCancelError: "Something went wrong while cancelling order",
	orderReturnSuccess: "Successfully returned order",
	orderReturnError: "Something went wrong while returning order",
	stripeTokenNotGenerated: "Token not generated. Try again",
	stripeNotLoaded: "Stripe.js hasn't loaded yet.",
	addedToFavourites: "Added to favorites",
	addedToFavouritesErr: "Something went wrong while adding to favorites",
	removedFromFavourites: "Removed from favorites",
	removedFromFavouritesErr: "Something went wrong while removing from favorites",
	liked: "Artwork liked",
	disliked: "Artwork disliked",
	addToCart: "Added to cart",
	removeFromCart: "Removed from cart",
	quoteSuccess: "Successfully quoted a price",
	quoteError: "Something went wrong while quoting",
	discloseSuccess: "Requested price disclosure",
	fetchOwnershipErr: "Some error occured while fetching ownership history",
	followerRemoved: "Follower removed",
	followerRemovedErr: "Follower removal failed",
	userProfileDirectingError: "The user is neither an artist nor a gallery",
	passwordChanged: "Successfully changed password",
	twoFacEnabled: "Enabled two factor authentication",
	twoFacDisabled: "Disabled two factor authentication",
	pushEnabled: "Enabled push notifications",
	pushDisabled: "Disabled push notifications",
	accReset: "Account reset successful",
	clipBoardCopied: "Copied to clipboard",
	messageSuccess: "Message send successsfully",
	ercTokenErrMsg: "Token is not minted.Please contact admin",
	metamaskNotInstalled: "Metamask plugin not installed...",
	mintingPending: "Token is not minted.Please contact Admin.",
	acceptAgreement: "Accept the agrement and continue",
	chooseBillingAddress: "Please select your billing address",
	videoFileAdd: "Video file added",
	interestMsg: "Thank you for your interest",
};

export const warningMessage = {
	totalMediaChoose: "Total media size should be below 250MB. Try choosing again",
	totalMediaChooseMainImage: "Main display image should be below 100MB. Try choosing again",

	mediaChoose: "Media size should be below 100MB. Try choosing again",
	mediaChooseForDigital: "Media size should be below 30MB. Try choosing again",

	invalidImage: "Try uploading a valid image file",
	invalidSize: "Image uploaded of lower resolution. Please upload image with minimum of 1080 (H) X 1980 (W)",
	imageChoose: "Please upload an image",
	displayPicture: "Please choose a display picture for your artwork",
	thumbnailPicture: "Please choose a thumbnail picture for your artwork",

	coverPhoto: "Please add a cover photo",
	fillAllFields: "Please fill up all the fields",
	invalidDate: "Please select valid dates",
	blockchainChoose: "Choose blockchain account or signin with wallet",
	privateKeyWarn: "Please upload a private key file or paste your key",
	bulkChoose: "Choose artist",
	categoryChoose: "Choose your artwork category",
	materialChoose: "Please choose any Materials & Techniques",
	categoryOfInterestChoose: "Choose atleast one Art Interests",
	purposeChoose: "Choose atleast one purpose",
	depthAdd: "Sculptures must have a depth value",
	artistChoose: "Choose an artist for adding artwork",
	artistChooseForGroup: "Choose atleast an artist to add to your group",
	mandatoryFill: "Please make sure all mandatory fields are entered",
	moreStockWarn: "Prefer bulk upload for stock more than 10",
	formCollName: "Please enter Collection Name",
	formCollSlug: "Please enter Collection Slug",
	formCollDesc: "Please describe your Collection",
	formCollBann: "Please upload a Collection Banner",
	formCollArt: "Please add atleast one art to the Collection",
	createCollectionWarn: "Atleast one Artwork must be available to create a Collection",
	formEvtName: "Event name is required",
	formEvtPlace: "Event place is required",
	formEvtDate: "From Date is required",
	formEvtToDate: "To Date is required",
	addressChoose: "Choose your address",
	productIdWarn: "Product ID not able to fetch. Try after refreshing the page",
	skipNfcWarning: "Please upload artwork images to proceed/Skip with NFC scanning",
	walletError: "Please connect & continue ",
	signatureVerificationError: "The wallet account with which you signed doesn't match with the Artwork Owner Account.",
	chooseBillingAddress: "Please select your billing address",
	addgalleryArtistBeforeApproval: "Please wait until the admin approves your  profile",
	connectedAddressMismatch: "Connected wallet address not matching with the selected Blockchain account",
	minimumBidAmountWarning: "The Bidding price is too low. Please reconsider your bid.",
	shippingBilling: "Please select your shipping & billing address",
};
