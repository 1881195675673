/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "redux/actions/GeneralActions";

const useAutoLogout = () => {
  const dispatch = useDispatch();
  const [shouldLogout, setShouldLogout] = useState(false);

  const eventListener = useCallback(e => {
    if (e.key === "authToken" && e.oldValue && !e.newValue) {
      dispatchLogout();
    }
  }, []);

  const dispatchLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    window.addEventListener("storage", eventListener);
    return () => {
      window.removeEventListener("storage", eventListener);
    };
  }, []);

  const general = useSelector(state => state.general);

  useEffect(() => {
    if (general && general.shouldLogout) {
      if (general.shouldLogout) {
        setShouldLogout(true);
        dispatch(logoutUser(false));//resetting state
      }

    }
  }, [general]);

  return [shouldLogout, dispatchLogout];
};

export default useAutoLogout;
