import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
    pickUpAddress: {},
    addPickUpAddress: {},
    updatePickUpAddress: {},
    deletePickUpAddress: {},
    defaultPickUpAddress: {}
};

const PickUpAddressReducer = createApiReducer(initialState, [
    types.GET_PICKUP_ADDRESS_REQUEST,
    types.ADD_PICKUP_ADDRESS_REQUEST,
    types.UPDATE_PICKUP_ADDRESS_REQUEST,
    types.DELETE_PICKUP_ADDRESS_REQUEST,
    types.SET_DEFAULT_PICKUP_ADDRESS_REQUEST
]);

export default PickUpAddressReducer;
