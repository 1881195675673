import React from "react";
import {
	TextSpan,
	TitleText,
	IconSpan,
	ArrowIcon,
	EndSpan,
	TextBlack,
	TextGrey,
	BlogMainWrapper,
	BlogSubWrapper,
	BlogImageDiv,
	BlogTitle,
	BlogImageWrapperNextGen,
	BloggerImage,
	ProfileIcon,
} from "./BlogCard.styled";
import { useHistory } from "react-router-dom";
import assets from "assets";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "lazysizes";

const BlogCard = ({ cardItem = {} }) => {
	const { thumbnail, title, body, bloggerName, bloggerImage, userRole, thumbnailUc, blogDate } = cardItem;
	const { artistIcon } = assets.images;

	let history = useHistory();
	const { t } = useTranslation();

	return (
		<BlogMainWrapper>
			<BlogSubWrapper>
				<div onClick={() => history.push(`/blog/${cardItem.slugId}`)}>
					<BlogImageWrapperNextGen
						data-src={thumbnailUc}
						className="lazyload"
						srcWebp={thumbnail}
						fallback={thumbnailUc}
						alt={`RtistiQ Art Blog:${title}`}
					/>
				</div>
				<IconSpan>
					{/* <ArrowIcon type="line" /> */}
					<BlogTitle>{title}</BlogTitle>
				</IconSpan>
				<TitleText>{body.replace(/(&nbsp;|<[^>]*>?)/gm, "")}</TitleText>
				<TextSpan>
					<BlogImageDiv>
						{/* <img src={bloggerImage ? bloggerImage : assets.images.rtistiqLogoIcon} alt={title} /> */}
						<ProfileIcon src={artistIcon} alt="artist" title="artist" width="12" height="12" />
					</BlogImageDiv>
					<EndSpan>
						<TextBlack>{`${bloggerName}`}</TextBlack>
						<TextGrey>{" | "}</TextGrey>
						<TextGrey>{` ${moment(blogDate).format("DD MMMM YYYY")}`}</TextGrey>
						{/* {userRole === 0 && <TextGrey>{t("Artist")}</TextGrey>}
						{userRole === 1 && <TextGrey>{t("User")}</TextGrey>}
						{userRole === 2 && <TextGrey>{t("Admin")}</TextGrey>}
						{userRole === 3 && <TextGrey>{t("Gallery")}</TextGrey>} */}
					</EndSpan>
				</TextSpan>
			</BlogSubWrapper>
		</BlogMainWrapper>
	);
};
export default BlogCard;
