import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTwoFactorAction } from "../actions/UserManagementAction";

const useToggleTwoFactor = () => {
    const onToggle = data => {
        dispatch(toggleTwoFactorAction(data));
    };
    const toggleData = useSelector(state => state.userManagement.toggleData);
    const dispatch = useDispatch();
    return [onToggle, toggleData.data, toggleData.error, toggleData.loading];
};
export default useToggleTwoFactor;
