import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Icon } from "antd";
import { DropdownContentArtist } from "styled/home.styled";
import { MenuItem, MainArtworkDropdownLink } from "../NavbarMenu/NavbarMenu.styled";
import { useTranslation } from "react-i18next";

const ArtistDropdown = ({ gotTypesData }) => {
	const { t } = useTranslation();

	// ========================= Menu ========================= //
	{
		/* <MainArtworkDropdownLink to={`/artists/all/1`}>{t(item.artistType)}</MainArtworkDropdownLink> */
	}
	const menu = (
		<DropdownContentArtist>
			<Menu>
				{gotTypesData ? (
					gotTypesData.map((item, index) => (
						<Menu.Item key={index}>
							{item._id === "3" && <Link to={`/artists/featured/1`}>{t(item.artistType)}</Link>}
							{item._id === "1" && <Link to={`/artists/new/1`}>{t(item.artistType)}</Link>}
							{item._id === "2" && <Link to={`/artists/popular/1`}>{t(item.artistType)}</Link>}
							{item._id === "0" && <Link to={`/artists/all/1`}>{t(item.artistType)}</Link>}
						</Menu.Item>
					))
				) : (
					<div>
						<h4 style={{ margin: "0", marginright: "5px", fontSize: "medium" }}>No data</h4>
					</div>
				)}
			</Menu>
		</DropdownContentArtist>
	);

	return (
		<Dropdown overlay={menu}>
			<MenuItem>
				{t("Artists")}
				<Icon type="down" />
			</MenuItem>
		</Dropdown>
	);
};

export default ArtistDropdown;
