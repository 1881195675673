import styled from "styled-components";
import { Icon } from "antd";
import DialogBox from "common/DialogBox";

export const Wrapper = styled.div`
    padding:25%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;;
    background:#58514B;`;

export const CheckIcon = styled(Icon)`
    font-size: 30px;
    text-align:center !important;
    display: flex !important;
    color:#FFF !important;
    align-items: center !important;
    height: 100%;
    justify-content: center !important;
`;

export const SelectButton = styled.div`
    height:79px;
    width:79px;
    border-radius:50%;
    background:#58514B;
    border: 3px solid #FFF;
`;

export const Text = styled.p`
    font-size:25px;
    font-weight:bold;
    text-align:center;
    color:#FFFFFF;`;

export const Modal = styled(DialogBox)`
.ant-modal-content .ant-modal-body {
    padding: 0px;
}

`;
