/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import SDK, { userManagementHooks, productDetailHooks, cartHooks } from "ecommerce-sdk/src/index";
import toast from "common/Toast";
import useRefreshBadge from "utils/useRefreshBadge";
import ModalTypes from "utils/ModalTypes";
import { useDispatch } from "react-redux";
import useModal from "utils/useModal";
import { isEmpty } from "lodash";
import { toastMessage } from "utils/toastMessages";
import { MoeTrackEvent } from "utils/moEngageHooks";

const useArtWorkCard = (apiHook) => {
	const [apiCall, apiData, apiError, apiLoader, hasCount = null] = apiHook();
	const [refreshBadges] = useRefreshBadge();
	const [artworks, setArtworks] = useState(null);
	useEffect(() => {
		if (hasCount) setArtworks(apiData && apiData[hasCount]);
		else setArtworks(apiData);
	}, [apiData]);

	const [data, setData] = useState(null);
	//Artwork actions
	// Hooks
	const { useProductLike, useProductDisLike, useAddToWishlist, useDeleteFromWishlist } = productDetailHooks;
	const { useAddToCart } = cartHooks;
	const { useFollowUser, useUnfollowUser } = userManagementHooks;
	// Container values
	const [onProductLike, productLikeResult, productLikeError] = useProductLike();
	const [onProductDislike, productDislikeResult, productDislikeError] = useProductDisLike();
	const [addToWishlist, addToWishlistResult, addToWishlistError] = useAddToWishlist();
	const [deleteFromWishlist, deleteFromWishlistResult, deleteFromWishlistError] = useDeleteFromWishlist();
	const [addToCart, addToCartSuccess, addToCartError] = useAddToCart();
	const [followUser, followUserSuccess, followUserError] = useFollowUser();
	const [unfollowUser, unfollowUserSuccess, unfollowUserError] = useUnfollowUser();

	const updateItem = (value, obj = data, firstArrId = null) => {
		// This case is only used in CategoryArtworks page
		if (firstArrId && artworks[firstArrId] && !isEmpty(artworks[firstArrId])) {
			const artworksArrCopy = artworks;
			const updatedItems = artworksArrCopy[firstArrId].map((item) => {
				return item._id === obj._id ? { ...obj, ...value } : item;
			});

			artworksArrCopy[firstArrId] = updatedItems;

			setArtworks(artworksArrCopy);
			setData(null);
		} else {
			const updatedItems =
				artworks &&
				artworks.map((item) => {
					return item._id === obj._id ? { ...obj, ...value } : item;
				});

			setArtworks(updatedItems);
			setData(null);
		}
	};

	useEffect(() => {
		if (artworks && artworks.length > 0) {
			if (addToWishlistResult) {
				refreshBadges();
			}
			if (deleteFromWishlistResult) {
				refreshBadges();
			}
			if (addToCartSuccess) {
				refreshBadges();
			}
			if (
				productLikeError ||
				productDislikeError ||
				addToWishlistError ||
				deleteFromWishlistError ||
				addToCartError ||
				followUserError ||
				unfollowUserError
			) {
				toast(
					productLikeError ||
					productDislikeError ||
					addToWishlistError ||
					deleteFromWishlistError ||
					addToCartError ||
					followUserError ||
					unfollowUserError,
					"tryAgain"
				);
			}
		}
	}, [
		productLikeResult,
		productLikeError,
		productDislikeResult,
		productDislikeError,
		addToWishlistResult,
		addToWishlistError,
		deleteFromWishlistResult,
		deleteFromWishlistError,
		addToCartSuccess,
		addToCartError,
		followUserSuccess,
		followUserError,
		unfollowUserSuccess,
		unfollowUserError,
	]);

	const [, , showModal] = useModal();
	const dispatch = useDispatch();

	const MoeTrackWhishListEvent = (eventName, productDetail) => {
		MoeTrackEvent(eventName, {
			"ProductName": productDetail.productName,
			"ProductId": productDetail._id,
			"ProductUrl": window.location.href,
			"ProductOriginalPrice": productDetail.price,
			"Currency": productDetail.currency,
			"ProductDiscountedPrice": productDetail.sellingPrice,
			"Category": productDetail.medium && productDetail.medium.name,
			"CategoryId": productDetail.medium && productDetail.medium._id,

		})
	}

	// Action - mandatory - String | Type of action we are doing
	// data - mandatory - The object data of artwork in which we are doing the action
	// firstArrId | Optional | Only applicable in View More artworks page : Bcz the result array is inside another array
	// callAPI | Optional | Only in Home page : Bcz actions (like..) done in one slider to reflect in other slider too
	//  : CallAPI value prevents calling api where only action (updateItem function) is needed [RECM 1250]
	const onArtworkAction = async (action, data, firstArrId, callAPI = true) => {
		if (!(await SDK.UserManagement.isLoggedIn())) {
			toast(toastMessage.doLogin, "warning");
			dispatch(showModal(ModalTypes.login));
		}
		// setData(data);
		switch (action) {
			case "like":
				if (data.isLiked) {
					updateItem({ isLiked: false }, data, firstArrId);
					if (callAPI) onProductDislike(data._id);
				} else {
					updateItem({ isLiked: true }, data, firstArrId);
					if (callAPI) onProductLike(data._id);
				}
				break;
			case "favourite":
				if (data.isFavorite) {
					updateItem({ isFavorite: false }, data, firstArrId);
					if (callAPI) {
						deleteFromWishlist(data._id);
						MoeTrackWhishListEvent("Delete from WishList", data);
					}
				} else {
					updateItem({ isFavorite: true }, data, firstArrId);
					if (callAPI) {
						addToWishlist(data._id);
						MoeTrackWhishListEvent("Add To WishList", data);
					}
				}
				break;
			case "addToCart":
				if (!data.isAddedToCart) {
					const requestBody = {
						ProductId: data._id,
						ProductCount: 1,
					};
					updateItem({ isAddedToCart: true }, data, firstArrId);
					if (callAPI) addToCart(requestBody);
				}
				break;
			case "follow":
				if (data.isFollowing) {
					updateItem({ isFollowing: false }, data, firstArrId);
					if (callAPI) unfollowUser(data.artistId);
				} else {
					updateItem({ isFollowing: true }, data, firstArrId);
					if (callAPI) followUser(data.artistId);
				}
				break;
			default:
				break;
		}
	};

	return [artworks, onArtworkAction, apiCall, apiError, apiLoader];
};
export default useArtWorkCard;
