import * as types from "../actions/ActionsTypes";

const initialState = {
    categoryArtworksLoader: false,
    categoryArtworksError: null,
    counts: {}
};

const CategoryArtworksReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {

        // Category Artworks list
        case types.CATEGORY_ARTWORKS_LIST_REQUEST:
            return {
                ...state,
                categoryArtworksLoader: true,
                categoryArtworksError: null
            }

        case types.CATEGORY_ARTWORKS_LIST_SUCCESS:
            return {
                ...state,
                categoryArtworksLoader: false,
                [payload.stateName]: payload.data,
                counts: {
                    ...state.counts,
                    [payload.stateName]: payload.count,
                },
                categoryArtworksError: null
            }

        case types.CATEGORY_ARTWORKS_LIST_FAILURE:
            return {
                ...state,
                categoryArtworksLoader: false,
                categoryArtworksError: (error.data && error.data.message) || error.data || error.statusText || 'Something went wrong'
            }

        // Clear reducer
        case types.CATEGORY_ARTWORKS_LIST_CLEAR:
            state = initialState;
            return state;

        default:
            return state;
    }
};

export default CategoryArtworksReducer;
