import * as types from "../actions/ActionsTypes";

const initialState = {
	isAlreadyLoaded: false,

	productId: "",
	slugId: "",
	isProductValid: null,
	productResponse: null,
	isMyProduct: null,
	isEditable: null,
	productType: "",

	// Basic
	categoryArray: [],
	mobileCategoryArray: [],
	categoryId: "",
	productName: "",
	artistId: "",
	tagsArray: [],
	tags: "",
	oneLineDescription: "",
	description: "",

	// Primary
	height: "",
	width: "",
	depth: "",
	medium: "",
	isLimitedEdition: false,
	materialsUsed: "",
	yearofCreation: "",
	weight: "",
	originalArtworkId: "",
	packageHeight: "",
	packageWidth: "",
	packageDepth: "",
	packaging: "",
	conditionReport: "",
	signature: "",

	// Pricing and stock
	price: "",
	isHidePrice: false,
	stockManagement: true,
	stock: 1,
	stockDetails: [],
	currency: null,
	autoApproveQuote: false,
	approvePercentage: 0,
	autoRejectQuote: false,
	rejectPercentage: 0,

	// Images and Videos
	imagesArray: [],
	existingImagesArray: [],
	existingImagesArrayUC: [],
	deletedFiles: [],
	displayPicture: "",
	displayPictureUC: "",

	// Features
	productProperties: [],
	style: {},
	isAbstract: false,
	framed: false,
	subject: [],
	editionPublisher: "",
	seriesName: "",
	panel: false,
	status: 0,
	productSubStatus: 0,
	successToast: null,
	errorToast: null,
	getPropertyLoader: false,
	getParentOfCategoryLoader: false,
	getProductLoader: false,

	updatingProductLoader: false,
};

const updateProductReducer = (state = initialState, { type, payload, error }) => {
	switch (type) {
		// ======= Get category properties ====== //
		case types.GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST:
			state = {
				...state,
				getPropertyLoader: true,
				productProperties: [],
			};
			return state;
		case types.GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS:
			state = {
				...state,
				getPropertyLoader: false,
				productProperties: payload,
			};
			return state;
		case types.GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE:
			state = {
				...state,
				getPropertyLoader: false,
				productProperties: [],
			};
			return state;

		// ========= Save Each Stage Data to Reducer ========= //
		case types.SAVE_BASIC_DETAILS:
			state = {
				...state,
				// TODO: Convert with spread
				categoryArray: payload.categoryArray,
				categoryId: payload.categoryId,
				productName: payload.productName,
				artistId: payload.artistId,
				materialsUsed: payload.materialsUsed,
				yearofCreation: payload.yearofCreation,
				originalArtworkId: payload.originalArtworkId,
				editionPublisher: payload.editionPublisher,
				isLimitedEdition: payload.isLimitedEdition,
				medium: payload.medium,
				stock: payload.stock === 0 ? 1 : payload.stock,
				height: payload.height,
				width: payload.width,
				depth: payload.depth,
				productType: payload.productType,
			};
			return state;
		case types.SAVE_ARTIST_DETAILS:
			state = {
				...state,
				IsAbstract: payload.isAbstract,
				style: payload.style,
				subject: payload.subject,
				tagsArray: payload.tagsArray,
				tags: payload.tags,
				description: payload.description,
				oneLineDescription: payload.oneLineDescription,
				seriesName: payload.seriesName,
				conditionReport: payload.conditionReport,
				signature: payload.signature,
			};
			return state;
		case types.SAVE_PRICING_DETAILS:
			state = {
				...state,
				// TODO: Convert with spread
				price: payload.price,
				isHidePrice: payload.isHidePrice,
				currency: payload.currency,
				autoApproveQuote: payload.autoApproveQuote,
				approvePercentage: payload.approvePercentage,
				autoRejectQuote: payload.autoRejectQuote,
				rejectPercentage: payload.rejectPercentage,
			};
			return state;
		case types.SAVE_LOGISTIC_DETAILS:
			state = {
				...state,
				panel: payload.panel,
				framed: payload.framed,
				// height: payload.height || 0,
				// width: payload.width || 0,
				// depth: payload.depth || 0,
				weight: payload.weight || 0,
				packageHeight: payload.packageHeight,
				packageWidth: payload.packageWidth,
				packageDepth: payload.packageDepth || 0,
				packaging: payload.packaging,
			};
		case types.SAVE_IV_DETAILS:
			state = {
				...state,
				imagesArray: payload.imagesArray,
				existingImagesArray: payload.existingImagesArray,
				deletedFiles: payload.deletedFiles || [],
				displayPicture: payload.displayPicture || null,
			};
			return state;

		case types.SAVE_FEATURES_DETAILS:
			state = {
				...state,
				productProperties: [],
			};
			return state;

		// ======= Get Product ====== //
		case types.GET_PRODUCT_REQUEST:
			state = {
				...state,
				getProductLoader: true,
				isAlreadyLoaded: false,
				tagsArray: [],
				isProductValid: null,
			};
			return state;

		case types.GET_PRODUCT_SUCCESS:
			state = {
				...state,
				isAlreadyLoaded: true,
				productId: payload._id,
				slugId: payload.slugId,
				isProductValid: "VALID",
				isMyProduct: payload.isMyProduct,
				isEditable: payload.isEditable,

				// Basic
				categoryId: payload.categoryId,
				productName: payload.productName,
				artistId: payload.artistId,
				tagsArray: payload.tags ? payload.tags.split(",") || [...state.tagsArray, payload.tags] : [],
				tags: payload.tags,
				oneLineDescription: payload.oneLineDescription,
				description: payload.description,
				productType: payload.productType,

				// Primary
				dimensions: payload.dimensions,
				height: payload.dimensions.height || 0,
				width: payload.dimensions.width || 0,
				depth: payload.dimensions.depth || 0,
				medium: payload.medium,
				isLimitedEdition: payload.isLimitedEdition,
				originalArtworkId: payload.originalArtworkId,
				materialsUsed: payload.materialsUsed,
				yearofCreation: payload.yearofCreation,
				weight: payload.productType === 1 ? payload.weight || 0 : 0,
				packageHeight: payload.productType === 1 ? payload.packageDimensions.height || 0 : 0,
				packageWidth: payload.productType === 1 ? payload.packageDimensions.width || 0 : 0,
				packageDepth: payload.productType === 1 ? payload.packageDimensions.depth || 0 : 0,

				// Pricing and stock
				price: payload.price,
				isHidePrice: payload.isHidePrice,
				stockManagement: true,
				stock: payload.stockDetails && payload.stockDetails.length,
				stockDetails: payload.stockDetails,
				currency: payload.currency,
				packaging: payload.packaging,
				autoApproveQuote: payload.autoApproveQuote,
				approvePercentage: payload.approvePercentage,
				autoRejectQuote: payload.autoRejectQuote,
				rejectPercentage: payload.rejectPercentage,

				// Images and Videos
				imagesArray: [],
				existingImagesArray: payload.productImages || [],
				existingImagesArrayUC: payload.productImagesUc || [],
				deletedFiles: [],
				displayPicture: payload.displayPicture,
				displayPictureUC: payload.displayPictureUc,

				// Features
				getProductLoader: false,

				style: payload.style,
				isAbstract: payload.isAbstract,
				framed: payload.framed,
				subject: payload.subject,
				editionPublisher: payload.editionPublisher,
				seriesName: payload.seriesName,
				panel: payload.panel,
				conditionReport: payload.conditionReport,
				signature: payload.signature,
				status: payload.status,
				productSubStatus: payload.productSubStatus,
			};
			return state;

		case types.GET_PRODUCT_FAILURE:
			state = {
				...state,
				getProductLoader: false,
				isAlreadyLoaded: false,
				isProductValid: "INVALID",
			};
			return state;

		// ======= Get Parent List Array ====== //
		case types.GET_PARENTS_BY_CATEGORY_REQUEST:
			return {
				...state,
				getParentOfCategoryLoader: true,
				categoryArray: [],
				mobileCategoryArray: [],
			};

		case types.GET_PARENTS_BY_CATEGORY_SUCCESS:
			state = {
				...state,
				getParentOfCategoryLoader: false,
				categoryArray: payload.idArray,
				mobileCategoryArray: payload.completeArr,
			};
			return state;

		case types.GET_PARENTS_BY_CATEGORY_FAILURE:
			return {
				...state,
				getParentOfCategoryLoader: false,
				categoryArray: [],
				mobileCategoryArray: [],
			};

		// ======= Update Product ====== //
		case types.UPDATE_PRODUCT_REQUEST:
			state = {
				...state,
				successToast: null,
				errorToast: null,
				productResponse: null,
				updatingProductLoader: true,
			};
			return state;
		case types.UPDATE_PRODUCT_SUCCESS:
			state = {
				...initialState,
				successToast: true,
				errorToast: false,
				updatingProductLoader: false,
				productResponse: payload,
			};
			return state;
		case types.UPDATE_PRODUCT_FAILURE:
			state = {
				...state,
				successToast: null,
				errorToast: error.message || error || "Something went wrong",
				updatingProductLoader: false,
				productResponse: (error && error.productResponse) || null,
			};
			return state;

		case types.CLEAR_UPDATE_PRODUCT_REDUCER_STATES:
			state = {
				...initialState,
			};
			return state;
		default:
			return state;
	}
};

export default updateProductReducer;
