import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	bulkUploadResult: {},
	bulkFormDownload: {}
};

const BulkUploadReducer = createApiReducer(initialState, [
    types.BULK_UPLOAD_REQUEST,
    types.BULK_FORM_DOWNLOAD_REQUEST
]);

export default BulkUploadReducer;
