/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { homeHooks } from "ecommerce-sdk/src/index";
import {
	WholeWrapper,
	Image,
	ImageWrapper,
	TitleWrapper,
	BlogImageDiv,
	BloggerImage,
	TextBlack,
	TextGrey,
	EndSpan,
	TextSpan,
	MainWapper,
	HomeSliderTitle,
	HomeSliderWrapper,
	BlogWrapRow,
} from "./BlogDetails.styled";
import MainContainer from "common/MainContainer";
import lodash, { get } from "lodash";
import { useLocation } from "react-router-dom";
import useRoutes from "routes/useRoutes";
import BlogCard from "../../BlogCard/BlogCard";
import { Col, Skeleton, Row } from "antd";

import { Helmet } from "react-helmet";
import assets from "assets";
import SafeHtmlRenderer from "common/SafeHtmlRenderer";
import { useTranslation } from "react-i18next";

const BlogDetails = ({ match }) => {
	function transform(node, index) {
		if (node.type === "tag" && node.name === "img") {
			node.attribs.style = "width:100%";
		}
	}

	const options = {
		decodeEntities: true,
		transform,
	};

	//----------------------Get BlogsList----------------------//
	const { useBlogs } = homeHooks;
	const [getBlogs, blogsDataList] = useBlogs();
	const { t } = useTranslation();

	const location = useLocation();
	//----------------------Get Blogs By Id----------------------//
	const { useGetBlogsById } = homeHooks;
	const [getBlogsById, blogDetail, , blogLoader] = useGetBlogsById();
	useEffect(() => {
		getBlogsById(match.params.slug);
		if (!blogsDataList) {
			getBlogs(1, 10);
		}
	}, [match.params.slug]);
	useEffect(() => {
		if (blogDetail) {
			addRoute(blogDetail.title, location.pathname);
		}
	}, [blogDetail]);
	console.log("🚀 ~ file: BlogDetails.js ~ line 65 ~ BlogDetails ~ blogDetail", blogDetail);
	//---------------------------------------------------------//
	const [, addRoute, removeRoute] = useRoutes();

	useEffect(() => {
		return () => {
			removeRoute();
		};
	}, []);

	return (
		<MainContainer>
			<Helmet>
				<title>{`RtistiQ Blog | ${blogDetail && blogDetail.title} || "Blog"`}</title>
				{/* <meta name="description" content={`${blogDetail && blogDetail.seoDescription}`} /> */}
				<link rel="canonical" href={`${process.env.REACT_APP_BASEURL}en/${blogDetail && blogDetail.slugId}`} />
				<meta property="url" content={`${process.env.REACT_APP_BASEURL}en/${blogDetail && blogDetail.slugId}`} />
				<meta property="og:url" content={`${process.env.REACT_APP_BASEURL}en/${blogDetail && blogDetail.slugId}`} />
			</Helmet>
			<Skeleton active loading={blogLoader} paragraph={{ rows: 6 }}>
				<WholeWrapper>
					{/* title */}
					<TitleWrapper>
						<h1>{blogDetail && blogDetail.title}</h1>
					</TitleWrapper>
					{/* image */}
					<ImageWrapper>
						<Image
							srcWebp={blogDetail && blogDetail.image}
							fallback={blogDetail && blogDetail.imageUc}
							alt={`RtistiQ Art Blog:${blogDetail && blogDetail.title}`}
						/>
					</ImageWrapper>
					<TextSpan>
						<BlogImageDiv>
							<BloggerImage
								srcWebp={blogDetail && blogDetail.bloggerImage ? blogDetail && blogDetail.bloggerImage : assets.images.LogoNew}
								fallback={blogDetail && blogDetail.bloggerImageUC ? blogDetail && blogDetail.bloggerImageUC : assets.images.LogoNew}
								alt={blogDetail && blogDetail.title}
							/>
						</BlogImageDiv>
						<EndSpan>
							<TextBlack>{blogDetail && blogDetail.bloggerName}</TextBlack>
							{blogDetail && blogDetail.userRole === 0 && <TextGrey>{"Artist"}</TextGrey>}
							{blogDetail && blogDetail.userRole === 1 && <TextGrey>{"User"}</TextGrey>}
							{blogDetail && blogDetail.userRole === 2 && <TextGrey>{"Admin"}</TextGrey>}
							{blogDetail && blogDetail.userRole === 3 && <TextGrey>{"Gallery"}</TextGrey>}
						</EndSpan>
					</TextSpan>
					{/* body */}
					<SafeHtmlRenderer body={blogDetail && blogDetail.body} />
					<MainWapper>
						<TitleWrapper>
							<HomeSliderTitle>{t("Latest Blog")}</HomeSliderTitle>
						</TitleWrapper>
						<HomeSliderWrapper>
							<Row type="flex" justify="center">
								{blogsDataList &&
									blogsDataList.count !== 0 &&
									lodash
										.get(blogsDataList, "blogs", [])
										.slice(0, 3)
										.map((item, index) => (
											<Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
												<BlogCard cardItem={item} />
											</Col>
										))}
							</Row>
						</HomeSliderWrapper>
					</MainWapper>
				</WholeWrapper>
			</Skeleton>
		</MainContainer>
	);
};

export default withRouter(BlogDetails);
