import LocalStorage from "./LocalStorageUtility";

export default class UserManagement {
	static isLoggedIn = async () => {
		return await LocalStorage.getItem("authToken")
			.then(result => {
				const loggedIn = result != undefined && result.length > 0;
				return loggedIn;
			})
			.catch(err => {
				return false;
			});
	};

	static getAuthToken = async () => {
		return await LocalStorage.getItem("authToken")
			.then(result => {
				return result;
			})
			.catch(err => {
				return false;
			});
	};

	static getRefreshToken = async () => {
		return await LocalStorage.getItem("refreshToken")
			.then(result => {
				return result;
			})
			.catch(err => {
				return false;
			});
	};

	static getUserData = async () => {
		return await LocalStorage.getItem("userData")
			.then(result => {
				return result;
			})
			.catch(err => {
				return false;
			});
	};

	static getFirstSocialLogin(callback) {
		LocalStorage.getItem("firstSocial")
			.then(result => {
				callback && callback(result);
			})
			.catch(err => {
				return callback && callback(false);
			});
	}

	static saveAuthToken = async token => {
		return await LocalStorage.saveItem("authToken", token);
	};

	static saveRoles(roles) {
		LocalStorage.saveItem("role", roles);
	}
	static refreshToken(token) {
		LocalStorage.saveItem("refreshToken", token);
	}

	static saveUserData(data) {
		LocalStorage.saveItem("userData", data);
	}
	static saveFirstSocialLogin(data) {
		LocalStorage.saveItem("firstSocial", data);
	}

	static getUserRoles(callback) {
		LocalStorage.getItem("role")
			.then(result => {
				callback && callback(result);
			})
			.catch(err => {
				return callback && callback(false);
			});
	}

	static logout() {
		LocalStorage.removeItem("authToken");
		LocalStorage.removeItem("role");
		LocalStorage.removeItem("refreshToken");
		LocalStorage.removeItem("userData");
		LocalStorage.removeItem("firstSocial");
		LocalStorage.removeItem("torusWalletAddress");
		LocalStorage.removeItem("isCustomeWalletConnected");
		LocalStorage.removeItem("walletName");
		localStorage.removeItem("walletAddress");


	}
}
