import { ImgNextGen } from "common/NextGenImage";
import styled, { css } from "styled-components";

const MainImage = styled(ImgNextGen)`
	${props => props.hideimage === "true" && css`display: none;`}
`;

const ImageLoading = styled.img`
	width: 45px;
`;

const ImageError = styled.img`
	width: 45px;
`;

export { MainImage, ImageLoading, ImageError };
