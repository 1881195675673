import { useDispatch, useSelector } from "react-redux";
import { getMyInterests, getArtistInterestsBySlugIdAction, updateArtistInterests } from "../actions/ArtistInterestsAction";

export const useGetMyInterests = () => {
	const dispatch = useDispatch();

	// Get ArtistInterests
	const getInterests = SlugId => {
		if (SlugId) dispatch(getArtistInterestsBySlugIdAction(SlugId));
		else dispatch(getMyInterests());
	};
	// Reducer states
	const result = useSelector(state => state.artistInterests.getMyInterests);

	return [getInterests, result.data, result.error, result.loading];
};

export const useUpdateArtistInterests = () => {
	const dispatch = useDispatch();

	// Update ArtistInterests
	const updateInterests = artistInterestsData => {
		dispatch(updateArtistInterests(artistInterestsData));
	};

	// Reducer states
	const result = useSelector(state => state.artistInterests.updateArtistInterests);

	return [updateInterests, result.data, result.error, result.loading];
};
