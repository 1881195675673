import styled, { css } from "styled-components";

export const PaymentCheckoutFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	padding: 0;

	@media (max-width: 375px) {
		padding: 0 5%;
	}
`;

export const PaymentCheckoutFormWrapper = styled.form`
	margin-top: 1rem;
	width: 20rem;
	padding: 0 0 2rem;

	@media (max-width: 320px) {
		width: 18rem;
	}
`;
export const PaymentCheckoutFormControl = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
	${(props) => props.addMargin && "margin-left: 15px;"}
`;
export const PaymentCheckoutFormRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const PaymentCheckoutFormLabel = styled.label`
	font-size: 13px;
	margin-bottom: 5px;
	font-weight: 500;
	color: #58514b;
`;
export const StripeElementStyles = css`
	display: block;
	padding: 10px 14px;
	font-size: 15px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	border: 1px solid #e5e5e5;
	outline: 0;
	background: white;
`;
export const CardNumber = styled.div`
	${StripeElementStyles}
	min-width: 200px;
`;
export const CardExpiry = styled.div`
	${StripeElementStyles}
`;
export const CardCVC = styled.div`
	${StripeElementStyles}
`;
