/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
	NavbarWrapper,
	HamburgerButton,
	MobileAction3Dot,
	FullWidthRow,
	LanguageWrapper,
	LanguageSelect,
	SearchWrapper,
	WalletButtonContainer,
	WalletPopUpContainer,
	WalletMainContainer,
	PopUpInnerDiv,
	ETHContinare,
} from "./Navbar.styled";
import IconContainer from "common/IconContainer";
import Logo from "common/Logo/Logo";
import assets from "../../assets";
import SearchComponet from "common/Search";
import NavbarMenu from "./NavbarMenu";
import { Col, Tooltip } from "antd";

import { artistTypesHooks, useWalletContainer } from "ecommerce-sdk/src/index";
import MobileMenu from "./MobileMenu";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./NavBar.style.css";

import { useHistory, Link } from "react-router-dom";
import { localStorageGetItem } from "utils/storage";
import { Input, Space } from "antd";
import { Popover } from "antd";
import { connectWallet, disconnectWallet, getWalletName, getProvider, getWeb3 } from "utils/walletCommonFunction";
import SDK, { userManagementHooks } from "ecommerce-sdk/src/index";
import toast from "common/Toast";
import { toastMessage, errorToast } from "utils/toastMessages";
import { authorizePost, unthorizePost } from "utils/apiConfig";
import { getTorusWallet } from "utils/torusLogin";
import { isEmpty, isUndefined } from "lodash";
import useCustomWalletConnect from "utils/useCustomWalletConnect";
import { EachCartItemDetailsWrapper } from "pages/CheckOut/CheckOut.styled";
import { Util } from "utils/util";

const { Search } = Input;

const Navbar = ({ iconData, onIconClick, isSearch, handleBlur, handleVisibleChange, ...props }) => {
	const location = useLocation();
	const history = useHistory();

	// const { onConnectWallet, getCurrentConnectedWalletDetails, disconnetCurrentWallet, walletName, provider, walletAddress, web3, isConnected } =
	// 	useWalletContainer();
	// ---------------------- Artist Types Dropdown ---------------------- //

	const defaultArtistTypes = [
		{
			_id: "3",
			artistType: "Featured Artists",
		},
		{
			_id: "1",
			artistType: "New Artists",
		},
		{
			_id: "2",
			artistType: "Popular Artists",
		},
		{
			_id: "0",
			artistType: "All Artists",
		},
	];

	const [artistTypes, setArtistTypes] = useState(defaultArtistTypes);

	// ---------------------- Dropdown functionalities ---------------------- //
	const { isWalletConnected, walletAddress, walletNameLocal, getIsUserLoggedIn } = useCustomWalletConnect();
	const [isOpenSidebar, setIsOpenSidebar] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [isClickWallet, setIsClickWallet] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	//const [isWalletConnected, setIsWalletConnected] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	//const [walletAdress, setWalletAdress] = useState("");
	//const [walletNameLocal, setWalletName] = useState("");

	const closeSidebar = () => {
		setIsOpenSidebar(false);
	};

	const [isOpenMobile3Dot, setIsOpenMobile3Dot] = useState(false);
	const closeMobile3DotMenu = () => setIsOpenMobile3Dot(false);

	// useEffect(() => {
	// 	closeSidebar();
	// }, [location]);
	const { t, i18n } = useTranslation();
	const [selectedLanguage, setSelectedLanugage] = useState(
		localStorageGetItem("i18nextLng") === "en-US" ? "en" : localStorageGetItem("i18nextLng")
	);

	const handleChange = (value) => {
		setSelectedLanugage(value);
		i18n.changeLanguage(value);
	};

	//For Mobile Menu
	const toggleCollapsed = () => {
		setIsOpenSidebar(!isOpenSidebar);
		props.onClickHamburgerButton();
	};
	// ------------- Artist Menu ------------- //
	useEffect(() => {
		if (location.pathname === "/search") {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			if (!isEmpty(parsedQuery)) {
				setSearchText(parsedQuery.q);
			}
		}
	}, [location.search]);
	const onChangeSerachText = (event) => {
		// document.body.style.overflow = event.target.value !== "" ? "hidden" : "auto";

		if (event.target.value === "") {
			onClearSearchText();
		} else {
			setSearchText(event.target.value || "");
		}
	};

	const onClickSearch = (searchTextValue) => {
		console.log("🚀 ~ file: Navbar.js ~ line 118 ~ onClickSearch ~ event", searchTextValue);
		if (searchTextValue !== "") {
			if (location.pathname !== "/search") {
				localStorage.setItem("searchBeforePage", location.pathname);
			}

			history.push("/search?q=" + searchTextValue);
		}
	};
	const onClearSearchText = () => {
		// document.body.style.overflow = "auto";
		setSearchText("");
		if (localStorage.getItem("searchBeforePage") !== null) {
			history.push(localStorage.getItem("searchBeforePage"));
		} else {
			history.push("/");
		}
	};

	const checkIsUserLoggedIn = async () => {
		const loggedIn = await getIsUserLoggedIn();
		setIsLoggedIn(loggedIn);
	};
	useEffect(() => {
		checkIsUserLoggedIn();
	}, []);

	// const onClickWallet = async () => {
	// 	const loggedIn = await SDK.UserManagement.isLoggedIn();
	// 	if (loggedIn) {
	// 		var addressData = await connectWallet();
	// 		if (addressData && addressData.length > 0) {
	// 			let walletDetails = {
	// 				walletName: await getWalletName(),
	// 				provider: await getProvider(),
	// 				walletAddress: addressData,
	// 				web3: await getWeb3(),
	// 			};
	// 			onConnectWallet(walletDetails);
	// 			//setWalletAdress(addressData);
	// 			//setWalletName(await getWalletName());
	// 			//setIsWalletConnected(true);
	// 			setIsClickWallet(true);
	// 		}
	// 	} else {
	// 		toast(t(toastMessage.doLogin), "warning");
	// 	}
	// };
	// const onDisconnectWallet = () => {
	// 	setIsClickWallet(false);
	// 	//setWalletAdress("");
	// 	//setWalletName("");
	// 	//setIsWalletConnected(false);
	// 	disconnectWallet();
	// 	disconnetCurrentWallet();
	// 	getCurrentConnectedWalletDetails();
	// };
	const onCopyToClipboard = (text) => {
		setIsCopied(false);
		navigator.clipboard.writeText(text);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	};
	// useEffect(() => {
	// 	console.log("Wallet Connected & disconneted trigger", localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
	// 	if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") && localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") === "injected") {
	// 		onClickWallet();
	// 	}
	// }, [localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")]);

	const walletContent = isClickWallet && isWalletConnected && (
		<WalletPopUpContainer>
			<Tooltip title={isCopied ? t("Copied") + "!" : t("Copy to clipboard")}>
				<PopUpInnerDiv>
					<p className="wallet-address-text">{walletAddress}</p>
					<img src={assets.images.copyIcon} onClick={() => onCopyToClipboard(walletAddress)} />
				</PopUpInnerDiv>
			</Tooltip>
			<PopUpInnerDiv>
				<img src={assets.images.ethBalnce} />
				<ETHContinare>
					<span className="balance-text">{t("Connected using")}</span>
					<span className="eth-text">{walletNameLocal}</span>
				</ETHContinare>
			</PopUpInnerDiv>
			{/* <PopUpInnerDiv onClick={onDisconnectWallet} className="disconnet-div">
				<img src={assets.images.walletDisconnet} />
				<p className="disconnet-text">{t("Disconnect")}</p>
			</PopUpInnerDiv> */}
		</WalletPopUpContainer>
	);

	//************* For Tours Wallet Section *********() */
	//const [userToken, setUserToken] = useState();

	// useEffect(() => {
	// 	async function fetchUserToken() {
	// 		let token = await SDK.UserManagement.getAuthToken();
	// 		setUserToken(token);
	// 	}

	// 	fetchUserToken();
	// }, []);
	// const { useGetUserInfo } = userManagementHooks;
	// const [getUserInfo, userStates] = useGetUserInfo();
	// console.log("🚀 ~ file: Navbar.js ~ line 216 ~ Navbar ~ userStates", userStates);

	// useEffect(() => {
	// 	if (isEmpty(userStates) && isLoggedIn) {
	// 		getUserInfo();
	// 	}
	// }, []);

	// useEffect(() => {
	// 	//&& !isLoggedIn
	// 	if (userToken !== "" && !localStorage.getItem("isCustomeWalletConnected") && !isEmpty(userStates)) {
	// 		authorizePost("UserManagment/api/UserManagement/TorusLogin", {}, userToken).then((res) => {
	// 			console.log("🚀 ~ file: torusLogin.js ~ line 16 ~ authorizePost ~ res", res);
	// 			if (res.resultCode === 1) {
	// 				let jwtToken = res.response.data.access_token;
	// 				getTorusWallet(jwtToken, userStates.email)
	// 					.then((keyRes) => {
	// 						debugger;
	// 						console.log("🚀 ~ file: LoginPage.js ~ line 47 ~ .then ~ keyRes", keyRes);
	// 						setWalletAdress(keyRes.publicAddress);
	// 						setWalletName('Torus');
	// 						setIsWalletConnected(true);
	// 					})
	// 					.catch((errro) => {
	// 						console.log("🚀 ~ file: LoginPage.js ~ line 49 ~ .then ~ errro", errro);
	// 					});
	// 			}
	// 		});
	// 	}
	// }, [userToken, userStates]);
	return (
		<NavbarWrapper className="nav-bar-menu">
			<FullWidthRow>
				<Col xs={4} sm={4} md={2} lg={2} xl={2}>
					{/* ========== Logo ========== */}
					<Logo />

					{/* ========== Mobile Menu [Hamburger Menu] ========== */}
					<HamburgerButton
						src={assets.images.MobileMenuListIcon}
						onClick={toggleCollapsed}
						alt="Menu"
						title="Menu"
						width="30"
						height="30"
					/>
				</Col>

				{/* ========== Big screen menu ========== */}
				{!props.hideAllOptions && (
					<>
						<Col xs={12} sm={12} md={14} lg={12} xl={12}>
							<NavbarMenu types={artistTypes} />
						</Col>
						<Col xs={6} sm={6} md={8} lg={10} xl={10}>
							<LanguageWrapper>
								{/* <LanguageSelect value={upperCase(selectedLanguage)} style={{ width: 80 }} onChange={handleChange}>
									{selectedLanguage !== "en" && <Option value={"en"}>English</Option>}
									{selectedLanguage !== "fr" && <Option value="fr">French</Option>}
									{selectedLanguage !== "es" && <Option value="es">Spanish</Option>}
									{selectedLanguage !== "de" && <Option value="de">German</Option>}
								</LanguageSelect> */}
								{/* Search Icon */}
								<SearchWrapper>
									<Search
										placeholder="Search"
										allowClear
										value={searchText}
										onChange={onChangeSerachText}
										onSearch={onClickSearch}
										enterButton
										onSearch={onClickSearch}
										style={{
											width: 300,
											marginTop: 15,
										}}
									/>
								</SearchWrapper>

								{!isSearch && isOpenMobile3Dot ? (
									<IconContainer
										iconList={iconData}
										onIconClick={onIconClick}
										handleVisibleChange={handleVisibleChange}
										closeMobile3DotMenu={closeMobile3DotMenu}
										isMobile3Dot={isOpenMobile3Dot}
									/>
								) : (
									<IconContainer iconList={iconData} onIconClick={onIconClick} handleVisibleChange={handleVisibleChange} />
								)}
								<WalletMainContainer>
									<Popover
										trigger="hover"
										// visible={isClickWallet && isWalletConnected}
										overlayClassName={isWalletConnected ? "wallet-popup-content" : "wallet-diconnected-popup"}
										placement="bottom"
										content={walletContent}
									>
										{/* onClick={onClickWallet} */}
										{isWalletConnected && isLoggedIn && (
											<WalletButtonContainer isActive={isWalletConnected}>
												<p> {t("Connected")}</p>
												{/* <p>{isWalletConnected ? t("Connected") : t("Connect wallet")}</p> */}
												{isWalletConnected ? (
													<img src={assets.images.walletActive} width="26" height="24" alt="Wallet Button" />
												) : (
													<img src={assets.images.cryptoWallet} width="26" height="24" alt="Wallet Button" />
												)}
											</WalletButtonContainer>
										)}
									</Popover>
								</WalletMainContainer>
							</LanguageWrapper>
							{/* Search Menu */}
							{/* {searchText !== "" && <SearchComponet handleBlur={onClearSearchText} searchTextValue={searchText} />} */}
						</Col>
					</>
				)}
			</FullWidthRow>
		</NavbarWrapper>
	);
};

export default Navbar;
