import { OrderManagementService } from "../services/OrderManagementService";
import * as types from "./ActionsTypes";

// ========================================== Get Order History ========================================== //
export const getOrderHistoryAction = () => {
	return {
		actionType: types.GET_ORDER_HISTORY_REQUEST,
		callAPI: () => OrderManagementService.getOrderHistory(),
		stateObject: "orderHistoryList",
	};
};

// ========================================== Get Order Details ========================================== //
export const getOrderDetailsAction = (orderId) => {
	return {
		actionType: types.GET_ORDER_DETAILS_REQUEST,
		callAPI: () => OrderManagementService.getOrderDetails(orderId),
		stateObject: "orderDetailsData",
	};
};

// ========================================== Get Seller Order List ========================================== //
export const getPlacedOrdersAction = (pageNum, pageSize, status) => {
	return {
		actionType: types.GET_SELLER_ORDER_LIST_REQUEST,
		callAPI: () => OrderManagementService.getPlacedOrders(pageNum, pageSize, status),
		stateObject: "orderListData",
	};
};

// ========================================== Update Order Status ========================================== //
export const updateOrderStatusAction = (orderStatus, UpdateText, orderDetailsId) => {
	return {
		actionType: types.UPDATE_ORDER_STATUS_REQUEST,
		callAPI: () => OrderManagementService.updateOrderStatus(orderStatus, UpdateText, orderDetailsId),
		stateObject: "updateOrderData",
		isTransient: true,
	};
};

// ========================================== Check Out Cart ========================================== //
export const checkoutCartAction = (shippingData, billingData, currency, blockchainAddress, couponCode) => {
	return {
		actionType: types.CHECK_OUT_CART_REQUEST,
		callAPI: () => OrderManagementService.checkoutCart(shippingData, billingData, currency, blockchainAddress, couponCode),
		stateObject: "checkOutCartData",
		isTransient: true,
	};
};

// ========================================== Cancel Order ========================================== //
export const cancelOrderAction = (orderDetailsId, reason) => {
	return {
		actionType: types.CANCEL_ORDER_REQUEST,
		callAPI: () => OrderManagementService.cancelOrder(orderDetailsId, reason),
		stateObject: "cancelOrderData",
		isTransient: true,
	};
};

// ========================================== Return Order ========================================== //
export const returnOrderAction = (orderDetailsId, reason, addrssId, picupTime) => {
	return {
		actionType: types.RETURN_ORDER_REQUEST,
		callAPI: () => OrderManagementService.returnOrder(orderDetailsId, reason, addrssId, picupTime),
		stateObject: "returnOrderData",
		isTransient: true,
	};
};

// ========================================== Order Invoice ========================================== //
export const orderInvoiceAction = (orderDetailsId) => {
	return {
		actionType: types.ORDER_INVOICE_REQUEST,
		callAPI: () => OrderManagementService.orderInvoice(orderDetailsId),
		stateObject: "invoiceData",
		isTransient: true,
	};
};

// ========================================== Order Invoice ========================================== //
export const getOrderDetailsByIdAction = (orderId) => {
	return {
		actionType: types.GET_ORDER_DETAILS_BY_ID,
		callAPI: () => OrderManagementService.getOrderDetailsById(orderId),
		stateObject: "getOrderDetailsById",
	};
};

// ========================================== Get all signature pending orders ========================================== //
export const getSignaturePendingOrdersAction = (pageNum, pageSize) => {
	return {
		actionType: types.GET_SIGNATURE_PENDING_ORDERS,
		callAPI: () => OrderManagementService.getSignaturePendingOrders(pageNum, pageSize),
		stateObject: "getSignaturePendingOrders",
	};
};

// ========================================== Order Summary ========================================== //
export const getOrderSummaryAction = (shippingAddressId, billingAddressId, currency, couponCode) => {
	return {
		actionType: types.GET_ORDER_SUMMARY,
		callAPI: () => OrderManagementService.getOrderSummary(shippingAddressId, billingAddressId, currency, couponCode),
		stateObject: "getOrderSummaryData",
	};
};

// ========================================== Need Help ========================================== //
export const needHelpAction = (data) => {
	return {
		actionType: types.NEED_HELP_REQUEST,
		callAPI: () => OrderManagementService.needHelp(data),
		stateObject: "needHelpData",
		isTransient: true,
	};
};

// ========================================== Create Shipment ========================================== //
export const createShipmentAction = (data) => {
	return {
		actionType: types.CREATE_SHIPMENT_REQUEST,
		callAPI: () => OrderManagementService.createShipment(data),
		stateObject: "createShipmentData",
		isTransient: true,
	};
};

// ========================================== Download Way Bill Number ========================================== //
export const getWaybillNumberAction = (orderDetailsId) => {
	return {
		actionType: types.WAY_BILL_REQUEST,
		callAPI: () => OrderManagementService.getWaybillNumber(orderDetailsId),
		stateObject: "wayBillData",
		isTransient: true,
	};
};

// ========================================== Pickup Details ========================================== //
export const getPickupDetailsAction = (orderDetailsId) => {
	return {
		actionType: types.PICKUP_DETAILS_REQUEST,
		callAPI: () => OrderManagementService.getPickupDetails(orderDetailsId),
		stateObject: "pickUpData",
	};
};
