import React from "react";
import { message } from "antd";
import "./Toast.css";

message.config({
  top: "90%",
  // duration: 4,
  maxCount: 1,
  duration: 1
});

const toast = (msg, type, time = 4, iconUrl) => {
  switch (type) {
    case "success":
      message.success(msg || "Success", time);
      break;

    case "error":
      message.error(msg || "Error", time);
      break;

    case "info":
      message.info(msg, time);
      break;

    case "warning":
      message.warning(msg, time);
      break;

    case "loading":
      message.loading(msg, time);
      break;

    default:
      message.open({
        content: msg || "Message",
        duration: time || 4,
        icon: iconUrl ? <img src={iconUrl} alt="*" /> : null
      });
      break;
  }
};

export default toast;
