import styled from "styled-components";
import { Icon } from "antd";
import { ImgNextGen } from "common/NextGenImage";

const BlogImageWrapperNextGen = styled(ImgNextGen)`
	height: 273px;
	width: 100%;
	object-fit: cover;
	cursor: pointer;
	@media only screen and (device-width: 768px) {
		object-fit: cover;
	}
	@media only screen and (max-device-width: 480px) {
		object-fit: cover;
	}
`;

const BlogImageWrapperNextGenSearch = styled(ImgNextGen)`
	height: 153px;
	width: 100%;
	object-fit: cover;
	cursor: pointer;
`;

const BlogMainWrapper = styled.div`
	display: flex;

	@media (max-width: 576px) {
		display: flex;
		justify-content: center;
		/* width:300px; */
	}
`;

const BlogSubWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 381px;
	margin-right: 20px;

	@media (max-width: 576px) {
		margin-right: 0;
		width: 95%;
	}
	@media only screen and (max-device-width: 768px) {
		margin-right: 0;
		width: 330px;
	}
	@media only screen and (max-device-width: 480px) {
		margin-right: 0;
		width: 330px;
	}
`;
const BlogSubWrapperSearch = styled.div`
	display: flex;
	flex-direction: column;
	width: 251px;
	margin-right: 20px;

	@media (max-width: 576px) {
		margin-right: 0;
	}
`;
const BlogTitle = styled.p`
	/* height:22px; */
`;
const BlogTitleSearch = styled.p`
	text-align: left;
	font: normal normal bold 16px/26px Lato !important;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	font-weight: 700;
`;

const TextSpan = styled.span`
	display: flex;
	padding: 5px 0px 5px 0px;
	margin-bottom: 1rem;
	align-items: center;
`;
const BlogImageDiv = styled.div`
	height: 25px;
	width: 13px;
`;

const BloggerImage = styled(ImgNextGen)`
	object-fit: contain;

	height: 20px;
	width: 10px;
	align-self: flex-start;
	img {
		object-fit: contain;
		height: 10px;
		width: 10px;
		border-radius: 10px;
	}
`;

const IconSpan = styled.span`
	display: flex;
	width: 100%;
	padding-top: 26px;
	p {
		color: rgb(0, 0, 0);
		font-size: 15px !important;
		margin: 0;
		font-weight: 600 !important;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;
const IconSpanSearch = styled.span`
	display: flex;
	width: 100%;
	padding-top: 6px;
	p {
		color: #000000;
		font-size: 15px;
		margin: 0;
		font-weight: 600;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;
const TitleText = styled.div`
	/* font-size: 14px; */
	overflow: hidden;
	font: normal normal 100 14px/27px Cormorant Garamond;
	color: rgb(36, 35, 35);
	/* padding-left: 30px; */
	margin-top: 1rem;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	height: 60px;
	width: 300px;
`;
const ArrowIcon = styled(Icon)`
	margin-right: 10px;
	color: #000000;
	font-size: 20px;
	font-weight: 600;
`;
const EndSpan = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 2px;
	align-items: flex-start;
`;
const TextBlack = styled.p`
	font-size: 14px;
	margin: 0 2px 0 2px;
	color: #000000;
	font-weight: 600;
`;
const TextGrey = styled.p`
	font-size: 12px;
	color: rgb(36, 35, 35);

	margin: 0 2px 0 2px;
`;
const ProfileIcon = styled.img`
	object-fit: unset;
`;
export {
	BlogMainWrapper,
	BlogSubWrapper,
	BlogTitle,
	TextSpan,
	TitleText,
	IconSpan,
	ArrowIcon,
	BlogImageDiv,
	BloggerImage,
	EndSpan,
	TextBlack,
	TextGrey,
	BlogImageWrapperNextGen,
	ProfileIcon,
	BlogTitleSearch,
	BlogSubWrapperSearch,
	BlogImageWrapperNextGenSearch,
	IconSpanSearch,
};
