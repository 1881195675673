import styled, { css } from "styled-components";
import { Input, Typography } from "antd";

const InputWrapper = styled.div`
	position: relative;
	margin-top: 18px;
	width: 100%;

	@media(max-width:425px) {
		margin-bottom:18px;
	}
`;

const FloatingLabeledInputField = styled(Input)`
  && {
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    opacity: 1;
    outline: none;
    border-radius: 0px;
    padding: 6px 10px 0px;
    font-size: 14px;
    color: #323643;
    &:focus,
    &:active {
      border-color: 0px solid rgb(0, 156, 38);
      -webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
      box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
      outline: 0;
    }

    ${({ error }) =>
		error &&
		css`
			border: 1px solid red;
			outline: none;

			&:focus,
			&:active {
				border-color: red;

				outline: 0;
				-webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
				box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
			}

			/* // } */

			/* Autocomplete styles in Chrome*/
			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus {
				border: 1px solid rgb(191, 49, 12);
			}
		`}
  }
`;

const FloatingLabeledInputLabel = styled.span`
	position: absolute;
	top: -5.5px;
	left: 9px;
	height: 16px;
	font-size: 13px;
	text-align: left;
	padding: 0px 7px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	pointer-events: none;
	background-color: #fff;
	color: #878787;
	letter-spacing: 0.35px;
	line-height: 1;
	opacity: 1;
`;

const ErrorDiv = styled.div`
	position: absolute;
	@media screen and (max-width: 575px) {
		position: unset;
	}
`;
const ErrorText = styled(Typography.Text)`
	&.ant-typography.ant-typography-danger {
		font-size: 12px;
		display: flex;
	}
`;

const RedSpan = styled.span`
	color: red;
`;
export { InputWrapper, FloatingLabeledInputField, FloatingLabeledInputLabel, ErrorDiv, ErrorText, RedSpan };
