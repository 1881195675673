import styled, { css } from "styled-components";
import { Badge } from "antd";
import { SlideInFromTop } from "styled/common.styled";

// Ant Design <Badge>
const SBadge = styled(Badge)`
	line-height: 1;
	.ant-badge-count {
		font-weight: bold;
		background-color: #f52541;
		box-shadow: 0.5px 0.4px #00000029;
		color: white;
		margin-right: 2px;
	}
`;

//IconContainer styling
const IconContainerBox = styled.div`
	display: flex;
	width: auto;
	align-items: center;
	justify-content: flex-end;
	padding-top: 1px;

	${(props) =>
		props.view &&
		css`
			z-index: 2;
			border-radius: 30px;
			background-color: #fff;
			${SBadge} {
				line-height: 2;
				opacity: 0.8;
				.ant-badge-count {
					margin-right: 0px;
					width: 14.4px;
				}
			}
		`};
	/* Mobile Screens */
	@media screen and (max-width: 768px) {
		position: absolute;
		display: flex;
		padding: 0 5px 10px;
		animation: ${SlideInFromTop} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		/* box-shadow: 0px 0px 9px -2px #9e9e9e; */
		margin-top: 10px;

		/* margin-left: 117px; */
	}

	@media screen and (max-width: 667px) {
		margin-left: 25px;
	}
	@media screen and (max-width: 564px) {
		margin-left: 25px;
	}
	@media screen and (max-width: 473px) {
		margin-left: 25px;
	}
	@media screen and (max-width: 425px) {
		margin-left: 20px;
	}
	@media screen and (max-width: 320px) {
		margin-left: 25px;
	}
`;

//IconImageBox styling
const IconImageBox = styled.div`
	width: auto;
	display: flex;
	padding-top: 8px;
	justify-content: center;

	${(props) => props.vertical && "flex-direction: column;"}

	@media (min-width: 1243px) {
		margin: 0 12px;
	}
	@media screen and (max-width: 1243px) {
		margin: 0 10px;
	}
	@media screen and (max-width: 1025px) {
		margin: 0 10px;
	}
	@media screen and (max-width: 840px) {
		margin: 0 10px;
	}
	@media (min-width: 840px) and (max-width: 1025px) {
		justify-content: space-around;
	}
	@media (min-width: 768px) and (max-width: 1025px) {
		> * {
			flex: 1;
			${(props) => (props.vertical ? "margin-top" : "margin-left")}: 0em;
		}
	}
`;

// IconImage styling
const IconImage = styled.img`
	display: flex;
	flex-flow: row nowrap;
	opacity: 0.7;
	cursor: pointer;
	display: inline;

	&:hover {
		opacity: 1;
	}

	@media screen and (max-width: 1280px) {
		/* width: 20px !important; */
	}

	@media screen and (max-width: 1024px) {
		/* width: 20px !important; */
	}

	@media screen and (max-width: 768px) {
		/* width: 21px !important; */
	}
`;

export { IconContainerBox, IconImage, SBadge, IconImageBox };
