import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfoAction } from "../actions/UserManagementAction";

const useUpdateUserInfo = () => {
	const dispatch = useDispatch();
	const updateUser = (formRequest) => {
		dispatch(updateUserInfoAction(formRequest));
	};
	const updateUserData = useSelector((state) => state.userManagement.updateUserData);
	return [updateUser, updateUserData.data, updateUserData.error, updateUserData.loading];
};
export default useUpdateUserInfo;
