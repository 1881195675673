import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { togglePushNotificationAction } from "../actions/UserManagementAction";

const useTogglePushNotification = () => {
	const isPushNotification = data => {
		dispatch(togglePushNotificationAction(data));
	};
	const togglePushNotificationResult = useSelector(state => state.userManagement.togglePushNotificationResult);
	const dispatch = useDispatch();
	return [isPushNotification, togglePushNotificationResult.data, togglePushNotificationResult.error, togglePushNotificationResult.loading];
};
export default useTogglePushNotification;
