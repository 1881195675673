import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class PickUpAddressService {
    static API = APIUrlConstants.API_URLS;
    static service = new BaseService();
    // Get PickUp Address
    static async getPickUpAddress() {
        return this.service.execute(
            this.API.getPickUpAddress,
            HTTP_METHOD.GET,
            {}
        );
    }
    // Add PickUp Address
    static async addPickUpAddress(addressToBeAdded) {
        let body = { ...addressToBeAdded };
        return this.service.execute(this.API.addPickUpAddress, HTTP_METHOD.POST, {
            body
        });
    }
    // Update PickUp Address
    static async updatePickUpAddress(addressToBeUpdated, addressId) {
        let urlDirectParams = `/${addressId}`;
        const body = { ...addressToBeUpdated };

        return this.service.execute(this.API.updatePickUpAddress, HTTP_METHOD.PUT, {
            body,
            urlDirectParams
        });
    }
    // Delete PickUp Address
    static async deletePickUpAddress(addressId) {
        let params = { addressId };
        return this.service.execute(this.API.deletePickUpAddress, HTTP_METHOD.DELETE, {
            params
        });
    }
    // Set Default PickUp Address
    static async setDefaultPickupAddress(addressId) {
        let urlDirectParams = `/${addressId}`;
        return this.service.execute(this.API.setDefaultPickupAddress, HTTP_METHOD.PUT, {
            urlDirectParams
        });
    }
}
