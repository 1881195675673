import React from "react";
import LoaderWrapper from "./FullScreenLoader.styled";
import { Spin, Icon, Skeleton } from "antd";
const FullScreenLoader = (props) => {
	const loadingIcon = <Icon type="loading" style={{ fontSize: 50, color: "#58514B" }} spin />;
	return (
		<LoaderWrapper>
			<Spin indicator={loadingIcon} />
		</LoaderWrapper>
	);
};

export default FullScreenLoader;
