const assets = {
	images: {
		userArtist: require("./images/userType1.png"),
		calendarIcon: require("./images/calendarIcon.svg"),
		userGallery: require("./images/usertype2.png"),
		Arrow: require("./images/Arrow.svg"),
		myProfilePageIcon: require("./images/Profile_Icon.svg"),
		breadCrumbArrow: require("./images/Arrow.svg"),
		warningIcon: require("./images/Warning.svg"),
		addImageIcon: require("./images/Add_Images.svg"),
		artCanvasBanner: require("./images/art-artist-canvas-374054.png"),
		profileIcon: require("./images/profileicon.svg"),
		searchIcon: require("./images/searchicon.svg"),
		notificationIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/notificationicon.svg",
		wishListIcon: require("./images/wishlisticon.svg"),
		cartIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/carticon.svg",
		walletIcon: require("./images/wallet.png"),
		Logo: require("./images/Logo.svg"),
		LogoNew: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/LogoNew.svg",
		arrowRight: require("./images/arrowRight.svg"),
		arrowRightForBid: require("./images/arrow-right.svg"),

		rightArrowIcon: require("./images/rightArrowIcon.svg"),
		rightArrowWhite: require("./images/rightArrowWhite.svg"),
		deleteIcon: require("./images/delete.svg"),
		editIcon: require("./images/edit.svg"),
		profilePicture: require("./images/profilePicture.png"),
		cameraIcon: require("./images/camera.svg"),
		twitterIcon: require("./images/twitterIcon.svg"),
		facebookIcon: require("./images/facebookIcon.svg"),
		instagramIcon: require("./images/instagramIcon.svg"),
		pinterestIcon: require("./images/pinterestIcon.svg"),
		linkedinIcon: require("./images/linkedinIcon.svg"),
		diamondBulletPoint: require("./images/diamondBulletPoint.svg"),
		plusIcon: require("./images/plusAddIcon.svg"),
		addPhoto: require("./images/addPhotoImage.svg"),
		artistIcon: require("./images/User.svg"),
		searchNoFound: require("./images/not-found.svg"),
		galleryButtonIcon: require("./images/galleryButtonIcon.svg"),
		galleyButtonIconActive: require("./images/galleyButtonIconActive.svg"),
		inventoryButtonIcon: require("./images/inventoryButtonIcon.svg"),
		inventoryActive: require("./images/Inventory.svg"),
		artworksActionIcon: require("./images/artworksActionIcon.svg"),
		gridBackground: require("./images/gridPattern.svg"),
		RegisterBanner: require("./images/registerBanner.png"),
		nullImage: require("./images/nullImage.png"),
		emptyGalleryDp: require("./images/emptyGalleryDp.svg"),
		emptyBanner: require("./images/EmptyBanner.png"),
		emptyArtistDp: require("./images/User_Placehoder.svg"),
		emptyArtistDpNew: require("./images/User_PlacehoderBlack.svg"),
		imagePlace: require("./images/image-plc.svg"),
		imageNoPlace: require("./images/image-no-plc.svg"),
		imageGreyPlace: require("./images/image-placeholder-grey.svg"),
		videoUploadIcon: require("./images/video_uploading.png"),
		MobileMenuListIcon: require("./images/MenuMobileViewIcon.svg"),
		IconListingIcon: require("./images/IconListingIcon.svg"),
		emptyUserDp: require("./images/emptyUserDp.svg"),
		artistListBanner: require("./images/artistListBanner.png"),
		galleryListBanner: require("./images/galleryListBanner.png"),
		collectionListBanner: require("./images/collectionListBanner.png"),
		aboutUs: require("./images/aboutUsTemp.png"),
		tricolourDivider: require("./images/Devider.svg"),
		certificateBg: require("./images/Certificatebg.svg"),
		certificate: require("./images/certificate.svg"),
		uploadIcon: require("./images/Upload.svg"),
		downloadIcon: require("./images/user-interface.svg"),
		emptyPhoto: require("./images/rtistIQ_Empty.svg"),
		noInternetImage: require("./images/noInternet.png"),
		noInternetImageWebP: require("./images/noInternet.webp"),
		visaCardIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/Visa_card.svg",
		masterCardIcon: require("./images/Master_Card.svg"),
		retryIcon: require("./images/retry.png"),
		deliveryTruck: require("./images/delivery_truck.svg"),
		divider: require("./images/Devider.svg"),
		facebook: require("./images/fbIcon.svg"),
		instagram: require("./images/instaIcon.svg"),
		twitter: require("./images/twitterIcon.svg"),
		pinterest: require("./images/pinterestIcon.svg"),
		linkedin: require("./images/Linkdin.svg"),
		galleryListHomeIcon: require("./images/HomeIcon.svg"),
		orderImage: require("./images/order_img.png"),
		artworkNoImage: require("./images/No_Image.png"),
		eventCalender: require("./images/eventcalender.svg"),
		invoice: require("./images/Invoice.svg"),
		delivery: require("./images/Delivery.svg"),
		roomView: require("./images/roomView.jpg"),
		quote: require("./images/quoteYourPriceButton.svg"),
		fullscreenIcon: require("./images/fullscreenIcon.svg"),
		tick: require("./images/tick.svg"),
		tickRed: require("./images/tick-red.svg"),
		like: require("./images/likeWhite.svg"),
		likeBlack: require("./images/likeBlack.svg"),
		likeActiveBlue: require("./images/Like_blue.svg"),
		heart: require("./images/heartWhite.svg"),
		heartBlack: require("./images/heartBlack.svg"),
		heartActive: require("./images/heartActive.svg"),
		heartActiveRed: require("./images/favorite_red.svg"),
		cart: require("./images/cartWhite.svg"),
		signIcon: require("./images/signIcon.svg"),
		cartBlack: require("./images/cartBlack.svg"),
		shareWhite: require("./images/shareWhite.svg"),
		shareBlack: require("./images/shareBlack.svg"),
		facebook2: require("./images/facebook.svg"),
		Google: require("./images/Google.svg"),
		loggedInProfileIcon: require("./images/loggedInProfileIcon.svg"),
		ownershipChainIcon: require("./images/ownershipChainIcon.svg"),
		apple: require("./images/apple.svg"),
		nfcIcon: require("./images/NfcIcon.svg"),
		blockChainIcon: require("./images/BlockChainIcon.svg"),
		rtistiqLogoIcon: require("./images/rtistiqLogo.svg"),
		buyArtBackground: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/buyArtBackground.svg",
		buyArtWall: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/buyArtWall.png",
		buyArtWallWebP: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/buyArtWall.webp",
		sliderArrow: require("./images/sliderArrow.svg"),
		alertRed: require("./images/alert_red.svg"),
		questions: require("./images/question.svg"),
		uploadIconWhite: require("./images/uploadWhite.svg"),
		commentBlack: require("./images/commentBlack.svg"),
		scrollTop: require("./images/scrollTop.svg"),
		linkedinGrey: require("./images/linkedinGrey.svg"),
		linkedinBlue: require("./images/linkedinBlue.svg"),
		leftArrowNav: require("./images/left-arrow-nav.svg"),
		rightArrowNav: require("./images/right-arrow-nav.svg"),
		logout: require("./images/logout_icon.svg"),
		dashProfile: require("./images/dash_profile.svg"),
		dashInventory: require("./images/dash_inventory.svg"),
		dashKyc: require("./images/dash_kyc.svg"),
		dashOrders: require("./images/dash_orders.svg"),
		dashBlockChain: require("./images/dash_block.svg"),
		dashAddress: require("./images/dash_address.svg"),
		noImageWhite: require("./images/no-photo-white.svg"),
		dotHiphen: require("./images/dotHiphen.svg"),
		step1: require("./images/Step_1.svg"),
		step2: require("./images/Step_2.svg"),
		step3: require("./images/Step_3.svg"),
		step4: require("./images/Step_4.svg"),
		step5: require("./images/Step_5.svg"),
		technology: require("./images/technology.svg"),
		master: require("./images/master.svg"),
		support: require("./images/support.svg"),
		PS1: require("./images/PS1.png"),
		PS2: require("./images/PS2.png"),
		PS3: require("./images/PS3.png"),
		PS4: require("./images/PS4.png"),
		sellerBackground: require("./images/Seller_banner.jpg"),
		doubleQuote: require("./images/doubleQuoteIcon.svg"),
		PB1: require("./images/PB1.png"),
		PB2: require("./images/PB2.png"),
		PB3: require("./images/PB3.png"),
		playStoreBlack: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/playStoreBlack.svg",
		appStoreBlack: require("./images/appStoreBlack.svg"),
		language: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/language.svg",
		language_black: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/language_black.svg",
		fb_white: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/facebook_white.svg",
		insta_white: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/instagram_white.svg",
		twiter_white: require("./images/twitter_white.svg"),
		linkedin_white: require("./images/linkedin_white.svg"),
		dicrod_white: require("./images/discord_white_icon.svg"),
		redit_white: require("./images/reddit_white_icon.svg"),
		notification_white: require("./images/notification_white.svg"),
		profile_white: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/profile_white.svg",
		down_arrow_white: require("./images/down_arrow_white.svg"),
		info: require("./images/info.svg"),
		roundTick: require("./images/round_tick.svg"),

		fb_mobile: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/facebook_mobile.svg",
		insta_mobile: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/instagram_mobile.svg",
		twiter_mobile: require("./images/twitter_mobile.svg"),
		linkedin_mobile: require("./images/linkedin_mobile.svg"),
		connect: require("./images/molecular.svg"),
		roomViewNew: require("./images/roomViewNew.svg"),

		// Footer
		globalShipping: require("./images/Global_Shipping.svg"),
		secureTransaction: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/Secure_Transaction.svg",
		authenticityCertified: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/Authenticity_Certified.svg",
		blockchainPowered: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/Blockchain_Powered.svg",
		freeReturns: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/Free_Returns.svg",
		appStoreIcon: require("./images/AppStoreIcon.svg"),
		playStoreIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/PlayStoreIcon.svg",
		visaIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/VisaIcon.svg",
		masterIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/MasterCardIcon.svg",
		expressIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/ExpressIcon.svg",
		payPalIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/PayPalIcon.svg",
		cryptoIcon: "https://uielement.s3-ap-southeast-1.amazonaws.com/Icon/crypto_payment_logo.svg",
		contactUsBackground: require("./images/ContactUsbg.svg"),
		locationIcon: require("./images/LocationIcon.svg"),
		callIcon: require("./images/CallIcon.svg"),
		inboxIcon: require("./images/InboxIcon.svg"),
		plusIcon: require("./images/plus.svg"),
		minusIcon: require("./images/minus.svg"),
		filterIcon: require("./images/fliter.svg"),
		filterIconWhite: require("./images/filter_icon_white.svg"),
		filterIconBlack: require("./images/filter_icon_black.svg"),
		closeIcon: require("./images/cancel.svg"),
		cancelBlack: require("./images/cancelBlack.svg"),
		paypal: require("./images/paypal.svg"),
		creditcard: require("./images/credit-card.svg"),
		bitcoin: require("./images/bitcoin.svg"),
		bitcoin_white: require("./images/bitcoin_white.svg"),
		bitcoin_: require("./images/bitcoin_.svg"),

		menuClose: require("./images/menu_close.svg"),
		cryptoWallet: require("./images/crypto_wallet.svg"),
		copyIcon: require("./images/copy_icon.svg"),
		ethBalnce: require("./images/eth_balance.svg"),
		walletDisconnet: require("./images/wallet_disconnect.svg"),
		walletActive: require("./images/wallet_active.svg"),
		walletBlack: require("./images/wallet_black.svg"),
		//about us
		aboutBannerImage: require("./images/aboutus_banner.png"),
		aboutSection: require("./images/aboutus_section.png"),
		aboutFooterSection: require("./images/about_footer_banner.png"),
		linkedinIcon: require("./images/linkedin_team.svg"),

		step_1: require("./images/steps/step_1.svg"),
		step_2: require("./images/steps/step_2.svg"),
		step_3: require("./images/steps/step_3.svg"),
		step_4: require("./images/steps/step_4.svg"),
		step_5: require("./images/steps/step_5.svg"),
		step_6: require("./images/steps/step_6.svg"),
		step_7: require("./images/steps/step_7.svg"),
		step_8: require("./images/steps/step_8.svg"),
		testimonialQoute: require("./images/testimonial_qoute.svg"),
		faqConetnt: "https://uielement.s3-ap-southeast-1.amazonaws.com/seller/faq_content.png",
		faqConetnt2: "https://uielement.s3-ap-southeast-1.amazonaws.com/seller/faq_content2.png",
		web: require("./images/web.svg"),
		magazine: require("./images/magazine.svg"),
		print: require("./images/print.svg"),
		share: require("./images/share.svg"),
		areYouArtist: require("./images/are_you_artist.png"),
		qouteGrey: require("./images/qoute_grey.svg"),
		qouteWhite: require("./images/qoute_white.svg"),
		soldArtwork: require("./images/art_sold.png"),
		ABT_new_logo: require("./images/ABT_new_logo.png"),
		logo2x_dark: require("./images/logo2x_dark.png"),
		Tech_Coffee_House_Logo2: require("./images/Tech_Coffee_House_Logo2.png"),
		wbologo: require("./images/wbologo.png"),
		Zaobao: require("./images/Zaobao.png"),
		WRDE_COAST_TV: require("./images/WRDE.png"),
		BusinessInAsia_White: require("./images/business_in_asia.png"),
		technode_global_logop3_light: require("./images/technode_global.png"),
		Digital_Journal_Logosm: require("./images/digital_journal.png"),
		logo_lifestyle_black3: require("./images/logo_lifestyle_black3.png"),
		money_fm: require("./images/money_fm.png"),
		pageNotFound: require("./images/png_404.png"),

		plusWhiteIcon: require("./images/plus_white_icon.svg"),
		//Checkout page
		leftArrowOrange: require("./images/orangeArrow.svg"),
		leftArrowBlack: require("./images/leftArrowBlack.svg"),
		deleteOrange: require("./images/delete_orange.svg"),
		deleteBlack: require("./images/deleteBlack.svg"),
		favOrange: require("./images/fav-orange.svg"),
		uploadWhiteIcon: require("./images/upload_white_icon.svg"),

		cardBtn: require("./images/checkout/card_btn.svg"),
		cardBtnActive: require("./images/checkout/card_btn_active.svg"),
		paypalBtn: require("./images/checkout/paypal_btn.svg"),
		paypalBtnActive: require("./images/checkout/paypal_btn_active.svg"),
		cryptoBtn: require("./images/checkout/crypto_btn.svg"),
		cryptoBtnActive: require("./images/checkout/crypto_btn_active.svg"),
		verifyIcon: require("./images/checkout/verify_icon.svg"),

		paypalNew: require("./images/paypal_new.svg"),
		creditCardNew: require("./images/debit_card_new.svg"),
		debitCardNew: require("./images/debit_card_new.svg"),
		bankingNew: require("./images/banking_new.svg"),
		cryptoNew: require("./images/crypto_new.svg"),
		qouteBlack: require("./images/qoute_black.svg"),
		likeImg: require("./images/like_new.svg"),
		likedImg: require("./images/liked_new.svg"),
		commentImg: require("./images/comment_new.svg"),
		favImg: require("./images/fav_img.svg"),
		favSelectedImg: require("./images/fav_selected_img.svg"),
		shareImg: require("./images/share_new.svg"),
		printImg: require("./images/print_new.svg"),
		nftPhysical: require("./images/nft_physical.svg"),
		digitlaArtworkIcon: require("./images/digital_artwork_icon.svg"),
		physicalArtworkIcon: require("./images/physical_artwork_icon.svg"),
		nftArtwokIcon: require("./images/nft_icon.svg"),
		nfcIconNew: require("./images/nfc_icon.svg"),
		copyBtn: require("./images/copy_btn.svg"),
		qouteBlack: require("./images/qoute_black.svg"),
		successGreenRound: require("./images/success-green-arrow-roound.svg"),
		fbLogo: require("./images/fb-logo.svg"),
		bitcoinLogo: require("./images/bitcoin-logo.svg"),
		googleLogo: require("./images/google-logo.svg"),
		appleLogo: require("./images/apple-logo.svg"),
		regDigital: require("./images/reg-digital-bg.svg"),
		regPhysical: require("./images/reg-pgysical-bg.svg"),
		regSignInBox: require("./images/reg-sign-box.svg"),
		regBanner: "https://uielement.s3.ap-southeast-1.amazonaws.com/register/graffiti-children-bicycle.png",
		artist_role: require("./images/art_seller.svg"),
		gallery_role: require("./images/art_buyer.svg"),
		buyer_role: require("./images/art_enthusiast.svg"),
		cardpayIcon: require("./images/cardpayIcon.svg"),
		cardpayIcon_filled: require("./images/cardpayIcon-filled.svg"),
		crypto: require("./images/crypto.svg"),
		crypto_filled: require("./images/crypto-filled.svg"),
		Metamask_button_a: require("./images/Metamask_button_a.svg"),
		likeActive: require("./images/artwork_like_active.svg"),
		likeInactive: require("./images/artwork_like_inactive.svg"),
		whishlistActive: require("./images/artwork_wishlist_active.svg"),
		whishlistInactive: require("./images/artwork_wishlist_inactive.svg"),
		saveIconSmall: require("./images/save_small.svg"),
		saveIconForMenu: require("./images/wishlist_header_s2.svg"),

		savedIconSmall: require("./images/saved_small.png"),
		saveIconForMenu: require("./images/wishlist_header_s3.svg"),
		shareIconForBanner: require("./images/banner_share_icon.svg"),
		calander_nft: require("./images/nftdrop/calendar_nft.svg"),
		share_nft: require("./images/nftdrop/share_nft.svg"),

		eyeIconBlack: require("./images/eye_icon_black.svg"),
		crossLine: require("./images/Cross_line.svg"),
		doneGreenTick: require("./images/done_green_tick_circle.svg"),
		digitalSign: require("./images/digital_sign.svg"),

		//NFT Drop
		commingSoonText: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/comming_soon.svg",
		mainBannerText: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/nft-banner-main-text.svg",

		nftMainBanner: require("./images/nftdrop/banner_img.png"),
		nftCommingSoonBanner: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/comming_soon_banner.webp",
		nftRtistiqLogo: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/nft_rtistiq.png",
		nftRajaLogo: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/raja_logo.png",
		nftRajaLogoJpg: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/rrv_logo.webp",
		nftTheFirst: require("./images/nftdrop/the_first_and_NFT.png"),
		ownPieceImgBlur1: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image5bw.webp",
		ownPieceImgBlur2: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image6bw.webp",
		ownPieceImgBlur3: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image7bw.webp",
		ownPieceImgBlur4: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image8bw.webp",
		ownPieceImgBlur5: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image9bw.webp",

		ownPieceImgNor1: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image5c.webp",
		ownPieceImgNor2: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image6c.webp",
		ownPieceImgNor3: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image7c.webp",
		ownPieceImgNor4: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image8c.webp",
		ownPieceImgNor5: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/image9c.webp",
		rajaRaviVarma: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/raja-ravi-varma.webp",
		questionIcon: require("./images/nftdrop/question_mark_yellow.svg"),
		oopsSorry: require("./images/nftdrop/oops_sorry.png"),
		galleryLogo: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/gallery_logo.webp",
		galleryLogoNew: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/gallery_logo.webp",
		galleryLogoMobile: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/gallery_logo_mobile.png",

		artManyIcon1: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/icon_01.png",
		artManyIcon2: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/icon_02.png",
		artManyIcon3: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/icon_03.png",
		artManyIcon4: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/icon_04.png",

		artManyBg1: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/art_bg_01.webp",
		artManyBg2: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/art_bg_02.webp",
		artManyBg3: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/art_bg_03.webp",
		artManyBg4: "https://uielement.s3.ap-southeast-1.amazonaws.com/nftdrop/webp/art_bg_04.webp",

		nftMenuIdel: require("./images/nftdrop/NFT_button_idle.svg"),
		nftMenuIdel2: require("./images/nftdrop/NFT_button_idle_02.svg"),
		nftMenuBliker: require("./images/nftdrop/NFT_button_blinker.svg"),

		privateKeysvg: require("./images/privatekey.svg"),
		textfilesvg: require("./images/textfile.svg"),
		eye_icon_blue: require("./images/Eye_icon_blue.svg"),
		download_icon_blue: require("./images/Download_icon_blue.svg"),
		next_nft_drop_logo_grey: require("./images/nftdrop/next-nft-drop-logo-grey.png"),

		//Artist New Menus
		artist_menu_profile: require("./images/menus/icon_profile.svg"),
		artist_menu_inventory: require("./images/menus/icon_inventory.svg"),
		artist_menu_blockchain: require("./images/menus/icon_blockchain.svg"),
		artist_menu_fav: require("./images/menus/icon_favorites.svg"),
		artist_menu_offers: require("./images/menus/icon_offers_orders.svg"),
		artist_menu_address: require("./images/menus/icon_address.svg"),
		artist_menu_account: require("./images/menus/iocn_account_settings.svg"),
		artist_menu_terms: require("./images/menus/icon_terms_conditions.svg"),
		artist_menu_support: require("./images/menus/icon_support.svg"),
		artist_menu_purchase: require("./images/menus/icon_purchase.svg"),
		artist_menu_signature: require("./images/menus/icon_signature.svg"),
		edit_auct_address: require("./images/edit_auct_address.svg"),
		delete_auct_address: require("./images/delete_auct_address.svg"),
	},
};

export default assets;
