import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import assets from "assets";
import {
	ArtworkCardWrapper,
	ArtworkCardImageWrapper,
	ArtworkCardImagePlaceholder,
	ArtworkCardImage,
	ArtworkCardActions,
	ArtwordCardTitle,
	ArtworkCardFooter,
	ArtworkCardArtist,
	ArtworkCardPrice,
	FollowButton,
	ArtworkCardFooterPrice,
	ArtwordCardDiscount,
	ArtworkCardOffer,
	ProfileIcon,
	FollowButtonWrapper,
	ArtworkCardImageSearch,
	ArtworkTitleContainer,
	Available,
} from "./LatestArtWork.styled";
import { Icon, Tooltip } from "antd";
import ConfirmPopup from "common/ConfirmPopup";
import { userManagementHooks } from "ecommerce-sdk/src/index";
import SDK from "ecommerce-sdk/src/index";
import { useDispatch } from "react-redux";
import useModal from "utils/useModal";
import ModalTypes from "utils/ModalTypes";
import { ceil } from "lodash";
import { useTranslation } from "react-i18next";

/**
 * NOTES
 * @param data Object Each data item to display
 * @param containerWidth Width of title / To set title overflow
 * @param margin String Margin for each card [By default 0]
 * @param type String To show which actions to show [By default like/favourite/cart]
 * @param hideFollowButton Boolean To show follow button for artists [By default shows follow button on hover]
 * @param showOffer Boolean To show/hide offer tag [By default false]
 * @param showAction Boolean To show/hide action icons [By default true]
 * @param sold Sold/Not
 * @function onClickEditButton Click to the edit button of action
 * @function onClickDeleteButton Click to the delete button of action
 * @
 */
const ArtWorkCardForSearch = ({ history, data = {}, onAction = () => {}, containerWidth, handleBlur, ...props }) => {
	const { useGetUserInfo } = userManagementHooks;
	const [, userStates] = useGetUserInfo();
	const { t } = useTranslation();

	const { margin, type, hideFollowButton = false, showOffer = false, showAction = true, sold = false } = props;
	// Functions
	const { onClickEditButton, onClickDeleteButton } = props;
	const { imageGreyPlace, like, likeActiveBlue, heart, heartActiveRed, artistIcon, editIcon, signIcon, deleteIcon } = assets.images;

	// Values used from data prop
	const {
		dimensions,
		displayPicture,
		displayPictureOriginal,
		displayPictureUc,
		productName,
		slugId,
		_id,
		sellingPrice,
		price,
		stockId,
		medium,
		status,
		isHidePrice,
		artistName,
	} = data;

	//login check
	const [, , showModal] = useModal();
	const dispatch = useDispatch();
	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		const checkLoggedIn = async () => {
			const isLoggedInResult = await SDK.UserManagement.isLoggedIn();
			setLoggedIn(isLoggedInResult);
		};
		checkLoggedIn();
	}, []);

	// TODO : Different currencies
	// TODO : Get image placeholder for no image
	// loading above count

	// ================= On click artwork ================= //
	const onClickArtwork = () => {
		handleBlur();
		sold === true
			? history.push(`/art/${medium && medium.slugId}/${slugId}/${stockId}`)
			: history.push(`/art/${medium && medium.slugId}/${slugId}`);
	};

	return (
		<ArtworkCardWrapper margin={"8px"} hideNameOnHover={false} key={_id}>
			<ArtworkCardImageWrapper>
				{/* Artwork Image */}
				{displayPicture ? (
					<ArtworkCardImageSearch
						shouldAddExtraWrap
						extraWrapClassName="artwork-card-empty-image"
						onClick={(e) => onClickArtwork(e)}
						src={displayPicture || imageGreyPlace}
						fallback={displayPictureUc || imageGreyPlace}
						alt={`${productName}`}
					/>
				) : (
					<ArtworkCardImagePlaceholder>
						<img src={imageGreyPlace} onClick={(e) => onClickArtwork(e)} alt="" />
					</ArtworkCardImagePlaceholder>
				)}
			</ArtworkCardImageWrapper>

			<ArtworkTitleContainer>
				{status === 1 && (
					<Available>
						<img src={assets.images.tick} alt="✓" />
					</Available>
				)}

				<ArtwordCardTitle customWidth={"100%"}>
					{`${productName}  (${dimensions.width}w X ${dimensions.height}h In)` || "Artwork"}
				</ArtwordCardTitle>
			</ArtworkTitleContainer>

			<ArtworkCardFooterPrice>
				{!isHidePrice && (
					<ArtworkCardPrice>{(sellingPrice || price) && `USD ${sellingPrice.toLocaleString() || price.toLocaleString()}`}</ArtworkCardPrice>
				)}
				<ArtworkCardPrice>{artistName !== null ? artistName : ""}</ArtworkCardPrice>
			</ArtworkCardFooterPrice>
		</ArtworkCardWrapper>
	);
};

export default withRouter(ArtWorkCardForSearch);
