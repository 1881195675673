import React, { useEffect, useState, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import SDK, { useRefreshTokenContainer } from "ecommerce-sdk/src/index";
import FullScreenLoader from "common/FullScreenLoader";
import toast from "common/Toast";
import { useHistory } from "react-router-dom";
import { toastMessage } from "utils/toastMessages";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const [loggedIn, setLoggedIn] = useState({ login: false, loading: true });
	const loadingDiv = useRef(<FullScreenLoader />);
	const { refreshToken, refreshTokenData } = useRefreshTokenContainer();
	let history = useHistory();

	useEffect(() => {
		refreshToken();
	}, []);

	useEffect(() => {
		if (refreshTokenData && refreshTokenData.status === 401) {
			SDK.UserManagement.logout();
			history.push("/");
			window.location.reload();
		}
	}, [refreshTokenData]);

	useEffect(() => {
		let loadLogInStatus = true;

		SDK.UserManagement.isLoggedIn().then((result) => {
			if (loadLogInStatus) {
				if (!result) toast(toastMessage.doLogin, "warning");
				setLoggedIn({ login: result, loading: false });
			}
		});
		return () => {
			loadLogInStatus = false;
		};
	}, []);

	return (
		<Route
			{...rest}
			render={(props) => {
				return loggedIn.loading ? (
					loadingDiv.current
				) : loggedIn.login ? (
					<Component {...props} />
				) : (
							<Redirect
								to={{
									pathname: "/",
									state: { from: props.location }
								}}
							/>
						);
			}
			}
		/>
	);
};

export default PrivateRoute;
