import assets from "assets";
import { Cookies } from "react-cookie-consent";

export const SellerProfileMenu = [
	{
		title: "Favorites & Following",
		route: "favorites-following",
		image: assets.images.arrowRight,
		subMenu: [
			{
				title: "Favorite Artworks",
				route: "artworks",
				image: assets.images.arrowRight,
			},
			{
				title: "Followers",
				route: "followers",
				image: assets.images.arrowRight,
			},
			{
				title: "Following",
				route: "following",
				image: assets.images.arrowRight,
			},
		],
	},

	{
		title: "Order Management",
		route: "order-management",
		image: assets.images.arrowRight,
		subMenu: [
			{
				title: "Received Orders",
				route: "received-orders",
				image: assets.images.arrowRight,
			},

			{
				title: "Orders",
				route: "orders",
				image: assets.images.arrowRight,
			},
		],
	},
	{
		title: "Request Received",
		route: "request",
		image: assets.images.arrowRight,
		subMenu: [
			{
				title: "Quotes",
				route: "quotes",
				image: assets.images.arrowRight,
			},
		],
	},
	{
		title: "Request Sent",
		route: "request",
		image: assets.images.arrowRight,
		subMenu: [
			{
				title: "My Quotes",
				route: "my-quotes",
				image: assets.images.arrowRight,
			},
		],
	},
	{
		title: "Account Settings",
		route: "account-settings",
		image: assets.images.arrowRight,
		subMenu: [
			{
				title: "Blockchain Account",
				route: "blockchain-account",
				image: assets.images.arrowRight,
			},
			{
				title: "General Account Settings",
				route: "general",
				image: assets.images.arrowRight,
			},
			{
				title: "Address Book",
				route: "address",
				image: assets.images.arrowRight,
			},
			{
				title: "Signature Pending Artworks",
				route: "signature-pending-artworks",
				image: assets.images.arrowRight,
			},
			{
				title: "Settings",
				route: "settings",
				image: assets.images.arrowRight,
			},
		],
	},
];

export const rootSubmenuKeys = ["favorites-following", "order-management", "request-received", "request-sent", "account-settings"];

export const newUserDashBoardMenu = [
	{
		title: "FAVORITES",
		icon: assets.images.artist_menu_fav,
		menuId: "fav_menu",
		subMenu: [
			{
				title: "Artworks",
				route: "artworks",
			},
			{
				title: "Artists",
				route: "following-artists",
			},
			{
				title: "Followers",
				route: "followers",
			},
			{
				title: "Galleries",
				route: "following-galleries",
			},
		],
	},
	{
		title: "MY PURCHASES",
		icon: assets.images.artist_menu_purchase,
		menuId: "my_purchases",
		subMenu: [
			{
				title: "Owned Artworks",
				route: "owned-artwork",
			},
			{
				title: "Pending Offers",
				route: "my-quotes",
			},
			{
				title: "Purchase History",
				route: "orders",
			},
		],
	},
	{
		title: "ADDRESS BOOK",
		icon: assets.images.artist_menu_address,
		menuId: "address_menu",
		subMenu: [
			{
				title: "Manage Addresses",
				route: "manage-address",
			},
		],
	},
	{
		title: "BLOCKCHAIN",
		icon: assets.images.artist_menu_blockchain,
		menuId: "blockchain_menu",
		subMenu: [],
	},

	{
		title: "ACCOUNT SETTINGS",
		icon: assets.images.artist_menu_account,
		menuId: "general",
		subMenu: [
			{
				title: "General Settings",
				route: "general",
			},
		],
	},
	{
		title: "TERMS & CONDITIONS",
		icon: assets.images.artist_menu_terms,
		menuId: "terms_menu",
		subMenu: [],
	},
	{
		title: "SUPPORT",
		icon: assets.images.artist_menu_support,
		menuId: "supprort_menu",
		subMenu: [
			{
				title: "About Us",
				route: "about-us",
			},
			{
				title: "Visit Help Center",
				route: "faq",
			},
			{
				title: "Contact RtistiQ",
				route: "contact-us",
			},
		],
	},
	{
		title: "LOGOUT",
		icon: assets.images.artist_menu_signature,
		menuId: "logout_menu",
		subMenu: [],
	},
];

export const newGalleryDashBoardMenu = [
	{
		title: "FAVORITES",
		icon: assets.images.artist_menu_fav,
		menuId: "fav_menu",
		subMenu: [
			{
				title: "Artworks",
				route: "artworks",
			},
			{
				title: "Artists",
				route: "following-artists",
			},
			{
				title: "Followers",
				route: "followers",
			},
			{
				title: "Galleries",
				route: "following-galleries",
			},
		],
	},
	{
		title: "MY PURCHASES",
		icon: assets.images.artist_menu_purchase,
		menuId: "my_purchases",
		subMenu: [
			{
				title: "Owned Artworks",
				route: "owned-artwork",
			},
			{
				title: "Pending Offers",
				route: "my-quotes",
			},
			{
				title: "Purchase History",
				route: "orders",
			},
		],
	},
	{
		title: "INVENTORY",
		icon: assets.images.artist_menu_inventory,
		menuId: "inventory_menu",
		subMenu: [
			{
				title: "Manage Inventory",
				route: "manage-inventory",
			},
			{
				title: "Add Artwork",
				route: "add-artwork",
			},

			{
				title: "Signature Pending Artworks",
				route: "signature-pending-artworks",
			},
		],
	},

	{
		title: "ADDRESS BOOK",
		icon: assets.images.artist_menu_address,
		menuId: "address_menu",
		subMenu: [
			{
				title: "Manage Addresses",
				route: "manage-address",
			},
		],
	},
	{
		title: "BLOCKCHAIN",
		icon: assets.images.artist_menu_blockchain,
		menuId: "blockchain_menu",
		subMenu: [],
	},

	{
		title: "ACCOUNT SETTINGS",
		icon: assets.images.artist_menu_account,
		menuId: "general",
		subMenu: [
			{
				title: "General Settings",
				route: "general",
			},
		],
	},
	{
		title: "TERMS & CONDITIONS",
		icon: assets.images.artist_menu_terms,
		menuId: "terms_menu",
		subMenu: [],
	},
	{
		title: "SUPPORT",
		icon: assets.images.artist_menu_support,
		menuId: "supprort_menu",
		subMenu: [
			{
				title: "About Us",
				route: "about-us",
			},
			{
				title: "Visit Help Center",
				route: "faq",
			},
			{
				title: "Contact RtistiQ",
				route: "contact-us",
			},
		],
	},
	{
		title: "LOGOUT",
		icon: assets.images.artist_menu_signature,
		menuId: "logout_menu",
		subMenu: [],
	},
];

export const newArtistDashboardMenus = [
	{
		title: "ARTIST PROFILE",
		icon: assets.images.artist_menu_profile,
		menuId: "artist_profile_menu",
		subMenu: [
			{
				title: "Edit Profile",
				route: "edit-profile",
			},
			{
				title: "Manage Events",
				route: "manage-events",
			},
			{
				title: "Awards & Recognitions",
				route: "awards",
			},
			{
				title: "Media and Publications",
				route: "media-publication",
			},

			{
				title: "View Profile",
				route: "view-profile",
			},
		],
	},
	{
		title: "INVENTORY",
		icon: assets.images.artist_menu_inventory,
		menuId: "inventory_menu",
		subMenu: [
			{
				title: "Manage Inventrory",
				route: "manage-inventory",
			},
			{
				title: "Add Artwork",
				route: "add-artwork",
			},

			{
				title: "Manage Collection",
				route: "manage-collection",
			},
			{
				title: "Add Collection",
				route: "add-collection",
			},
			{
				title: "Signature Pending Artworks",
				route: "signature-pending-artworks",
			},
		],
	},
	{
		title: "FAVORITES",
		icon: assets.images.artist_menu_fav,
		menuId: "fav_menu",
		subMenu: [
			{
				title: "Artworks",
				route: "artworks",
			},
			{
				title: "Artists",
				route: "following-artists",
			},
			{
				title: "Followers",
				route: "followers",
			},
			{
				title: "Galleries",
				route: "following-galleries",
			},
		],
	},
	{
		title: "MY PURCHASES",
		icon: assets.images.artist_menu_purchase,
		menuId: "my_purchases",
		subMenu: [
			{
				title: "Owned Artworks",
				route: "owned-artwork",
			},
			{
				title: "Pending Offers",
				route: "my-quotes",
			},
			{
				title: "Purchase History",
				route: "orders",
			},
		],
	},
	{
		title: "OFFERS, ORDERS & Other Requests",
		menuId: "offer_menu",
		icon: assets.images.artist_menu_offers,
		subMenu: [
			{
				title: "Pending Offer",
				route: "quotes",
			},
			{
				title: "Disclose Price",
				route: "disclose-price",
			},
			{
				title: "Manage Orders",
				route: "received-orders",
			},
			{
				title: "Gallery Request ",
				route: "group-requests",
			},
		],
	},
	{
		title: "ADDRESS BOOK",
		icon: assets.images.artist_menu_address,
		menuId: "address_menu",
		subMenu: [
			{
				title: "Manage Addresses",
				route: "manage-address",
			},
		],
	},
	{
		title: "BLOCKCHAIN & OWNERSHIP",
		icon: assets.images.artist_menu_blockchain,
		menuId: "blockchain_menu",
		subMenu: [],
	},
	{
		title: "SIGNATURE",
		icon: assets.images.artist_menu_signature,
		menuId: "signature_menu",
		subMenu: [],
	},

	{
		title: "ACCOUNT SETTINGS",
		icon: assets.images.artist_menu_account,
		menuId: "general",
		subMenu: [
			{
				title: "General Settings",
				route: "general",
			},
			{
				title: "KYC Details ",
				route: "view_kyc",
			},
		],
	},
	{
		title: "TERMS & CONDITIONS",
		icon: assets.images.artist_menu_terms,
		menuId: "terms_menu",
		subMenu: [],
	},
	{
		title: "SUPPORT",
		icon: assets.images.artist_menu_support,
		menuId: "supprort_menu",
		subMenu: [
			{
				title: "About Us",
				route: "about-us",
			},
			{
				title: "Visit Help Center",
				route: "faq",
			},
			{
				title: "Contact RtistiQ",
				route: "contact-us",
			},
		],
	},
];

export const countryList = [
	{ name: "India", value: "India" },
	{ name: "Singapore", value: "Singapore" },
	{ name: "USA", value: "USA" },
	{ name: "UK", value: "UK" },
];

export const geolocationGoogleKey = "AIzaSyApdTLDu6THYptD9fvk4q6v6OesCm-Zm3Y";

export const AppTitle = "RtistiQ";
export const MAX_YEAR = new Date().getFullYear();
// export const siteKey = "6LeZ3dgUAAAAACPq-dyjpYJLVFiIDBHf7W_1g2Ya";
export const siteKey = "6LeOCAccAAAAAEoM5uk7a-XR8Z0qwwMKGilZceHJ";
// export const secretKeys = {
// 	facebookAppId: 1006512806454687,
// 	"captchaKey": "6LeOCAccAAAAAEoM5uk7a-XR8Z0qwwMKGilZceHJ",
// 	"googleClientId": "672920033440-nq2qah1llisoaua8pnu0tv7ai9qbd9u5.apps.googleusercontent.com",
// 	"geolocationGoogleKey": "AIzaSyApdTLDu6THYptD9fvk4q6v6OesCm-Zm3Y",
// }
//Common secret keys
export const secretKeys = {
	facebookAppId: 338191470556329,
	captchaKey: "6LdP9f4UAAAAANg8lDbyzYDqd9zt4Fs3svxWYgmI",
	googleClientId_old: "672920033440-nq2qah1llisoaua8pnu0tv7ai9qbd9u5.apps.googleusercontent.com",
	googleClientId: "1058154572317-1cjvr0msl2nf71m3envf4qn8m9qdfd9e.apps.googleusercontent.com",
	geolocationGoogleKey: "AIzaSyApdTLDu6THYptD9fvk4q6v6OesCm-Zm3Y",
	appleClientId: "com.rtistiq.artweb",
	appleRedirectUri: `${process.env.REACT_APP_BASEURL}en/appleredirect`,
	stripeKey: "pk_test_B55c7LshdFvVeI6em4m6kMzV00ZNHXR6J2",
	paypalClientId: "Ab6MZlJb6FkzBKtcrDkpA2yEfdlXpF341gwpskYeMjVgr9HrRha582Ovov2W968NK28rVNv0oKmVVbtw",
	mailChimpUrl: "https://rtistiq.us7.list-manage.com/subscribe/post?u=75329353c0400bc5abd3a6b81&amp;id=c8596c8b23",
	mailChipUrlNft: "https://rtistiq.us7.list-manage.com/subscribe/post?u=75329353c0400bc5abd3a6b81&id=c8596c8b23&SIGNUP=NFTDrop",
};

export const AlphabeticalFilter = [
	"All",
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
];

export const Currencies = [
	"USD",
	"AED",
	"AFN",
	"ALL",
	"AMD",
	"ANG",
	"AOA",
	"ARS",
	"AUD",
	"AWG",
	"AZN",
	"BAM",
	"BBD",
	"BDT",
	"BGN",
	"BIF",
	"BMD",
	"BND",
	"BOB",
	"BRL",
	"BSD",
	"BWP",
	"BZD",
	"CAD",
	"CDF",
	"CHF",
	"CLP",
	"CNY",
	"COP",
	"CRC",
	"CVE",
	"CZK",
	"DJF",
	"DKK",
	"DOP",
	"DZD",
	"EGP",
	"ETB",
	"EUR",
	"FJD",
	"FKP",
	"GBP",
	"GEL",
	"GIP",
	"GMD",
	"GNF",
	"GTQ",
	"GYD",
	"HKD",
	"HNL",
	"HRK",
	"HTG",
	"HUF",
	"IDR",
	"ILS",
	"INR",
	"ISK",
	"JMD",
	"JPY",
	"KES",
	"KGS",
	"KHR",
	"KMF",
	"KRW",
	"KYD",
	"KZT",
	"LAK",
	"LBP",
	"LKR",
	"LRD",
	"LSL",
	"MAD",
	"MDL",
	"MGA",
	"MKD",
	"MMK",
	"MNT",
	"MOP",
	"MRO",
	"MUR",
	"MVR",
	"MWK",
	"MXN",
	"MYR",
	"MZN",
	"NAD",
	"NGN",
	"NIO",
	"NOK",
	"NPR",
	"NZD",
	"PAB",
	"PEN",
	"PGK",
	"PHP",
	"PKR",
	"PLN",
	"PYG",
	"QAR",
	"RON",
	"RSD",
	"RUB",
	"RWF",
	"SAR",
	"SBD",
	"SCR",
	"SEK",
	"SGD",
	"SHP",
	"SLL",
	"SOS",
	"SRD",
	"STD",
	"SZL",
	"THB",
	"TJS",
	"TOP",
	"TRY",
	"TTD",
	"TWD",
	"TZS",
	"UAH",
	"UGX",
	"UYU",
	"UZS",
	"VND",
	"VUV",
	"WST",
	"XAF",
	"XCD",
	"XOF",
	"XPF",
	"YER",
	"ZAR",
	"ZMW",
];

export const RoomImageSmall = [
	{ image: require("../assets/roomViewImages/R1-S.png"), hegihtIndex: 12.7, widthIndex: 12.7 },
	{ image: require("../assets/roomViewImages/R2-S.png"), hegihtIndex: 15.97, widthIndex: 15.97 },
	{ image: require("../assets/roomViewImages/R3-S.png"), hegihtIndex: 9.83, widthIndex: 9.83 },
];

export const RoomImageMedium = [
	{ image: require("../assets/roomViewImages/R1-M.png"), hegihtIndex: 7.38, widthIndex: 7.38 },
	{ image: require("../assets/roomViewImages/R2-M.png"), hegihtIndex: 9.7, widthIndex: 9.7 },
	{ image: require("../assets/roomViewImages/R3-M.png"), hegihtIndex: 7.24, widthIndex: 7.24 },
	{ image: require("../assets/roomViewImages/R4-M.png"), hegihtIndex: 10.23, widthIndex: 10.23 },
];

export const RoomImageLarge = [
	{ image: require("../assets/roomViewImages/R1-L.png"), hegihtIndex: 3.76, widthIndex: 3.76 },
	{ image: require("../assets/roomViewImages/R2-L.png"), hegihtIndex: 3.69, widthIndex: 3.69 },
	{ image: require("../assets/roomViewImages/R3-L.png"), hegihtIndex: 3.9, widthIndex: 3.9 },
	// { image: require("../assets/roomViewImages/R4-L.png"), hegihtIndex: 11.62, widthIndex: 11.62 },
	{ image: require("../assets/roomViewImages/R5-L.png"), hegihtIndex: 4.84, widthIndex: 4.84 },
];
export const GDPR_CONSENT_755 = "yes";
export const GDPR = Cookies.get("rtistiqCookieConsent") ? 1 : 0;

export const RoomImageExtraLarge = [
	{ image: require("../assets/roomViewImages/R1-EL.png"), hegihtIndex: 3.33, widthIndex: 3.33 },
	{ image: require("../assets/roomViewImages/R2-EL.png"), hegihtIndex: 4.18, widthIndex: 4.18 },
	{ image: require("../assets/roomViewImages/R3-EL.png"), hegihtIndex: 3.05, widthIndex: 3.05 },
];

export const PressImageArray = [
	require("../assets/PR1.png"),
	require("../assets/PR2.png"),
	require("../assets/PR3.png"),
	require("../assets/PR4.png"),
	require("../assets/PR5.png"),
	require("../assets/PR6.png"),
	require("../assets/PR7.png"),
	require("../assets/PR8.png"),
	require("../assets/PR9.png"),
	require("../assets/PR10.png"),
];

export const CollaborationImageArray = [
	{ image: require("../assets/TechnologyPartners/TripleA.webp"), url: "https://triple-a.io/" },
	{ image: require("../assets/TechnologyPartners/stripe.svg"), url: "https://stripe.com/" },
	{ image: require("../assets/CR3.png"), url: "https://bluecast.tech/" },
	{ image: require("../assets/TechnologyPartners/aws.png"), url: "https://aws.amazon.com/" },
	{ image: require("../assets/TechnologyPartners/polygon.svg"), url: "https://polygontechnology.notion.site/" },
];

export const HashLink = "https://ropsten.etherscan.io/tx/";
export const PolygonHashLink = "https://mumbai.polygonscan.com/tx/";
export const EthHashLink = "https://ropsten.etherscan.io/tx/";
export const PaypalSupprotCurrency = [
	"AUD",
	"BRL",
	"CAD",
	"CNY",
	"CZK",
	"DKK",
	"EUR",
	"HKD",
	"HUF",
	"ILS",
	"JPY",
	"MYR",
	"MXN",
	"TWD",
	"NZD",
	"NOK",
	"PHP",
	"PLN",
	"GBP",
	"RUB",
	"SGD",
	"SEK",
	"CHF",
	"THB",
	"USD",
];

export const ourTeam = [
	{
		name: "Jothi Menon",
		designation: "CEO & FOUNDER",
		email: "jothi.menon@rtistiq.com",
		profileImg: require("../assets/images/teams/JothiMenon.png"),
		description:
			"Entrepreneur & Technologist by Profession in the space of Investment Banking and Financial Markets. Extensive experience in international business, strategy, financial markets, having founded and merged a leading financial technology consulting firm. Family driven passion to wards Art, History and South-Asian Culture. ",
		linkedinLink: "https://www.linkedin.com/in/jothimenon",
	},
	{
		name: "Vinita Angelo",
		designation: "COO & CO-FOUNDER",
		email: "vinita@rtistiq.com",
		profileImg: require("../assets/images/teams/VinitaAngelo.png"),
		description:
			"Operations and networking expert with a keen passion for performing arts. Art to me is a form of communication where you can connect with the true person behind a painting, and hence I would like to bring this true essence to the surface wherein technology connects the artist.",
		linkedinLink: "https://www.linkedin.com/in/vinita-angelo/",
	},
	{
		name: "Aude Rech",
		designation: "Curator",
		email: "aude@rtistiq.com",
		profileImg: require("../assets/images/teams/AudeRech.png"),
		description:
			"I have worked solely as an artist from my studio based in Paris and in the south of France. I recognized the importance of communicating and being actively involved in the art community. I have followed through the years, many artists, private collectors, and galleries in their journey through art and I'm constantly on the lookout to reach out to new or well-established talents. Art is the only thing that elevates me, giving me the feeling of being positively alive, defining a new meaning to life and existence. Art! such a powerful life force!",
		linkedinLink: "https://www.linkedin.com/in/auderech/",
	},
	{
		name: "Floarea Bänziger",
		designation: "Curator",
		email: "floarea@rtistiq.com",
		profileImg: require("../assets/images/teams/FloareaBanziger.png"),
		description:
			"I had my art epiphany when I discovered Walter de Maria. However, it was always clear to me that understanding contemporary art requires a good knowledge of art history. I see my role as a catalyst for establishing partnerships between artists and collectors.",
		linkedinLink: "",
	},
];

export const ourTeam1 = [
	{
		name: "Moniykka Pushpanathan",
		designation: "Marketing Analyst",
		email: "moniykka@rtistiq.com",
		profileImg: require("../assets/images/teams/MoniykkaPushpanathan.png"),
		description:
			"Learner at heart and go-getter by nature, I aim to deliver the best experience possible to all artists and collectors with my creative resources and marketing strategies. I can tell you that I'm constantly thinking about new ways to do things, new innovations to try, or new approaches to problems, so that 'script,' if you will, is constantly running in my brain!",
		linkedinLink: "https://www.linkedin.com/in/moniykka-nathan/",
	},
	{
		name: "Cyril",
		designation: "Business Developer",
		email: "cyril@rtistiq.com",
		profileImg: require("../assets/images/teams/Cyril.png"),
		description:
			"I started painting as a hobby when I was little.I believe talent is just an interest and anybody can do what others do. Love is an art that comes from the heart. Whenever I go to art museums it's something that makes me breathe with a different kind of happiness, a ways to forget life.",
		linkedinLink: "https://www.linkedin.com/in/cyril-c-10459130/",
	},
	{
		name: "Amna Binte Swaleh",
		designation: "Junior Curator",
		email: "amna@rtistiq.com",
		profileImg: require("../assets/images/teams/amna.png"),
		description:
			"I have always had a deep love and understanding of the arts. Already during my master's degree, after being introduced to Curatorial Studies as a subject, I knew that I wanted to be active in this field and fulfill my dream of becoming a Curator one day. Curation, for me, is sharing the knowledge of art within the understanding of the space by working closely with the artists.",
		linkedinLink: "",
	},
	{
		name: "Suraj",
		designation: "Technical Architect",
		email: "suraj@rtistiq.com",
		profileImg: require("../assets/images/teams/Suraj.png"),
		description:
			"I am passionate about building products with new technologies. Currently focused on all things blockchain and crypto-related. Through Rtistiq we are trying to achieve transparency in the Art Market with technology as an enabler.",
		linkedinLink: "https://www.linkedin.com/in/suraj-nair-ba617016/",
	},
];

export const FeaturedInData = [
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/yahoo.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/yahoo.webp",
		featuredName: "Yahoo Finance",
		featuredUrl: "https://finance.yahoo.com/news/rtistiq-set-launch-digital-nfts-143000135.html",
	},
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/hindu.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/hindu.webp",
		featuredName: "The Hindu",
		featuredUrl: "https://www.thehindu.com/entertainment/art/raja-ravi-varma-paintings-nft-auction-2022/article65051383.ece",
	},
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/BBCNews.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/BBCNews.webp",
		featuredName: "BBC News",
		featuredUrl: "https://www.bbc.com/culture/article/20220715-raja-ravi-varma-indias-most-influential-artist",
	},
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/zao.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/zao.webp",
		featuredName: "Zao Bao",
		featuredUrl: "https://www.zaobao.com.sg/lifestyle/culture/story20211021-1205396",
	},
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/business_times.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/business_times.webp",
		featuredName: "The Business Times",
		featuredUrl: "https://www.businesstimes.com.sg/technology/indian-star-kamal-haasan-to-launch-metaverse-avatar",
	},
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/ad.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/ad.webp",
		featuredName: "Architectural Digest",
		featuredUrl: "https://www.architecturaldigest.in/story/two-of-raja-ravi-varmas-best-known-paintings-can-now-be-owned-as-nfts/",
	},
	{
		featuredImg: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/Hindustan.svg",
		featuredImgWebP: "https://uielement.s3.ap-southeast-1.amazonaws.com/featuredin/Hindustan.webp",
		featuredName: "Hindustan Times",
		featuredUrl: "https://www.hindustantimes.com/brand-post/rtistiq-set-to-launch-digital-nfts-of-raja-ravi-varma-s-artworks-101644498936673.html",
	},
];

export const videoExt = [
	"mp4",
	"m4a",
	"m4v",
	"f4v",
	"f4a",
	"m4b",
	"m4r",
	"f4b",
	"mov",
	"3gp",
	"3gp2",
	"3g2",
	"3gpp",
	"3gpp2",
	"ogg",
	"oga",
	"ogv",
	"ogx",
	"wmv",
	"wma",
	"asf",
	"webm",
	"flv",
	"avi",
	"mov",
	"qt",
	"hdv",
	"ts",
	"wav",
	"fbx",
	"gitf",
	"glb",
];
