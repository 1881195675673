import * as types from "./ActionsTypes";
import { AddProductService } from "../services/AddProductService";
import { ProductManagementService } from "../services/ProductManagementService";
import { CategoryManagementService } from "../services/CategoryManagementService";
import { onUpdateImagesAndVideos } from "./AddProductAction";

// ============================= Save Details ============================== //
export const onUpdateDetailsInReducer = (reducerType, payload, isMobile = false) => {
	return async (dispatch) => {
		await dispatch({ type: types[reducerType], payload });
		if (reducerType === "SAVE_LOGISTIC_DETAILS") await dispatch(onUpdateProduct(isMobile));
	};
};

// ============================= Get Product ============================= //
export function getProductDetailsAction(id) {
	return (dispatch) => {
		dispatch(request());
		if (id) {
			ProductManagementService.getProductBySlug(id).then(
				async (response) => {
					if (response) {
						// dispatch(getParentsByCategoryId(response.categoryId));
						// dispatch(getCategoryPropertiesAndMap(response));
						console.log("🚀 ~ file: UpdateProductAction.js ~ line 26 ~ getProductDetailsAction ~ response", response);

						dispatch(success(response));
					} else {
						dispatch(failure(response));
					}
				},
				(error) => {
					dispatch(failure(error));
				}
			);
		}
	};

	function request() {
		return { type: types.GET_PRODUCT_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_PRODUCT_FAILURE, error };
	}
}

// ============================= Get Category Properties ============================= //
export function getCategoryPropertiesAndMap(productData) {
	return (dispatch, getState) => {
		dispatch(request());
		CategoryManagementService.getCategoryProperty(productData.categoryId || productData).then(
			(response) => {
				if (response) {
					// Initialising value fields in product property array
					let newPropArray = response.map((item, index) => {
						const { justFilter = false, propertySuggestions = [] } = item;
						if (!justFilter) {
							if (propertySuggestions.length > 0) {
								return {
									isSelectDropdown: true,
									propertyId: item._id,
									propertyName: item.propertyName,
									dataType: item.dataType,
									propertyUnit: item.unit,
									value: item.dataType === 4 ? [] : item.dataType === 3 ? false : "",
									isMandatory: item.manadatory,
									chooseFromSuggestion: item.chooseFromSuggestion,
									propertySuggestions: item.propertySuggestions,
								};
							} else {
								return {
									isSelectDropdown: false,
									propertyId: item._id,
									propertyName: item.propertyName,
									dataType: item.dataType,
									propertyUnit: item.unit,
									value: item.dataType === 4 ? [] : item.dataType === 3 ? false : "",
									isMandatory: item.manadatory,
								};
							}
						}
					});

					// Get from API : Map value of every property from API to custom array
					if (productData && productData.productProperties) {
						const updatedPropArray = newPropArray.filter((val, index) => {
							const objectFound = productData.productProperties.find(function(o) {
								return o.propertyId === val.propertyId;
							});
							if (objectFound) {
								val.value = objectFound.value;
							}
							return val;
						});
						dispatch(success(updatedPropArray));
					}

					// Map plain array to reducer
					else dispatch(success(newPropArray));
				} else {
					dispatch(failure(response));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE, error };
	}
}

// ============================= Get Parents List Array ============================= //
export function getParentsByCategoryId(id) {
	return (dispatch) => {
		dispatch(request());
		CategoryManagementService.getParentsByCategoryId(id).then(
			(response) => {
				if (response) {
					let respReverse = response.reverse();
					let responseObj = {
						idArray: respReverse.map((item) => {
							return item._id;
						}),
						completeArr: respReverse.map((item) => {
							return {
								key: item._id,
								text: item.categoryName,
							};
						}),
					};
					dispatch(success(responseObj));
				} else {
					dispatch(failure());
				}
			},
			(error) => {
				// TODO
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: types.GET_PARENTS_BY_CATEGORY_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_PARENTS_BY_CATEGORY_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_PARENTS_BY_CATEGORY_FAILURE, error };
	}
}

// ============================= Update Product ============================= //
export function onUpdateProduct(isMobile) {
	return async (dispatch, getState) => {
		dispatch(request());

		let reducerValues = getState().updateProductManagement;

		// TODO : Issue with this method is, the original object in reducer that is mapped to component also changes
		// This should be prevented
		// Keep an array to save in reducer and an array to pass over to api
		// Unless stringify error gets multiplied
		// await reducerValues.productProperties.map((item, index) => {
		// 	if (item.dataType === 4) {
		// 		// TODO: JSON.stringify(compact(item.value))
		// 		item.value = typeof item.value !== "string" ? JSON.stringify(psuedoCompactFunction(item.value)) : item.value;
		// 		return item;
		// 	}
		// 	if (item.dataType === 3) {
		// 		item.value = typeof item.value !== "string" ? JSON.stringify(item.value) : item.value;
		// 		return item;
		// 	}
		// 	if (item.dataType === 1 || item.dataType === 2) {
		// 		item.value = typeof item.value !== "string" ? item.value.toString() : item.value;
		// 		return item;
		// 	} else return item;
		// });

		let requestObj = {
			...reducerValues,
			dimensions: {
				height: reducerValues.height,
				width: reducerValues.width,
				depth: reducerValues.depth || 0,
			},
			packageDimensions: {
				height: reducerValues.packageHeight,
				width: reducerValues.packageWidth,
				depth: reducerValues.packageDepth || 0,
			},
		};

		delete requestObj.displayPicture;

		AddProductService.updateProduct(reducerValues.productId, requestObj).then(
			(response) => {
				if (response) {
					dispatch(success(response));
				} else {
					dispatch(failure(response));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.UPDATE_PRODUCT_REQUEST };
	}
	function success(payload) {
		return { type: types.UPDATE_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.UPDATE_PRODUCT_FAILURE, error };
	}
}

function psuedoCompactFunction(array) {
	let filteredArr = array.filter((el) => {
		return el && el.toString().trim();
	});
	return filteredArr;
}
