import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";


const initialState = {
    getBasicDescription:{},
    updateArtistProfile:{}, 
    updateSocialLinks:{},
    updateArtistProfileImages:{}
};

const ArtistBasicProfileReducer = createApiReducer(initialState, [
    types.GET_ARTIST_BASIC_DESCRIPTION_REQUEST,
    types.UPDATE_ARTIST_PROFILE_REQUEST,
    types.UPDATE_SOCIAL_LINKS_REQUEST,
    types.UPDATE_ARTIST_PROFILE_IMAGES_REQUEST
  ]);

export default ArtistBasicProfileReducer;
