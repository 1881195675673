/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PaypalButtonWrapper } from "../PaymentCheckout.styled";
import { PayPalButton } from "react-paypal-button-v2";
import { secretKeys } from "utils/constants";

const Paypal = ({ orderDetails, doPaypalPayment, paypalPaymentLoading }) => {
	const createOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: orderDetails.totalPayableAmount.toString(),
					},
				},
			],
		});
	};
	return (
		<PaypalButtonWrapper>
			<PayPalButton
				createOrder={(data, actions) => createOrder(data, actions)}
				onSuccess={(details) => {
					console.log("paypal detail", details);
					doPaypalPayment(details.id);
				}}
				onError={(err) => {
					console.log("Error ", err);
				}}
				options={{
					clientId: secretKeys.paypalClientId,
					currency: orderDetails ? orderDetails.currency : "USD",
					disableFunding: "card",
				}}
			/>
		</PaypalButtonWrapper>
	);
};

export default Paypal;
