import * as types from "./ActionsTypes";
import { ProductManagementService } from "../services/ProductManagementService";
import { AddProductService } from "../services/AddProductService";

//--------------------Get Product Details Action-------------------------//

export const getProductDetailAction = (productId) => {
	return {
		actionType: types.GET_PRODUCT_DETAIL_REQUEST,
		callAPI: () => ProductManagementService.getProductDetails(productId),
		stateObject: "productDetail",
	};
};

// -------------------- Get Product Details by Slug ID -------------------- //
export const getProductBySlugAction = (slugId, stockId, IsAdminTempFix) => {
	return {
		actionType: types.GET_PRODUCT_BY_SLUG_REQUEST,
		callAPI: () => ProductManagementService.getProductBySlug(slugId, stockId, IsAdminTempFix),
		stateObject: "productDetail",
	};
};

// -------------------- Get Sold Artwork Details by Slug ID -------------------- //
export const getSoldArtWorkDetailsAction = (slugId, stockId) => {
	return {
		actionType: types.GET_SOLD_ARTWORK_DETAILS_REQUEST,
		callAPI: () => ProductManagementService.getSoldArtWorkDetails(slugId, stockId),
		stateObject: "soldArtworkDetail",
	};
};
// -------------------- Get product ownership history -------------------- //
export const getProductOwnershipHistoryAction = (stockId) => {
	return {
		actionType: types.GET_PRODUCT_OWNERSHIP_HISTORY_REQUEST,
		callAPI: () => ProductManagementService.getProductOwnershipHistory(stockId),
		stateObject: "productOwnershipHistory",
	};
};

// -------------------- Get all products list -------------------- //
export const getProductListAction = (params) => {
	return {
		actionType: types.GET_PRODUCT_LIST_REQUEST,
		callAPI: () => ProductManagementService.getProductList(params),
		stateObject: "productList",
	};
};

// -------------------- Add to WishList -------------------- //
export const addToWishlistAction = (productId) => {
	return {
		actionType: types.ADD_TO_WISHLIST_REQUEST,
		callAPI: () => ProductManagementService.addToWishlist(productId),
		stateObject: "addToWishlist",
		isTransient: true,
	};
};

// -------------------- Get WishList -------------------- //
export const getWishlist = (pageNum, pageSize) => {
	return {
		actionType: types.GET_WISHLIST_REQUEST,
		callAPI: () => ProductManagementService.getWishlist(pageNum, pageSize),
		stateObject: "wishlist",
	};
};

// -------------------- Remove from Wishlist -------------------- //
export const deleteFromWishlistAction = (productId) => {
	return {
		actionType: types.DELETE_FROM_WISHLIST_REQUEST,
		callAPI: () => ProductManagementService.deleteFromWishlist(productId),
		stateObject: "deleteFromWishlist",
		isTransient: true,
	};
};

// -------------------- Disclose Price -------------------- //
export const disclosePriceAction = (stockId) => {
	return {
		actionType: types.DISCLOSE_PRICE_REQUEST,
		callAPI: () => ProductManagementService.disclosePrice(stockId),
		stateObject: "disclosePrice",
		isTransient: true,
	};
};

// -------------------- Quote Rate -------------------- //
export const quoteRateAction = (stockId, amount, message, currency) => {
	return {
		actionType: types.QUOTE_RATE_REQUEST,
		callAPI: () => ProductManagementService.quoteRate(stockId, amount, message, currency),
		stateObject: "quoteRate",
		isTransient: true,
	};
};

// -------------------- Product Like -------------------- //
export const productLikeAction = (productId) => {
	return {
		actionType: types.PRODUCT_LIKE_REQUEST,
		callAPI: () => ProductManagementService.likeProduct(productId),
		stateObject: "productLike",
		isTransient: true,
	};
};

// -------------------- Product Dislike -------------------- //
export const productDislikeAction = (productId) => {
	return {
		actionType: types.PRODUCT_DISLIKE_REQUEST,
		callAPI: () => ProductManagementService.dislikeProduct(productId),
		stateObject: "productDisLike",
		isTransient: true,
	};
};

// -------------------- Get Filter Products -------------------- //
export const filterArtworks = (body) => {
	return {
		actionType: types.GET_FILTER_ARTWORKS_REQUEST,
		callAPI: () => ProductManagementService.filterArtworks(body),
		stateObject: "filterProducts",
	};
};

// -------------------- Get Seller Products -------------------- //
export const getSellerProductsAction = (pageNo, pageSize) => {
	return {
		actionType: types.GET_SELLER_PRODUCTS_REQUEST,
		callAPI: () => ProductManagementService.getSellerProducts(pageNo, pageSize),
		stateObject: "sellerProducts",
	};
};
// -------------------- Get Seller Artworks -------------------- //
//For use in artist page gallery tab. Same as getSellerProductsAction. But different state variable
export const getSellerArtworksAction = (pageNo, pageSize) => {
	return {
		actionType: types.GET_SELLER_ARTWORKS_REQUEST,
		callAPI: () => ProductManagementService.getSellerProducts(pageNo, pageSize),
		stateObject: "sellerArtworks",
	};
};
// -------------------- Search Artwork List -------------------- //
export const searchArtworksListAction = (pageNo, pageSize, ownerId, artistId) => {
	return {
		actionType: types.GET_SELLER_PRODUCTS_REQUEST,
		callAPI: () => ProductManagementService.searchArtworksList(pageNo, pageSize, ownerId, artistId),
		stateObject: "sellerProducts",
	};
};
// -------------------- Search Seller Artworks -------------------- //
export const searchSellerArtworksListAction = (pageNo, pageSize, ownerId, artistId) => {
	return {
		actionType: types.GET_SELLER_ARTWORKS_REQUEST,
		callAPI: () => ProductManagementService.searchArtworksList(pageNo, pageSize, ownerId, artistId),
		stateObject: "sellerArtworks",
	};
};

// -------------------- Get My Sold Products -------------------- //
export const getMySoldProducts = (pageNo, pageSize) => {
	return {
		actionType: types.GET_MY_SOLD_PRODUCTS,
		callAPI: () => ProductManagementService.getMySoldProducts(pageNo, pageSize),
		stateObject: "mySoldProducts",
	};
};
// -------------------- Get Sold Products -------------------- //
export const getSoldProducts = (pageNo, pageSize, userId) => {
	return {
		actionType: types.GET_SOLD_PRODUCTS,
		callAPI: () => ProductManagementService.getSoldProducts(pageNo, pageSize, userId),
		stateObject: "mySoldProducts",
	};
};

// -------------------- Get Seller Quotes -------------------- //
export const getSellerQuotes = (pageNum, pageSize) => {
	return {
		actionType: types.GET_SELLER_QUOTES_REQUEST,
		callAPI: () => ProductManagementService.getSellerQuotes(pageNum, pageSize),
		stateObject: "sellerQuotes",
	};
};

// -------------------- Update Seller Quote Status -------------------- //
export const updateSellerQuoteStatus = (quoteId, Status) => {
	return {
		actionType: types.UPDATE_SELLER_QUOTE_STATUS_REQUEST,
		callAPI: () => ProductManagementService.updateSellerQuoteStatus(quoteId, Status),
		stateObject: "updateSellerQuoteStatus",
		isTransient: true,
	};
};
// -------------------- Get Seller Price Request -------------------- //
export const getSellerPriceRequest = (pageNum, pageSize) => {
	return {
		actionType: types.GET_SELLER_PRICE_REQUEST_REQUEST,
		callAPI: () => ProductManagementService.getSellerPriceRequest(pageNum, pageSize),
		stateObject: "sellerPriceRequest",
	};
};

// -------------------- Update Seller Price Status -------------------- //
export const updateSellerPriceStatus = (priceId, Status) => {
	return {
		actionType: types.UPDATE_SELLER_PRICE_STATUS_REQUEST,
		callAPI: () => ProductManagementService.updateSellerPriceStatus(priceId, Status),
		stateObject: "updateSellerPriceStatus",
		isTransient: true,
	};
};

// -------------------- Get My Quotes -------------------- //
export const getMyQuote = (pageNum, pageSize) => {
	return {
		actionType: types.GET_MY_QUOTE_REQUEST,
		callAPI: () => ProductManagementService.getMyQuotes(pageNum, pageSize),
		stateObject: "myQuoteList",
	};
};

// -------------------- Delete My Quotes -------------------- //
export const deleteMyQuote = (productId) => {
	return {
		actionType: types.DELETE_MY_QUOTE_REQUEST,
		callAPI: () => ProductManagementService.deleteMyQuotes(productId),
		stateObject: "deleteMyQuote",
	};
};

// ========================================== Create New Artist ========================================== //
export const getSignaturePendingArtworksAction = (pageNum, pageSize) => {
	return {
		actionType: types.GET_SIGNATURE_PENDING_ARTWORKS,
		callAPI: () => ProductManagementService.getSignaturePendingArtworks(pageNum, pageSize),
		stateObject: "getSignaturePendingArtworks",
	};
};

// ========================================== Create New Artist ========================================== //
export const doCheckRFIDAction = (params) => {
	return {
		actionType: types.CHECK_RFID_EXISTS,
		callAPI: () => ProductManagementService.doCheckRFID(params),
		stateObject: "doesRFIDExist",
	};
};

// ========================================== Update Product Status ========================================== //
export const updateProductStatus = (stockId, productStatus) => {
	return {
		actionType: types.UPDATE_PRODUCT_STATUS_REQUEST,
		callAPI: () => ProductManagementService.updateProductStatus(stockId, productStatus),
		stateObject: "updateProductStatus",
		isTransient: true,
	};
};

// ========================================== Check Add Product Eligibility ========================================== //
export const checkAddProductEligibilityAction = (isPhysical) => {
	return {
		actionType: types.CHECK_ADD_PRODUCT_ELIGIBILITY,
		callAPI: () => ProductManagementService.checkAddProductEligibility(isPhysical),
		stateObject: "checkAddProductEligibilityData",
	};
};

// ========================================== Get Gallery Added Products ========================================== //
export const getGalleryAddedProducts = (pageNum, pageSize) => {
	return {
		actionType: types.CHECK_ADD_PRODUCT_ELIGIBILITY,
		callAPI: () => ProductManagementService.getGalleryAddedProducts(pageNum, pageSize),
		stateObject: "galleryAddedProducts",
	};
};

// ========================================== Get Artist Products ========================================== //
export const getArtistProductsAction = (pageNum, pageSize, artistId) => {
	return {
		actionType: types.GET_ARTIST_PRODUCTS,
		callAPI: () => ProductManagementService.getArtistProducts(pageNum, pageSize, artistId),
		stateObject: "artistProducts",
	};
};

// ========================================== Get Gallery view ========================================== //
export const getGalleryViewAction = (pageNum, pageSize, galleryId) => {
	return {
		actionType: types.GET_GALLERY_VIEW,
		callAPI: () => ProductManagementService.getGalleryView(pageNum, pageSize, galleryId),
		stateObject: "getGalleryView",
	};
};

// ========================================== Get Inventory ========================================== //
export const getInventoryAction = (pageNum, pageSize) => {
	return {
		actionType: types.GET_INVENTORY,
		callAPI: () => ProductManagementService.getInventory(pageNum, pageSize),
		stateObject: "getInventory",
	};
};

// ========================================== Get Owned Artworks ========================================== //
export const getOwnedArtworksAction = (pageNum, pageSize) => {
	return {
		actionType: types.GET_OWNED_ARTWORKS,
		callAPI: () => ProductManagementService.getOwnedArtworks(pageNum, pageSize),
		stateObject: "getOwnedArtworks",
	};
};

// ========================================== Update Images ========================================== //
export const updateImagesAction = (filesListObj) => {
	return {
		actionType: types.UPDATE_IMAGES_REQUEST,
		callAPI: () => AddProductService.updateImagesAndVideos(filesListObj),
		stateObject: "updateImages",
		isTransient: true,
	};
};

// ========================================== NFC Bypass ========================================== //
export const bypassNfcAction = (productId) => {
	return {
		actionType: types.NFC_BYPASS_REQUEST,
		callAPI: () => ProductManagementService.bypassNfc(productId),
		stateObject: "NFCBypassData",
		isTransient: true,
	};
};

// ========================================== Update Price ========================================== //
export const updatePriceAction = (productId, sellingPrice) => {
	return {
		actionType: types.UPDATE_PRICE_REQUEST,
		callAPI: () => ProductManagementService.updatePrice(productId, sellingPrice),
		stateObject: "updatePriceData",
		isTransient: true,
	};
};
