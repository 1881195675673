import styled from "styled-components";
import { Row } from "antd";
import { ImgNextGen } from "common/NextGenImage";
const BlogWrapRow = styled(Row)`
	overflow: hidden;
	@media screen {
		max-height: 410px;
	}
	@media screen and (max-width: 768px) {
		max-height: 835px;
	}
	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
		/* max-height: 835px; */
	}
`;
const WholeWrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 70%;
	margin-bottom: 20px;
	@media (max-width: 768px) {
			
		width: 90%;
		}
	
`;
const ImageWrapper = styled.div`
	text-align: center;
`;
const Image = styled(ImgNextGen)`
	max-width: 100%;
	max-height: 400px;
	object-fit: contain;
`;

const TitleWrapper = styled.div`
	padding: 30px 0px 15px 0px;
	width: 100%;
`;

const BodyWrapper = styled.div`
	font-weight: bold;
	padding-bottom: 2rem;
`;

const EndWrapper = styled.div`
	margin-top: 40px;
`;

const BlogImageDiv = styled.div`
	height: 61px;
	width: 61px;
	img {
		object-fit: contain;
		height: 60px;
		width: 60px;
		border-radius: 30px;
	}
`;

const BloggerImage = styled(ImgNextGen)`
	object-fit: contain;
	height: 60px;
	width: 60px;
	border-radius: 30px;
`;

const TextBlack = styled.p`
	font-size: 14px;
	margin: 0;
	color: #000000;
	font-weight: 600;
`;
const TextGrey = styled.p`
	font-size: 12px;
	color: #828282;
	margin: 0;
`;

const EndSpan = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	align-items: center;
`;

const TextSpan = styled.span`
	display: flex;
	margin-top: 10px;
	margin-bottom: 1rem;
	align-items: center;
`;

const MainWapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

const HomeSliderTitle = styled.h2`
	color: #58514b;
`;

const HomeSliderWrapper = styled.div`
	/* display: flex;
	margin-bottom: 2rem;
	/* padding-left: 3.5rem;
	@media screen and (max-width: 1500px) {
		padding-left: 0;
	} */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0px;
`;

const ListTitleWrapper = styled.div`
	width: 100%;
	@media screen and (max-width: 1500px) {
		padding-left: 30px;
	}
`;

export {
	WholeWrapper,
	Image,
	TitleWrapper,
	BodyWrapper,
	EndWrapper,
	BlogImageDiv,
	BloggerImage,
	TextBlack,
	TextGrey,
	EndSpan,
	TextSpan,
	MainWapper,
	HomeSliderTitle,
	HomeSliderWrapper,
	ListTitleWrapper,
	BlogWrapRow,
	ImageWrapper
};
