import * as types from "../actions/ActionsTypes";
const initialState = {
    walletName: '',
    provider: '',
    walletAddress: '',
    web3: '',
    isConnected: false
}
const walletReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case types.CONNECT_WALLET:
            state = {
                ...state,
                walletName: payload.walletName,
                provider: payload.provider,
                walletAddress: payload.walletAddress,
                web3: payload.web3,
                isConnected: true,


            };
            return state;
        case types.DISCONNECT_WALLET:
            state = {
                ...state,
                walletName: '',
                provider: '',
                walletAddress: '',
                web3: '',
                isConnected: false,

            };
            console.log("🚀 ~ file: WalletReducer.js ~ line 42 ~ walletReducer ~ state", state)

            return state;
        case types.GET_WALLET_DETAILS:
            state = {
                ...state,
            };
            return state;
        default:
            return state;
    }
}
export default walletReducer;