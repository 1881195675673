import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class ArtistFollowService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Follow Artist
	static async followArtist(followerData) {
		let body = { ...followerData };
		return this.service.execute(this.API.followUser, HTTP_METHOD.POST, {
			body
		});
	}
	// Get Following List
	static async getMyFollowingUserList() {
		return this.service.execute(this.API.getMyFollowingUserList, HTTP_METHOD.GET);
	}

	// Get Following List by UserId
	static async getFollowingList(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getFollowingList, HTTP_METHOD.GET, {
			params,
			isAuthorizedAPI: false
		});
	}

	// Get Followers List
	static async getMyFollowersList() {
		return this.service.execute(this.API.getMyFollowersList, HTTP_METHOD.GET);
	}

	// Get Followers List by UserId
	static async getFollowersList(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getFollowersList, HTTP_METHOD.GET, {
			params,
			isAuthorizedAPI: false
		});
	}

	// Unfollow Artist
	static async unfollowArtist(artistId) {
		const urlDirectParams = `/${artistId}`;
		return this.service.execute(this.API.unfollowUser, HTTP_METHOD.DELETE, {
			urlDirectParams
		});
	}
	// Get My Following Artist List
	static async getMyArtistFollowing() {
		return this.service.execute(this.API.getMyArtistFollowing, HTTP_METHOD.GET, {});
	}
}
