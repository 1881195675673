import { useDispatch, useSelector } from "react-redux";
import {
	getFilterCategoryProperties,
	filterArtworks,
	loadBreadCrumbs,
	getProductOfCategories,
	clearCategoryArtworksStates
} from "../actions/FilterArtworksAction";

// useFilterArtworks => useFilterCategoryProperties, useSearchArtworks, useGetBreadcrumbs
// useCategoryArtworks

// ========================== Filter Category Properties ========================== //
export const useFilterCategoryProperties = () => {
	const dispatch = useDispatch();

	// Category properties
	const fetchFilterCategoryProperties = id => {
		dispatch(getFilterCategoryProperties(id));
	};

	const { data, error, loading } = useSelector(state => state.filterArtworks.getFilterCategoryProperties);

	// getPropertyLoader,
	// getFilterCategoryPropertiesList
	// getFilterCategoryPropertiesError,
	return [fetchFilterCategoryProperties, data, error, loading];
};

// ========================== Search Artworks ========================== //
export const useSearchArtworks = () => {
	const dispatch = useDispatch();

	// Filter artworks
	const searchArtworks = (searchData, filtersArray) => {
		dispatch(filterArtworks(searchData, filtersArray));
	};

	const { data, error, loading } = useSelector(state => state.filterArtworks.filterProducts);

	// filterProductsLoader,
	// filterProductsList,
	// filterProductsError,
	return [searchArtworks, data, error, loading];
};

// ========================== Get Breadcrumbs ========================== //
export const useGetBreadcrumbs = () => {
	const dispatch = useDispatch();

	// To get all parent of a category
	const getParentOfCategory = id => {
		dispatch(loadBreadCrumbs(id));
	};

	const { data, error } = useSelector(state => state.filterArtworks.getBreadcrumbs);

	// breadcrumbList,
	return [getParentOfCategory, data, error];
};

// NOTE : Below container is using old method
// ========================== Category Artworks ========================== //
export const useCategoryArtworks = () => {
	const dispatch = useDispatch();

	// Search for product under a category
	const searchForArtworksList = (id, PageNum, PageSize) => {
		dispatch(getProductOfCategories(id, PageNum, PageSize));
	};

	// Clear reducer
	const clearCategoryArtworks = id => {
		dispatch(clearCategoryArtworksStates(id));
	};

	const reducerStates = useSelector(state => state.categoryArtworks);
	const { categoryArtworksLoader, categoryArtworksError } = useSelector(state => state.categoryArtworks);

	return {
		searchForArtworksList,
		reducerStates,
		clearCategoryArtworks,
		categoryArtworksLoader,
		categoryArtworksError
	};
};
