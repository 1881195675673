import { combineReducers } from "redux";
import authentication from "./AuthenticationReducer";
import userManagement from "./UserManagementReducer";
import comments from "./CommentsReducer";
import cartManagement from "./CartManagementReducer";
import shippingAddress from "./ShippingAddressReducer";
import productManagement from "./ProductManagementReducer";
import recommendations from "./RecommendationsReducer";
import addProductManagement from "./AddProductReducer";
import updateProductManagement from "./UpdateProductReducer";
import deleteProductManagement from "./DeleteProductReducer";
import CategoryManagement from "./CategoryManagementReducer";
import artistTypes from "./ArtistTypesReducer";
import artistUserTypes from "./ArtistUserTypesReducer";
import artistBasicProfile from "./ArtistBasicProfileReducer";
import artistFollow from "./ArtistFollowReducer";
import artistInterests from "./ArtistInterestsReducer";
import artistReducer from "./ArtistReducer";
import orderManagement from "./OrderManagementReducer";
import filterArtworks from "./FilterArtworksReducer";
import categoryArtworks from "./CategoryArtworksReducer";
import artistCollection from "./ArtistCollectionReducer";
import homeReducer from "./HomeReducer";
import galleryReducer from "./GalleryReducer";
import blockchainReducer from "./BlockchainReducer";
import bulkUploadReducer from "./BulkUploadReducer";
import payment from "./PaymentReducer";
import billingAddress from "./BillingAddressReducer";
import pickUpAddress from "./PickUpAddressReducer";
import refreshTokenMangement from "./RefreshTokenReducer";
import kycDocumentReducer from "./KycDocumentReducer";
import walletReducer from './WalletReducer';
export const reducers = {
	authentication,
	recommendations,
	authentication,
	userManagement,
	shippingAddress,
	comments,
	cartManagement,
	productManagement,
	recommendations,
	addProductManagement,
	updateProductManagement,
	deleteProductManagement,
	CategoryManagement,
	orderManagement,
	artistTypes,
	artistUserTypes,
	artistBasicProfile,
	artistFollow,
	artistInterests,
	artistReducer,
	filterArtworks,
	categoryArtworks,
	homeReducer,
	artistCollection,
	galleryReducer,
	blockchainReducer,
	bulkUploadReducer,
	payment,
	billingAddress,
	pickUpAddress,
	refreshTokenMangement,
	kycDocumentReducer,
	walletReducer
};

export function createReducerManager(initialReducers = reducers) {
	// Create an object which maps keys to reducers
	const reducers = { ...initialReducers };

	// Create the initial combinedReducer
	let combinedReducer = combineReducers(reducers);

	// An array which is used to delete state keys when reducers are removed
	let keysToRemove = [];

	return {
		getReducerMap: () => reducers,

		// The root reducer function exposed by this object
		// This will be passed to the store
		reduce: (state, action) => {
			// If any reducers have been removed, clean up their state first
			if (keysToRemove.length > 0) {
				state = { ...state };
				for (let key of keysToRemove) {
					delete state[key];
				}
				keysToRemove = [];
			}

			// Delegate to the combined reducer
			return combinedReducer(state, action);
		},

		// Adds a new reducer with the specified key
		add: (key, reducer) => {
			if (!key || reducers[key]) {
				return;
			}

			// Add the reducer to the reducer mapping
			reducers[key] = reducer;

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
		},

		// Removes a reducer with the specified key
		remove: key => {
			if (!key || !reducers[key]) {
				return;
			}

			// Remove it from the reducer mapping
			delete reducers[key];

			// Add the key to the list of keys to clean up
			keysToRemove.push(key);

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
		}
	};
}
