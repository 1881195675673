import { GalleryService } from "../services/GalleryService";
import * as types from "./ActionsTypes";

// ========================================== Get My Gallery Profile ========================================== //
export const getMyGalleryProfile = () => {
	return {
		actionType: types.GET_GALLERY_PROFILE_REQUEST,
		callAPI: () => GalleryService.getMyGalleryProfile(),
		stateObject: "galleryProfile",
	};
};
// ========================================== Get Gallery Profile By Id ========================================== //
export const getGalleryProfileById = (SlugId, loginStatus) => {
	return {
		actionType: types.GET_GALLERY_PROFILE_REQUEST,
		callAPI: () => GalleryService.getGalleryProfileById(SlugId, loginStatus),
		stateObject: "galleryProfile",
	};
};

// ========================================== Update ArtistBasicProfile ========================================== //
export const updateGalleryProfile = (data) => {
	return {
		actionType: types.UPDATE_GALLERY_PROFILE_REQUEST,
		callAPI: () => GalleryService.updateGalleryProfile(data),
		stateObject: "updateGalleryProfile",
		isTransient: true,
	};
};
// ========================================== Update ArtistBasicProfileImages ========================================== //
export const updateGalleryProfileImages = (data) => {
	return {
		actionType: types.UPDATE_GALLERY_PROFILE_IMAGES_REQUEST,
		callAPI: () => GalleryService.updateGalleryProfileImages(data),
		stateObject: "updateGalleryProfileImages",
		isTransient: true,
	};
};
// ========================================== Get My Gallery Following ========================================== //
export const getMyGalleryFollowing = () => {
	return {
		actionType: types.GET_MY_GALLERY_FOLLOWING_REQUEST,
		callAPI: () => GalleryService.getMyGalleryFollowing(),
		stateObject: "myGalleryFollowing",
	};
};
// ========================================== Get My Followers ========================================== //
export const getMyFollowers = () => {
	return {
		actionType: types.GET_MY_FOLLOWERS_REQUEST,
		callAPI: () => GalleryService.getMyFollowers(),
		stateObject: "myFollowers",
	};
};
// ========================================== Get My Following ========================================== //
export const getMyFollowing = () => {
	return {
		actionType: types.GET_MY_FOLLOWING_REQUEST,
		callAPI: () => GalleryService.getMyFollowing(),
		stateObject: "myFollowing",
	};
};
// ========================================== Get Artist List By Gallery ========================================== //
export const getArtistListByGallery = (PageNum, PageSize) => {
	return {
		actionType: types.GET_ARTIST_LIST_REQUEST,
		callAPI: () => GalleryService.getArtistListByGallery(PageNum, PageSize),
		stateObject: "artistList",
	};
};
export const getGalleryArtistListByGallery = (PageNum, PageSize) => {
	return {
		actionType: types.GET_GALLERY_ARTIST_LIST_REQUEST,
		callAPI: () => GalleryService.getArtistListByGallery(PageNum, PageSize),
		stateObject: "galleryArtistList",
	};
};
// ========================================== Get Artist List By User Id ========================================== //
export const getArtistListByUserId = (PageNum, PageSize, UserId) => {
	return {
		actionType: types.GET_ARTIST_LIST_REQUEST,
		callAPI: () => GalleryService.getArtistListByUserId(PageNum, PageSize, UserId),
		stateObject: "artistList",
	};
};
export const getGalleryArtistListByUserId = (PageNum, PageSize, UserId) => {
	return {
		actionType: types.GET_GALLERY_ARTIST_LIST_REQUEST,
		callAPI: () => GalleryService.getArtistListByUserId(PageNum, PageSize, UserId),
		stateObject: "galleryArtistList",
	};
};

// =========================================Add New Artist To Gallery============================================= //
export const addNewArtistToGalleryAction = (artistIds) => {
	return {
		actionType: types.ADD_NEW_ARTIST_TO_GALLERY_REQUEST,
		callAPI: () => GalleryService.addNewArtistToGallery(artistIds),
		stateObject: "addArtistToGalleryResult",
		isTransient: true,
	};
};

// =========================================Delete artist from Gallery============================================= //
export const deleteArtistFromGalleryAction = (artistId) => {
	return {
		actionType: types.DELETE_ARTIST_FROM_GALLERY_REQUEST,
		callAPI: () => GalleryService.deleteArtistFromGallery(artistId),
		stateObject: "deleteArtistFromGalleryResult",
		isTransient: true,
	};
};

// ========================================== Get Event List ========================================== //
export const getEventListAction = () => {
	return {
		actionType: types.GET_EVENT_LIST_GALLERY_REQUEST,
		callAPI: () => GalleryService.getEventList(),
		stateObject: "getEventListResult",
	};
};

// ========================================== Get Event List by UserId ========================================== //
export const getEventListByUserIdAction = (userId) => {
	return {
		actionType: types.GET_EVENT_LIST_GALLERY_REQUEST,
		callAPI: () => GalleryService.getEventListByUserId(userId),
		stateObject: "getEventListResult",
	};
};

// ========================================== Add New Event ========================================== //
export const addNewEventAction = (body) => {
	return {
		actionType: types.ADD_NEW_EVENT_GALLERY_REQUEST,
		callAPI: () => GalleryService.addNewEvent(body),
		stateObject: "addNewEventResult",
		isTransient: true,
	};
};

// ========================================== Update Event ========================================== //
export const updateEventAction = (body, eventId) => {
	return {
		actionType: types.UPDATE_EVENT_GALLERY_REQUEST,
		callAPI: () => GalleryService.updateEvent(body, eventId),
		stateObject: "updateEventResult",
		isTransient: true,
	};
};

// ========================================== Delete From Event ========================================== //
export const deleteFromEventAction = (Id) => {
	return {
		actionType: types.DELETE_FROM_EVENT_GALLERY_REQUEST,
		callAPI: () => GalleryService.deleteFromEvent(Id),
		stateObject: "deleteFromEventResult",
		isTransient: true,
	};
};

// ========================================== Get Award List ========================================== //
export const getAwardListAction = () => {
	return {
		actionType: types.GET_AWARD_LIST_GALLERY_REQUEST,
		callAPI: () => GalleryService.getAwardList(),
		stateObject: "awardListResult",
	};
};

// ========================================== Get Award List by UserId ========================================== //
export const getAwardListByUserIdAction = (userId) => {
	return {
		actionType: types.GET_AWARD_LIST_GALLERY_REQUEST,
		callAPI: () => GalleryService.getAwardListByUserId(userId),
		stateObject: "awardListResult",
	};
};

// ========================================== Add New Award ========================================== //
export const addNewAwardAction = (year, award) => {
	return {
		actionType: types.ADD_AWARD_GALLERY_REQUEST,
		callAPI: () => GalleryService.addNewAward(year, award),
		stateObject: "addAwardResult",
		isTransient: true,
	};
};

// ========================================== Update Award ========================================== //
export const updateAwardAction = (year, award, awardId) => {
	return {
		actionType: types.UPDATE_AWARD_GALLERY_REQUEST,
		callAPI: () => GalleryService.updateAward(year, award, awardId),
		stateObject: "updateAwardResult",
		isTransient: true,
	};
};

// ========================================== Delete Award ========================================== //
export const deleteAwardAction = (awardId) => {
	return {
		actionType: types.DELETE_AWARD_GALLERY_REQUEST,
		callAPI: () => GalleryService.deleteAward(awardId),
		stateObject: "deleteAwardResult",
		isTransient: true,
	};
};

// ========================================== Get Historical Data List ========================================== //
export const getHistoricalDataListAction = () => {
	return {
		actionType: types.GET_HISTORICAL_DATA_LIST_GALLERY_REQUEST,
		callAPI: () => GalleryService.getHistoricalDataList(),
		stateObject: "historicalDataListResult",
	};
};

// ========================================== Get Historical Data List bu UserId ========================================== //
export const getHistoricalDataListByUserIdAction = (userId) => {
	return {
		actionType: types.GET_HISTORICAL_DATA_LIST_GALLERY_REQUEST,
		callAPI: () => GalleryService.getHistoricalDataListByUserId(userId),
		stateObject: "historicalDataListResult",
	};
};

// ========================================== Add New Historical Data ========================================== //
export const addNewHistoricalAction = (year, events) => {
	return {
		actionType: types.ADD_HISTORICAL_DATA_GALLERY_REQUEST,
		callAPI: () => GalleryService.addNewHistorical(year, events),
		stateObject: "addHistoricalDataResult",
		isTransient: true,
	};
};

// ========================================== Update Historical Data ========================================== //
export const updateHistoricalDataAction = (year, events, historicalId) => {
	return {
		actionType: types.UPDATE_HISTORICAL_DATA_GALLERY_REQUEST,
		callAPI: () => GalleryService.updateHistoricalData(year, events, historicalId),
		stateObject: "updateHistoricalDataResult",
		isTransient: true,
	};
};

// ========================================== Delete Historical Data ========================================== //
export const deleteHistoricalDataAction = (historicalId) => {
	return {
		actionType: types.DELETE_HISTORICAL_DATA_GALLERY_REQUEST,
		callAPI: () => GalleryService.deleteHistoricalData(historicalId),
		stateObject: "deleteHistoricalDataResult",
		isTransient: true,
	};
};
// ========================================== Filter Galleries ========================================== //
export const filterGalleries = (PageNum, PageSize, SearchKey, alphabetSearch) => {
	return {
		actionType: types.FILTER_GALLERIES_REQUEST,
		callAPI: () => GalleryService.filterGalleries(PageNum, PageSize, SearchKey, alphabetSearch),
		stateObject: "galleryList",
	};
};

// ========================================== Update Gallery Address ========================================== //
export const updateGalleryAddress = (body) => {
	return {
		actionType: types.FILTER_GALLERIES_REQUEST,
		callAPI: () => GalleryService.updateGalleryAddress(body),
		stateObject: "updateGalleryAddress",
		isTransient: true,
	};
};

// ========================================== Search For Artists ========================================== //
export const searchArtistsAction = (name) => {
	return {
		actionType: types.SEARCH_FOR_ARTISTS,
		callAPI: () => GalleryService.searchArtistsAction(name),
		stateObject: "searchArtists",
	};
};
