import { ArtistTypesService } from "../services/ArtistTypesService";
import * as types from "./ActionsTypes";

// ========================================== Get ArtistTypes By Id ========================================== //
export const getArtistTypesById = (artistTypeId) => {
	return {
		actionType: types.GET_ARTIST_TYPES_BY_ID_REQUEST,
		callAPI: () => ArtistTypesService.getArtistTypesById(artistTypeId),
		stateObject: "getArtistTypesById",
	};
};

// ========================================== Get ArtistTypes ========================================== //
export const getArtistTypes = () => {
	return {
		actionType: types.GET_ARTIST_TYPES_REQUEST,
		callAPI: () => ArtistTypesService.getArtistTypes(),
		stateObject: "getArtistTypes",
	};
};

// ========================================== Add ArtistTypes ========================================== //
export const addArtistTypes = (artistTypesData) => {
	return {
		actionType: types.ADD_ARTIST_TYPES_REQUEST,
		callAPI: () => ArtistTypesService.addArtistTypes(artistTypesData),
		stateObject: "addArtistTypes",
		isTransient: true,
	};
};
// ========================================== Update ArtistTypes ========================================== //
export const updateArtistTypes = (artistTypesData, artistTypeId) => {
	return {
		actionType: types.UPDATE_ARTIST_TYPES_REQUEST,
		callAPI: () => ArtistTypesService.updateArtistTypes(artistTypesData, artistTypeId),
		stateObject: "updateArtistTypes",
		isTransient: true,
	};
};
// ========================================== Delete ArtistTypes ========================================== //
export const deleteArtistTypes = (artistTypeId) => {
	return {
		actionType: types.DELETE_ARTIST_TYPES_REQUEST,
		callAPI: () => ArtistTypesService.deleteArtistTypes(artistTypeId),
		stateObject: "deleteArtistTypes",
		isTransient: true,
	};
};

// ========================================== Filter Artist by ArtistTypes ========================================== //
export const filterArtistByTypes = (artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder) => {
	return {
		actionType: types.FILTER_ARTIST_BY_TYPES_REQUEST,
		callAPI: () => ArtistTypesService.filterArtist(artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder),
		stateObject: "filterArtistTypes",
	};
};

// ========================================== Filter Artist admin by ArtistTypes ========================================== //
export const filterAdminArtistByTypes = (artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder) => {
	return {
		actionType: types.FILTER_ADMIN_ARTIST_BY_TYPES_REQUEST,
		callAPI: () => ArtistTypesService.filterAdminArtistByTypes(artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder),
		stateObject: "filterAdminArtistByTypes",
	};
};
