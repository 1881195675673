import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class ArtistBasicProfileService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Get Artist Basic Description
	static async getBasicDescription() {
		return this.service.execute(this.API.getBasicDescription, HTTP_METHOD.GET, {});
	}

	// Get Artist Basic Description Using SlugId
	static async getBasicDescriptionById(SlugId, loginStatus) {
		let params = { SlugId };
		if (loginStatus === true) {
			return this.service.execute(this.API.getBasicDescriptionById, HTTP_METHOD.GET, {
				params
			});
		} else {
			return this.service.execute(this.API.getBasicDescriptionById, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
		}
	}
	// Update ArtistBasicProfile
	static async updateArtistProfile(body) {
		return this.service.execute(this.API.updateArtistProfile, HTTP_METHOD.PUT, {
			body
		}); 
	}
	// Update ArtistBasicProfileImages
	static async updateArtistProfileImages(body) {
		return this.service.execute(this.API.updateArtistProfileImages, HTTP_METHOD.PUT, {
			body
		});
	}
}
