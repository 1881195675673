import { HomeService } from "../services/HomeService";
import * as types from "./ActionsTypes";

// ========================================== Get Latest Artists List ========================================== //
export const getLatestArtists = (pageNum, pageSize) => {
    return {
        actionType: types.GET_LATEST_ARTISTS_REQUEST,
        callAPI: () => HomeService.getLatestArtists(pageNum, pageSize),
        stateObject: "latestArtists"
    };
};

// ========================================== Get Popular Artists List ========================================== //
export const getPopularArtists = (pageNum, pageSize) => {
    return {
        actionType: types.GET_POPULAR_ARTISTS_REQUEST,
        callAPI: () => HomeService.getPopularArtists(pageNum, pageSize),
        stateObject: "popularArtists"
    };
};

// ========================================== Get HomeScreen Banner ========================================== //
export const getHomeScreenBannerAction = () => {
    return {
        actionType: types.GET_HOME_BANNER_REQUEST,
        callAPI: () => HomeService.getHomeScreenBanner(),
        stateObject: "getHomeScreenBannerResult"
    };
};

// ========================================== Get HeaderCount ========================================== //
export const getHeaderCountAction = () => {
    return {
        actionType: types.GET_HEADER_COUNT_REQUEST,
        callAPI: () => HomeService.getHeaderCount(),
        stateObject: "getHeaderCountResult"
    };
};

// ========================================== Get Blogs By Id ========================================== //
export const getBlogsByIdAction = slugId => {
    return {
        actionType: types.GET_BLOGS_BY_ID_REQUEST,
        callAPI: () => HomeService.getBlogsById(slugId),
        stateObject: "getBlogsById"
    };
};

// ========================================== Get Blogs List ========================================== //
export const getBlogsListAction = (pageNum, pageSize) => {
    return {
        actionType: types.GET_BLOGS_LIST_REQUEST,
        callAPI: () => HomeService.getBlogsList(pageNum, pageSize),
        stateObject: "blogsList"
    };
};

// ========================================== Get News List ========================================== //
export const getNewsListAction = (pageNum, pageSize) => {
    return {
        actionType: types.GET_NEWS_LIST_REQUEST,
        callAPI: () => HomeService.getNewsList(pageNum, pageSize),
        stateObject: "newsList"
    };
};

// ========================================== Get News By Id ========================================== //
export const getNewsByIdAction = (NewsId) => {
    return {
        actionType: types.GET_NEWS_BY_ID_REQUEST,
        callAPI: () => HomeService.getNewsById(NewsId),
        stateObject: "newsById"
    };
};

// ========================================== Get News By Slug Id ========================================== //
export const getNewsBySlugIdAction = (SlugId) => {
    return {
        actionType: types.GET_NEWS_BY_SLUG_ID_REQUEST,
        callAPI: () => HomeService.getNewsBySlugId(SlugId),
        stateObject: "newsBySlugId"
    };
};
// ========================================== Get Selected Collection ========================================== //
export const getSelectedCollectionAction = () => {
    return {
        actionType: types.GET_SELECTED_COLLECTION_REQUEST,
        callAPI: () => HomeService.getSelectedCollections(),
        stateObject: "selectedCollectionList"
    };
};

// ========================================== Get Search List ========================================== //
export const getSearchAction = keyword => {
    return {
        actionType: types.GET_SEARCH_REQUEST,
        callAPI: () => HomeService.getSearchList(keyword),
        stateObject: "searchResult",
        isTransient: true
    };
};

// ========================================== Save Search ========================================== //
export const saveSearchAction = (body) => {
    return {
        actionType: types.SAVE_SEARCH_REQUEST,
        callAPI: () => HomeService.saveSearch(body),
        stateObject: "saveSearchResult",
        isTransient: true
    };
};
