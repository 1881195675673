import { CartManagementService } from "../services/CartManagementService";
import * as types from "./ActionsTypes";

// ========================================== Get Cart Items ========================================== //
export const getCartItems = (currency) => {
	return {
		actionType: types.GET_CART_LIST_REQUEST,
		callAPI: () => CartManagementService.getCartItems(currency),
		stateObject: "getCartItem"
	};
};

// ========================================== Delete Cart Item ========================================== //
// TODO : Call get items in response
export const onDeleteFromCart = id => {
	return {
		actionType: types.DELETE_CART_REQUEST,
		callAPI: () => CartManagementService.deleteItemFromCart(id),
		stateObject: "deleteCart",
		isTransient: true
	};
};

// ========================================== Add to Cart ========================================== //
// TODO : Call get items in response
export const onAddToCart = body => {
	return {
		actionType: types.ADD_TO_CART_REQUEST,
		callAPI: () => CartManagementService.addToCart(body),
		stateObject: "addToCart",
		isTransient: true
	};
};
