import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
import UserManagement from "../util/UserManagement";

export class UserManagementService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Login
	static async doLogin(body) {
		return this.service
			.execute(this.API.login, HTTP_METHOD.POST, {
				body,
				isAuthorizedAPI: false
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
					if (response.firstLogin && response.isSocialLogin)
						UserManagement.saveFirstSocialLogin(true);
					else
						UserManagement.saveFirstSocialLogin(false);
				}
				return response;
			});
	}

	// Login
	static async doWalletLogin(address) {
		var body = {};
		return this.service
			.execute(`${this.API.walletLogin}?Token=${address}`, HTTP_METHOD.POST, {
				body,
				isAuthorizedAPI: false
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
					if (response.firstLogin && response.isSocialLogin)
						UserManagement.saveFirstSocialLogin(true);
					else
						UserManagement.saveFirstSocialLogin(false);
				}
				return response;
			});
	}

	// Google Login
	static async googleLogin(Token) {
		let params = { Token };
		return this.service
			.execute(this.API.googleLogin, HTTP_METHOD.POST, {
				params,
				isAuthorizedAPI: false
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
					if (response.firstLogin && response.isSocialLogin)
						UserManagement.saveFirstSocialLogin(true);
					else
						UserManagement.saveFirstSocialLogin(false);
				}
				return response;
			});
	}

	// Facebook Login
	static async facebookLogin(Token) {
		let params = { Token };
		return this.service
			.execute(this.API.facebookLogin, HTTP_METHOD.POST, {
				params,
				isAuthorizedAPI: false
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
					if (response.firstLogin && response.isSocialLogin)
						UserManagement.saveFirstSocialLogin(true);
					else
						UserManagement.saveFirstSocialLogin(false);
				}
				return response;
			});
	}

	// Login using Refresh Token
	static async loginRefreshToken(refreshToken) {
		let params = { refreshToken };
		return this.service
			.execute(this.API.loginRefreshToken, HTTP_METHOD.POST, {
				params
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
				}
				return response;
			});
	}

	// Validate Two factor authentication OTP
	// params : UserName=asish980@gmail.com&OTP=286568
	static async validate2FOtp(UserName, OTP) {
		let params = { UserName, OTP };
		return this.service
			.execute(this.API.validate2FOtp, HTTP_METHOD.POST, {
				params,
				isAuthorizedAPI: false
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
				}
				return response;
			});
	}

	// Change Password
	// Params : OldPassword=123457&NewPassword=123456
	static async changePassword(OldPassword, NewPassword) {
		let params = { OldPassword, NewPassword };
		return this.service.execute(this.API.changePassword, HTTP_METHOD.POST, {
			params
		});
	}

	// User role updating
	static async updateUserRole(userRole) {
		let params = { userRole };
		return this.service
			.execute(this.API.roleChange, HTTP_METHOD.POST, {
				params
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
					if (response.firstLogin && response.isSocialLogin)
						UserManagement.saveFirstSocialLogin(true);
					else
						UserManagement.saveFirstSocialLogin(false);
				}
				return response;
			});
	}

	// Lock User
	static async blockUser(userId) {
		let params = { userId };
		return this.service.execute(this.API.blockUser, HTTP_METHOD.PUT, {
			params
		});
	}

	// Unlock user

	static async unblockUser(userId) {
		let params = { userId };
		return this.service.execute(this.API.unBlockUser, HTTP_METHOD.PUT, {
			params
		});
	}

	// Get user info
	static async getUserInfo() {
		return this.service.execute(this.API.getMyInfo, HTTP_METHOD.GET);
	}

	// Get user info for mobile
	static async getUserInfoForMobile() {
		return this.service.execute(this.API.getMyInfo, HTTP_METHOD.GET)
			.then(response => {
				if (response) {
					UserManagement.saveUserData(response);
				}
				return response;
			});
	}
	// Update user info
	static async updateUserInfo(body) {
		return this.service.execute(this.API.updateUser, HTTP_METHOD.MULTIPART_PUT, {
			body
		});
	}

	//logout user
	static async logoutUser(refreshToken) {
		let params = { refreshToken };
		return this.service.execute(this.API.logout, HTTP_METHOD.POST, {
			params
		});
	}

	// Toggle Two Factor Authentication
	// Params : status=true || status=false
	static async toggleTwoFactor(status) {
		let params = { status };
		return this.service.execute(this.API.toggleTwoFactor, HTTP_METHOD.POST, {
			params
		});
	}

	// Toggle Two Push Notification
	// Params : status=True || status=False
	static async togglePushNotification(status) {
		let params = { status };
		return this.service.execute(this.API.togglePushNotification, HTTP_METHOD.POST, {
			params
		});
	}

	// Reset User Account
	static async resetUserAccount() {
		return this.service.execute(this.API.resetUserHistory, HTTP_METHOD.POST);
	}

	//Initial Update
	static async initialUpdate(body) {
		return this.service.execute(this.API.initialUpdate, HTTP_METHOD.PUT, {
			body
		});
	}

	// Follow user
	static async followUser(FollowerId) {
		let body = { FollowerId };
		return this.service.execute(this.API.followUser, HTTP_METHOD.POST, { body });
	}

	// Unfollow user
	static async unfollowUser(userId) {
		let urlDirectParams = `/${userId}`;
		return this.service.execute(this.API.unfollowUser, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	// Remove Follower
	static async removeFollower(userId) {
		let urlDirectParams = `/${userId}`;
		return this.service.execute(this.API.removeFollower, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	// Get Notifications
	static async getNotifications() {
		return this.service.execute(this.API.getNotifications, HTTP_METHOD.GET, {});
	}

	// Get Country list
	static async getCountryList() {
		return this.service.execute(this.API.getCountriesList, HTTP_METHOD.GET);
	}

	// Create new artist
	static async createNewArtist(body) {
		return this.service.execute(this.API.createNewArtist, HTTP_METHOD.POST, { body });
	}

	// Apple Login
	static async appleLogin(Token) {
		let params = { Token };
		return this.service
			.execute(this.API.appleLogin, HTTP_METHOD.POST, {
				params,
				isAuthorizedAPI: false
			})
			.then(response => {
				if (response && response.roles && response.roles.indexOf("USER") > -1) {
					UserManagement.saveAuthToken(response.access_token);
					UserManagement.saveRoles(response.roles);
					UserManagement.refreshToken(response.refresh_token);
				}
				return response;
			});
	}

	// Email OTP Verification
	static async emailOTPVerification(UserName, OTP) {
		let params = { UserName, OTP };
		return this.service.execute(this.API.emailOTPVerification, HTTP_METHOD.POST, {
			params,
			isAuthorizedAPI: false
		}).then(response => {
			if (response && response.roles && response.roles.indexOf("USER") > -1) {
				UserManagement.saveAuthToken(response.access_token);
				UserManagement.saveRoles(response.roles);
				UserManagement.refreshToken(response.refresh_token);
				if (response.firstLogin && response.isSocialLogin)
					UserManagement.saveFirstSocialLogin(true);
				else
					UserManagement.saveFirstSocialLogin(false);
			}
			return response;
		});
	}

	// send contact message
	static async sendContactUs(body) {
		return this.service.execute(this.API.sendContactUs, HTTP_METHOD.POST, {
			body,
			isAuthorizedAPI: false
		});
	}
	//Blockchain anonymous
	static async toggleBlockchainAnonymous(status) {
		let params = { status };
		return this.service.execute(this.API.blockchainAnonymous, HTTP_METHOD.POST, {
			params
		});
	}
}
