import * as types from "./ActionsTypes";
import { PickUpAddressService } from "../services/PickUpAddressService";

// ========================================== Get PickUpAddress ========================================== //
export const getPickUpAddressAction = () => {
    return {
        actionType: types.GET_PICKUP_ADDRESS_REQUEST,
        callAPI: () => PickUpAddressService.getPickUpAddress(),
        stateObject: "pickUpAddress"
    };
};

// ========================================== Add PickUpAddress ========================================== //
export const addPickUpAddressAction = pickUpAddressData => {
    return {
        actionType: types.ADD_PICKUP_ADDRESS_REQUEST,
        callAPI: () => PickUpAddressService.addPickUpAddress(pickUpAddressData),
        stateObject: "addPickUpAddress",
        isTransient: true
    };
};

// ========================================== Update PickUpAddress ========================================== //
export const updatePickUpAddressAction = (pickUpAddressData, pickUpAddressId) => {
    return {
        actionType: types.UPDATE_PICKUP_ADDRESS_REQUEST,
        callAPI: () => PickUpAddressService.updatePickUpAddress(pickUpAddressData, pickUpAddressId),
        stateObject: "updatePickUpAddress",
        isTransient: true
    };
};

// ========================================== Delete PickUpAddress ========================================== //
export const deletePickUpAddressAction = pickUpAddressId => {
    return {
        actionType: types.DELETE_PICKUP_ADDRESS_REQUEST,
        callAPI: () => PickUpAddressService.deletePickUpAddress(pickUpAddressId),
        stateObject: "deletePickUpAddress",
        isTransient: true
    };
};

// ========================================== Set Default PickUp Address ========================================== //
export const defaultPickUpAddressAction = (pickupAddressId) => {
    return {
        actionType: types.SET_DEFAULT_PICKUP_ADDRESS_REQUEST,
        callAPI: () => PickUpAddressService.setDefaultPickupAddress(pickupAddressId),
        stateObject: "defaultPickUpAddress",
        isTransient: true
    };
};
