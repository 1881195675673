import styled from "styled-components";
import { Row, Select, Badge } from "antd";

export const MainTopWrapper = styled.div`
	background-color: black;
	width: 100%;
	height: 40px;
	color: white;
	display: flex;
	align-items: center;
`;
export const LanguageWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	width: 60px;
	margin: 0px 10px;
	align-items: center;
`;
export const SocialMediaWrapper = styled.div`
	img {
		width: 15px;
		height: 15px;
		margin: 0 5px;
	}
`;

export const NotificationWrapper = styled.div`
	display: flex;
	margin: 0 30px;
	justify-content: space-around;
	/* width: 100px; */
	a {
		color: rgb(255, 255, 255) !important;
		font: normal normal 600 15px/24px Lato !important;
	}
`;

export const AccountIconWrapper = styled.div`
	display: flex;
	margin-left: 1px;
	justify-content: space-around;
	align-items: center;
	width: ${(props) => `${props.width}px`};
	.vertical-line {
		border: solid 1px #ffffff;
		height: 15px;
	}
	.logout-btn {
		margin: 0px 10px;
	}
	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-left: 5px;
	}
	.profile-down-arrow {
		width: 10px;
		height: 10px;
		margin-left: 5px;
	}
`;
export const FullWidthRow = styled(Row)`
	/* width: 100%; */
	padding: 0px 4%;
	display: flex;
	flex-direction: row;

	width: 100%;
	@media only screen and (max-device-width: 1360px) {
		padding: 0px 1%;
	}
	span {
		font: normal normal 600 15px/24px Lato !important;
		letter-spacing: 0.13px;
		color: rgb(255, 255, 255);
	}
`;
export const SBadge = styled(Badge)`
	line-height: 1;
	.ant-badge-count {
		font-weight: bold;
		background-color: #f52541;
		box-shadow: 0.5px 0.4px #00000029;
		color: white;
		margin-right: 2px;
	}
`;
