import React, { Component } from "react";

export default function (ComposedComponent) {
	class NetworkDetector extends Component {
		state = {
			isDisconnected: false
		};

		componentDidMount() {
			this.handleConnectionChange();
			window.addEventListener("online", this.handleConnectionChange);
			window.addEventListener("offline", this.handleConnectionChange);
		}

		componentWillUnmount() {
			window.removeEventListener("online", this.handleConnectionChange);
			window.removeEventListener("offline", this.handleConnectionChange);
		}

		// 172.217.163.78 Google IP
		handleConnectionChange = () => {
			this.setState({ isDisconnected: !navigator.onLine });
			// const condition = navigator.onLine ? "online" : "offline";
			// if (condition === "online") {
			// 	const webPing = setInterval(() => {
			// 		fetch("//google.com", {
			// 			mode: "no-cors"
			// 		})
			// 			.then(() => {
			// 				this.setState({ isDisconnected: false }, () => {
			// 					return clearInterval(webPing);
			// 				});
			// 			})
			// 			.catch(() => this.setState({ isDisconnected: true }));
			// 	}, 6000);
			// 	return;
			// }

			// return this.setState({ isDisconnected: true });
		};

		render() {
			const { isDisconnected } = this.state;
			return <ComposedComponent {...this.props} isDisconnected={isDisconnected} />;
		}
	}

	return NetworkDetector;
}
