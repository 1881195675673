import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class ArtistInterestsService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get MyInterests
	static async getMyInterests() {
		return this.service.execute(this.API.getMyInterests, HTTP_METHOD.GET, {});
	}

	// Get User Interests Using SlugId
	static async getArtistInterestsBySlugId(SlugId) {
		let params = { SlugId };
		return this.service.execute(this.API.getArtistInterestsBySlugId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Update ArtistInterests
	static async updateArtistInterests(updateData) {
		let body = { ...updateData };
		return this.service.execute(this.API.updateArtistInterests, HTTP_METHOD.PUT, {
			body
		});
	}
}
