import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
    getCartItem: {},
    deleteCart: {},
    addToCart: {}
};

const CartManagementReducer = createApiReducer(initialState, [types.GET_CART_LIST_REQUEST, types.DELETE_CART_REQUEST, types.ADD_TO_CART_REQUEST]);

export default CartManagementReducer;

