import styled, { css } from "styled-components";

export const BannerImageStyle = css`
	height: 500px;
	width: 100%;

	@media (min-width: 480px) {
		height: 200px;
	}

	@media (min-width: 768px) {
		height: 300px;
	}

	@media (min-width: 992px) {
		height: 500px;
	}
`;

export const DropdownHeader = styled.div`
	display: flex;
	justify-content: center;
	height: 100%;
	padding: 25px 25px 14px 25px;
	cursor: pointer;
`;

// --------- DropdownContentArtworks --------- //
export const DropdownContent = styled.div`
	width: ${(props) => (props.width ? props.width : "15rem")};
	position: absolute;
	top: 5px;
	left: -40px;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
	max-height: 400px;
	/* overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	} */
	scrollbar-width: none;
	@media (max-width: 1024px) {
		top: -30px;
	}
	@media (max-width: 1440px) {
		top: 5px;
	}

	ul {
		padding: 1rem 0;
		background: #58514b;
		border: 1px solid #58514b;

		li a {
			color: #fff;
			font-size: 16px;
		}

		.ant-menu-item > a:hover {
			color: #ad9972;
			font-weight: 500;
		}

		.ant-menu-item:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background: #58514b;
		}

		.ant-menu-item:active,
		.ant-menu-submenu-title:active {
			background: #58514b;
		}

		&:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background: #58514b;
		}
	}
`;

// --------- DropdownContentArtist --------- //
export const DropdownContentArtist = styled.div`
	width: ${(props) => (props.width ? props.width : "15rem")};
	position: absolute;
	top: 5px;
	left: 0px;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
	max-height: 400px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
	@media (max-width: 1024px) {
		top: -30px;
	}
	@media (max-width: 1440px) {
		top: 5px;
	}

	ul {
		padding: 1rem 0;
		background: black;
		border: 1px solid black;

		li a {
			color: #fff;
			font-size: 16px;
		}

		.ant-menu-item > a:hover {
			color: #ad9972;
			font-weight: 500;
		}

		.ant-menu-item:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background: #58514b;
		}

		.ant-menu-item:active,
		.ant-menu-submenu-title:active {
			background: #58514b;
		}

		&:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background: #58514b;
		}
	}
`;
