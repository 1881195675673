import { ArtistServices } from "../services/ArtistServices";
import * as types from "./ActionsTypes";

// ========================================== Get Gallery List ========================================== //
export const getGalleryListAction = () => {
	return {
		actionType: types.GET_GALLERY_LIST_REQUEST,
		callAPI: () => ArtistServices.getGalleryList(),
		stateObject: "getGalleryListResult"
	};
};

// ========================================== Get Gallery List by UserId ========================================== //
export const getGalleryListByUserIdAction = userId => {
	return {
		actionType: types.GET_GALLERY_LIST_REQUEST,
		callAPI: () => ArtistServices.getGalleryListByUserId(userId),
		stateObject: "getGalleryListResult"
	};
};

// ========================================== Add New Gallery ========================================== //
export const addNewGalleryAction = body => {
	return {
		actionType: types.ADD_NEW_GALLERY_REQUEST,
		callAPI: () => ArtistServices.addNewGallery(body),
		stateObject: "addNewGalleryResult",
		isTransient: true
	};
};

// ========================================== Update Gallery ========================================== //
export const updateGalleryAction = (galleryId, body) => {
	return {
		actionType: types.UPDATE_GALLERY_REQUEST,
		callAPI: () => ArtistServices.updateGallery(galleryId, body),
		stateObject: "updateGalleryResult",
		isTransient: true
	};
};

// ========================================== Delete From Gallery ========================================== //
export const deleteFromGalleryAction = galleryId => {
	return {
		actionType: types.DELETE_FROM_GALLERY_REQUEST,
		callAPI: () => ArtistServices.deleteFromGallery(galleryId),
		stateObject: "deleteFromGalleryResult",
		isTransient: true
	};
};

// ========================================== Get All Artists List ========================================== //
export const getAllArtistsList = params => {
	return {
		actionType: types.GET_ARTISTS_LIST_REQUEST,
		callAPI: () => ArtistServices.getAllArtists(params),
		stateObject: "getArtistsListData"
	};
};

// ========================================== Get Event List ========================================== //
export const getEventListAction = () => {
	return {
		actionType: types.GET_EVENT_LIST_REQUEST,
		callAPI: () => ArtistServices.getEventList(),
		stateObject: "getEventListResult"
	};
};

// ========================================== Get Event List by UserId ========================================== //
export const getEventListByUserIdAction = userId => {
	return {
		actionType: types.GET_EVENT_LIST_REQUEST,
		callAPI: () => ArtistServices.getEventListByUserId(userId),
		stateObject: "getEventListResult"
	};
};

// ========================================== Add New Event ========================================== //
export const addNewEventAction = body => {
	return {
		actionType: types.ADD_NEW_EVENT_REQUEST,
		callAPI: () => ArtistServices.addNewEvent(body),
		stateObject: "addNewEventResult",
		isTransient: true
	};
};

// ========================================== Update Event ========================================== //
export const updateEventAction = (body, eventId) => {
	return {
		actionType: types.UPDATE_EVENT_REQUEST,
		callAPI: () => ArtistServices.updateEvent(body, eventId),
		stateObject: "updateEventResult",
		isTransient: true
	};
};

// ========================================== Delete From Event ========================================== //
export const deleteFromEventAction = Id => {
	return {
		actionType: types.DELETE_FROM_EVENT_REQUEST,
		callAPI: () => ArtistServices.deleteFromEvent(Id),
		stateObject: "deleteFromEventResult",
		isTransient: true
	};
};

// ========================================== Get Award List ========================================== //
export const getAwardListAction = () => {
	return {
		actionType: types.GET_AWARD_LIST_REQUEST,
		callAPI: () => ArtistServices.getAwardList(),
		stateObject: "awardListResult"
	};
};

// ========================================== Get Award List by UserId ========================================== //
export const getAwardListByUserIdAction = userId => {
	return {
		actionType: types.GET_AWARD_LIST_REQUEST,
		callAPI: () => ArtistServices.getAwardListByUserId(userId),
		stateObject: "awardListResult"
	};
};

// ========================================== Add New Award ========================================== //
export const addNewAwardAction = (year, award) => {
	return {
		actionType: types.ADD_AWARD_REQUEST,
		callAPI: () => ArtistServices.addNewAward(year, award),
		stateObject: "addAwardResult",
		isTransient: true
	};
};

// ========================================== Update Award ========================================== //
export const updateAwardAction = (year, award, awardId) => {
	return {
		actionType: types.UPDATE_AWARD_REQUEST,
		callAPI: () => ArtistServices.updateAward(year, award, awardId),
		stateObject: "updateAwardResult",
		isTransient: true
	};
};

// ========================================== Delete Award ========================================== //
export const deleteAwardAction = awardId => {
	return {
		actionType: types.DELETE_AWARD_REQUEST,
		callAPI: () => ArtistServices.deleteAward(awardId),
		stateObject: "deleteAwardResult",
		isTransient: true
	};
};

// ========================================== Get Historical Data List ========================================== //
export const getHistoricalDataListAction = () => {
	return {
		actionType: types.GET_HISTORICAL_DATA_LIST_REQUEST,
		callAPI: () => ArtistServices.getHistoricalDataList(),
		stateObject: "historicalDataListResult"
	};
};

// ========================================== Get Historical Data List bu UserId ========================================== //
export const getHistoricalDataListByUserIdAction = userId => {
	return {
		actionType: types.GET_HISTORICAL_DATA_LIST_REQUEST,
		callAPI: () => ArtistServices.getHistoricalDataListByUserId(userId),
		stateObject: "historicalDataListResult"
	};
};

// ========================================== Add New Historical Data ========================================== //
export const addNewHistoricalAction = (year, events) => {
	return {
		actionType: types.ADD_HISTORICAL_DATA_REQUEST,
		callAPI: () => ArtistServices.addNewHistorical(year, events),
		stateObject: "addHistoricalDataResult",
		isTransient: true
	};
};

// ========================================== Update Historical Data ========================================== //
export const updateHistoricalDataAction = (year, events, historicalId) => {
	return {
		actionType: types.UPDATE_HISTORICAL_DATA_REQUEST,
		callAPI: () => ArtistServices.updateHistoricalData(year, events, historicalId),
		stateObject: "updateHistoricalDataResult",
		isTransient: true
	};
};

// ========================================== Delete Historical Data ========================================== //
export const deleteHistoricalDataAction = historicalId => {
	return {
		actionType: types.DELETE_HISTORICAL_DATA_REQUEST,
		callAPI: () => ArtistServices.deleteHistoricalData(historicalId),
		stateObject: "deleteHistoricalDataResult",
		isTransient: true
	};
};

// ========================================== Get My Blogs ========================================== //
export const getMyBlogsAction = (pageNum, pageSize) => {
	return {
		actionType: types.GET_MY_BLOGS_REQUEST,
		callAPI: () => ArtistServices.getMyBlogs(pageNum, pageSize),
		stateObject: "getMyBlogsResult"
	};
};

// ========================================== Get Artist's Blogs ========================================== //
export const getBlogsByUserAction = (pageNum, pageSize, UserId) => {
	return {
		actionType: types.GET_BLOGS_BY_USER_REQUEST,
		callAPI: () => ArtistServices.getBlogsByUser(pageNum, pageSize, UserId),
		stateObject: "getBlogsByUserResult"
	};
};
// ========================================== Add Blog ========================================== //
export const addBlogAction = body => {
	return {
		actionType: types.ADD_BLOG_REQUEST,
		callAPI: () => ArtistServices.addBlog(body),
		stateObject: "addBlogResult",
		isTransient: true
	};
};

// ========================================== Update Blog ========================================== //
export const updateBlogAction = (params, body) => {
	return {
		actionType: types.UPDATE_BLOG_REQUEST,
		callAPI: () => ArtistServices.updateBlog(params, body),
		stateObject: "updateBlogResult",
		isTransient: true
	};
};

// ========================================== Delete Blog ========================================== //
export const deleteBlogAction = params => {
	return {
		actionType: types.DELETE_BLOG_REQUEST,
		callAPI: () => ArtistServices.deleteBlog(params),
		stateObject: "deleteBlogResult",
		isTransient: true
	};
};

// ========================================== Get Gallery Membership Requests ========================================== //
export const getGalleryMembershipRequestsAction = status => {
	return {
		actionType: types.GET_GALLERY_MEMBERSHIP_REQUESTS,
		callAPI: () => ArtistServices.getGalleryMembershipRequests(status),
		stateObject: "getGalleryMembershipRequests"
	};
};

// ========================================== Approve Gallery Request ========================================== //
export const updateGalleryMembershipRequestAction = (status, galleryId) => {
	return {
		actionType: types.UPDATE_GALLERY_MEMBERSHIP_REQUEST,
		callAPI: () => ArtistServices.updateGalleryMembershipRequest(status, galleryId),
		stateObject: "updateGalleryMembershipRequest",
		isTransient: true
	};
};
