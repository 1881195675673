import { useDispatch, useSelector } from "react-redux";
import { getProductDetailsAction, onUpdateDetailsInReducer, getCategoryPropertiesAndMap } from "../actions/UpdateProductAction";
import { setReducerStates } from "../actions/AddProductAction";

const useUpdateProductContainer = () => {
	const dispatch = useDispatch();

	// Save data to reducer
	const onSaveDataToReducer = (type, data) => {
		dispatch(onUpdateDetailsInReducer(type, data));
	};

	// Get Product Details
	const getProductDetails = (slugID) => {
		dispatch(getProductDetailsAction(slugID));
	};

	// Get Category property
	const getCategoryProperty = (categoryId) => {
		dispatch(getCategoryPropertiesAndMap(categoryId));
	};

	// Clear states
	const clearStates = () => {
		dispatch(setReducerStates("CLEAR_UPDATE_PRODUCT_REDUCER_STATES", null));
	};

	const {
		isAlreadyLoaded,
		isProductValid,
		productId,
		slugId,
		productResponse,
		isMyProduct,
		isEditable,

		// Basic
		categoryArray,
		mobileCategoryArray,
		productName,
		artistId,
		tagsArray,
		tags,
		description,
		oneLineDescription,
		productType,

		// Primary
		dimensions,
		height,
		width,
		depth,
		medium,
		isLimitedEdition,
		materialsUsed,
		yearofCreation,
		weight,
		originalArtworkId,
		packageHeight,
		packageWidth,
		packageDepth,
		packaging,
		conditionReport,
		signature,
		// Pricing and stock
		price,
		isHidePrice,
		stockManagement,
		stock,
		stockDetails,
		currency,
		autoApproveQuote,
		approvePercentage,
		autoRejectQuote,
		rejectPercentage,

		// Images and Videos
		imagesArray,
		existingImagesArray,
		existingImagesArrayUC,
		deletedFiles,
		displayPicture,
		displayPictureUC,

		// Features
		productProperties,
		style,
		isAbstract,
		framed,
		subject,
		editionPublisher,
		seriesName,
		panel,

		status,
		productSubStatus,
		successToast,
		errorToast,
		getPropertyLoader,
		getParentOfCategoryLoader,
		getProductLoader,

		updatingProductLoader,
	} = useSelector((state) => state.updateProductManagement);

	return {
		// ------------- Actions ------------- //
		getProductDetails,
		onSaveDataToReducer,
		getCategoryProperty,
		clearStates,

		// ------------- Reducer ------------- //
		isAlreadyLoaded,
		isProductValid,
		productId,
		slugId,
		productResponse,
		isMyProduct,
		isEditable,

		// Basic
		categoryArray,
		mobileCategoryArray,
		productName,
		artistId,
		tagsArray,
		tags,
		description,
		oneLineDescription,
		productType,

		// Primary
		dimensions,
		height,
		width,
		depth,
		medium,
		isLimitedEdition,
		materialsUsed,
		yearofCreation,
		weight,
		originalArtworkId,
		packageHeight,
		packageWidth,
		packageDepth,
		packaging,
		conditionReport,
		signature,
		// Pricing and stock
		price,
		isHidePrice,
		stockManagement,
		stock,
		stockDetails,
		currency,
		autoApproveQuote,
		approvePercentage,
		autoRejectQuote,
		rejectPercentage,

		// Images and Videos
		imagesArray,
		existingImagesArray,
		existingImagesArrayUC,
		deletedFiles,
		displayPicture,
		displayPictureUC,

		// Features
		productProperties,
		style,
		isAbstract,
		framed,
		subject,
		editionPublisher,
		seriesName,
		panel,
		status,
		productSubStatus,
		successToast,
		errorToast,
		getPropertyLoader,
		getParentOfCategoryLoader,
		getProductLoader,

		updatingProductLoader,
	};
};

export default useUpdateProductContainer;
