import { PaymentService } from "../services/PaymentService";
import * as types from "./ActionsTypes";

// ========================================== getCurrencies ========================================== //
export const getCurrencies = () => {
	return {
		actionType: types.GET_CURRENCIES_REQUEST,
		callAPI: () => PaymentService.getCurrencies(),
		stateObject: "currencies",
	};
};

// ========================================== convertCurrency ========================================== //
export const convertCurrency = (Amount, CurrencyName) => {
	return {
		actionType: types.CONVERT_CURRENCY_REQUEST,
		callAPI: () => PaymentService.convertCurrency(Amount, CurrencyName),
		stateObject: "convertCurrency",
		isTransient: true,
	};
};

// ========================================== Stripe Payment ========================================== //
export const stripePaymentForOrderAction = (StripeToken, OrderId) => {
	return {
		actionType: types.STRIPE_PAYMENT_REQUEST,
		callAPI: () => PaymentService.stripePayment(StripeToken, OrderId),
		stateObject: "stripePayment",
		isTransient: true,
	};
};
export const stripePaymentIntentForOrderAction = (OrderId, Type) => {
	return {
		actionType: types.STRIPE_PAYMENT_INTENT,
		callAPI: () => PaymentService.stripePaymentIntent(OrderId, Type),
		stateObject: "stripePaymentIntent",
		// isTransient: true
	};
};

export const stripePaymentOrderConfirmationAction = (OrderId, IntentId) => {
	return {
		actionType: types.STRIPE_PAYMENT_CONFIRMATION,
		callAPI: () => PaymentService.stripePaymentConfirmation(OrderId, IntentId),
		stateObject: "stripePaymentConfirmation",
		isTransient: true,
	};
};
// ========================================== Paypal Client Code ========================================== //
export const getPaypalIDForOrderAction = () => {
	return {
		actionType: types.GET_PAYPAL_ID_REQUEST,
		callAPI: () => PaymentService.getPaypalIDForOrder(),
		stateObject: "getPaypalIDForOrder",
		// isTransient: true
	};
};

// ========================================== Paypal Payment ========================================== //
export const paypalPaymentForOrderAction = (rtistIQOrderId, PaypalOrderId) => {
	return {
		actionType: types.PAYPAL_PAYMENT_REQUEST,
		callAPI: () => PaymentService.paypalPayment(rtistIQOrderId, PaypalOrderId),
		stateObject: "paypalPayment",
		isTransient: true,
	};
};

// ========================================== Paypal Failed Payment ========================================== //
export const paypalPaymentFailureAction = (rtistIQOrderId) => {
	return {
		actionType: types.PAYPAL_PAYMENT_FAILURE_REQUEST,
		callAPI: () => PaymentService.paypalFailedPayment(rtistIQOrderId),
		stateObject: "paypalFailurePayment",
		isTransient: true,
	};
};
