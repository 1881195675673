import { APIUrlConstants } from "../util/APIConstants";
import { BaseService, HTTP_METHOD } from "./BaseService";

export class RecommendationsManagementService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get Similiar products
	static async getSimilarProducts(productId) {
		const params = { productId };
		return this.service.execute(this.API.getSimilarProducts, HTTP_METHOD.GET, { params });
	}

	// Get suggested artworks
	static async getSuggestedArtworks() {
		return this.service.execute(this.API.getSuggestedProducts, HTTP_METHOD.GET, {});
	}

	// Get Continue Your Search
	static async getContinueYourSearch(pageNum = 1, pageSize = 30) {
		const params = { pageNum, pageSize };
		return this.service.execute(this.API.continueSearch, HTTP_METHOD.GET, { params });
	}

	//Get Recommended Categories
	static async getRecommendedCategories() {
		return this.service.execute(this.API.getRecommendedCategories, HTTP_METHOD.GET, {});
	}

	//Get Trending Categories
	static async getTrendingCategories() {
		return this.service.execute(this.API.getTrendingCategories, HTTP_METHOD.GET, {});
	}

	// Get Artworks on offer
	static async getArtworksOnOffer() {
		return this.service.execute(this.API.artworksOnOffer, HTTP_METHOD.GET, {});
	}

	// Get Featured Artists
	static async getFeaturedArtists() {
		return this.service.execute(this.API.getFeaturedArtists, HTTP_METHOD.GET, {});
	}

	static async getFeaturedGallery() {
		return this.service.execute(this.API.featuredSlider, HTTP_METHOD.GET, {});
	}

	//Latest artworks
	static async getLatestArtworks() {
		return this.service.execute(this.API.getLatestArtworks, HTTP_METHOD.GET, {});
	}

	//Like And WishList artworks
	static async getLikeAndWishList() {
		return this.service.execute(this.API.getLikeAndWishList, HTTP_METHOD.GET, {});
	}

	//Get Popular Artworks
	static async getPopularArtworks() {
		return this.service.execute(this.API.getPopularProducts, HTTP_METHOD.GET, {});
	}

	//Get Featured Artworks
	static async getFeaturedArtworks() {
		return this.service.execute(this.API.getFeaturedArtworks, HTTP_METHOD.GET, {});
	}
}
