import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import messages_en from "./i18n/en.json";
import messages_fr from "./i18n/fre.json";
import messages_es from "./i18n/spn.json";
import messages_de from "./i18n/ger.json";

i18n.use(LanguageDetector).init({
    debug: true,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false // react already safes from xss
    },
    // we init with resources
    resources: {
        en: {
            translations: messages_en
        },
        fr: {
            translations: messages_fr
        },
        es: {
            translations: messages_es
        },
        de: {
            translations: messages_de
        }
    },

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    // interpolation: {
    //     escapeValue: false, // not needed for react!!
    //     formatSeparator: ","
    // },

    react: {
        wait: true
    }
});

export default i18n;
