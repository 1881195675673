import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getArtistTypes,
	getArtistTypesById,
	addArtistTypes,
	updateArtistTypes,
	deleteArtistTypes,
	filterArtistByTypes,
	filterAdminArtistByTypes
} from "../actions/ArtistTypesAction";

export const useGetArtistTypesById = () => {
	const dispatch = useDispatch();

	// Get ArtistTypes
	const getTypesById = (artistTypeId) => {
		dispatch(getArtistTypesById(artistTypeId));
	};
	// Reducer states
	const result = useSelector((state) => state.artistTypes.getArtistTypesById);

	return [getTypesById, result.data, result.error, result.loading];
};

export const useGetArtistTypes = () => {
	const dispatch = useDispatch();

	// Get ArtistTypes
	const getTypes = () => {
		dispatch(getArtistTypes());
	};
	// Reducer states
	const result = useSelector((state) => state.artistTypes.getArtistTypes);

	return [getTypes, result.data, result.error, result.loading];
};
export const useAddArtistTypes = () => {
	const dispatch = useDispatch();

	// Add ArtistTypes
	const addTypes = (artistTypesData) => {
		dispatch(addArtistTypes(artistTypesData));
	};

	// Reducer states
	const result = useSelector((state) => state.artistTypes.addArtistTypes);

	return [addTypes, result.data, result.error, result.loading];
};
export const useUpdateArtistTypes = () => {
	const dispatch = useDispatch();

	// Update ArtistTypes
	const updateTypes = (artistTypesData, artistTypeId) => {
		dispatch(updateArtistTypes(artistTypesData, artistTypeId));
	};

	// Reducer states
	const result = useSelector((state) => state.artistTypes.updateArtistTypes);

	return [updateTypes, result.data, result.error, result.loading];
};
export const useDeleteArtistTypes = () => {
	const dispatch = useDispatch();

	// Delete ArtistTypes
	const deleteTypes = (artistTypeId) => {
		dispatch(deleteArtistTypes(artistTypeId));
	};
	// Reducer states
	const result = useSelector((state) => state.artistTypes.deleteArtistTypes);

	return [deleteTypes, result.data, result.error, result.loading];
};
export const useFilterArtistByTypes = () => {
	const dispatch = useDispatch();

	const filterArtist = (artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder) => {
		dispatch(filterArtistByTypes(artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder));
	};

	const result = useSelector((state) => state.artistTypes.filterArtistTypes);

	return [filterArtist, result.data, result.error, result.loading];
};

export const useFilterAdminArtistByTypes = () => {
	const dispatch = useDispatch();

	const filterAdminArtist = (artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder) => {
		dispatch(filterAdminArtistByTypes(artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, country, style, medium, IsFeatured, userSortOption, userSortOrder));
	};

	const result = useSelector((state) => state.artistTypes.filterAdminArtistByTypes);

	return [filterAdminArtist, result.data, result.error, result.loading];
};

