import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProfileDropdown.css";
import { ProfileDropdownWrapper } from "./ProfileDropdown.styled";
import toast from "common/Toast";
import SDK, { productDetailHooks, LoginHooks, userManagementHooks } from "ecommerce-sdk/src/index";
import { includes } from "lodash";
import useModal from "utils/useModal";
import ModalTypes from "utils/ModalTypes";
import { toastMessage } from "utils/toastMessages";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

export const ProfileDropDownMobile = ({ dismiss, allowSellArtwork, userInfo, onHandleSideBar }) => {
	const { useLogout } = LoginHooks;
	let history = useHistory();

	const [logoutUser, logoutData, logoutError] = useLogout();
	const { useCheckAddProductEligibility } = productDetailHooks;

	// const [, logout] = useAutoLogout();
	const [userRoles, setUserRole] = useState();
	const [, checkAddProductEligibilityData] = useCheckAddProductEligibility();
	const { t } = useTranslation();
	const isTabletOrMobileDevice = useMediaQuery({
		query: "(max-device-width: 1224px)",
	});
	useEffect(() => {
		SDK.UserManagement.getUserRoles((result) => setUserRole(result));
	}, []);

	const [, , showModal] = useModal(ModalTypes.sellAddArtworkModal);

	const onItemClick = () => {
		onHandleSideBar();
		dismiss && dismiss();
	};
	useEffect(() => {
		if (logoutError) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		} else if (logoutData) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		}
	}, [logoutData, logoutError]);

	return (
		<ProfileDropdownWrapper>
			{userInfo && userInfo.kycStatus === 0 && (
				<Link
					to="#"
					onClick={() => {
						onHandleSideBar();

						showModal(ModalTypes.kycDocument, {
							data: "dropdown",
						});
					}}
				>
					{t("Pending KYC Upload")}
				</Link>
			)}
			{(() => {
				if (includes(userRoles, "ARTIST")) {
					return (
						<Link
							to="#"
							onClick={() => {
								onHandleSideBar();
								history.push("/artist/dashboard");
							}}
						>
							My Dashboard
						</Link>
					);
				} else if (includes(userRoles, "GALLERY")) {
					return (
						<Link
							to="#"
							onClick={() => {
								onHandleSideBar();
								history.push("/galleries/me");
							}}
						>
							My Profile
						</Link>
					);
				} else if (isTabletOrMobileDevice) {
					return <Link to="/buyer/dashboard">My Profile</Link>;
				}
			})()}

			<Link to="/profile/general" onClick={onItemClick}>
				{t("General Account Settings")}
			</Link>
			{/* <Link to="/profile/artworks" onClick={onItemClick}>
				{t("Favorite Artworks")}
			</Link>
			<Link to="/profile/address" onClick={onItemClick}>
				{t("Address Book")}
			</Link> */}
			<Link
				to={includes(userRoles, "ARTIST") || includes(userRoles, "GALLERY") ? "/profile/received-orders" : "/profile/orders"}
				onClick={onItemClick}
			>
				{t("Orders")}
			</Link>
			{/*  <Link to="/profile/settings" onClick={onItemClick}>
                Settings
			</Link>
           {allowSellArtwork && (
				<Link to="#">
					<span
						onClick={() => {
							if (checkAddProductEligibilityData && checkAddProductEligibilityData.isEligible) {
								let display = true;
								showModal(ModalTypes.sellAddArtworkModal, display);
							} else if (checkAddProductEligibilityData && !checkAddProductEligibilityData.isEligible) {
								showModal(ModalTypes.responseRedirectionModal,
									{
										data: checkAddProductEligibilityData,
										showButton: (checkAddProductEligibilityData && checkAddProductEligibilityData.messageCode === 2) || (checkAddProductEligibilityData && checkAddProductEligibilityData.messageCode === 4) ? false : true
									});
							}
							dismiss && dismiss();
						}}
					>
						Sell Your Artwork
					</span>
				</Link>
			)} */}
			<Link
				to="/"
				onClick={async (e) => {
					e.preventDefault();
					let token = await SDK.UserManagement.getRefreshToken();
					logoutUser(token);
					toast(t(toastMessage.loggedOut), "success");
				}}
			>
				{t("Logout")}
			</Link>
		</ProfileDropdownWrapper>
	);
};
