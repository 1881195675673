import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getBasicDescription,
	getBasicDescriptionByIdAction,
	updateArtistProfile,
	updateSocialLinks,
	updateArtistProfileImages
} from "../actions/ArtistBasicProfileAction";

export const useGetBasicDescription = () => {
	const dispatch = useDispatch();

	// Get ArtistBasicDecription
	const getDescription = (SlugId, loginStatus) => {
		if (SlugId) {
			//-----------------Get Artist Description Using SlugId----------------//
			dispatch(getBasicDescriptionByIdAction(SlugId, loginStatus));
		} else {
			dispatch(getBasicDescription());
		}
	};
	// Reducer states
	const result = useSelector(state => state.artistBasicProfile.getBasicDescription);

	return [getDescription, result.data, result.error, result.loading];
};

export const useUpdateArtistProfile = () => {
	const dispatch = useDispatch();

	// Update ArtistProfile
	const updateProfile = infoData => {
		dispatch(updateArtistProfile(infoData));
	};

	// Reducer states
	const result = useSelector(state => state.artistBasicProfile.updateArtistProfile); 

	return [updateProfile, result.data, result.error, result.loading];
};
export const useUpdateSocialLinks = () => {
	const dispatch = useDispatch();

	// Update ArtistProfile
	const updateLinks = infoData => {
		dispatch(updateSocialLinks(infoData));
	};

	// Reducer states
	const result = useSelector(state => state.artistBasicProfile.updateSocialLinks);

	return [updateLinks, result.data, result.error, result.loading];
};
export const useUpdateArtistProfileImages = () => {
	const dispatch = useDispatch();
 
	// Update ArtistProfileImages
	const updateProfileImages = data => {
		dispatch(updateArtistProfileImages(data));
	};

	// Reducer states
	const result = useSelector(state => state.artistBasicProfile.updateArtistProfileImages);

	return [updateProfileImages, result.data, result.error, result.loading];
};
