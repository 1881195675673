import React from "react";
import { useHistory } from "react-router-dom";
import { HexagonImageForSliderName, HexagonImageForSliderPlace, HexagonImageForSliderDetailsWrapper } from "common/HexagonImage/HexagonImage.styled";
import lodash from "lodash";
import assets from "assets";
import { ImageForSliderWrapper, NormalImageSearch, NormalDivSearch } from "./ImageSlider.styled";

const ImageSliderForSearch = ({ data }) => {
	let history = useHistory();
	return (
		<ImageForSliderWrapper
			onClick={() => {
				history.push(`/artist/${data.slugId}`);
			}}
		>
			{/* <HexagonImage image={lodash.get(data, "profilePhoto", "")} type="hoverHexagon" /> */}
			<NormalDivSearch>
				<NormalImageSearch
					srcWebp={lodash.get(data, "profilePhoto", "") || assets.images.emptyArtistDp}
					fallback={lodash.get(data, "profilePhotoUC", "") || assets.images.emptyArtistDp}
					alt={`RtistiQ Artist:${data && data.name}${data && data.place}`}
				/>
			</NormalDivSearch>
			<HexagonImageForSliderDetailsWrapper>
				<HexagonImageForSliderName>{lodash.get(data, "alaisName", "") || lodash.get(data, "name", "")}</HexagonImageForSliderName>
				<HexagonImageForSliderPlace>{lodash.get(data, "place", "") || " "}</HexagonImageForSliderPlace>
			</HexagonImageForSliderDetailsWrapper>
		</ImageForSliderWrapper>
	);
};

export default ImageSliderForSearch;
