import TorusSdk from "@toruslabs/customauth";
import toast from "common/Toast";
import { authorizePost } from "./apiConfig";

export const getTorusWallet = async (token, email) => {
	try {
		const torusdirectsdk = new TorusSdk({
			baseUrl: `${window.location.origin}/serviceworker`,
			enableLogging: true,
			network: "testnet", // details for test net
		});
		let obj = {};
		await torusdirectsdk.init({ skipSw: true });
		if (torusdirectsdk) {
			// authorizePost("UserManagment/api/UserManagement/TorusLogin", {}, token).then((res) => {
			// 	console.log("🚀 ~ file: torusLogin.js ~ line 16 ~ authorizePost ~ res", res);
			// 	if (res.resultCode === 1) {

			console.log("🚀 ~ file: torusLogin.js ~ line 18 ~ authorizePost ~ jwtToken", token);

			let dt = await torusdirectsdk.getTorusKey("rtistiq-testnet", email, { verifier_id: email }, token);
			localStorage.setItem("torusWalletAddress", dt.publicAddress);
			localStorage.setItem("walletName", "Torus");
			localStorage.setItem("isCustomeWalletConnected", true);
			console.log("🚀 ~ file: torusLogin.js ~ line 21 ~ //authorizePost ~ dt", dt);
			return dt;
		}
	} catch (error) {
		console.log("🚀 ~ file: torusLogin.js ~ line 30 ~ getTorusWal ~ error", error);
		console.error(error, "mounted caught");
		if (error && error.message) {
			toast(error.message, "error");
		} else {
			toast(error, "error");
		}
	}
};

//This is for externly sold artwork

export const getTorusWalletForNewBuyer = async (token, email) => {
	try {
		const torusdirectsdk = new TorusSdk({
			baseUrl: `${window.location.origin}/serviceworker`,
			enableLogging: true,
			network: "testnet", // details for test net
		});
		let obj = {};
		await torusdirectsdk.init({ skipSw: true });
		if (torusdirectsdk) {
			let dt = await torusdirectsdk.getTorusKey("rtistiq-testnet", email, { verifier_id: email }, token);

			return dt;
		}
	} catch (error) {
		console.log("🚀 ~ file: torusLogin.js ~ line 30 ~ getTorusWal ~ error", error);
		console.error(error, "mounted caught");
		if (error && error.message) {
			toast(error.message, "error");
		} else {
			toast(error, "error");
		}
	}
};
