import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getProductDetailAction,
	getProductBySlugAction,
	getSoldArtWorkDetailsAction,
	addToWishlistAction,
	getWishlist,
	deleteFromWishlistAction,
	disclosePriceAction,
	productLikeAction,
	productDislikeAction,
	quoteRateAction,
	getSellerProductsAction,
	getSellerArtworksAction,
	getMySoldProducts,
	getSoldProducts,
	searchArtworksListAction,
	searchSellerArtworksListAction,
	getProductOwnershipHistoryAction,
	getSellerQuotes,
	updateSellerQuoteStatus,
	getSellerPriceRequest,
	updateSellerPriceStatus,
	getMyQuote,
	deleteMyQuote,
	getSignaturePendingArtworksAction,
	doCheckRFIDAction,
	updateProductStatus,
	checkAddProductEligibilityAction,
	getGalleryAddedProducts,
	getArtistProductsAction,
	getGalleryViewAction,
	getInventoryAction,
	getOwnedArtworksAction,
	updateImagesAction,
	bypassNfcAction,
	updatePriceAction,
} from "../actions/ProductManagementAction";

export const useProductDetail = () => {
	const dispatch = useDispatch();
	//Get Product Info
	const getProductDetail = (productId) => {
		dispatch(getProductDetailAction(productId));
	};

	const productDetail = useSelector((state) => state.productManagement.productDetail);

	return [getProductDetail, productDetail.data, productDetail.error, productDetail.loading];
};

export const useDisclosePrice = () => {
	const dispatch = useDispatch();
	const onDisclosePrice = (productId) => {
		dispatch(disclosePriceAction(productId));
	};
	const response = useSelector((state) => state.productManagement.disclosePrice);

	return [onDisclosePrice, response.data, response.error, response.loading];
};

export const useProductLike = () => {
	const dispatch = useDispatch();
	const onProductLike = (productId) => {
		dispatch(productLikeAction(productId));
	};
	const response = useSelector((state) => state.productManagement.productLike);

	return [onProductLike, response.data, response.error, response.loading];
};

export const useProductDisLike = () => {
	const dispatch = useDispatch();
	const onProductDislike = (productId) => {
		dispatch(productDislikeAction(productId));
	};
	const response = useSelector((state) => state.productManagement.productDisLike);

	return [onProductDislike, response.data, response.error, response.loading];
};

export const useAddToWishlist = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.productManagement.addToWishlist);

	const addToWishlist = (productId) => {
		dispatch(addToWishlistAction(productId));
	};
	return [addToWishlist, result.data, result.error, result.loading];
};

export const useGetWishlist = () => {
	const dispatch = useDispatch();

	const getFunction = (pageNum, pageSize) => {
		dispatch(getWishlist(pageNum, pageSize));
	};
	const result = useSelector((state) => state.productManagement.wishlist);
	return [getFunction, result.data, result.error, result.loading];
};

export const useDeleteFromWishlist = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.productManagement.deleteFromWishlist);

	const deleteFromWishlist = (productId) => {
		dispatch(deleteFromWishlistAction(productId));
	};
	return [deleteFromWishlist, result.data, result.error, result.loading];
};

export const useQuoteRate = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.productManagement.quoteRate);
	const quoteRate = (stockId, amount, message, currency) => {
		dispatch(quoteRateAction(stockId, amount, message, currency));
	};
	return [quoteRate, result.data, result.error, result.loading];
};

export const useGetProductBySlug = () => {
	const dispatch = useDispatch();
	const productDetail = useSelector((state) => state.productManagement.productDetail);
	const getProductBySlug = (slugId, stockId, IsAdminTempFix = false) => {
		dispatch(getProductBySlugAction(slugId, stockId, IsAdminTempFix));
	};
	return [getProductBySlug, productDetail.data, productDetail.error, productDetail.loading];
};
export const useGetSoldArtworkBySlug = () => {
	const dispatch = useDispatch();
	const soldArtworkDetail = useSelector((state) => state.productManagement.soldArtworkDetail);
	const getSoldArtworkDetail = (slugId, stockId) => {
		dispatch(getSoldArtWorkDetailsAction(slugId, stockId));
	};
	return [getSoldArtworkDetail, soldArtworkDetail.data, soldArtworkDetail.error, soldArtworkDetail.loading];
};

export const useGetProductOwnershipHistory = () => {
	const dispatch = useDispatch();
	const productOwnershipHistory = useSelector((state) => state.productManagement.productOwnershipHistory);
	const getProductOwnershipHistory = (stockId) => {
		dispatch(getProductOwnershipHistoryAction(stockId));
	};
	return [getProductOwnershipHistory, productOwnershipHistory.data, productOwnershipHistory.error, productOwnershipHistory.loading];
};

export const useSellerProductList = () => {
	const dispatch = useDispatch();
	const sellerProductList = (pageNo, pageSize, ownerId = null, artistId = null) => {
		if (ownerId === null && artistId === null) {
			dispatch(getSellerProductsAction(pageNo, pageSize));
		} else if (ownerId === null) {
			dispatch(searchArtworksListAction(pageNo, pageSize, null, artistId));
		} else {
			dispatch(searchArtworksListAction(pageNo, pageSize, ownerId));
		}
	};
	const result = useSelector((state) => state.productManagement.sellerProducts);
	return [sellerProductList, result.data, result.error, result.loading];
};

//For use in galleries tab in artist profile page. Same as useSellerProductList hook, but different state variable
export const useSellerArtworksList = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.productManagement.sellerArtworks);
	const sellerArtworksList = (pageNo, pageSize, ownerId = null, artistId = null) => {
		if (ownerId === null && artistId === null) {
			dispatch(getSellerArtworksAction(pageNo, pageSize));
		} else if (ownerId === null) {
			dispatch(searchSellerArtworksListAction(pageNo, pageSize, null, artistId));
		} else {
			dispatch(searchSellerArtworksListAction(pageNo, pageSize, ownerId));
		}
	};
	return [sellerArtworksList, result.data, result.error, result.loading];
};
export const useGetMySoldProducts = () => {
	const dispatch = useDispatch();
	const getMySold = (pageNo, pageSize, userId) => {
		if (userId === null) dispatch(getMySoldProducts(pageNo, pageSize));
		else dispatch(getSoldProducts(pageNo, pageSize, userId));
	};
	const result = useSelector((state) => state.productManagement.mySoldProducts);
	return [getMySold, result.data, result.error, result.loading];
};

export const useGetSellerQuotes = () => {
	const dispatch = useDispatch();
	const get = (pageNo, pageSize) => {
		dispatch(getSellerQuotes(pageNo, pageSize));
	};
	const result = useSelector((state) => state.productManagement.sellerQuotes);
	return [get, result.data, result.error, result.loading];
};

export const useUpdateSellerQuoteStatus = () => {
	const dispatch = useDispatch();
	const update = (quoteId, Status) => {
		dispatch(updateSellerQuoteStatus(quoteId, Status));
	};
	const result = useSelector((state) => state.productManagement.updateSellerQuoteStatus);
	return [update, result.data, result.error, result.loading];
};

export const useGetPriceRequest = () => {
	const dispatch = useDispatch();
	const get = (pageNo, pageSize) => {
		dispatch(getSellerPriceRequest(pageNo, pageSize));
	};
	const result = useSelector((state) => state.productManagement.sellerPriceRequest);
	return [get, result.data, result.error, result.loading];
};

export const useUpdateSellerPriceStatus = () => {
	const dispatch = useDispatch();
	const update = (priceId, Status) => {
		dispatch(updateSellerPriceStatus(priceId, Status));
	};
	const result = useSelector((state) => state.productManagement.updateSellerPriceStatus);
	return [update, result.data, result.error, result.loading];
};

export const useGetMyQuotes = () => {
	const dispatch = useDispatch();
	const getMyQuotes = (pageNum, pageSize) => {
		dispatch(getMyQuote(pageNum, pageSize));
	};
	const result = useSelector((state) => state.productManagement.myQuoteList);
	return [getMyQuotes, result.data, result.error, result.loading];
};

export const useDeleteMyQuote = () => {
	const dispatch = useDispatch();
	const deleteMyQuotes = (productId) => {
		dispatch(deleteMyQuote(productId));
	};
	const result = useSelector((state) => state.productManagement.deleteMyQuote);
	return [deleteMyQuotes, result.data, result.error, result.loading];
};

// ========================================== Get Signature pending artworks ========================================== //
export const useGetSignaturePendingArtworks = () => {
	const dispatch = useDispatch();

	const getSignaturePendingArtworks = (pageNum, pageSize) => {
		dispatch(getSignaturePendingArtworksAction(pageNum, pageSize));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.getSignaturePendingArtworks);

	return [getSignaturePendingArtworks, data, error, loading];
};

// ========================================== Check RFID Exist ========================================== //
export const useDoesRFIDExists = () => {
	const dispatch = useDispatch();

	const checkRFIDs = (rfids) => {
		dispatch(doCheckRFIDAction(rfids));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.doesRFIDExist);

	return [checkRFIDs, data, error, loading];
};

// ========================================== Check RFID Exist ========================================== //
export const useUpdateProductStatus = () => {
	const dispatch = useDispatch();

	const update = (stockId, productStatus) => {
		dispatch(updateProductStatus(stockId, productStatus));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.updateProductStatus);

	return [update, data, error, loading];
};

// ========================================== Check Add Product Eligibility ========================================== //
export const useCheckAddProductEligibility = () => {
	const dispatch = useDispatch();

	const checkAddProductEligibility = (isPhysical = true) => {
		dispatch(checkAddProductEligibilityAction(isPhysical));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.checkAddProductEligibilityData);

	return [checkAddProductEligibility, data, error, loading];
};

// ========================================== Get Gallery Added Products ========================================== //
export const useGetGalleryAddedProducts = () => {
	const dispatch = useDispatch();

	const get = (pageNum, pageSize) => {
		dispatch(getGalleryAddedProducts(pageNum, pageSize));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.galleryAddedProducts);

	return [get, data, error, loading];
};

// ========================================== Get Artist Products ========================================== //
export const useGetArtistProducts = () => {
	const dispatch = useDispatch();

	const getArtistProducts = (pageNum, pageSize, artistId) => {
		dispatch(getArtistProductsAction(pageNum, pageSize, artistId));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.artistProducts);

	return [getArtistProducts, data, error, loading];
};

// ========================================== Get Gallery View ========================================== //
export const useGetGalleryView = () => {
	const dispatch = useDispatch();

	const getGalleryView = (pageNum, pageSize, galleryId) => {
		dispatch(getGalleryViewAction(pageNum, pageSize, galleryId));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.getGalleryView);

	return [getGalleryView, data, error, loading];
};

// ========================================== Get Inventory ========================================== //
export const useGetInventory = () => {
	const dispatch = useDispatch();

	const getInventory = (pageNum, pageSize) => {
		dispatch(getInventoryAction(pageNum, pageSize));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.getInventory);

	return [getInventory, data, error, loading];
};

// ========================================== Get Owned Artworks ========================================== //
export const useGetOwnedArtworks = () => {
	const dispatch = useDispatch();

	const getOwnedArtworks = (pageNum, pageSize) => {
		dispatch(getOwnedArtworksAction(pageNum, pageSize));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.getOwnedArtworks);

	return [getOwnedArtworks, data, error, loading];
};

// ========================================== Update Images ========================================== //
export const useUpdateImages = () => {
	const dispatch = useDispatch();

	const updateImages = (data, isUpdate = false) => {
		let filesListObj = new FormData();
		filesListObj.append("ProductId", data.productId);
		if (isUpdate) {
			// If display picture chosed
			if (data.displayPicture) filesListObj.append("DiplayImage", data.displayPicture);
		} else {
			if (data.displayPictureArray && data.displayPictureArray.media) filesListObj.append("DiplayImage", data.displayPictureArray.media);
			else filesListObj.append("DiplayImage", data.imagesArray[0].media);
			//For Video Thumbnail
			if (data.videoThubnailImg && data.videoThubnailImg.media) filesListObj.append("MediaThumbnail", data.videoThubnailImg.media);
			else filesListObj.append("MediaThumbnail", null);
		}

		// NEW IMAGES
		for (let index = 0; index < data.imagesArray.length; index++) {
			filesListObj.append("ProductImage", data.imagesArray[index].media);
		}
		// If there is deletedFiles
		if (isUpdate)
			if (data.deletedFiles.length > 0)
				for (let index = 0; index < data.deletedFiles.length; index++) {
					filesListObj.append("DeletedFiles", data.deletedFiles[index]);
				}
		dispatch(updateImagesAction(filesListObj));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.updateImages);

	return [updateImages, data, error, loading];
};

// ========================================== NFC Bypass ========================================== //
export const useNFCBypass = () => {
	const dispatch = useDispatch();

	const NFCBypass = (productId) => {
		dispatch(bypassNfcAction(productId));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.NFCBypassData);

	return [NFCBypass, data, error, loading];
};

// ========================================== Update Price ========================================== //
export const useUpdatePrice = () => {
	const dispatch = useDispatch();

	const updatePrice = (productId, sellingPrice) => {
		dispatch(updatePriceAction(productId, sellingPrice));
	};
	const { data, error, loading } = useSelector((state) => state.productManagement.updatePriceData);

	return [updatePrice, data, error, loading];
};
