import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getArtistListByGallery,
	getGalleryArtistListByGallery,
	getArtistListByUserId,
	getGalleryArtistListByUserId,
	getMyGalleryProfile,
	getGalleryProfileById,
	updateGalleryProfile,
	updateGalleryProfileImages,
	getMyGalleryFollowing,
	getMyFollowers,
	getMyFollowing,
	addNewArtistToGalleryAction,
	deleteArtistFromGalleryAction,
	getEventListAction,
	getEventListByUserIdAction,
	addNewEventAction,
	deleteFromEventAction,
	updateEventAction,
	getAwardListAction,
	getAwardListByUserIdAction,
	addNewAwardAction,
	updateAwardAction,
	deleteAwardAction,
	getHistoricalDataListAction,
	getHistoricalDataListByUserIdAction,
	addNewHistoricalAction,
	updateHistoricalDataAction,
	deleteHistoricalDataAction,
	filterGalleries,
	updateGalleryAddress,
	searchArtistsAction,
} from "../actions/GalleryAction";
import Util from "../../src/index";
export const useGetGalleryProfile = () => {
	const dispatch = useDispatch();

	// Get GalleryProfile
	const getprofile = (SlugId, loginStatus) => {
		if (SlugId) dispatch(getGalleryProfileById(SlugId, loginStatus));
		else dispatch(getMyGalleryProfile());
	};
	// Reducer states
	const result = useSelector((state) => state.galleryReducer.galleryProfile);

	return [getprofile, result.data, result.error, result.loading];
};

export const useUpdateGalleryProfile = () => {
	const dispatch = useDispatch();

	// Update GalleryProfile
	const updateProfile = (data) => {
		dispatch(updateGalleryProfile(data));
	};

	// Reducer states
	const result = useSelector((state) => state.galleryReducer.updateGalleryProfile);

	return [updateProfile, result.data, result.error, result.loading];
};
export const useUpdateGalleryProfileImages = () => {
	const dispatch = useDispatch();

	// Update GalleryProfileImages
	const updateImages = (data) => {
		dispatch(updateGalleryProfileImages(data));
	};

	// Reducer states
	const result = useSelector((state) => state.galleryReducer.updateGalleryProfileImages);

	return [updateImages, result.data, result.error, result.loading];
};
export const useGetMyGalleryFollowing = () => {
	const dispatch = useDispatch();

	// Get GetMyGalleryFollowing
	const getMyFollowing = () => {
		dispatch(getMyGalleryFollowing());
	};
	// Reducer states
	const result = useSelector((state) => state.galleryReducer.myGalleryFollowing);

	return [getMyFollowing, result.data, result.error, result.loading];
};
export const useGetMyFollowers = () => {
	const dispatch = useDispatch();

	// Get My Followers
	const getFollowers = () => {
		dispatch(getMyFollowers());
	};
	// Reducer states
	const result = useSelector((state) => state.galleryReducer.myFollowers);

	return [getFollowers, result.data, result.error, result.loading];
};
export const useGetMyFollowing = () => {
	const dispatch = useDispatch();

	// Get My Following
	const getFollowing = () => {
		dispatch(getMyFollowing());
	};
	// Reducer states
	const result = useSelector((state) => state.galleryReducer.myFollowing);

	return [getFollowing, result.data, result.error, result.loading];
};

export const useGetArtistList = () => {
	const dispatch = useDispatch();

	// Get Artist List
	const getArtistList = (PageNum, PageSize, UserId) => {
		if (UserId) dispatch(getArtistListByUserId(PageNum, PageSize, UserId));
		else dispatch(getArtistListByGallery(PageNum, PageSize));
	};

	// Reducer states
	const result = useSelector((state) => state.galleryReducer.artistList);

	return [getArtistList, result.data, result.error, result.loading];
};
export const useGetGalleryArtistList = () => {
	const dispatch = useDispatch();

	// Get Artist List
	const getGalleryArtistList = (PageNum, PageSize, UserId) => {
		if (UserId) dispatch(getGalleryArtistListByUserId(PageNum, PageSize, UserId));
		else dispatch(getGalleryArtistListByGallery(PageNum, PageSize));
	};

	// Reducer states
	const result = useSelector((state) => state.galleryReducer.galleryArtistList);

	return [getGalleryArtistList, result.data, result.error, result.loading];
};

// Add Artist To Gallery
export const useAddArtistToGallery = () => {
	const dispatch = useDispatch();
	const addNewArtistToGallery = (artistIds) => {
		dispatch(addNewArtistToGalleryAction(artistIds));
	};
	// Reducer states
	const { data, error, loading } = useSelector((state) => state.galleryReducer.addArtistToGalleryResult);

	return [addNewArtistToGallery, data, error, loading];
};

// Delete Artist from Gallery
export const useDeleteArtistFromGallery = () => {
	const dispatch = useDispatch();
	const deleteArtistFromGallery = (artistId) => {
		dispatch(deleteArtistFromGalleryAction(artistId));
	};
	// Reducer states
	const { data, error } = useSelector((state) => state.galleryReducer.deleteArtistFromGalleryResult);

	return [deleteArtistFromGallery, data, error];
};

// Get List of Events
export const useGetEventList = () => {
	const dispatch = useDispatch();
	const getEventList = (userId) => {
		if (userId) dispatch(getEventListByUserIdAction(userId));
		else dispatch(getEventListAction());
	};
	const getEventListResult = useSelector((state) => state.galleryReducer.getEventListResult);
	return [getEventList, getEventListResult.data, getEventListResult.error, getEventListResult.loading];
};

// Add new Event
export const useAddNewEvent = () => {
	const dispatch = useDispatch();
	const addNewEvent = (body, isMobile = false) => {
		if (isMobile) {
			dispatch(addNewEventAction(body));
		} else {
			const formData = Util.objectToFormData(body);
			dispatch(addNewEventAction(formData));
		}
	};
	const addNewEventResult = useSelector((state) => state.galleryReducer.addNewEventResult);
	return [addNewEvent, addNewEventResult.data, addNewEventResult.error, addNewEventResult.loading];
};

//Update new Event
export const useUpdateEvent = () => {
	const dispatch = useDispatch();
	const updateEvent = (body, eventId, isMobile = false) => {
		if (isMobile) {
			dispatch(updateEventAction(body, eventId));
		} else {
			const formData = Util.objectToFormData(body);
			dispatch(updateEventAction(formData, eventId));
		}
	};
	const updateEventResult = useSelector((state) => state.galleryReducer.updateEventResult);
	return [updateEvent, updateEventResult.data, updateEventResult.error, updateEventResult.loading];
};

//Delete from Event
export const useDeleteFromEvent = () => {
	const dispatch = useDispatch();
	const deleteFromEvent = (_id) => {
		dispatch(deleteFromEventAction(_id));
	};
	const deleteFromEventResult = useSelector((state) => state.galleryReducer.deleteFromEventResult);
	return [deleteFromEvent, deleteFromEventResult.data, deleteFromEventResult.error, deleteFromEventResult.loading];
};

// Get List of Awards
export const useGetAwardList = () => {
	const dispatch = useDispatch();
	const getAwardList = (userId) => {
		if (userId) dispatch(getAwardListByUserIdAction(userId));
		else dispatch(getAwardListAction());
	};
	const awardListResult = useSelector((state) => state.galleryReducer.awardListResult);
	return [getAwardList, awardListResult.data, awardListResult.error, awardListResult.loading];
};

// Add New Award
export const useAddAward = () => {
	const dispatch = useDispatch();
	const addNewAward = (year, award) => {
		dispatch(addNewAwardAction(year, award));
	};
	const addAwardResult = useSelector((state) => state.galleryReducer.addAwardResult);
	return [addNewAward, addAwardResult.data, addAwardResult.error, addAwardResult.loading];
};

// Update Award
export const useUpdateAward = () => {
	const dispatch = useDispatch();
	const updateAward = (year, award, awardId) => {
		dispatch(updateAwardAction(year, award, awardId));
	};
	const updateAwardResult = useSelector((state) => state.galleryReducer.updateAwardResult);
	return [updateAward, updateAwardResult.data, updateAwardResult.error, updateAwardResult.loading];
};

// Delete Award
export const useDeleteAward = () => {
	const dispatch = useDispatch();
	const deleteAward = (awardId) => {
		dispatch(deleteAwardAction(awardId));
	};
	const deleteAwardResult = useSelector((state) => state.galleryReducer.deleteAwardResult);
	return [deleteAward, deleteAwardResult.data, deleteAwardResult.error, deleteAwardResult.loading];
};

// Get List of Historical Data
export const useGetHistoricalDataList = () => {
	const dispatch = useDispatch();
	const getHistoricalDataList = (userId) => {
		if (userId) dispatch(getHistoricalDataListByUserIdAction(userId));
		else dispatch(getHistoricalDataListAction());
	};
	const historicalDataListResult = useSelector((state) => state.galleryReducer.historicalDataListResult);
	return [getHistoricalDataList, historicalDataListResult.data, historicalDataListResult.error, historicalDataListResult.loading];
};

// Add New Historical Data
export const useAddHistoricalData = () => {
	const dispatch = useDispatch();
	const addNewHistoricalData = (year, events) => {
		dispatch(addNewHistoricalAction(year, events));
	};
	const addHistoricalDataResult = useSelector((state) => state.galleryReducer.addHistoricalDataResult);
	return [addNewHistoricalData, addHistoricalDataResult.data, addHistoricalDataResult.error, addHistoricalDataResult.loading];
};

// Update Historical Data
export const useUpdateHistoricalData = () => {
	const dispatch = useDispatch();
	const updateHistoricalData = (year, events, historicalId) => {
		dispatch(updateHistoricalDataAction(year, events, historicalId));
	};
	const updateHistoricalDataResult = useSelector((state) => state.galleryReducer.updateHistoricalDataResult);
	return [updateHistoricalData, updateHistoricalDataResult.data, updateHistoricalDataResult.error, updateHistoricalDataResult.loading];
};

// Delete Historical Data
export const useDeleteHistoricalData = () => {
	const dispatch = useDispatch();
	const deleteHistoricalData = (historicalId) => {
		dispatch(deleteHistoricalDataAction(historicalId));
	};
	const deleteHistoricalDataResult = useSelector((state) => state.galleryReducer.deleteHistoricalDataResult);
	return [deleteHistoricalData, deleteHistoricalDataResult.data, deleteHistoricalDataResult.error, deleteHistoricalDataResult.loading];
};
export const useFilterGalleries = () => {
	const dispatch = useDispatch();

	// Filter Galleries
	const get = (PageNum, PageSize, SearchKey, alphabetSearch) => {
		dispatch(filterGalleries(PageNum, PageSize, SearchKey, alphabetSearch));
	};
	// Reducer states
	const result = useSelector((state) => state.galleryReducer.galleryList);

	return [get, result.data, result.error, result.loading];
};

export const useUpdateGalleryAddress = () => {
	const dispatch = useDispatch();

	const update = (body) => {
		dispatch(updateGalleryAddress(body));
	};

	const result = useSelector((state) => state.galleryReducer.updateGalleryAddress);

	return [update, result.data, result.error, result.loading];
};

// ======================= Search For Artists [Gallery registering new artist] ======================= //
export const useSearchForArtists = () => {
	const dispatch = useDispatch();

	const searchArtists = (name) => {
		dispatch(searchArtistsAction(name));
	};

	const { data, error, loading } = useSelector((state) => state.galleryReducer.searchArtists);

	return [searchArtists, data, error, loading];
};
