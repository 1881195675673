import React from "react";
import { MainWrapper, TextSpan, TitleText, IconSpan, ArrowIcon, NewsCardImageWrapper, ShopNowButton } from "./SelectedCollectionsNew.styled";
import { Link } from "react-router-dom";
import assets from "assets";
import { useHistory } from "react-router-dom";

const SelectedCollectionsSearch = ({ data = {}, ...props }) => {
	const { imageGreyPlace, noImageWhite } = assets.images;
	let history = useHistory();

	return (
		<Link
			onClick={() => {
				history.push(`/collections/${data.slugId}`);
			}}
		>
			<MainWrapper {...props}>
				{data.collectionThumbnail ? (
					<NewsCardImageWrapper
						src={data.collectionThumbnail || noImageWhite}
						fallback={data.collectionThumbnailUc || noImageWhite}
						alt={`RtistiQ Art Collection:${data && data.collectionName}${data && data.ownerName}`}
					/>
				) : (
					<NewsCardImageWrapper src={noImageWhite} alt={`RtistiQ Art Collection`} />
				)}
				<TextSpan>
					<TitleText>{data.collectionName}</TitleText>

					<ShopNowButton to={"/collections/" + data.slugId}>SHOP NOW</ShopNowButton>
				</TextSpan>
			</MainWrapper>
		</Link>
	);
};

export default SelectedCollectionsSearch;
