import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class CartManagementService {
    static API = APIUrlConstants.API_URLS;
    static service = new BaseService();

    // Get Cart
    static async getCartItems(Currency) {
        if (Currency) {
            let params = { Currency };
            return this.service.execute(this.API.getCartList, HTTP_METHOD.GET, { params });
        }
        else {
            return this.service.execute(this.API.getCartList, HTTP_METHOD.GET, {});
        }
    }

    // Delete from Cart
    static async deleteItemFromCart(cartId) {
        let params = { cartId };
        return this.service.execute(this.API.deleteCart, HTTP_METHOD.DELETE, { params });
    }

    // Add to Cart
    static async addToCart(body) {
        return this.service.execute(this.API.addToCart, HTTP_METHOD.POST, { body });
    }
}
