import styled from "styled-components";
import { Icon } from "antd";
import Image from "common/Image";
import { Link } from "react-router-dom";

const MainWrapper = styled.div`
    position: relative;
    height: 289px;
    background-color: #0a0909;
    width: ${props => props.width || '289px'};
`;
const NewsCardImageWrapper = styled(Image)`
	height: 100%;
	max-width:289px;
    opacity:0.7;
	object-fit: fill;
`;

const TextSpan = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    width:100%;
    /* max-width: 235px; */
    padding: 0 20px;
    top: 50%;
`;
const IconSpan = styled.span`
    display: flex;
    align-items: center;
    width: 4rem;
    cursor: pointer;
    p {
        color: #ffffff;
        font-size: 10px;
        margin: 0;
    }
    position:relative;
    top:40px;
    font-size:10px;
`;
const TitleText = styled.p`
    /* font-size: 14px;
    font-weight: 600; */
    color: #ffffff;
    margin-bottom: 5px;
    max-height: 55px;
    position: relative;
    /* top: 50px; */
    overflow: hidden;
    width: 100%;
    word-break: normal;
    text-align: center;
    text-transform: uppercase;
font: normal normal 600 22px/26px Cormorant Garamond;
`;

const ShopNowButton = styled(Link)`
	display: flex;
	align-items: center;
	border: 1px solid #ffffff;
	color:#ffffff;
	font-size: 15px;
	font-weight: 800;
	padding: 3px 10px;
    width: 105px;
	/* text-shadow: 0px 2px 5px rgb(0, 0, 0); */
	&:hover{
	color:#ffffff;
	background-color: #000000;
	border: 1px solid #000000;
	}
	.anticon {
		margin-left: 5px;
	}

`;
const ArrowIcon = styled(Icon)`
    margin-left: 5px;
    color: #ffffff;
    width: 25px;
`;
export { MainWrapper, NewsCardImageWrapper, TextSpan, TitleText, IconSpan, ArrowIcon, ShopNowButton };
