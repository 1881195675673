import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chooseUserRole } from "../actions/UserManagementAction";

const useChooseUserRole = () => {
	const userRoleSelect = role => {
		dispatch(chooseUserRole(role));
	};
	const chooseUserRoleResult = useSelector(state => state.userManagement.chooseUserRoleResult);
	const dispatch = useDispatch();
	return [userRoleSelect, chooseUserRoleResult.data, chooseUserRoleResult.error, chooseUserRoleResult.loading];
};
export default useChooseUserRole;
