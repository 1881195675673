import SDK from "ecommerce-sdk/src/index";
const queryString = require("query-string");
const EthCrypto = require("eth-crypto");
const keythereum = require("keythereum");
const web3 = require("web3");
const Compress = require("compress.js");

export class Util {
	// Parse/Stringify the Query String
	static queryStringHandling(query, type) {
		switch (type) {
			case "STR":
				let stringified = queryString.stringify(query, { arrayFormat: "comma", skipEmptyString: true });
				return stringified;

			case "PARSE":
				let parsedObj = queryString.parse(query, { arrayFormat: "comma" });
				return parsedObj;

			default:
				return undefined;
		}
	}

	// Check if it is logged in;
	// If logged in it returns true
	// If not logged in , returns false
	static isUserLoggedIn = async () => {
		const isLoggedIn = await SDK.UserManagement.isLoggedIn();
		return isLoggedIn;
	};

	//Retreive Private Key from Keystore File
	static getPrivateKey(keyStoreFilePassword, keystoreJson) {
		return keythereum.recover(keyStoreFilePassword, keystoreJson);
	}

	static getMeesagehash(message) {
		return EthCrypto.hash.keccak256(message);
	}
	//Verify whether the User actually owns the account
	static verifyEthereumAccountOwnership(privateKey, ownerAccount) {
		try {
			let isOwner = false;
			const message = "RTISTIQ";
			const messageHash = EthCrypto.hash.keccak256(message);

			// @Suraj TODO : RangeError: private key length is invalid

			const signature = EthCrypto.sign(privateKey, messageHash);
			const signerAddress = EthCrypto.recover(signature, messageHash);
			if (signerAddress.toLowerCase() === ownerAccount.toLowerCase()) {
				isOwner = true;
			}
			return isOwner;
		} catch (e) {
			return false;
		}
	}

	static signData(privateKey, data) {
		// let messageHash = EthCrypto.hash.keccak256(data);
		var messageHash = web3.utils.soliditySha3(data);
		let signedMesage = EthCrypto.sign(privateKey.toString("hex"), messageHash);

		return { messageHash: messageHash, signedMessage: signedMesage };
	}

	static signDataForOwnershipTransfer(privateKey, rfid, address) {
		let rfIdStringPadd = web3.utils.rightPad(rfid, 32, " ");
		let messageHash = web3.utils.soliditySha3(rfIdStringPadd, address);

		let signedMesage = EthCrypto.sign(privateKey.toString("hex"), messageHash);
		return { messageHash: messageHash, signedMessage: signedMesage };
	}
	static signDataForOwnershipTransferV2(privateKey, tokenId, address) {
		let messageHash = web3.utils.soliditySha3(tokenId, address);

		let signedMesage = EthCrypto.sign(privateKey.toString("hex"), messageHash);
		return { messageHash: messageHash, signedMessage: signedMesage };
	}

	static verifySignData(ownerAccount, messageHash, signature) {
		console.log("🚀 ~ file: util.js ~ line 77 ~ Util ~ verifySignData ~ signature", signature);
		console.log("🚀 ~ file: util.js ~ line 77 ~ Util ~ verifySignData ~ messageHash", messageHash);
		console.log("🚀 ~ file: util.js ~ line 77 ~ Util ~ verifySignData ~ ownerAccount", ownerAccount);
		const signerAddress = EthCrypto.recover(signature, messageHash);
		if (signerAddress.toLowerCase() === "0x" + ownerAccount) {
			return true;
		}
		return false;
	}
	static async compressImage(file) {
		const compress = new Compress();
		const files = [file];
		let results = await compress
			.compress(files, {
				size: 4, // the max size in MB, defaults to 2MB
				quality: 0.75, // the quality of the image, max is 1,
				maxWidth: 1920, // the max width of the output image, defaults to 1920px
				maxHeight: 1920, // the max height of the output image, defaults to 1920px
				resize: true, // defaults to true, set false if you do not want to resize the image width and height
			})
			.then((data) => data);
		const img1 = results[0];
		const base64str = img1.data;
		const imgExt = img1.ext;
		const image = await Compress.convertBase64ToFile(base64str, imgExt);
		return new File([image], file.name, { lastModified: new Date().getTime(), type: image.type });
	}

	static signDataForApprovalToGallery(privateKey, artistAddress, galleryAddress, isMetamask = false) {
		let messageHash = web3.utils.soliditySha3(artistAddress, galleryAddress);
		if (isMetamask === false) {
			let signedMesage = EthCrypto.sign(privateKey.toString("hex"), messageHash);
			return { messageHash: messageHash, signedMessage: signedMesage };
		}
		else {
			return { messageHash: messageHash };
		}
	}

	static signDataForEscrow(privateKey, productId, isMetamask = false) {
		let messageHash = web3.utils.soliditySha3(productId);
		if (isMetamask === false) {
			let signedMesage = EthCrypto.sign(privateKey.toString("hex"), messageHash);
			return { messageHash: messageHash, signedMessage: signedMesage };
		} else {
			return { messageHash: messageHash }
		}
	}
	static signDataForBurn(privateKey, signerAddress, tokenId, isMetamask = false) {
		let messageHash = web3.utils.soliditySha3(signerAddress, tokenId);
		if (isMetamask === false) {
			let signedMesage = EthCrypto.sign(privateKey.toString("hex"), messageHash);
			return { messageHash: messageHash, signedMessage: signedMesage };
		} else {
			return { messageHash: messageHash }
		}
	}
}
