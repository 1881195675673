import { ShippingAddressService } from "../services/ShippingAddressService";
import * as types from "./ActionsTypes";

// ========================================== Get ShippingAddress ========================================== //
export const getShippingAddress = () => {
  return {
    actionType: types.GET_SHIPPING_ADDRESS_REQUEST,
    callAPI: () => ShippingAddressService.getShippingAddress(),
    stateObject: "shippingAddress"
  };
};

// ========================================== Add ShippingAddress ========================================== //
export const addShippingAddress = shippingAddressData => {
  return {
    actionType: types.ADD_SHIPPING_ADDRESS_REQUEST,
    callAPI: () =>
      ShippingAddressService.addShippingAddress(shippingAddressData),
    stateObject: "addShippingAddress",
    isTransient: true
  };
};
// ========================================== Update ShippingAddress ========================================== //
export const updateShippingAddress = (
  shippingAddressData,
  shippingAddressId
) => {
  return {
    actionType: types.UPDATE_SHIPPING_ADDRESS_REQUEST,
    callAPI: () =>
      ShippingAddressService.updateShippingAddress(
        shippingAddressData,
        shippingAddressId
      ),
    stateObject: "updateShippingAddress",
    isTransient: true
  };
};
// ========================================== Delete ShippingAddress ========================================== //
export const deleteShippingAddress = shippingAddressId => {
  return {
    actionType: types.DELETE_SHIPPING_ADDRESS_REQUEST,
    callAPI: () =>
      ShippingAddressService.deleteShippingAddress(shippingAddressId),
    stateObject: "deleteShippingAddress",
    isTransient: true
  };
};

// ========================================== Set Default ShippingAddress ========================================== //
export const defaultShippingAddressAction = (shippingAddressId) => {
  return {
    actionType: types.SET_DEFAULT_SHIPPING_ADDRESS_REQUEST,
    callAPI: () =>
      ShippingAddressService.setDefaultShippingAddress(shippingAddressId),
    stateObject: "defaultShippingAddress",
    isTransient: true
  };
};

