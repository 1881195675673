// A store holds the whole state tree of your application.
// The only way to change the state inside it is to dispatch an action on it.

import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createReducerManager } from "../reducers/ReducerManager";
import callAPIMiddleware from "../util/callAPIMiddleware";

export function configureStore(initialState) {
  const reducerManager = createReducerManager();
  const store = createStore(
    reducerManager.reduce,
    initialState,
    applyMiddleware(...[thunkMiddleware, callAPIMiddleware])
  );
  store.reducerManager = reducerManager;
  return store;
}
