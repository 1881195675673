import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getOrderHistoryAction,
	getOrderDetailsAction,
	getPlacedOrdersAction,
	updateOrderStatusAction,
	checkoutCartAction,
	cancelOrderAction,
	returnOrderAction,
	orderInvoiceAction,
	getOrderDetailsByIdAction,
	getSignaturePendingOrdersAction,
	getOrderSummaryAction,
	needHelpAction,
	createShipmentAction,
	getWaybillNumberAction,
	getPickupDetailsAction,
} from "../actions/OrderManagementAction";

//----------------Order History List------------------//
export const useOrderHistory = () => {
	const getHistory = () => {
		dispatch(getOrderHistoryAction());
	};
	const orderHistoryList = useSelector((state) => state.orderManagement.orderHistoryList);
	const dispatch = useDispatch();
	return [getHistory, orderHistoryList.data, orderHistoryList.error, orderHistoryList.loading];
};

//----------------Order Details------------------//
export const useOrderDetails = () => {
	const getOrder = (orderId) => {
		dispatch(getOrderDetailsAction(orderId));
	};
	const orderDetailsData = useSelector((state) => state.orderManagement.orderDetailsData);
	const dispatch = useDispatch();
	return [getOrder, orderDetailsData.data, orderDetailsData.error, orderDetailsData.loading];
};

//----------------Seller Orders List------------------//
export const useSellerOrderList = () => {
	const getSellerOrderList = (pageNum, pageSize, status) => {
		dispatch(getPlacedOrdersAction(pageNum, pageSize, status));
	};
	const orderListData = useSelector((state) => state.orderManagement.orderListData);
	const dispatch = useDispatch();
	return [getSellerOrderList, orderListData.data, orderListData.error, orderListData.loading];
};

//----------------Update Order Status------------------//
export const useUpdateOrderStatus = () => {
	const updateOrderStatus = (orderStatus, UpdateText, orderDetailsId) => {
		dispatch(updateOrderStatusAction(orderStatus, UpdateText, orderDetailsId));
	};
	const updateOrderData = useSelector((state) => state.orderManagement.updateOrderData);
	const dispatch = useDispatch();
	return [updateOrderStatus, updateOrderData.data, updateOrderData.error, updateOrderData.loading];
};

//----------------CheckOut Cart------------------//
export const useCheckOutCart = () => {
	const checkOutCart = (shippingAddressData, billingData, currency, blockchainAddress, couponCode) => {
		dispatch(checkoutCartAction(shippingAddressData, billingData, currency, blockchainAddress, couponCode));
	};
	const checkOutCartData = useSelector((state) => state.orderManagement.checkOutCartData);
	const dispatch = useDispatch();
	return [checkOutCart, checkOutCartData.data, checkOutCartData.error, checkOutCartData.loading];
};

//----------------Cancel Order------------------//
export const useCancelOrder = () => {
	const cancelOrder = (orderDetailsId, reason) => {
		dispatch(cancelOrderAction(orderDetailsId, reason));
	};
	const cancelOrderData = useSelector((state) => state.orderManagement.cancelOrderData);
	const dispatch = useDispatch();
	return [cancelOrder, cancelOrderData.data, cancelOrderData.error, cancelOrderData.loading];
};

//----------------Return Order------------------//
export const useReturnOrder = () => {
	const returnOrder = (orderDetailsId, reason, addrssId, picupTime) => {
		dispatch(returnOrderAction(orderDetailsId, reason, addrssId, picupTime));
	};
	const returnOrderData = useSelector((state) => state.orderManagement.returnOrderData);
	const dispatch = useDispatch();
	return [returnOrder, returnOrderData.data, returnOrderData.error, returnOrderData.loading];
};

//----------------Order Invoice------------------//
export const useOrderInvoice = () => {
	const dispatch = useDispatch();
	const orderInvoice = (orderDetailsId) => {
		dispatch(orderInvoiceAction(orderDetailsId));
	};
	const { data, error } = useSelector((state) => state.orderManagement.invoiceData);
	return [orderInvoice, data, error];
};

// ------------------ Get order details by Id [For Payment Page] ------------------ //
export const useGetOrderDetailsById = () => {
	const dispatch = useDispatch();
	const getOrderDetailsById = (orderId) => {
		dispatch(getOrderDetailsByIdAction(orderId));
	};
	const { data, error, loading } = useSelector((state) => state.orderManagement.getOrderDetailsById);
	return [getOrderDetailsById, data, error, loading];
};

// ------------------ Get all signature pending orders ------------------ //
export const useGetSignaturePendingOrders = () => {
	const dispatch = useDispatch();
	const getSignaturePendingOrders = (pageNum, pageSize) => {
		dispatch(getSignaturePendingOrdersAction(pageNum, pageSize));
	};
	const { data, error, loading } = useSelector((state) => state.orderManagement.getSignaturePendingOrders);
	return [getSignaturePendingOrders, data, error, loading];
};

//---------------- Order Summary------------------//
export const useGetOrderSummary = () => {
	const getOrderSummary = (shippingAddressId, billingAddressId, currency, couponCode) => {
		dispatch(getOrderSummaryAction(shippingAddressId, billingAddressId, currency, couponCode));
	};
	const getOrderSummaryData = useSelector((state) => state.orderManagement.getOrderSummaryData);
	const dispatch = useDispatch();
	return [getOrderSummary, getOrderSummaryData.data, getOrderSummaryData.error, getOrderSummaryData.loading];
};

//---------------- Need Help------------------//
export const useAddNeedHelp = () => {
	const addNeedHelp = (data) => {
		dispatch(needHelpAction(data));
	};
	const needHelpData = useSelector((state) => state.orderManagement.needHelpData);
	const dispatch = useDispatch();
	return [addNeedHelp, needHelpData.data, needHelpData.error, needHelpData.loading];
};

//---------------- Create Shipment------------------//
export const useCreateShipment = () => {
	const createShipment = (data) => {
		dispatch(createShipmentAction(data));
	};
	const createShipmentData = useSelector((state) => state.orderManagement.createShipmentData);
	const dispatch = useDispatch();
	return [createShipment, createShipmentData.data, createShipmentData.error, createShipmentData.loading];
};

//----------------Download Way Bill Number------------------//
export const useWayBillDownload = () => {
	const dispatch = useDispatch();
	const getWaybillNumber = (orderDetailsId) => {
		dispatch(getWaybillNumberAction(orderDetailsId));
	};
	const { data, error } = useSelector((state) => state.orderManagement.wayBillData);
	return [getWaybillNumber, data, error];
};

//----------------Pickup Dtails------------------//
export const useGetPickupDetail = () => {
	const dispatch = useDispatch();
	const getPickupDetail = (orderDetailsId) => {
		dispatch(getPickupDetailsAction(orderDetailsId));
	};
	const { data, error, loading } = useSelector((state) => state.orderManagement.pickUpData);
	return [getPickupDetail, data, error, loading];
};
