import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class ShippingAddressService {
  static API = APIUrlConstants.API_URLS;
  static service = new BaseService();
  // Get ShippingAddress
  static async getShippingAddress() {
    return this.service.execute(
      this.API.getAddress,
      HTTP_METHOD.GET,
      {}
    );
  }
  // Add ShippingAddress
  static async addShippingAddress(addressToBeAdded) {
    let body = { ...addressToBeAdded };
    return this.service.execute(this.API.addAddress, HTTP_METHOD.POST, {
      body
    });
  }
  // Update ShippingAddress
  static async updateShippingAddress(addressToBeUpdated, addressId) {
    let urlDirectParams = `/${addressId}`;
    const body = { ...addressToBeUpdated };

    return this.service.execute(this.API.updateAddress, HTTP_METHOD.PUT, {
      body,
      urlDirectParams
    });
  }
  // Delete ShippingAddress
  static async deleteShippingAddress(addressId) {
    let params = { addressId };
    return this.service.execute(this.API.deleteAddress, HTTP_METHOD.DELETE, {
      params
    });
  }

}
