import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
import Util from "../util/Util";

export class ProductManagementService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Get Product Details
	static async getProductDetails(productId) {
		let params = { productId };
		return this.service.execute(this.API.getProductDetail, HTTP_METHOD.GET, {
			params,
		});
	}

	// Get Product by Slug
	static async getProductBySlug(SlugId, StockId, IsAdminTempFix) {
		let params = { SlugId, StockId, IsAdminTempFix };
		return this.service.execute(this.API.getProductBySlug, HTTP_METHOD.GET, {
			params,
		});
	}
	// Get Sold Artwork Details By Slug
	static async getSoldArtWorkDetails(SlugId, StockId) {
		let params = { SlugId, StockId };
		return this.service.execute(this.API.getSoldArtWorkDetails, HTTP_METHOD.GET, {
			params,
		});
	}
	// Get product ownership history
	static async getProductOwnershipHistory(stockId) {
		let params = { stockId };
		return this.service.execute(this.API.getProductOwnershipHistory, HTTP_METHOD.GET, {
			params,
		});
	}
	// Get Product List
	static async getProductList(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getProductList, HTTP_METHOD.GET, {
			params,
		});
	}

	// Disclose Price Request
	static async disclosePrice(StockId) {
		let params = { StockId };
		return this.service.execute(this.API.disclosePrice, HTTP_METHOD.POST, {
			params,
		});
	}

	// Add to WishList
	static async addToWishlist(productId) {
		const body = { ProductId: productId };
		return this.service.execute(this.API.addToWishlist, HTTP_METHOD.POST, {
			body,
		});
	}

	// Get WishList
	static async getWishlist(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getWishlist, HTTP_METHOD.GET, {
			params,
		});
	}

	// Delete from WishList
	static async deleteFromWishlist(productId) {
		let params = { productId };
		return this.service.execute(this.API.deleteFromWishlist, HTTP_METHOD.DELETE, {
			params,
		});
	}

	// Quote Rate
	static async quoteRate(stockId, amount, message, currency) {
		const body = {
			StockId: stockId,
			Amount: amount,
			Message: message,
			Currency: currency,
		};
		return this.service.execute(this.API.quoteRate, HTTP_METHOD.POST, {
			body,
		});
	}

	// Like Product
	static async likeProduct(productId) {
		const body = { ParentId: productId };
		return this.service.execute(this.API.likeProduct, HTTP_METHOD.POST, {
			body,
		});
	}

	// Dislike Product
	static async dislikeProduct(ParentId) {
		let params = { ParentId };
		return this.service.execute(this.API.dislikeProduct, HTTP_METHOD.DELETE, {
			params,
		});
	}

	// Search Artworks
	static async searchArtworks(body) {
		return this.service.execute(this.API.searchArtwork, HTTP_METHOD.POST, {
			body,
		});
	}

	// Search Artworks without Authorization
	static async searchArtworksList(PageNum, PageSize, OwnerId, ArtistId) {
		if (PageNum === null && PageSize === null) {
			const body = OwnerId ? { OwnerId } : { ArtistId };
			return this.service.execute(this.API.searchArtwork, HTTP_METHOD.POST, {
				body,
				isAuthorizedAPI: false,
			});
		} else {
			const body = OwnerId ? { PageNum, PageSize, OwnerId } : { PageNum, PageSize, ArtistId };
			return this.service.execute(this.API.searchArtwork, HTTP_METHOD.POST, {
				body,
				isAuthorizedAPI: false,
			});
		}
	}
	// Get Seller Product List
	static async getSellerProducts(PageNum, PageSize) {
		if (PageNum === null && PageSize === null) {
			return this.service.execute(this.API.getSellerProducts, HTTP_METHOD.POST, {});
		} else {
			const body = { PageNum, PageSize };
			return this.service.execute(this.API.getSellerProducts, HTTP_METHOD.POST, {
				body,
			});
		}
	}

	//Get My Sold Products
	static async getMySoldProducts(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getMySoldProducts, HTTP_METHOD.GET, {
			params,
		});
	}

	//Get a User's Sold Products
	static async getSoldProducts(pageNum, pageSize, userId) {
		let params = { pageNum, pageSize, userId };
		return this.service.execute(this.API.getSoldProducts, HTTP_METHOD.GET, {
			params,
		});
	}

	//Get Seller Quotes
	static async getSellerQuotes(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getSellerQuotes, HTTP_METHOD.GET, {
			params,
		});
	}

	//Get My Quotes
	static async getMyQuotes(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getMyQuotes, HTTP_METHOD.GET, {
			params,
		});
	}

	// Delete My Quotes
	static async deleteMyQuotes(productId) {
		let urlDirectParams = `/${productId}`;
		return this.service.execute(this.API.deleteMyQuotes, HTTP_METHOD.DELETE, {
			urlDirectParams,
		});
	}

	//Update Seller Quote Status
	static async updateSellerQuoteStatus(quoteId, Status) {
		let urlDirectParams = `/${quoteId}`;
		let params = { Status };
		return this.service.execute(this.API.updateSellerQuoteStatus, HTTP_METHOD.PUT, {
			urlDirectParams,
			params,
		});
	}
	//Get Price Request
	static async getSellerPriceRequest(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getSellerPriceRequest, HTTP_METHOD.GET, {
			params,
		});
	}
	//Update Seller Price Status
	static async updateSellerPriceStatus(priceId, Status) {
		let urlDirectParams = `/${priceId}`;
		let params = { Status };
		return this.service.execute(this.API.updateSellerPriceStatus, HTTP_METHOD.PUT, {
			urlDirectParams,
			params,
		});
	}

	// Create new artist
	static async getSignaturePendingArtworks(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getSignaturePendingArtworks, HTTP_METHOD.GET, { params });
	}

	// RFID Check
	static async doCheckRFID(rfids) {
		let urlDirectParams = "?" + Util.arrayToStringList(rfids, "RFID");
		return this.service.execute(this.API.doCheckRFID, HTTP_METHOD.GET, { urlDirectParams });
	}

	// Update Product Status
	static async updateProductStatus(stockId, productStatus) {
		const params = { stockId: stockId, productStatus: productStatus };
		return this.service.execute(this.API.updateProductStatus, HTTP_METHOD.PUT, { params });
	}

	//Check Add Product Eligibility
	static async checkAddProductEligibility(isPhysical) {
		const params = { isPhysical };
		return this.service.execute(this.API.checkAddProductEligibility, HTTP_METHOD.GET, { params });
	}

	//getGalleryAddedProducts
	static async getGalleryAddedProducts(pageNum, pageSize) {
		const params = { pageNum, pageSize };
		return this.service.execute(this.API.getGalleryAddedProducts, HTTP_METHOD.GET, { params });
	}

	//Get Artist Products
	static async getArtistProducts(pageNum, pageSize, artistId) {
		const params = { pageNum, pageSize, artistId };
		return this.service.execute(this.API.getArtistProducts, HTTP_METHOD.GET, { params });
	}

	//Get gallery view
	static async getGalleryView(pageNum, pageSize, galleryId) {
		const params = { pageNum, pageSize, galleryId };
		return this.service.execute(this.API.getGalleryView, HTTP_METHOD.GET, { params });
	}

	// Get Inventory
	static async getInventory(pageNum, pageSize) {
		const params = { pageNum, pageSize };
		return this.service.execute(this.API.getInventory, HTTP_METHOD.GET, { params });
	}

	// Get Owned Artworks
	static async getOwnedArtworks(pageNum, pageSize) {
		const params = { pageNum, pageSize };
		return this.service.execute(this.API.getOwnedArtworks, HTTP_METHOD.GET, { params });
	}

	// Bypass NFC
	static async bypassNfc(ProductId) {
		const params = { ProductId };
		return this.service.execute(this.API.bypassNfc, HTTP_METHOD.GET, { params });
	}

	//update price
	static async updatePrice(ProductId, SellingPrice) {
		const params = { ProductId, SellingPrice };
		return this.service.execute(this.API.updatePrice, HTTP_METHOD.PUT, { params });
	}
}
