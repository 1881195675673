import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	currencies: {},
	convertCurrency: {},
	stripePayment: {},
	stripePaymentIntent: {},
	stripePaymentConfirmation: {},
	getPaypalIDForOrder: {},
	paypalPayment: {},
	paypalFailurePayment: {}
};

const PaymentReducer = createApiReducer(initialState, [
	types.GET_CURRENCIES_REQUEST,
	types.CONVERT_CURRENCY_REQUEST,
	types.STRIPE_PAYMENT_REQUEST,
	types.GET_PAYPAL_ID_REQUEST,
	types.PAYPAL_PAYMENT_REQUEST,
	types.PAYPAL_PAYMENT_FAILURE_REQUEST,
	types.STRIPE_PAYMENT_INTENT,
	types.STRIPE_PAYMENT_CONFIRMATION

]);

export default PaymentReducer;
