import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	orderHistoryList: {},
	orderDetailsData: {},
	orderListData: {},
	updateOrderData: {},
	checkOutCartData: {},
	cancelOrderData: {},
	returnOrderData: {},
	invoiceData: {},
	getOrderDetailsById: {},
	getSignaturePendingOrders: {},
	getOrderSummaryData: {},
	needHelpData: {},
	createShipmentData: {},
	wayBillData: {},
	pickUpData: {}
};

const OrderManagementReducer = createApiReducer(initialState, [
	types.GET_ORDER_HISTORY_REQUEST,
	types.GET_ORDER_DETAILS_REQUEST,
	types.GET_SELLER_ORDER_LIST_REQUEST,
	types.UPDATE_ORDER_STATUS_REQUEST,
	types.CHECK_OUT_CART_REQUEST,
	types.CANCEL_ORDER_REQUEST,
	types.RETURN_ORDER_REQUEST,
	types.ORDER_INVOICE_REQUEST,
	types.GET_ORDER_DETAILS_BY_ID,
	types.GET_SIGNATURE_PENDING_ORDERS,
	types.GET_ORDER_SUMMARY,
	types.NEED_HELP_REQUEST,
	types.CREATE_SHIPMENT_REQUEST,
	types.WAY_BILL_REQUEST,
	types.PICKUP_DETAILS_REQUEST
]);

export default OrderManagementReducer;
