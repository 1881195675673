import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import assets from "assets";
import {
	ArtworkCardWrapper,
	ArtworkCardImageWrapper,
	ArtworkCardImagePlaceholder,
	ArtworkCardImage,
	ArtworkCardActions,
	ArtwordCardTitle,
	ArtworkCardFooter,
	ArtworkCardArtist,
	ArtworkCardPrice,
	FollowButton,
	ArtworkCardFooterPrice,
	ArtwordCardDiscount,
	ArtworkCardOffer,
	ProfileIcon,
	FollowButtonWrapper,
} from "./LatestArtWork.styled";
import { Icon, Tooltip } from "antd";
import ConfirmPopup from "common/ConfirmPopup";
import { userManagementHooks } from "ecommerce-sdk/src/index";
import SDK from "ecommerce-sdk/src/index";
import { useDispatch } from "react-redux";
import useModal from "utils/useModal";
import ModalTypes from "utils/ModalTypes";
import { ceil } from "lodash";
import { useTranslation } from "react-i18next";

/**
 * NOTES
 * @param data Object Each data item to display
 * @param containerWidth Width of title / To set title overflow
 * @param margin String Margin for each card [By default 0]
 * @param type String To show which actions to show [By default like/favourite/cart]
 * @param hideFollowButton Boolean To show follow button for artists [By default shows follow button on hover]
 * @param showOffer Boolean To show/hide offer tag [By default false]
 * @param showAction Boolean To show/hide action icons [By default true]
 * @param sold Sold/Not
 * @function onClickEditButton Click to the edit button of action
 * @function onClickDeleteButton Click to the delete button of action
 * @
 */
const LatestArtWork = ({ history, data = {}, onAction = () => {}, containerWidth, ...props }) => {
	const { useGetUserInfo } = userManagementHooks;
	const [, userStates] = useGetUserInfo();
	const { t } = useTranslation();

	const { margin, type, hideFollowButton = false, showOffer = false, showAction = true, sold = false } = props;
	// Functions
	const { onClickEditButton, onClickDeleteButton } = props;
	const { imageGreyPlace, like, likeActiveBlue, heart, heartActiveRed, artistIcon, editIcon, signIcon, deleteIcon } = assets.images;

	// Values used from data prop
	const {
		artistName,
		artistId,
		displayPicture,
		displayPictureUc,
		isLiked,
		isFavorite,
		isFollowing,
		oneLineDescription,
		productName,
		sellingPrice,
		price,
		currency,
		discountPercentage,
		slugId,
		yearofCreation,
		medium,
		materialsUsed,
		categoryName,
		dimensions,
		stockId,
	} = data;

	const discountValue = +discountPercentage && ceil(+discountPercentage) > 1 ? ceil(discountPercentage) : null;

	//login check
	const [, , showModal] = useModal();
	const dispatch = useDispatch();
	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		const checkLoggedIn = async () => {
			const isLoggedInResult = await SDK.UserManagement.isLoggedIn();
			setLoggedIn(isLoggedInResult);
		};
		checkLoggedIn();
	}, []);

	// TODO : Different currencies
	// TODO : Get image placeholder for no image
	// loading above count

	// ================= LIKE ================= //
	const onClickLikeButton = async () => {
		loggedIn ? onAction("like", data) : dispatch(showModal(ModalTypes.login));
	};

	// ================= FAVOURITE ================= //
	const onClickFavouriteButton = async () => {
		loggedIn ? onAction("favourite", data) : dispatch(showModal(ModalTypes.login));
	};

	// ================= FOLLOW ================= //
	const onClickFollowButton = async () => {
		loggedIn ? onAction("follow", data) : dispatch(showModal(ModalTypes.login));
	};

	// ================= On click artwork ================= //
	const onClickArtwork = () => {
		sold === true
			? history.push(`/art/${medium && medium.slugId}/${slugId}/${stockId}`)
			: history.push(`/art/${medium && medium.slugId}/${slugId}`);
	};

	// ================= ACTIONS ================= //
	const artworkCardActionsSwitcher = () => {
		switch (type) {
			// EDIT DELETE
			case "edit-delete":
				return (
					<ArtworkCardActions>
						<Tooltip title={"Edit"}>
							<img src={editIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data)} />
						</Tooltip>
						<Tooltip title={t("Delete")}>
							<ConfirmPopup
								text={t("Confirm Deletion") + "?"}
								image={assets.images.deleteIcon}
								buttonText={t("Delete")}
								data={data}
								confirmAction={() => onClickDeleteButton(data)}
							>
								<img src={deleteIcon} alt="Delete" />
							</ConfirmPopup>
						</Tooltip>
					</ArtworkCardActions>
				);
			case "delete":
				return (
					<ArtworkCardActions>
						<Tooltip title={t("Delete")}>
							<ConfirmPopup
								text={t("Confirm Deletion") + "?"}
								image={assets.images.deleteIcon}
								buttonText={t("Delete")}
								confirmAction={() => onClickDeleteButton(data)}
							>
								<img src={deleteIcon} alt="Delete" />
							</ConfirmPopup>
						</Tooltip>
					</ArtworkCardActions>
				);
			case "edit":
				return (
					<ArtworkCardActions>
						<Tooltip title={"Edit"}>
							<img src={editIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data)} />
						</Tooltip>
					</ArtworkCardActions>
				);
			case "sign":
				return (
					<ArtworkCardActions>
						<Tooltip title={t("Update Signature")}>
							<img src={signIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data)} />
						</Tooltip>
					</ArtworkCardActions>
				);
			case "orderSign":
				return (
					<ArtworkCardActions>
						<Tooltip title={t("Update Signature")}>
							<img src={signIcon} alt="✎Edit" onClick={(e) => onClickEditButton(data, props.orderData)} />
						</Tooltip>
					</ArtworkCardActions>
				);

			// LIKE, FAVOURITE, CART actions
			default:
				return (
					<ArtworkCardActions>
						{/* <Tooltip title={isLiked ? "Dislike artwork" : "Like Artwork"}> */}
						<img src={loggedIn && isLiked ? likeActiveBlue : like} alt="🖒Like" onClick={(e) => onClickLikeButton()} />
						{/* </Tooltip> */}
						{/* <Tooltip title={isFavorite ? "Remove from favourites" : "Mark as Favourite"}> */}
						<img src={loggedIn && isFavorite ? heartActiveRed : heart} alt="❤Favorite" onClick={(e) => onClickFavouriteButton()} />
						{/* </Tooltip> */}
					</ArtworkCardActions>
				);
		}
	};

	// To check if the artwork card is of same user // To hide follow button
	const checkIfThisUserProfile = () => {
		if (artistId && !hideFollowButton) {
			const id = (userStates && userStates.userId) || "";
			if (artistId === id) return false;
			else return true;
		} else return true;
	};

	return (
		<ArtworkCardWrapper margin={margin} hideNameOnHover={artistId && !hideFollowButton && checkIfThisUserProfile() ? true : false}>
			<ArtworkCardImageWrapper>
				{/* Artwork Image */}
				{displayPicture ? (
					<ArtworkCardImage
						shouldAddExtraWrap
						extraWrapClassName="artwork-card-empty-image"
						onClick={(e) => onClickArtwork(e)}
						src={displayPicture || imageGreyPlace}
						fallback={displayPictureUc || imageGreyPlace}
						alt={`${categoryName}:${artistName}${productName}${yearofCreation}${medium}${materialsUsed}`}
					/>
				) : (
					<ArtworkCardImagePlaceholder>
						<img src={imageGreyPlace} onClick={(e) => onClickArtwork(e)} alt="" />
					</ArtworkCardImagePlaceholder>
				)}

				{/* Artwork Offer */}
				{/* {showOffer && discountPercentage && parseFloat(discountPercentage) > 1 ? (
					<ArtworkCardOffer>{parseInt(discountPercentage)}%</ArtworkCardOffer>
				) : null} */}
				{/* {showOffer && discountValue && <ArtworkCardOffer>{discountValue}%</ArtworkCardOffer>} */}

				{/* Action Buttons */}
				{showAction && artworkCardActionsSwitcher()}
			</ArtworkCardImageWrapper>
			{/* <ArtworkCardFooter>
				<ArtwordCardTitle customWidth={containerWidth}>{productName || oneLineDescription || "Artwork"}</ArtwordCardTitle>
				<ArtworkCardPrice>
					{(sellingPrice || price) && `${currency} ${sellingPrice.toLocaleString() || price.toLocaleString()}`}
				</ArtworkCardPrice>
			</ArtworkCardFooter> */}

			<ArtworkCardFooter>
				<ArtwordCardTitle customWidth={containerWidth}>
					{`${productName}  (${Math.round(dimensions.width)} x ${Math.round(dimensions.height)} In)` || oneLineDescription || "Artwork"}
				</ArtwordCardTitle>
			</ArtworkCardFooter>

			<ArtworkCardFooterPrice>
				<ArtworkCardPrice>
					{/* TODO : check isHidePrice/ disclose price each to show price */}
					{(sellingPrice || price) && `${currency} ${sellingPrice.toLocaleString() || price.toLocaleString()}`}
				</ArtworkCardPrice>

				{showOffer && discountValue && <ArtwordCardDiscount>{discountValue}% off</ArtwordCardDiscount>}
			</ArtworkCardFooterPrice>

			{/* Footer */}
			<ArtworkCardFooter>
				<ArtworkCardArtist>
					{/* <ProfileIcon src={artistIcon} alt="" /> */}
					<p>{artistName || "Unknown"}</p>
					{artistId && !hideFollowButton && checkIfThisUserProfile() && (
						<FollowButton onClick={() => onClickFollowButton()}>{isFollowing ? t("Unfollow") : t("Follow")}</FollowButton>
					)}
				</ArtworkCardArtist>
			</ArtworkCardFooter>
		</ArtworkCardWrapper>
	);
};

export default withRouter(LatestArtWork);
