import React, { useEffect, useState, useRef } from "react";
import { isEmpty, includes } from "lodash";
import { Empty, Skeleton, Alert } from "antd";
import MainContainer from "common/MainContainer";
import {
	CartHeader,
	CartWrapper,
	CartHeadRow,
	ProductCol,
	PriceCol,
	EachCartItemDetailsWrapper,
	StyledText,
	CheckoutWrapper,
	CartFooterWrapper,
	EndRow,
	EndWrapper,
	EndRowWrapper,
	PriceWrapper,
	CheckBoxDiv,
	MessageDiv,
	FormLabel,
} from "./CheckOut.styled";
import SDK, { cartHooks, shippingAddressHooks, orderHooks, blockchainHooks, paymentHooks } from "ecommerce-sdk/src/index";
import CheckOutItem from "./CheckOutItem";
import { Helmet } from "react-helmet";
import StyledButton from "common/StyledButton";
import { useHistory } from "react-router-dom";
import { isArray } from "lodash";
import { MandatoryText, StyledAntCheckbox } from "styled/common.styled";
import { useTranslation } from "react-i18next";
import assets from "assets";
import { loadStripe } from "@stripe/stripe-js";
import { PaypalSupprotCurrency, secretKeys } from "utils/constants";
import { authorizePost, unAuthorizedGet } from "utils/apiConfig";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import Paypal from "../Payment/Paypal/Paypal";
import toast from "common/Toast";
import { Util } from "utils/util";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";
import NewCheckoutForm from "../Payment/NewPaymentStrip/NewCheckOutForms";
import { Elements } from "@stripe/react-stripe-js";
import CardSection from "./NewPaymentStrip/CardSection";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import "../Payment/NewPaymentStrip/CardSectionStyles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CheckOut.css";
import "./CheckOut.style.scss";
import "../Cart/Cart.styles.scss";
import PayNow from "./PayNow/PayNow";
import { MoeTrackEvent } from "utils/moEngageHooks";
let stripePromise;

const RePayment = ({ match }) => {
	const history = useHistory();
	const { t } = useTranslation();
	const { useGetCart } = cartHooks;
	const { useGetBlockchainAccount } = blockchainHooks;
	const { useGetOrderSummary, useGetOrderDetailsById, useCheckOutCart } = orderHooks;
	const { useGetShippingAddress, useUpdateShippingAddress } = shippingAddressHooks;
	const { usePaypalPaymentForOrder, useStripePaymentForOrder, useStripePaymentIntent, useStripePaymentConfirmation } = paymentHooks;

	const [stripePaymentForOrder, stripePaymentSuccess, stripePaymentError, stripePaymentLoading] = useStripePaymentForOrder();
	const [paypalPaymentForOrder, paypalPaymentSuccess, paypalPaymentError, paypalPaymentLoading] = usePaypalPaymentForOrder();
	const [stripePaymentIntentForOrder, stripePaymentIntentSuccess, stripePaymentIntentError, stripePaymentIntentLoading] = useStripePaymentIntent();
	const [
		stripePaymentOrderConfirmation,
		stripePaymentOrderConfirmationSuccess,
		stripePaymentOrderConfirmationError,
		stripePaymentOrderConfirmationLoading,
	] = useStripePaymentConfirmation();
	const [getOrderSummary, orderSummaryData, orderSummeryError, orderSummaryLoading] = useGetOrderSummary();
	const [updateAddress, updatedShippingAddressData, updatedShippingAddressError, updating] = useUpdateShippingAddress();
	const [getOrderDetails, getOrderDetailsSuccess, , getOrderDetailsLoading] = useGetOrderDetailsById();

	const [getAddress, addressData, , loading] = useGetShippingAddress();
	const [defaultDeliveryAddress, setDefaultDeliveryAddress] = useState(null);
	const [defaultBillingAddress, setDefaultBillingAddress] = useState(null);
	const [billingAddressId, setBillingAddressId] = useState(null);
	const [shippingAddressId, setShippingAddressId] = useState(null);
	const [cryptoWalletUrl, setCryptoWalletUrl] = useState("");
	const [isBlockChainVerifyed, setIsBlockChainVerifyed] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
	const [fetchingStripeToken, setFetchingStripeToken] = useState(false);
	const [orderId, setOrderId] = useState(null);
	const [cryptoLoading, setCryptoLoading] = useState(false);
	const [couponCode, setCouponCode] = useState(null);
	const [paypalReturnId, setPaypalReturnId] = useState("");
	const [isGetOrderByIdCalled, setIsGetOrderByIdCalled] = useState(false);

	const fileSelect = useRef(null);
	const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
	const getStripe = () => {
		if (!stripePromise) {
			stripePromise = loadStripe(secretKeys.stripeKey);
		}
		return stripePromise;
	}
	useEffect(() => {
		if (match.params.orderId) {
			setOrderId(match.params.orderId);
			getOrderDetails(match.params.orderId);
		}
	}, [match.params.orderId]);
	// Get cart items on page load
	useEffect(() => {
		console.log("🚀 ~ file: RePayment.js ~ line 109 ~ RePayment ~ getOrderDetailsSuccess", getOrderDetailsSuccess);
		if (getOrderDetailsSuccess) {
			if (getOrderDetailsSuccess && getOrderDetailsSuccess.couponCode !== null) {
				setCouponCode(getOrderDetailsSuccess.couponCode);
			}
			setDefaultBillingAddress(getOrderDetailsSuccess.billingAddress);
			setDefaultDeliveryAddress(getOrderDetailsSuccess.shippingAddress);
		}
	}, [getOrderDetailsSuccess]);

	useEffect(() => {
		// errorToast(orderSummeryError, "checkoutError");
		if (orderSummaryData) {
			if (orderSummaryData.couponStatus === 1 || orderSummaryData.couponStatus === 3) {
				localStorage.setItem("appliedCouponCode", couponCode);
			}
		}
	}, [orderSummaryData, orderSummeryError]);

	//Order Sucess
	useEffect(() => {
		if (getOrderDetailsSuccess && getOrderDetailsSuccess.orderStatus && isGetOrderByIdCalled) {
			if (getOrderDetailsSuccess.orderStatus === 1 || getOrderDetailsSuccess.orderStatus === 2 || getOrderDetailsSuccess.orderStatus === 3) {
				history.push(`/order-confirm/${orderId}`);
				// history.push(`/order-confirm/${orderId}`);
			}
			//  else if (getOrderDetailsSuccess.orderStatus === 2) {
			// 	// alert("fail");
			// 	history.push(`/order-confirm/${orderId}`);
			// } else if (getOrderDetailsSuccess.orderStatus === 3) {
			// 	// alert("sucess");
			// 	history.push(`/order-confirm/${orderId}`);
			// }
		}
	}, [getOrderDetailsSuccess]);

	useEffect(() => {
		if (stripePaymentOrderConfirmationSuccess || stripePaymentOrderConfirmationError || paypalPaymentSuccess || paypalPaymentError) {
			setIsGetOrderByIdCalled(true);
		}

		if (stripePaymentOrderConfirmationSuccess) {
			getOrderDetails(orderId);
		} else if (stripePaymentOrderConfirmationError) {
			getOrderDetails(orderId);
		} else if (paypalPaymentSuccess) {
			setLoading(false);
			getOrderDetails(orderId);
		} else if (paypalPaymentError) {
			getOrderDetails(orderId);
		}
	}, [stripePaymentOrderConfirmationSuccess, stripePaymentOrderConfirmationError, paypalPaymentSuccess, paypalPaymentError]);

	let totalPrice = 0;
	let currency = "";
	let shippingCharge = 0;

	// Checkbox
	const handleCheckbox = () => (e) => {
		if (e.target.checked) {
			setDefaultBillingAddress(defaultDeliveryAddress);
			setBillingAddressId(shippingAddressId);
			let addressData = {
				Name: defaultDeliveryAddress.name,
				AddressLine1: defaultDeliveryAddress.addressLine1,
				AddressLine2: defaultDeliveryAddress.addressLine2,
				City: defaultDeliveryAddress.city,
				State: defaultDeliveryAddress.state,
				Country: defaultDeliveryAddress.country,
				PostalCode: defaultDeliveryAddress.postalCode,
				PhoneNumber: defaultDeliveryAddress.phoneNumber,
				AlternatePhoneNumber: defaultDeliveryAddress.alternatePhoneNumber,
				AddressType: defaultDeliveryAddress.addresstype,
				IsDefault: defaultDeliveryAddress.isDefault,
				IsBillingAddress: true,
				IsPickupAddress: defaultDeliveryAddress.isPickupAddress,
				IsGalleryAddress: defaultDeliveryAddress.isGalleryAddress,
			};
			updateAddress(addressData, shippingAddressId);
		} else {
			setDefaultBillingAddress(null);
			setBillingAddressId(null);
			let addressData = {
				Name: defaultDeliveryAddress.name,
				AddressLine1: defaultDeliveryAddress.addressLine1,
				AddressLine2: defaultDeliveryAddress.addressLine2,
				City: defaultDeliveryAddress.city,
				State: defaultDeliveryAddress.state,
				Country: defaultDeliveryAddress.country,
				PostalCode: defaultDeliveryAddress.postalCode,
				PhoneNumber: defaultDeliveryAddress.phoneNumber,
				AlternatePhoneNumber: defaultDeliveryAddress.alternatePhoneNumber,
				AddressType: defaultDeliveryAddress.addresstype,
				IsDefault: defaultDeliveryAddress.isDefault,
				IsBillingAddress: false,
				IsPickupAddress: defaultDeliveryAddress.isPickupAddress,
				IsGalleryAddress: defaultDeliveryAddress.isGalleryAddress,
			};
			updateAddress(addressData, shippingAddressId);
		}
	};

	//Checkout
	const onClickCheckOut = () => {
		if (selectedPaymentMode !== null) {
			if (selectedPaymentMode === "crypto") {
				setCryptoLoading(true);
				if (orderId !== null) {
					excuteCryptoPayment(orderId);
				} else {
				}
			}
		}
	};

	//Crypto Payment
	const excuteCryptoPayment = async (ordId) => {
		let token = await SDK.UserManagement.getAuthToken();
		var url = `OrderManagement/api/Payment/TripleAPayment?orderId=${ordId}`;
		authorizePost(url, {}, token).then((responseJson) => {
			setCryptoLoading(false);
			if (responseJson.resultCode === 1) {
				if (responseJson.response.message === "Success") {
					setCryptoWalletUrl(responseJson.response.data);

					// window.location.href = responseJson.response.data;
				}
				console.log("🚀 ~ file: PaymentCheckout.js ~ line 90 ~ unAuthorizedGet ~ responseJson", responseJson);
			}
		});
	};
	const onClickPaymentMethod = (method) => {
		setSelectedPaymentMode(method);
	};
	//Card Payment
	const getOrderIdForStripePayment = () => { };
	const stripePaymentForOrderFt = (intentId) => {
		stripePaymentOrderConfirmation(orderId, intentId);
	};

	//Paypal Payment
	const doPaypalPayment = (paypalId) => {
		setLoading(true);
		setPaypalReturnId(paypalId);
		if (orderId !== null) {
			paypalPaymentForOrder(orderId, paypalId);
		} else {
			// if (shippingAddressId && billingAddressId && currency)
			// 	checkOutCart(shippingAddressId, billingAddressId, currency, selectedBlockChain, couponCode);
			// else toast(toastMessage.brokenURL, "error");
		}
	};

	//Render Payment Screen

	const renderCheckOutButtonText = () => {
		if (selectedPaymentMode !== null) {
			if (selectedPaymentMode === "crypto") {
				if (cryptoLoading) {
					return "Loading..";
				} else {
					return "PAY WITH CRYPTO";
				}
			} else if (selectedPaymentMode === "paypal") {
				return "PROCEED TO PAYPAL";
			} else {
				return "CHECKOUT";
			}
		} else {
			return "CHECKOUT";
		}
	};
	const renderCouponStatus = () => {
		if (getOrderDetailsSuccess) {
			if (getOrderDetailsSuccess.couponCode !== null) {
				return <p className="coupon-code-text-success">{`${getOrderDetailsSuccess.couponPercentage}% discount applied for the promocode`}</p>;
			}
		}
	};

	const triggerMoeCheckoutCompletedEvent = () => {
		if (getOrderDetailsSuccess) {
			let productName = getOrderDetailsSuccess.productList.map((s) => s.productName);
			let productId = getOrderDetailsSuccess.productList.map((s) => s._id);
			let productUrl = getOrderDetailsSuccess.productList.map(
				(s) => `${window.location.origin}/en/art/${s.medium.slugId}/${s.slugId}/${s.stockId}`
			);
			let productPrice = getOrderDetailsSuccess.productList.map((s) => s.sellingPrice);
			let productImageUrl = getOrderDetailsSuccess.productList.map((s) => s.displayPicture);
			let category = getOrderDetailsSuccess.productList.map((s) => s.medium && s.medium.name);
			let categoryId = getOrderDetailsSuccess.productList.map((s) => s.medium && s.medium._id);
			MoeTrackEvent("Repayment started", {
				ProductName: isArray(productName) ? productName.join(",") : "",
				ProductId: isArray(productId) ? productId.join(",") : "",
				ProductUrl: isArray(productUrl) ? productUrl.join(",") : "",
				Currency: getOrderDetailsSuccess.currency,
				ProductPrice: isArray(productPrice) ? productPrice.join(",") : "",
				Category: isArray(category) ? category.join(",") : "",
				CategoryId: isArray(categoryId) ? categoryId.join(",") : "",
				ProductImageUrl: isArray(productImageUrl) ? productImageUrl.join(",") : "",
				TotalQuantity: getOrderDetailsSuccess.productList && getOrderDetailsSuccess.productList.length,
				TotalItemPrice: getOrderDetailsSuccess.subTotal,
				ShippingCharge: getOrderDetailsSuccess.totalShippingCharge,
				TotalPrice: getOrderDetailsSuccess.orderTotal,
				PaymentType: getOrderDetailsSuccess.paymentType,
				OrderId: getOrderDetailsSuccess._id,
			});
		}
	};
	return (
		<MainContainer spin={isLoading || orderSummaryLoading || paypalPaymentLoading}>
			<Helmet>
				<title>{t("Checkout")}</title>
			</Helmet>
			<CartWrapper>
				<Container className="cart-checkout-wrapper">
					<Row>
						<Col lg={7} md={7} sm={12} xl={7} xs={12} xxl={7}>
							<div className="cart-top-button">
								<div className="back-btn" onClick={() => history.goBack()}>
									<img src={assets.images.leftArrowBlack} alt="Back Arrow" />
									<span>Back</span>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={7} md={7} sm={12} xl={7} xs={12} xxl={7} className="checkout-section-wrapper">
							{defaultDeliveryAddress && (
								<div className="checkout-shipping-address-wrapper">
									<div className="shipping-heading-container">
										<div>
											<h2 className="section-heading-text-repayment">{t("Shipping address")}</h2>
										</div>
									</div>
									<div className="address-container">
										<Row>
											<Col>
												<p>{defaultDeliveryAddress.name ? defaultDeliveryAddress.name : ""}</p>
											</Col>
										</Row>
										<Row>
											<Col>
												<p>
													{defaultDeliveryAddress.addressLine1 ? defaultDeliveryAddress.addressLine1 : ""},
													{defaultDeliveryAddress.addressLine2 ? defaultDeliveryAddress.addressLine2 : ""}
												</p>
											</Col>
										</Row>
										<Row>
											<Col>
												<p>
													{defaultDeliveryAddress.city ? defaultDeliveryAddress.city : ""},
													{defaultDeliveryAddress.state ? defaultDeliveryAddress.state : ""},
													{defaultDeliveryAddress.postalCode ? defaultDeliveryAddress.country : ""},
													{defaultDeliveryAddress.postalCode ? defaultDeliveryAddress.postalCode : ""}
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>{defaultDeliveryAddress.phoneNumber ? defaultDeliveryAddress.phoneNumber : ""}</p>
											</Col>
										</Row>
										<Row></Row>
										<Row>
											<Col>
												<p>{defaultDeliveryAddress.addresstype ? defaultDeliveryAddress.addresstype : ""}</p>
											</Col>
										</Row>
									</div>
								</div>
							)}
							<div className="checkout-shipping-address-wrapper">
								<div className="shipping-heading-container">
									<h2 className="section-heading-text-repayment">{t("Billing Address")}</h2>
								</div>

								{defaultBillingAddress && (
									<div className="address-container">
										<Row type="flex">
											<Col>
												<p>{defaultBillingAddress.name ? defaultBillingAddress.name : ""}</p>
											</Col>
										</Row>
										<Row type="flex">
											<Col>
												<p>
													{defaultBillingAddress.addressLine1 ? defaultBillingAddress.addressLine1 : ""},&nbsp;
													{defaultBillingAddress.addressLine2 ? defaultBillingAddress.addressLine2 : ""}
												</p>
											</Col>
										</Row>
										<Row type="flex">
											<Col>
												<p>
													{defaultBillingAddress.city ? defaultBillingAddress.city : ""},&nbsp;
													{defaultBillingAddress.state ? defaultBillingAddress.state : ""},&nbsp;
													{defaultBillingAddress.country ? defaultBillingAddress.country : ""},&nbsp;
													{defaultBillingAddress.postalCode ? defaultBillingAddress.postalCode : ""}
												</p>
											</Col>
										</Row>

										<Row type="flex">
											<Col>
												<p>{defaultBillingAddress.phoneNumber ? defaultBillingAddress.phoneNumber : ""}</p>
											</Col>
										</Row>
									</div>
								)}
							</div>
						</Col>
						<Col lg={5} md={5} sm={12} xl={5} xs={12} xxl={5}>
							{getOrderDetailsSuccess && getOrderDetailsSuccess.couponCode !== null && (
								<div className="coupon-wrapper">
									<p className="coupon-title-text">Gift cards, Voucher & Promotional codes</p>
									<div className="coupon-text-container">
										<input type="text" name="coupon" value={couponCode} className="txt-coupon" readOnly />
									</div>
									{renderCouponStatus()}
								</div>
							)}
							<div className="cart-product-details">
								{getOrderDetailsSuccess && getOrderDetailsSuccess.auctionOrder === false &&
									getOrderDetailsSuccess.productList &&
									getOrderDetailsSuccess.productList.map((cartProduct, index) => (
										<div className="cart-product-single">
											<div className="actual-price">
												<h2>{cartProduct.productName || "Product Name"}</h2>
												<h4>
													{cartProduct.sellingPrice
														? `${getOrderDetailsSuccess.currency} ${cartProduct.sellingPrice.toLocaleString()}`
														: "N/A"}
												</h4>
											</div>
										</div>
									))}
								{/* {getOrderDetailsSuccess && getOrderDetailsSuccess.couponCode !== null ? (
									getOrderDetailsSuccess.productList &&
									!isEmpty(getOrderDetailsSuccess.productList) &&
									getOrderDetailsSuccess.productList.map((item, index) => {
										return (
											<div className="cart-product-single">
												{parseInt(item.discount) > 0 && (
													<div class="actual-price">
														<h2>{item.productName || "Product Name"}</h2>
														<div className="price-section">
															<h4 className="og-price">
																{item.sellingPrice
																	? `${getOrderDetailsSuccess.currency} ${item.sellingPrice.toLocaleString()}`
																	: "N/A"}
															</h4>
															<h4>
																{item.sellingPrice
																	? `${getOrderDetailsSuccess.currency} ${(
																			parseFloat(item.sellingPrice) - parseFloat(item.discount)
																	  ).toLocaleString()}`
																	: "N/A"}
															</h4>
														</div>
													</div>
												)}
											</div>
										);
									})
								) : (
									<></>
								)} */}
								{getOrderDetailsSuccess && (
									<>
										{getOrderDetailsSuccess.auctionOrder === false && <div className="shipping-charge-box">
											<p>{`${getOrderDetailsSuccess.currency
												} ${getOrderDetailsSuccess.totalShippingCharge.toLocaleString()} Shipping Charges`}</p>

											<p>{`${getOrderDetailsSuccess.currency
												} ${getOrderDetailsSuccess.totalOrderTax.toLocaleString()} Tax Amount`}</p>
										</div>}
										{getOrderDetailsSuccess && (
											<>
												<div className="total-charge-box">
													<div>
														<h2>Total Cost</h2>
														<p className="price-text">
															{getOrderDetailsSuccess.currency}&nbsp;
															{getOrderDetailsSuccess.orderTotal.toLocaleString()}
														</p>
													</div>
												</div>
												{getOrderDetailsSuccess.auctionOrder === true && (
													<div className="total-charge-box">
														<div>
															<h2>Balance Payment</h2>
															<p className="price-text">
																{getOrderDetailsSuccess.currency}&nbsp;
																{getOrderDetailsSuccess.balanceToPay.toLocaleString()}
															</p>
														</div>
													</div>
												)}
											</>
										)}
									</>
								)}

								<div className="checkout-payment-wrapper">
									<h2 className="section-sub-head-text">{t("Payment Method")}</h2>
									<div className="payment-icon-container">
										<div className={`payment-btn`} onClick={() => onClickPaymentMethod("card")}>
											<img
												src={selectedPaymentMode === "card" ? assets.images.cardBtnActive : assets.images.cardBtn}
												alt="Payment Icon"
											/>
										</div>
										<div className={`payment-btn`} onClick={() => onClickPaymentMethod("paypal")}>
											<img
												src={selectedPaymentMode === "paypal" ? assets.images.paypalBtnActive : assets.images.paypalBtn}
												alt="Payment Icon"
											/>
										</div>

										<div className={`payment-btn`} onClick={() => onClickPaymentMethod("crypto")}>
											<img
												src={selectedPaymentMode === "crypto" ? assets.images.cryptoBtnActive : assets.images.cryptoBtn}
												alt="Payment Icon"
											/>
										</div>
									</div>
								</div>
								<>
									{selectedPaymentMode === "card" ? (
										<Elements stripe={getStripe()}>
											<NewCheckoutForm
												intentId={stripePaymentIntentSuccess}
												orderDetails={getOrderDetailsSuccess}
												stripePaymentForOrder={stripePaymentForOrderFt}
												getOrderIdForStripePayment={getOrderIdForStripePayment}
												orderId={orderId}
												t={t}
												triggerMoeCheckoutStartedEvent={triggerMoeCheckoutCompletedEvent}
											/>
										</Elements>
									) : selectedPaymentMode === "paypal" ? (
										<Paypal
											doPaypalPayment={doPaypalPayment}
											orderDetails={orderSummaryData}
											paypalPaymentLoading={paypalPaymentLoading}
										/>
									) : selectedPaymentMode === "paynow" ? (
										<Elements stripe={getStripe()}>
											<PayNow intentId={stripePaymentIntentSuccess} orderDetails={getOrderDetailsSuccess} />
										</Elements>
									) : selectedPaymentMode === "crypto" && cryptoWalletUrl !== "" ? (
										<div>
											<iframe id="triplea-payment-form" src={cryptoWalletUrl} className="iframe-style" scrolling="no"></iframe>
										</div>
									) : (
										<div className={`make-payment-btn`} onClick={onClickCheckOut}>
											{renderCheckOutButtonText()}
										</div>
									)}
								</>
							</div>
						</Col>
					</Row>
				</Container>
			</CartWrapper>
		</MainContainer>
	);
};

export default RePayment;
