import { SHOW_MODAL, HIDE_MODAL } from "./ActionTypes";
export const showModal = (modalType, modalProps) => {
  return {
    type: SHOW_MODAL,
    payload: {
      modalType,
      modalProps
    }
  };
};

export const hideModal = modalType => {
  return {
    type: HIDE_MODAL,
    payload: {
      modalType
    }
  };
};
