import { useDispatch, useSelector } from "react-redux";
import {
    getCollectionByArtist,
    getCollectionListByUserIdAction,
    addCollectionByArtist,
    updateCollection,
    deleteCollection,
    filterMyProduct,
    getCollectionById,
    deleteCollectionItem,
    getAllCollections
} from "../actions/ArtistCollectionAction";
import Util from "../util/Util";

export const useGetCollectionByArtist = () => {
    const dispatch = useDispatch();

    // Get ArtistInterests
    const getCollection = userId => {
        if (userId) dispatch(getCollectionListByUserIdAction(userId));
        else dispatch(getCollectionByArtist());
    };
    // Reducer states
    const result = useSelector(state => state.artistCollection.getCollectionByArtist);

    return [getCollection, result.data, result.error, result.loading];
};

export const useAddCollectionByArtist = () => {
    const dispatch = useDispatch();

    // Add ArtistInterests
    const addCollection = (collectionData, isMobile = false) => {
        if (isMobile) {
            dispatch(addCollectionByArtist(collectionData));
        } else {
            const formData = Util.objectToFormData(collectionData);
            dispatch(addCollectionByArtist(formData));
        }
    };

    // Reducer states
    const result = useSelector(state => state.artistCollection.addCollectionByArtist);

    return [addCollection, result.data, result.error, result.loading];
};
export const useUpdateCollection = () => {
    const dispatch = useDispatch();

    // Update ArtistInterests
    const updateCollectionFunction = (collectionData, collectionId, isMobile = false) => {
        if (isMobile) {
            dispatch(updateCollection(collectionData, collectionId));
        } else {
            const formData = Util.objectToFormData(collectionData);
            dispatch(updateCollection(formData, collectionId));
        }
    };

    // Reducer states
    const result = useSelector(state => state.artistCollection.updateCollection);

    return [updateCollectionFunction, result.data, result.error, result.loading];
};
export const useDeleteCollection = () => {
    const dispatch = useDispatch();

    // Delete ArtistInterests
    const deleteCollectionFunction = collectionId => {
        dispatch(deleteCollection(collectionId));
    };
    // Reducer states
    const result = useSelector(state => state.artistCollection.deleteCollection);

    return [deleteCollectionFunction, result.data, result.error, result.loading];
};

export const useFilterMyProduct = () => {
    const dispatch = useDispatch();

    // Update ArtistInterests
    const filterFunction = searchData => {
        dispatch(filterMyProduct(searchData));
    };

    // Reducer states
    const result = useSelector(state => state.artistCollection.filterMyProduct);

    return [filterFunction, result.data, result.error, result.loading];
};

export const useGetCollection = () => {
    const dispatch = useDispatch();

    // getCollection By Id
    const getCollection = slugId => {
        dispatch(getCollectionById(slugId));
    };

    // Reducer states
    const result = useSelector(state => state.artistCollection.collection);

    return [getCollection, result.data, result.error, result.loading];
};

export const useDeleteCollectionItem = () => {
    const dispatch = useDispatch();

    // deleteCollectionItem
    const deleteItem = (collectionId, collectionItemId) => {
        dispatch(deleteCollectionItem(collectionId, collectionItemId));
    };

    // Reducer states
    const result = useSelector(state => state.artistCollection.deleteCollectionItem);

    return [deleteItem, result.data, result.error, result.loading];
};

export const useGetAllCollections = () => {
    const dispatch = useDispatch();

    // get all collections
    const getAll = (pageNum, pageSize) => {
        dispatch(getAllCollections(pageNum, pageSize));
    };

    // Reducer states
    const result = useSelector(state => state.artistCollection.allCollections);

    return [getAll, result.data, result.error, result.loading];
};
