import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction } from "../actions/UserManagementAction";

const useChangePassword = () => {
    const onChangePassword = (oldPassword, newPassword) => {
        dispatch(changePasswordAction(oldPassword, newPassword));
    };
    const changePasswordData = useSelector(state => state.userManagement.changePasswordData);
    const dispatch = useDispatch();
    return [onChangePassword, changePasswordData.data, changePasswordData.error, changePasswordData.loading];
};
export default useChangePassword;
