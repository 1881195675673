import { useDispatch, useSelector } from "react-redux";
import Util from "../util/Util";
import { bulkUploadAction, bulkFormDownloadAction } from "../actions/BulkUploadAction";

export const useBulkUpload = () => {
	const dispatch = useDispatch();
	const bulkUpload = (body) => {
		let formbody = {
			Excel: body,
		};
		const formData = Util.objectToFormData(formbody);
		dispatch(bulkUploadAction(formData));
	};
	const bulkUploadResult = useSelector((state) => state.bulkUploadReducer.bulkUploadResult);
	return [bulkUpload, bulkUploadResult.data, bulkUploadResult.error, bulkUploadResult.loading];
};

export const useBulkFormDownload = () => {
	const dispatch = useDispatch();
	const bulkFormDownload = () => {
		dispatch(bulkFormDownloadAction());
	};
	const { data, error, loading } = useSelector((state) => state.bulkUploadReducer.bulkFormDownload);
	return [bulkFormDownload, data, error, loading];
};
