import loadash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  showModal as showModalAction
} from "redux/actions/ModalActions";

export default function useModal(modalType) {
  const dispatch = useDispatch();
  const isShown = useSelector(state => {
    const path = loadash.get(state, "modal.modalType");
    return path === modalType;
  });

  const data = useSelector(state => {
    const data = loadash.get(state, "modal.modalProps");
    return data;
  });

  const onCancel = () => dispatch(hideModal());
  const showModal = (modalType, props) =>
    dispatch(showModalAction(modalType, props));
  return [isShown, data, showModal, onCancel];
}
