import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, walletLogin, logoutUserAction, googleLoginAction, facebookLoginAction, loginRefreshTokenAction, appleLoginAction } from "../actions/UserManagementAction";

//Login
export const useLogin = () => {
	const authenticate = data => {
		dispatch(login(data));
	};
	const userLoginData = useSelector(state => state.userManagement.userLoginData);
	const dispatch = useDispatch();
	return [authenticate, userLoginData.data, userLoginData.error, userLoginData.loading];
};

export const useWalletLogin = () => {
	const authenticate = data => {
		dispatch(walletLogin(data));
	};
	const userLoginData = useSelector(state => state.userManagement.userLoginData);
	const dispatch = useDispatch();
	return [authenticate, userLoginData.data, userLoginData.error, userLoginData.loading];
};

//Logout
export const useLogout = () => {
	const authenticate = refreshToken => {
		dispatch(logoutUserAction(refreshToken));
	};
	const logoutData = useSelector(state => state.userManagement.logoutData);
	const dispatch = useDispatch();
	return [authenticate, logoutData.data, logoutData.error, logoutData.loading];
};

//Login With Google
export const useLoginGoogle = () => {
	const authenticate = data => {
		dispatch(googleLoginAction(data));
	};
	const googleLoginData = useSelector(state => state.userManagement.googleLoginData);
	const dispatch = useDispatch();
	return [authenticate, googleLoginData.data, googleLoginData.error, googleLoginData.loading];
};

//Login With Facebook
export const useLoginFacebook = () => {
	const authenticate = data => {
		dispatch(facebookLoginAction(data));
	};
	const facebookLoginData = useSelector(state => state.userManagement.facebookLoginData);
	const dispatch = useDispatch();
	return [authenticate, facebookLoginData.data, facebookLoginData.error, facebookLoginData.loading];
};

//Login Using Refresh Token
export const useLoginRefreshToken = () => {
	const loginRefreshToken = data => {
		dispatch(loginRefreshTokenAction(data));
	};
	const refreshLoginData = useSelector(state => state.userManagement.refreshLoginData);
	const dispatch = useDispatch();
	return [loginRefreshToken, refreshLoginData.data, refreshLoginData.error, refreshLoginData.loading];
};

//Login With Apple
export const useLoginApple = () => {
	const authenticate = data => {
		dispatch(appleLoginAction(data));
	};
	const appleLoginData = useSelector(state => state.userManagement.appleLoginData);
	const dispatch = useDispatch();
	return [authenticate, appleLoginData.data, appleLoginData.error, appleLoginData.loading];
};
