let errorCode = null;
let errorData = null;

export function requestInterceptor(axios) {
	axios.interceptors.response.use(
		res => res,
		error => {
			if (error && error.response) {
				errorCode = error.response.status;
				errorData = error.response;
				setTimeout(() => {
					errorCode = null;
					errorData = null;
				}, 3);
			} else {
				errorCode = null;
				errorData = null;
			}
			// requestErrorInterception(error.response);
			// bindActionCreators(requestErrorInterception, dispatch);
			// const dispatch = useDispatch();
			// dispatch(requestErrorInterception(error.response));
		}
	);
}

// Subscription Hook
export const useRequestInterception = () => {
	// const { errorCode, errorData } = useSelector(state => state.refreshTokenMangement);
	return [errorCode, errorData];
};
export default useRequestInterception;
