import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class PaymentService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get Currencies
	static async getCurrencies() {
		return this.service.execute(this.API.getCurrencies, HTTP_METHOD.GET);
	}

	// Convert Currency
	static async convertCurrency(Amount, CurrencyName) {
		let params = { Amount, CurrencyName };
		return this.service.execute(this.API.convertCurrency, HTTP_METHOD.POST, { params });
	}

	// Stripe payment
	static async stripePayment(StripeToken, OrderId) {
		let params = { StripeToken, OrderId };
		return this.service.execute(this.API.stripePayment, HTTP_METHOD.POST, { params });
	}

	static async stripePaymentIntent(OrderId, Type) {
		let params = { OrderId, Type };
		return this.service.execute(this.API.stripeIntent, HTTP_METHOD.POST, { params });
	}
	static async stripePaymentConfirmation(OrderId, IntentId) {
		let params = { OrderId, IntentId };
		return this.service.execute(this.API.stripeConfirmation, HTTP_METHOD.POST, { params });
	}

	// Get Paypal client code
	static async getPaypalIDForOrder() {
		return this.service.execute(this.API.getPaypalClientCode, HTTP_METHOD.GET);
	}

	// Paypal payment
	static async paypalPayment(rtistIQOrderId, PaypalOrderId) {
		let params = { rtistIQOrderId, PaypalOrderId };
		return this.service.execute(this.API.paypalPayment, HTTP_METHOD.POST, { params });
	}

	//Paypal payment case
	static async paypalFailedPayment(rtistIQOrderId) {
		let params = { rtistIQOrderId };
		return this.service.execute(this.API.paypalFailedPayment, HTTP_METHOD.POST, { params });
	}
}
