import { CommentsService } from "../services/CommentsService";
import * as types from "./ActionsTypes";

// ========================================== Get Comments ========================================== //
export const getComments = (pageNum, pageSize, parentId) => {
  return {
    actionType: types.GET_COMMENTS_REQUEST,
    callAPI: () => CommentsService.getComments(pageNum, pageSize, parentId),
    stateObject: "getCommentsData"
  };
};

// ========================================== Add Comment ========================================== //
export const addComment = (comment, parentId) => {
  return {
    actionType: types.ADD_COMMENTS_REQUEST,
    callAPI: () => CommentsService.addComment(comment, parentId),
    stateObject: "addCommentsData",
    isTransient: true
  };
};

// ========================================== Update Comment ========================================== //
export const updateComment = (comment, parentId) => {
  return {
    actionType: types.UPDATE_COMMENTS_REQUEST,
    callAPI: () => CommentsService.updateComment(comment, parentId),
    stateObject: "updateCommentsData",
    isTransient: true
  };
};

// ========================================== Delete Comment ========================================== //
export const deleteComment = commentId => {
  return {
    actionType: types.DELETE_COMMENTS_REQUEST,
    callAPI: () => CommentsService.deleteComment(commentId),
    stateObject: "deleteCommentsData",
    isTransient: true
  };
};
