import * as types from "../actions/ActionsTypes";
import { ProductManagementService } from "../services/ProductManagementService";
import { CategoryManagementService } from "../services/CategoryManagementService";
// import { reverse, flatten, includes } from 'lodash';

// ============================= Get Category Properties ============================= //
export function getFilterCategoryProperties(id) {
	return {
		actionType: types.GET_FILTER_CATEGORY_PROPERTIES_REQUEST,
		callAPI: () => CategoryManagementService.getCategoryPropertyForFilters(id),
		stateObject: "getFilterCategoryProperties"
	};
}

// ============================= Get Filter Products ============================= //
export function filterArtworks(searchData, filtersArray = []) {
	if (filtersArray && filtersArray.length > 0) {
		return {
			actionType: types.GET_FILTER_ARTWORKS_REQUEST,
			callAPI: () => CategoryManagementService.getCategoryPropertyAndFilterProducts(searchData, filtersArray),
			stateObject: "filterProducts"
		};
	} else
		return {
			actionType: types.GET_FILTER_ARTWORKS_REQUEST,
			callAPI: () => CategoryManagementService.getSearchProductsWithoutFilter(searchData),
			stateObject: "filterProducts"
		};
}

// ============================= Get Breadcrumbs Array ============================= //
export function loadBreadCrumbs(id) {
	return {
		actionType: types.GET_BREADCRUMBS_REQUEST,
		callAPI: () => CategoryManagementService.getParentsBySlugId(id),
		stateObject: "getBreadcrumbs"
	};
}

// NOTE : Below actions are not changing atm. Will do that later // they are accessing an old reducer
// ============================= Get Filter Products ============================= //
export const getProductOfCategories = (id, PageNum = 1, PageSize = 4) => {
	return dispatch => {
		dispatch(request());
		let body = {
			CategoryId: id,
			PageNum,
			PageSize
		};
		ProductManagementService.searchArtworks(body).then(
			response => {
				if (response) {
					let payload = {
						stateName: id,
						data: (response && response.productList) || [],
						count: (response && response.count) || null
					};
					dispatch(success(payload));
				} else {
					dispatch(failure(response));
				}
			},
			error => {
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.CATEGORY_ARTWORKS_LIST_REQUEST };
	}
	function success(payload) {
		return { type: types.CATEGORY_ARTWORKS_LIST_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.CATEGORY_ARTWORKS_LIST_FAILURE, error };
	}
};

// ============================= Clear Category Artworks Reducer ============================= //
export const clearCategoryArtworksStates = () => {
	return dispatch => {
		dispatch({ type: types.CATEGORY_ARTWORKS_LIST_CLEAR });
	};
};
