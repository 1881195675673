import React from "react";
import { InputWrapper, FloatingLabeledInputField, FloatingLabeledInputLabel, ErrorDiv, ErrorText, RedSpan } from "./FloatingLabeledInput.styled";

const FloatingLabeledInput = ({ ...props }) => {
	const { label, required, error, errorMessage, touched, ...fields } = props; //hasError
	return (
		<InputWrapper>
			<FloatingLabeledInputField size="large" {...fields} error={error ? 1 : 0} />
			{required ? (
				<FloatingLabeledInputLabel>
					{label}
					<RedSpan>*</RedSpan>
				</FloatingLabeledInputLabel>
			) : (
				<FloatingLabeledInputLabel>{label}</FloatingLabeledInputLabel>
			)}
			{touched && error && props.name === "registerpassword" ? (
				<ErrorDiv>
					<ErrorText type="danger">{errorMessage}</ErrorText>
				</ErrorDiv>
			) : (
				touched && error && props.name !== "registerpassword" && <ErrorText type="danger">{errorMessage}</ErrorText>
			)}
		</InputWrapper>
	);
};
export default FloatingLabeledInput;
