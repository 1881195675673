import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
  latestArtists: {},
  popularArtists: {},
  getHomeScreenBannerResult: {},
  getHeaderCountResult: {},
  getBlogsById:{},
  blogsList: {},
  newsList: {},
  newsById: {},
  newsBySlugId: {},
  selectedCollectionList: {},
  searchResult: {},
  saveSearchResult: {}
};

const HomeReducer = createApiReducer(initialState, [
  types.GET_LATEST_ARTISTS_REQUEST,
  types.GET_POPULAR_ARTISTS_REQUEST,
  types.GET_HOME_BANNER_REQUEST,
  types.GET_HEADER_COUNT_REQUEST,
  types.GET_BLOGS_BY_ID_REQUEST,
  types.GET_BLOGS_LIST_REQUEST,
  types.GET_NEWS_LIST_REQUEST,
  types.GET_NEWS_BY_ID_REQUEST,
  types.GET_NEWS_BY_SLUG_ID_REQUEST,
  types.GET_SELECTED_COLLECTION_REQUEST,
  types.GET_SEARCH_REQUEST,
  types.SAVE_SEARCH_REQUEST
]);

export default HomeReducer;
