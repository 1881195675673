import * as types from "../actions/ActionsTypes";

const initialState = {
	refreshTokenData: null,
	refreshTokenLoader: false,
	errorCode: null,
	errorData: null
};

const RefreshTokenReducer = (state = initialState, { type, payload, error }) => {
	switch (type) {
		// ======= Get refrsh token data ====== //
		case types.REFRESH_TOKEN_DATA_REQUEST:
			state = {
				...state,
				refreshTokenLoader: true,
				refreshTokenData: null
			};
			return state;
		case types.REFRESH_TOKEN_DATA_SUCCESS:
			state = {
				...state,
				refreshTokenLoader: false,
				refreshTokenData: payload
			};
			return state;
		case types.REFRESH_TOKEN_DATA_FAILURE:
			state = {
				...state,
				refreshTokenLoader: false,
				refreshTokenData: error
			};
			return state;
		case types.REQUEST_ERROR_INTERCEPT:
            console.log("HEEE REDUCER", state)
			state = {
				...state,
				errorCode: error ? error.status : null,
				errorData: error
			};
			return state;

		default:
			return state;
	}
};

export default RefreshTokenReducer;
