import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
	getGalleryListResult: {},
	addNewGalleryResult: {},
	updateGalleryResult: {},
	deleteFromGalleryResult: {},

	// Get artists list
	getArtistsListData: {},

	// Get Event List
	getEventListResult: {},
	addNewEventResult: {},
	updateEventResult: {},
	deleteFromEventResult: {},

	// Get Award List
	awardListResult: {},
	addAwardResult: {},
	updateAwardResult: {},
	deleteAwardResult: {},

	// Get HistoricalData List
	historicalDataListResult: {},
	addHistoricalDataResult: {},
	updateHistoricalDataResult: {},
	deleteHistoricalDataResult: {},

	//Articles by artist
	getMyBlogsResult: {},
	getBlogsByUserResult: {},
	addBlogResult: {},
	updateBlogResult: {},
	deleteBlogResult: {},
	getGalleryMembershipRequests: {},
	updateGalleryMembershipRequest: {}
};

const ArtistReducer = createApiReducer(initialState, [
	types.GET_GALLERY_LIST_REQUEST,
	types.ADD_NEW_GALLERY_REQUEST,
	types.UPDATE_GALLERY_REQUEST,
	types.DELETE_FROM_GALLERY_REQUEST,
	types.GET_ARTISTS_LIST_REQUEST,
	types.GET_EVENT_LIST_REQUEST,
	types.ADD_NEW_EVENT_REQUEST,
	types.UPDATE_EVENT_REQUEST,
	types.DELETE_FROM_EVENT_REQUEST,
	types.GET_AWARD_LIST_REQUEST,
	types.ADD_AWARD_REQUEST,
	types.UPDATE_AWARD_REQUEST,
	types.DELETE_AWARD_REQUEST,
	types.GET_HISTORICAL_DATA_LIST_REQUEST,
	types.ADD_HISTORICAL_DATA_REQUEST,
	types.UPDATE_HISTORICAL_DATA_REQUEST,
	types.DELETE_HISTORICAL_DATA_REQUEST,
	types.GET_MY_BLOGS_REQUEST,
	types.GET_BLOGS_BY_USER_REQUEST,
	types.ADD_BLOG_REQUEST,
	types.UPDATE_BLOG_REQUEST,
	types.DELETE_BLOG_REQUEST,
	types.GET_GALLERY_MEMBERSHIP_REQUESTS,
	types.UPDATE_GALLERY_MEMBERSHIP_REQUEST
]);

export default ArtistReducer;
