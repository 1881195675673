import React from "react";
import assets from "assets";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LogoBox = styled(Link)`
	display: flex;
	padding: 7px 2px 2px 2px;
	img {
		cursor: pointer;
		height: 50px;
		@media (max-width: 992px) {
			height: 35px;
			margin-left: 10px;
		}
		@media (max-width: 768px) {
			height: 50px;
		}
	}
	@media screen and (max-width: 1280px) {
		width: 100%;
		margin-right: 110rem;
	}
	@media screen and (max-width: 768px) {
		margin-left: 50px;
	}

	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
		/* margin-left: 50px !important; */
	}
	@media screen and (min-width: 769px) and (max-device-width: 1020px) {
		margin-left: 30px;
		padding: 7px 2px 2px 0;
	}
`;

const Logo = () => {
	return (
		<LogoBox to="/">
			<img src={assets.images.LogoNew} alt="RtistiQ" title="RtistiQ Logo" width="92" height="65" />
		</LogoBox>
	);
};
export default Logo;
