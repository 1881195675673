import { useDispatch, useSelector } from "react-redux";
import {
    registerUserAction,
    sendOtpAction,
    validateOtpAction,
    resetPasswordAction,
    resendCofirmationOTPAction
} from "../actions/AuthenticationAction";

//User Registration
export const useRegisterUser = () => {
    const dispatch = useDispatch();
    const registerUser = userInfo => {
        dispatch(registerUserAction(userInfo));
    };
    // Reducer states
    const result = useSelector(state => state.authentication.userRegisterResult);
    return [registerUser, result.data, result.error, result.loading];
};

//Resend confirmation OTP
export const useResendOTP = () => {
    const dispatch = useDispatch();
    const resendOTP = userId => {
        dispatch(resendCofirmationOTPAction(userId));
    };
    // Reducer states
    const result = useSelector(state => state.authentication.getMailResult);

    return [resendOTP, result.data, result.error, result.loading];
};

//Send OTP
export const useSendOTP = () => {
    const dispatch = useDispatch();
    const sendOTP = mailId => {
        dispatch(sendOtpAction(mailId));
    };
    // Reducer states
    const result = useSelector(state => state.authentication.otpSendtoMailResult);

    return [sendOTP, result.data, result.error, result.loading];
};

//Validate OTP
export const useValidateOTP = () => {
    const dispatch = useDispatch();
    const validateOTP = (email, otp) => {
        dispatch(validateOtpAction(email, otp));
    };
    // Reducer states
    const result = useSelector(state => state.authentication.validateOtpResult);

    return [validateOTP, result.data, result.error, result.loading];
};

//Validate OTP
export const useResetPassword = () => {
    const dispatch = useDispatch();
    const resetPassword = (email, password, resetPasswordToken) => {
        dispatch(resetPasswordAction(email, password, resetPasswordToken));
    };
    // Reducer states
    const result = useSelector(state => state.authentication.resetPasswordResult);

    return [resetPassword, result.data, result.error, result.loading];
};
