import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialUpdateAction } from "../actions/UserManagementAction";

const useInitialUpdate = () => {
	const initialUpdate = initialData => {
		dispatch(initialUpdateAction(initialData));
	};

	// Reducer states
	const initialUpdateResult = useSelector(state => state.userManagement.initialUpdateResult);
	const dispatch = useDispatch();

	return [initialUpdate, initialUpdateResult.data, initialUpdateResult.error, initialUpdateResult.loading];
};

export default useInitialUpdate;
