import React, { useState, useEffect } from "react";

import {
	PaymentCheckoutFormWrapper,
	CardNumber,
	CardExpiry,
	CardCVC,
	PaymentCheckoutFormControl,
	PaymentCheckoutFormLabel,
	PaymentCheckoutFormRow,
	PaymentCheckoutFormContainer,
} from "../PaymentCheckoutForm/PaymentCheckoutForm.styled";
import { MandatoryText } from "styled/common.styled";
import { PaymentCheckoutFooterWrapper } from "../PaymentCheckout.styled";
import StyledButton from "common/StyledButton";
import toast from "common/Toast";
import { toastMessage } from "utils/toastMessages";
import { paymentHooks, orderHooks } from "ecommerce-sdk/src/index";
import { useTranslation } from "react-i18next";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import { isFunction } from "lodash";

export default function NewCheckoutForm({ intentId, orderDetails, stripePaymentForOrder,
	getOrderIdForStripePayment, orderId, triggerMoeCheckoutStartedEvent }) {
	const { useStripePaymentConfirmation } = paymentHooks;
	const { useCheckOutCart } = orderHooks;
	const { useStripePaymentIntent } = paymentHooks;

	const [, checkOutCartData, checkOutCartError] = useCheckOutCart();
	const [stripePaymentIntentForOrder, stripePaymentIntentSuccess, stripePaymentIntentError, stripePaymentIntentLoading] = useStripePaymentIntent();

	const { t } = useTranslation();
	const stripe = useStripe();
	const elements = useElements();
	const [fetchingStripeToken, setFetchingStripeToken] = useState(false);

	useEffect(() => {
		if (checkOutCartData) {
			stripePaymentIntentForOrder(checkOutCartData, "");
		}
	}, [checkOutCartData, checkOutCartError]);

	useEffect(() => {
		if (stripePaymentIntentSuccess) {
			callStripeCardValidation();
		}
	}, [stripePaymentIntentSuccess]);

	const callStripeCardValidation = async () => {
		if (isFunction(triggerMoeCheckoutStartedEvent)) {
			triggerMoeCheckoutStartedEvent("card payment")
		}
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			toast(toastMessage.stripeNotLoaded, "warning");
			setFetchingStripeToken(false);
			return;
		}
		let cardDetail = elements.getElement(CardElement);
		console.log("🚀 ~ file: NewCheckOutForms.js ~ line 22 ~ handleSubmit ~ cardDetail", cardDetail);
		const result = await stripe.confirmCardPayment(stripePaymentIntentSuccess, {
			payment_method: {
				card: elements.getElement(CardElement),
			},
		});

		if (result.error) {
			// Show error to your customer (e.g., insufficient funds)
			console.log(result.error.message);
			toast(result.error.message, "error");
			setFetchingStripeToken(false);
		} else {
			// The payment has been processed!

			// setFetchingStripeToken(false)
			if (result.paymentIntent && result.paymentIntent.id) {
				stripePaymentForOrder(result.paymentIntent.id);
			}
			if (result.paymentIntent.status === "succeeded") {
				// Show a success message to your customer
				// There's a risk of the customer closing the window before callback
				// execution. Set up a webhook or plugin to listen for the
				// payment_intent.succeeded event that handles any business critical
				// post-payment actions.
			}
		}
	};
	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		setFetchingStripeToken(true);
		if (orderId !== null) {
			stripePaymentIntentForOrder(orderId, "");
		} else {
			getOrderIdForStripePayment();
		}
	};

	// if (this.props.stripe) {
	// 	this.props.stripe.createToken().then(({ token, ...props }) => {
	// 		if (props.error && props.error.message) {
	// 			toast(props.error.message, "error");
	// 			this.setState({ fetchingStripeToken: false });
	// 			return;
	// 		} else if (!token) {
	// 			this.setState({ errorMessage: null, fetchingStripeToken: false });
	// 			toast(toastMessage.stripeTokenNotGenerated, "warning");
	// 			return;
	// 		} else {
	// 			this.props.stripePaymentForOrder(token);
	// 			this.setState({ errorMessage: null, fetchingStripeToken: false });
	// 		}
	// 	});
	// } else {
	// 	toast(toastMessage.stripeNotLoaded, "warning");
	// 	this.setState({ fetchingStripeToken: false });
	// }
	const handleChange = ({ error }) => {
		if (error) {
			this.setState({ errorMessage: error.message });
		}
	};

	// Button Function Component
	// To solve button disable issue
	{
		/* <StyledButton
				disabled={fetchingStripe}
				width="11rem"
				onClick={e => handleSubmit(e)}
			>
			   
			</StyledButton> */
	}
	const PaymentConfirmButton = ({ fetchingStripe, handleSubmit }) => {
		const { usePaypalPaymentForOrder, useStripePaymentForOrder } = paymentHooks;
		const [, , , stripePaymentLoading] = useStripePaymentForOrder();
		const [, , , paypalPaymentLoading] = usePaypalPaymentForOrder();
		const { useGetOrderDetailsById } = orderHooks;
		const [, , , getOrderDetailsLoading] = useGetOrderDetailsById();

		return (
			<div className="make-payment-btn" onClick={(e) => handleSubmit(e)}>
				{fetchingStripe ? t("Loading...") : t("CHECKOUT BY CARD")}
			</div>
		);
	};

	return (
		<PaymentCheckoutFormContainer>
			<PaymentCheckoutFormWrapper>
				<CardSection />
			</PaymentCheckoutFormWrapper>

			{/* <PaymentCheckoutFooterWrapper> */}
			{/* <div>

                    <p className="text-price-title">{t("Sub Total")}</p>
                    <p className="text-price">
                        {orderDetails.currency}&nbsp;{(+orderDetails.orderTotal).toLocaleString() || 0}
                    </p>
                </div> */}
			{/* </PaymentCheckoutFooterWrapper> */}
			<PaymentConfirmButton fetchingStripe={fetchingStripeToken} handleSubmit={handleSubmit} />
		</PaymentCheckoutFormContainer>
	);
}
