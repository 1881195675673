import { SHOW_MODAL, HIDE_MODAL } from "redux/actions/ActionTypes";

const initialState = {
  modalType: null,
  modalProps: {}
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps
      };
    case HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default modal;
