import styled, { css } from "styled-components";
import { Icon } from "antd";
import { ImgNextGen } from "common/NextGenImage";

export const ArtworkCheckboxWrapper = styled.div`
	height: 12rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align-last: start;
	cursor: pointer;
	margin: 15px 10px 0 10px;
`;
export const ImgContainerDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const CardImg = styled(ImgNextGen)`
	min-height: 200px;
	max-height: 200px;
`;

export const CheckIcon = styled(Icon)`
	font-size: 24px;
	color: #ffffff;
`;

export const Text = styled.p`
	text-align: center;
	margin: 5%;
	font-weight: bold;
	font-size: 14px;
	color: black;
`;

export const SelectButton = styled.div`
	min-width: 29px;
	min-height: 29px;
	max-width: 29px;
	max-height: 29px;
	border-radius: 50%;
	background: #000;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	bottom: 110px;
	visibility: hidden;
	${props =>
		props.isChecked &&
		css`
			visibility: visible;
		`}
`;
