import React, { useState, useEffect } from "react";
import { MenuContainer, MenuItemLink, BlinkingMenu } from "./NavbarMenu.styled";
import ArtistDropdown from "../ArtistDropdown";
import ArtworkDropDown from "../ArtworkDropDown";
import { useTranslation } from "react-i18next";
import { MoreDropDown } from "../MoreDropdown";
import assets from "assets";
import { Link } from "react-router-dom";

const NavbarMenu = ({ types }) => {
	const [nftMenuImages, setNftMenuImages] = useState([assets.images.nftMenuIdel, assets.images.nftMenuBliker]);
	const [isNftMenuHover, setIsNftMenuHover] = useState(false);
	const [currentImg, setCurrentImg] = useState(0);
	const { t } = useTranslation();
	const switchImage = () => {
		if (currentImg < nftMenuImages.length - 1) {
			setCurrentImg(currentImg + 1);
		} else {
			setCurrentImg(0);
		}
		return currentImg;
	};

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		switchImage();
	// 	}, 1000);
	// });
	return (
		<MenuContainer>
			<ArtworkDropDown />
			<ArtistDropdown gotTypesData={types} />

			<MenuItemLink to="/collections">{t("Collections")}</MenuItemLink>
			<MenuItemLink to="/blog">{t("Blog")}</MenuItemLink>
			<MenuItemLink to="/news">{t("News")}</MenuItemLink>
			<Link to="/nftdrop" onMouseEnter={() => setIsNftMenuHover(true)} onMouseLeave={() => setIsNftMenuHover(false)}>
				<BlinkingMenu
					src={isNftMenuHover ? assets.images.nftMenuBliker : assets.images.nftMenuIdel2}
					alt="menu"
					width="100%"
					height="100%"
					className=""
				/>
			</Link>

			{/* <MoreDropDown/> */}

			{/* <MenuItemLink to="/galleries">{t("Galleries")}</MenuItemLink> */}

			{/* <MenuItemLink to="/news">{t("News")}</MenuItemLink> */}
		</MenuContainer>
	);
};

export default NavbarMenu;
