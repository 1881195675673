import { useDispatch, useSelector } from "react-redux";
import { refreshTokenAction } from "../actions/RefreshTokenAction";

const useRefreshTokenContainer = () => {
    // refresh token
    const refreshToken = () => {
        dispatch(refreshTokenAction());
    };

    // Reducer states
    const { refreshTokenData, refreshTokenLoader } = useSelector(state => state.refreshTokenMangement);
    const dispatch = useDispatch();

    return { refreshToken, refreshTokenData, refreshTokenLoader };

}
export default useRefreshTokenContainer;
