import * as types from "./ActionsTypes";
import { BlockchainService } from "../services/BlockchainService";

// ========================================== Get Blockchain Account List ========================================== //
export const getBlockchainAccountAction = (pageNum, pageSize) => {
    return {
        actionType: types.GET_BLOCKCHAIN_ACCOUNTS_REQUEST,
        callAPI: () => BlockchainService.getBlockchainAccount(pageNum, pageSize),
        stateObject: "getBlockchainAccountList"
    };
};

// ========================================== Add Comment ========================================== //
export const createBlockchainAccountAction = (body) => {
    return {
        actionType: types.CREATE_BLOCKCHAIN_ACCOUNT_REQUEST,
        callAPI: () => BlockchainService.createBlockchainAccount(body),
        stateObject: "createBlockchainAccount",
        isTransient: true
    };
};

// ========================================= Buy Artwork Transaction ======================================= //
export const buyArtWorkTransactionAction = (body) => {
    return {
        actionType: types.BUY_ARTWORK_TRANSACTION_REQUEST,
        callAPI: () => BlockchainService.buyArtWorksTransaction(body),
        stateObject: "buyArtworkTransaction",
        isTransient: true
    };
}

// ========================================== Add Signed Message Action ======================================//
export const addSignMessageAction = body => {
    return {
        actionType: types.ADD_SIGNED_MESSAGE_REQUEST,
        callAPI: () => BlockchainService.addSignedMessage(body),
        stateObject: "addSignedMessage",
        isTransient: true
    };
};


// ========================================== Add Meta Data Signature ======================================//
export const metaDataSignatureUpdateAction = body => {
    return {
        actionType: types.ADD_SIGNATURE_METADATA_UPDATE,
        callAPI: () => BlockchainService.metaDataSignatureUpdates(body),
        stateObject: "metaDataSignature",
        isTransient: true
    };
};


// ========================================== Get Blockchain Certificate ========================================== //
export const getBlockchainCertificate = (rfid) => {
    return {
        actionType: types.GET_CERTIFICATE_REQUEST,
        callAPI: () => BlockchainService.getCertificate(rfid),
        stateObject: "getCertificate"
    };
};
