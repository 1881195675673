import loadash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { refreshBadges } from "redux/actions/GeneralActions";
const useRefreshBadge  = ()=>{
    const dispatch = useDispatch();
    const refreshBadgesAction = (refresh)=>{
        dispatch(refreshBadges(refresh))
    }
    const shouldRefresh = useSelector(state => {
     return  loadash.get(state, "general.refreshBadges");
    });

    return [refreshBadgesAction,shouldRefresh]

   
}
export default useRefreshBadge;