import styled from "styled-components";
import { ImgNextGen } from "common/NextGenImage";

const MainContainerWrapper = styled.div``;
const NoInternetImage = styled(ImgNextGen)`
	object-fit: contain;
	max-width: 100%; 
`;
const NoInternetImageWrapper = styled.div`
	display: ${props => (props.isDisconnected ? "flex" : "none")};
	justify-content: center;
	background: #f6f6f6;
`;
export { MainContainerWrapper, NoInternetImage, NoInternetImageWrapper };
