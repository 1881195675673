import React, { useState, useEffect, useRef } from "react";
import assets from "assets";
import { Icon } from "antd";
import toast from "common/Toast";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";

import "./scss/ArtistSignature.style.scss";
import "./scss/DigtalCertificateAuthenticity.scss";
import { authorizePostFormData, postFormData } from "utils/apiConfig";
import { isEmpty, isNil } from "lodash";

const ArtistSignature = ({ signatureData, closeModal, userToken }) => {
	const attach_image = useRef(null);
	const [signatureFile, setSignatureFile] = useState();
	const onImageSelect = async (e, type) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		e.target.value = null;
		let image = "";
		let img = new Image();
		img.src = window.URL.createObjectURL(file);

		if (file) {
			// let compressedImage = await Util.compressImage(file);

			image = reader.result;
			reader.onloadend = () => {
				// Creating a media object
				let mediaObj = {
					index: generateKey(),
					media: file,
					mediaResult: reader.result,
				};
				setSignatureFile(mediaObj);
			};

			reader.readAsDataURL(file);
		}
	};
	const generateKey = (pre = "MEDIA") => {
		return `${pre}_${new Date().getTime()}`;
	};
	const submitSignature = () => {
		var formData = new FormData();
		formData.append("Signature", signatureFile.media);
		postFormData(`UserManagment/api/UserManagement/UpdateSignature`, formData, userToken).then((res) => {
			if (res.resultCode === 1) {
				toast(toastMessage.uploadSuccess, "success");
				closeModal();
			} else if (res.resultCode === 2) {
				toast(res.error && res.error.message, "error");
			}
		});
	};

	const renderImageBtn = () => {
		if (!signatureFile && signatureData === "") {
			return (
				<div onClick={(e) => attach_image.current.click()} className="image-container">
					<img src={assets.images.addImageIcon} alt="add" />

					<p>Upload your signature here</p>
				</div>
			);
		} else if (signatureData && !signatureFile) {
			return (
				<div className="image-container">
					<img src={`data:image/png;base64,${signatureData}`} alt="Signature " />
					<div className="edit-icon-container">
						<Icon className="edit-icon" type="edit" theme="filled" twoToneColor="#948664" onClick={(e) => attach_image.current.click()} />
					</div>
					<p>Update your signature here</p>
				</div>
			);
		} else if (signatureFile && signatureFile.mediaResult) {
			return (
				<div className="image-container">
					<img src={signatureFile.mediaResult} alt="add" />
					<div className="edit-icon-container">
						<Icon className="edit-icon" type="edit" theme="filled" twoToneColor="#948664" onClick={(e) => attach_image.current.click()} />
					</div>
					<p>Upload your signature here</p>
				</div>
			);
		}
	};
	return (
		<div className="signature-container">
			<div className="form-group">
				<input
					type="file"
					style={{ display: "none" }}
					accept=".bmp,.jpeg, .jpg, .png, .pjpeg, .pjp"
					onChange={(e) => onImageSelect(e, "image")}
					ref={attach_image}
				/>

				{renderImageBtn()}
			</div>
			<div className="button-container">
				<button className="cancel-button" onClick={closeModal}>
					Cancel
				</button>
				<button className="submit-button" onClick={submitSignature}>
					Upload
				</button>
			</div>
		</div>
	);
};

export default ArtistSignature;
