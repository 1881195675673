import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import LocalStorage from "./util/LocalStorageUtility";
import * as LoginHooks from "./containers/LoginContainer";
import { Provider as ReduxProvider } from "react-redux";
import { configureStore } from "./store/store";
import * as userManagementHooks from "./containers/UserManagementContainer";
import * as orderHooks from "./containers/OrderManagementContainer";
import useToggleTwoFactor from "./containers/ToggleTwoFactorContainer";
import useChangePassword from "./containers/ChangePasswordContainer";
import * as cartHooks from "./containers/CartContainer";
import useUpdateUserInfo from "./containers/UpdateUserInfoContainer";
import useChooseUserRole from "./containers/ChooseUserRoleContainer";
import useTwoFactorAuth from "./containers/TwoFactorAuthContainer";
import useResetUserAccount from "./containers/ResetAccountContainer";
import useTogglePushNotification from "./containers/TogglePushNotificationContainer";
import UserManagement from "./util/UserManagement";
import * as productDetailHooks from "./containers/ProductDetailContainer";
import * as shippingAddressHooks from "./containers/ShippingAddressContainer";
import * as commentHooks from "./containers/CommentsContainer";
import * as AuthenticationHook from "./containers/AuthenticationContainer";
import * as recommendationsHooks from "./containers/RecommendationsContainer";
import useInitialUpdate from "./containers/InitialUpdateContainer";
import useProductList from "./containers/ProductListContainer";
import useAddProductContainer from "./containers/AddProductContainer";
import useUpdateProductContainer from "./containers/UpdateProductContainer";
import useDeleteProduct from "./containers/DeleteProductContainer";
import * as artistTypesHooks from "./containers/ArtistTypesContainer";
import * as artistBasicProfileHooks from "./containers/ArtistBasicProfileContainer";
import * as artistFollowHooks from "./containers/ArtistFollowContainer";
import * as artistInterestsHooks from "./containers/ArtistInterestsContainer";
import * as categoryHooks from "./containers/CategoryContainer";
import * as filterArtworksHooks from "./containers/FilterArtworksContainer";
import * as artistUserTypesHooks from "./containers/ArtistUserTypesContainer";
import * as artistHooks from "./containers/ArtistContainer";
import * as homeHooks from "./containers/HomeContainer";
import * as blockchainHooks from "./containers/BlockchainContainer";
import Util from "./util/Util";
import * as artistCollectionHooks from "./containers/ArtistCollectionContainer";
import * as galleryHooks from "./containers/GalleryContainer";
import * as BulkUploadHooks from "./containers/BulkUploadContainer";
import * as paymentHooks from "./containers/PaymentContainer";
import * as billingAddressHooks from "./containers/BillingAddressContainer";
import * as pickUpAddressHooks from "./containers/PickUpContainer";
import useRefreshTokenContainer from "./containers/RefreshTokenContainer";
import useRequestInterception from "./containers/RequestInterceptor";
import * as kycDocumentHooks from "./containers/KYCDocumentContainer";
import useWalletContainer from "./containers/WalletContainer";
const initialState = {};
const ReduxStore = configureStore(initialState);

export {
	cartHooks,
	LoginHooks,
	userManagementHooks,
	useToggleTwoFactor,
	useChangePassword,
	useUpdateUserInfo,
	useChooseUserRole,
	useTwoFactorAuth,
	useTogglePushNotification,
	useResetUserAccount,
	orderHooks,
	useInitialUpdate,
	useAddProductContainer,
	useUpdateProductContainer,
	useDeleteProduct,
	artistTypesHooks,
	artistUserTypesHooks,
	artistBasicProfileHooks,
	artistFollowHooks,
	artistInterestsHooks,
	useProductList,
	productDetailHooks,
	filterArtworksHooks,
	shippingAddressHooks,
	commentHooks,
	AuthenticationHook,
	recommendationsHooks,
	categoryHooks,
	artistCollectionHooks,
	artistHooks,
	homeHooks,
	galleryHooks,
	blockchainHooks,
	BulkUploadHooks,
	paymentHooks,
	billingAddressHooks,
	pickUpAddressHooks,
	useRefreshTokenContainer,
	useRequestInterception,
	kycDocumentHooks,
	useWalletContainer,
};

export default {
	ReduxStore,
	ReduxProvider,
	Util,
	UserManagement,
	LocalStorage,
};
