import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
    deleteProduct: {}
};

const DeleteProductReducer = createApiReducer(initialState, [
	types.DELETE_PRODUCT_REQUEST
	]);

export default DeleteProductReducer;
