import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { authorizedGet, authorizePost, unAuthorizedGet, unthorizePost } from "utils/apiConfig";
import moment from "moment";
import SDK from "ecommerce-sdk/src/index";
import { set } from "react-ga";
import toast from "common/Toast";
import { toastMessage } from "utils/toastMessages";
import { MoeTrackEvent } from "utils/moEngageHooks";

const useAuctionList = () => {
	const history = useHistory();
	const [auctionList, setAuctionList] = useState();
	const [auctionListLoading, setAuctionListLoading] = useState(false);
	const [auctionLotItems, setAuctionLotItems] = useState();
	const [auctionOverViews, setauctionOverViews] = useState();
	const [currentAuctions, setCurrentAuctions] = useState([]);
	const [allAuctionList, setAllAuctionList] = useState([]);
	const [userToken, setUserToken] = useState();
	const [loggedIn, setLoggedIn] = useState(false);
	const [featuredAuctionList, setFeaturedAuctionList] = useState([]);
	const [auctionBlogList, setAuctionBlogList] = useState([]);
	const [auctionLotSavedLoadder, setAuctionLotSavedLoadder] = useState(false);

	useEffect(() => {
		async function fetchUserToken() {
			let token = await SDK.UserManagement.getAuthToken();
			setUserToken(token);
		}

		fetchUserToken();
	}, []);
	useEffect(() => {
		const checkLoggedIn = async () => {
			const isLoggedInResult = await SDK.UserManagement.isLoggedIn();
			setLoggedIn(isLoggedInResult);
		};
		checkLoggedIn();
	}, []);

	const getCurrentTimeZoneName = () => {
		try {
			var time = new Date();
			var timeZoneOffset = time.getTimezoneOffset();
			var zone = new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
			return zone;
		} catch (e) {
			return "";
		}
	};

	const getAuctionList = (tab, pageNum) => {
		setAuctionListLoading(true);
		if (tab === "current") {
			unAuthorizedGet("/OrderManagement/api/Auction/List?PageNum=0&PageSize=0&status=2&status=1&status=0&SortOrder=0").then((res) => {
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setAuctionList(res.response.data.auctionList);
						setCurrentAuctions(res.response.data.auctionList);
						setAuctionListLoading(false);
					}
				} else if (res.resultCode === 2) {
					setAuctionListLoading(false);
				}
			});
		} else if (tab === "past") {
			let statusCode = 3;
			unAuthorizedGet(`/OrderManagement/api/Auction/List?PageNum=${pageNum}&PageSize=10&status=${statusCode}&SortOrder=1`).then((res) => {
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setAuctionList(res.response.data.auctionList);
						setAuctionListLoading(false);
					}
				} else if (res.resultCode === 2) {
					setAuctionListLoading(false);
				}
			});
		}
	};
	const intrestedAction = (email, auctionId) => {
		if (userToken !== null) {
			let data = {
				AuctionId: auctionId,
			};
			authorizePost("/OrderManagement/api/AuctionInterest", data, userToken).then((res) => {
				if (res.resultCode === 1) {
					MoeTrackEvent("Interest Capture", { Email: localStorage.getItem("userName") });
					toast(res && res.response && res.response.data, "success");
				} else if (res.resultCode === 2) {
					toast(res && res.error && res.error.message, "warning");
				}
			});
		} else {
			let data = {
				Email: email,
				AuctionId: auctionId,
			};
			unthorizePost("/OrderManagement/api/AuctionInterest", data).then((res) => {
				if (res.resultCode === 1) {
					MoeTrackEvent("Interest Capture", data);
					toast(res && res.response && res.response.data, "success");
				} else if (res.resultCode === 2) {
					toast(res && res.error && res.error.message, "warning");
				}
			});
		}
	};

	const getAllAuctionList = (tab, pageNum) => {
		setAuctionListLoading(true);

		unAuthorizedGet("/OrderManagement/api/Auction/List?PageNum=0&PageSize=0&status=3&status=2&status=1&status=0&SortOrder=1").then((res) => {
			if (res.resultCode === 1) {
				if (res.response.result === 200) {
					let tempArray = [];
					let itreator = 0;

					res.response.data.auctionList.map((data) => {
						if (data.status !== 3) {
							tempArray.push(data);
						}

						// if (data.status === 3 && itreator > 0) {
						// } else if (data.status === 3 && itreator === 0) {
						// 	// itreator = itreator + 1;
						// 	// tempArray.push(data);
						// } else {
						// }
					});
					let lastCompletedAuction = res.response.data.auctionList.filter((item) => item.status === 3);
					if (lastCompletedAuction && lastCompletedAuction.length > 0) {
						tempArray.push(lastCompletedAuction[lastCompletedAuction.length - 1]);
					}
					setAllAuctionList(tempArray);
				}

				setAuctionListLoading(false);
			} else if (res.resultCode === 2) {
				setAuctionListLoading(false);
			}
		});
	};

	const getAuctionOverView = (lotId) => {
		setAuctionListLoading(true);
		if (userToken !== null) {
			authorizedGet(`OrderManagement/api/Auction?SlugId=${lotId}`, userToken).then((res) => {
				setAuctionListLoading(false);
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setauctionOverViews(res.response.data);
						getAuctionMappedBlogs(res.response.data._id);
					}
				} else if (res.resultCode === 2) {
					if (res.error && res.error.result === 400) {
						history.push("/page-not-found");
					}
				}
			});
		} else {
			unAuthorizedGet(`OrderManagement/api/Auction?SlugId=${lotId}`).then((res) => {
				setAuctionListLoading(false);
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setauctionOverViews(res.response.data);
					}
				} else if (res.resultCode === 2) {
					if (res.error && res.error.result === 400) {
						history.push("/page-not-found");
					}
				}
			});
		}
	};
	const getAuctionMappedBlogs = (auctionId) => {
		unAuthorizedGet(`eCommerce//api/Blogs/GetBlogs?pageNum=1&pageSize=3&AuctionId=${auctionId}`).then((res) => {
			setAuctionListLoading(false);
			if (res.resultCode === 1) {
				if (res.response.result === 200) {
					setAuctionBlogList(res.response.data && res.response.data.blogs);
				}
			}
		});
	};
	const getFeaturedAuctionLots = (AuctionSlugId) => {
		let reqdata = {
			AuctionId: "",
			AuctionSlugId: AuctionSlugId,
			PageNum: 1,
			PageSize: 100,
			SortOrder: 0,
			MinPrice: null,
			MaxPrice: null,
			SearchText: "",
			PriceRange: false,
			UniqueEdition: false,
			MultipleEdition: false,
			Rare: false,
			NoBids: false,
			ActiveBids: false,
			PopularBids: false,
			Saved: false,
			Featured: true,
		};
		setAuctionListLoading(true);
		if (userToken !== null) {
			authorizePost(`OrderManagement/api/AuctionLot/List`, reqdata, userToken).then((res) => {
				setAuctionListLoading(false);
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setFeaturedAuctionList(res.response.data);
					}
				}
			});
		} else {
			unthorizePost(`OrderManagement/api/AuctionLot/List`, reqdata).then((res) => {
				setAuctionListLoading(false);
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setFeaturedAuctionList(res.response.data);
					}
				}
			});
		}
	};
	const getAuctionLots = (data) => {
		let reqdata = {
			AuctionId: "",
			AuctionSlugId: data.AuctionSlugId,
			PageNum: parseInt(data.PageNum),
			PageSize: data.PageSize,
			SortOrder: data.SortOrder === "null" ? null : parseInt(data.SortOrder),
			MinPrice: data.MinPrice,
			MaxPrice: data.MaxPrice,
			SearchText: data.SearchText === "null" ? null : data.SearchText,
			PriceRange: data.PriceRange,
			UniqueEdition: stringToBoolean(data.UniqueEdition),
			MultipleEdition: stringToBoolean(data.MultipleEdition),
			Rare: stringToBoolean(data.Rare),
			NoBids: stringToBoolean(data.NoBids),
			ActiveBids: stringToBoolean(data.ActiveBids),
			PopularBids: stringToBoolean(data.PopularBids),
			Saved: data.Saved,
		};
		console.log("🚀 ~ file: useAuctionList.js ~ line 102 ~ getAuctionLots ~ data", data);
		setAuctionLotSavedLoadder(true);
		setAuctionLotItems([]);
		if (userToken !== null) {
			authorizePost(`OrderManagement/api/AuctionLot/List`, reqdata, userToken).then((res) => {
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setAuctionLotItems(res.response.data);
						setAuctionLotSavedLoadder(false);
					}
				} else if (res.resultCode === 2) {
					setAuctionListLoading(false);
				}
			});
		} else {
			unthorizePost(`OrderManagement/api/AuctionLot/List`, reqdata).then((res) => {
				if (res.resultCode === 1) {
					if (res.response.result === 200) {
						setAuctionLotItems(res.response.data);
						setAuctionLotSavedLoadder(false);
					}
				} else if (res.resultCode === 2) {
					setAuctionLotSavedLoadder(false);
				}
			});
		}
	};
	const viewAuctionDetails = () => {
		history.push("/auction-details");
	};

	const stringToBoolean = (string) => {
		if (string === true) {
			return true;
		} else if (string === false) {
			return false;
		} else if (string !== "" && string !== undefined) {
			switch (string.toLowerCase().trim()) {
				case "true":
				case "yes":
				case "1":
					return true;
				case "false":
				case "no":
				case "0":
				case null:
					return false;
				default:
					return Boolean(string);
			}
		}
	};
	return {
		auctionList,
		auctionListLoading,
		auctionLotSavedLoadder,
		auctionLotItems,
		auctionOverViews,
		currentAuctions,
		featuredAuctionList,
		loggedIn,
		allAuctionList,
		auctionBlogList,

		/* functions */
		getAuctionList,
		viewAuctionDetails,
		getAuctionLots,
		getAuctionOverView,
		getCurrentTimeZoneName,
		getFeaturedAuctionLots,
		getAllAuctionList,
		stringToBoolean,
		getAuctionMappedBlogs,
		intrestedAction,
	};
};

export default useAuctionList;
