import { Modal } from "antd";
import styled from "styled-components";
import { BaseTheme } from "utils/theme";
const DialogModal = styled(Modal)`
	padding: 1rem;
	.ant-modal-content {
		box-shadow: 0px 6px 12px #00000029;
		border-radius: 4px;		
		@media (max-width: 725px) {
			top: 30px;
		}
		.ant-modal-close {
			top: -10px;
			right: -10px;
			.ant-modal-close-x {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				line-height: initial;
				background-color: #fff;
				border-radius: 50%;
				box-shadow: 0px 3px 6px #00000029;
				border: 1px solid ${BaseTheme.primary};
				font-size: 10px;
				@media (max-width: 725px) {
					width: 25px;
					height: 25px;
				}
			}
			@media (max-width: 725px) {
				top: -8px;
				right: -5px;
			}
		}
		.ant-modal-header {
			border: 0;
			padding: 20px 24px;
			.ant-modal-title {
				color: ${BaseTheme.primary};
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				font-family: "Cormorant Garamond", sans-serif;
			}
		}
		.ant-modal-body {
			padding-top: 0px;
			padding-bottom: 20px;
			font-family: "Montserrat", sans-serif;
			@media (max-width: 425px) {
				padding-left: 14px;
				padding-right: 14px;
			}
		}
	}
`;

export { DialogModal };
