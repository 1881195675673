import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class ArtistTypesService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Get ArtistTypes
	static async getArtistTypes() {
		return this.service.execute(this.API.getArtistTypes, HTTP_METHOD.GET, {});
	}
	// Get ArtistTypesById
	static async getArtistTypesById(artistTypeId) {
		let params = { artistTypeId };
		return this.service.execute(this.API.getArtistTypesById, HTTP_METHOD.GET, {
			params,
		});
	}
	// Add ArtistTypes
	static async addArtistTypes(artistTypesToBeAdded) {
		let body = { ...artistTypesToBeAdded };
		return this.service.execute(this.API.addArtistTypes, HTTP_METHOD.POST, {
			body,
		});
	}
	// Update ArtistTypes
	static async updateArtistTypes(artistTypesToBeUpdated, artistTypeId) {
		let body = { ...artistTypesToBeUpdated };
		let params = { artistTypeId };
		return this.service.execute(this.API.updateArtistTypes, HTTP_METHOD.PUT, {
			body,
			params,
		});
	}
	// Delete ArtistTypes
	static async deleteArtistTypes(artistTypeId) {
		let params = { artistTypeId };
		return this.service.execute(this.API.deleteArtistTypes, HTTP_METHOD.DELETE, {
			params,
		});
	}

	// Filter Artist by ArtistTypes
	static async filterArtist(artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, CountryName, Style, Medium, IsFeatured, UserSortOptions, SortOrder) {
		let body = { artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, CountryName, Style, Medium, IsFeatured, UserSortOptions, SortOrder };
		return this.service.execute(this.API.filterArtistByTypes, HTTP_METHOD.POST, {
			body,
		});
	}

	// Get ArtistUserTypes
	static async getArtistUserTypes() {
		const resp = await APIService.doGet("getArtistTypes");
		if (resp) {
			return resp;
		}
	}
	// Get ArtistUserTypesById
	static async getArtistUserTypesByUserId(UserId) {
		let params = { UserId };
		const resp = await APIService.doGet("getArtistTypesById", params);
		if (resp) {
			return resp;
		}
	}
	// Update ArtistUserTypes
	static async updateArtistUserTypes(artistTypesToBeUpdated) {
		const resp = await APIService.doPut("updateArtistTypes", artistTypesToBeUpdated);
		if (resp) {
			return resp;
		}
	}

	// Get ArtistTypes for gallery admin
	static async filterAdminArtistByTypes(artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, CountryName, Style, Medium, IsFeatured, UserSortOptions, SortOrder) {
		let body = { artistTypeId, searchKey, alphabetSearch, pageNum, pageSize, CountryName, Style, Medium, IsFeatured, UserSortOptions, SortOrder };
		return this.service.execute(this.API.filterAdminArtistByTypes, HTTP_METHOD.POST, {
			body,
		});
	}

}
