// Common styled components
import styled, { css, keyframes } from "styled-components";
import { Checkbox, Pagination } from "antd";

// ---------------------------------- GENERAL ------------------------------ //
const wrapperPadding = css`
	padding: 0px 4%;
	@media screen and (max-width: 973px) {
		padding: 0px 4%;
	}
	@media screen and (max-width: 917px) {
		padding: 0px 3%;
	}
	@media screen and (max-width: 890px) {
		padding: 0px 2.5%;
	}
	@media screen and (max-width: 880px) {
		padding: 0px 2%;
	}
`;

// Animation
const SlideInFromTop = keyframes`
	0% {
		transform: translateY(-14px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
`;

// animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
const SlideInFromLeft = keyframes`
	0% {
		transform: translateX(-200px);
	}
	100% {
		transform: translateX(0px);
	}
`;

const StyledAntCheckbox = styled(Checkbox)`
	& {
		.ant-checkbox-inner {
			border-color: #000000;
			border-radius: 3px;
			width: 25px;
			height: 25px;
		}
		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #000000;
			border-color: #000000;
		}
		.ant-checkbox-checked::after {
			border: 1px solid #000000;
		}
		.ant-checkbox-wrapper:hover .ant-checkbox-inner,
		.ant-checkbox:hover .ant-checkbox-inner,
		.ant-checkbox-input:focus + .ant-checkbox-inner {
			border-color: #000000;
		}
		.ant-checkbox-checked::after {
			border: 1px solid #000000;
			border-radius: 3px;
		}
		.ant-checkbox-inner::after {
			left: 22%;
		}
		span {
			font-size: 13px;
			color: #0a0a0a;
			font-family: Lato !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-size: 16px !important;
			line-height: 16px;

			color: #000000;
		}
	}
	&.ant-checkbox-wrapper:hover .ant-checkbox-inner {
		border-color: #000000 !important;
	}
	.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
		border-color: #ffffff;
	}
`;

const horizontalScroll = (height) => {
	return `
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: thin;
        scrollbar-color: transparent;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 0;
        }
        &::-webkit-scrollbar:horizontal {
            height: ${height};
        }
        &::-webkit-scrollbar-thumb {
            background-color: #6B6969;
            border-radius: 10rem;
            border: 2px solid transparent !important;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10rem;
            background-color: #d9d9d9 !important;
        }
    `;
};

const verticalScroll = (width) => {
	return `
        overflow-x: hidden;
        overflow-y: scroll;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: thin;
        scrollbar-color: transparent;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: ${width};
        }
        &::-webkit-scrollbar:horizontal {
            height: 0px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #6B6969;
            border-radius: 10rem;
            border: 2px solid transparent !important;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10rem;
            background-color: #d9d9d9 !important;
        }
    `;
};

const Hr = styled.hr`
	width: 100%;
	margin-top: ${(props) => {
		return props.top || "20px";
	}};
	margin-bottom: ${(props) => {
		return props.bottom;
	}};
	border-color: ${(props) => {
		return props.color;
	}};
	border-top: 0;
	border-left: 0;
	border-width: ${(props) => {
		return props.width || "1px";
	}};
	@-moz-document url-prefix() {
		border-width: 1px;
	}
`;

const HeadingCharcoalGrey = styled.h1`
	color: #5d5753;
	font-size: 23px;
	font-weight: 600;
	margin-top: ${(props) => {
		return props.top || "0.5rem";
	}};
	margin-bottom: ${(props) => {
		return props.bottom;
	}};
	text-align: ${(props) => {
		return props.align;
	}};
	position: ${(props) => {
		return props.position;
	}};
	@media only screen and (max-device-width: 768px) {
		margin: 15px 0px;
	}
	@media only screen and (max-device-width: 480px) {
		margin: 15px 0px;
	}
`;
const HeadingBlack = styled.h2`
	color: #000000;
	font-size: 23px;
	font-weight: 600;
	margin-top: ${(props) => {
		return props.top || "2rem";
	}};
	margin-bottom: ${(props) => {
		return props.bottom;
	}};
`;

const MandatoryText = styled.span`
	color: #ff0000;
`;

const StyledPagination = styled(Pagination)`
	align-self: center;
	text-align: center;
	margin: 7px 0;
	.ant-pagination-item-link,
	.ant-pagination-item {
		border-radius: 0% !important;
		font-weight: 500;
		min-width: 32px;
		min-height: 32px;
		vertical-align: middle;
		padding: 0;
	}
	.ant-pagination-prev {
		:hover,
		:focus,
		:active {
			a {
				border-color: #eeeeee;
				color: #ffff;
			}
			.ant-pagination-item-link {
				border-color: #000;
				color: #000;
			}
		}
	}
	.ant-pagination-next {
		:hover,
		:focus,
		:active {
			a {
				border-color: #000;
				color: #000;
			}
			.ant-pagination-item-link {
				border-color: #000;
				color: #000;
			}
		}
	}
	.ant-pagination-item:focus,
	.ant-pagination-item:hover,
	.ant-pagination-item-active {
		border-color: #000;

		a {
			background-color: #000;
			color: #fff;
		}
	}
	.ant-pagination-disabled {
		display: none;
	}
	.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
	.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
		color: #ad9972;
	}
`;

export {
	StyledAntCheckbox,
	SlideInFromTop,
	SlideInFromLeft,
	wrapperPadding,
	horizontalScroll,
	verticalScroll,
	Hr,
	HeadingCharcoalGrey,
	HeadingBlack,
	MandatoryText,
	StyledPagination,
};
