import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProfileDropdown.css";
import { ProfileDropdownWrapper, LogoutPopWrapper } from "./ProfileDropdown.styled";
import toast from "common/Toast";
import SDK, { productDetailHooks, LoginHooks, userManagementHooks } from "ecommerce-sdk/src/index";
import { includes } from "lodash";
import useModal from "utils/useModal";
import ModalTypes from "utils/ModalTypes";
import { toastMessage } from "utils/toastMessages";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { disconnectWallet } from "utils/walletCommonFunction";
import DialogBox from "common/DialogBox";
import { Result, Button } from "antd";
import StyledButton from "common/StyledButton";
import { MoeLogout, MoeTrackEvent } from "utils/moEngageHooks";

export const ProfileDropdown = ({ dismiss, allowSellArtwork, userInfo, onHandleSideBar }) => {
	const { useLogout } = LoginHooks;

	const [logoutUser, logoutData, logoutError] = useLogout();
	const { useCheckAddProductEligibility } = productDetailHooks;

	// const [, logout] = useAutoLogout();
	const [userRoles, setUserRole] = useState();
	const [, checkAddProductEligibilityData] = useCheckAddProductEligibility();
	const { t } = useTranslation();
	const [isWalletModelOpen, setIsWalletModelOpen] = useState(false);

	const isTabletOrMobileDevice = useMediaQuery({
		query: "(max-device-width: 1224px)",
	});
	useEffect(() => {
		SDK.UserManagement.getUserRoles((result) => setUserRole(result));
	}, []);

	const [, , showModal] = useModal(ModalTypes.sellAddArtworkModal);

	const onItemClick = () => dismiss && dismiss();
	useEffect(() => {
		if (logoutError) {
			SDK.UserManagement.logout();

			window.location.href = "/";
		} else if (logoutData) {
			MoeTrackEvent("User Logout", {
				userName: userInfo.name,
				email: userInfo.email,
				uniqueId: userInfo.userId
			})
			MoeLogout();
			SDK.UserManagement.logout();
			window.location.href = "/";
		}
	}, [logoutData, logoutError]);
	const onCancelModal = () => {
		setIsWalletModelOpen(false);
	};

	const onClickConfirmBox = async (option) => {
		if (option === "yes") {
			disconnectWallet();
		}
		let token = await SDK.UserManagement.getRefreshToken();
		logoutUser(token);
		toast(t(toastMessage.loggedOut), "success");
	};

	return (
		<ProfileDropdownWrapper>
			{userInfo && userInfo.kycStatus === 0 && (
				<Link
					to="#"
					onClick={() => {
						if (isTabletOrMobileDevice) {
							onHandleSideBar();
						}

						showModal(ModalTypes.kycDocument, {
							location: "dropdown",
						});
					}}
				>
					{t("Pending KYC Upload")}
				</Link>
			)}
			{(() => {
				if (includes(userRoles, "ARTIST")) {
					return <Link to="/artist/dashboard">{t("My Dashboard")}</Link>;
				} else if (includes(userRoles, "GALLERY")) {
					return <Link to="/galleries/me">My Profile</Link>;
				}
			})()}

			<Link to="/profile/general" onClick={onItemClick}>
				{t("General Account Settings")}
			</Link>
			<Link to="/profile/artworks" onClick={onItemClick}>
				{t("Favorite Artworks")}
			</Link>
			{/*
			<Link to="/profile/address" onClick={onItemClick}>
				{t("Address Book")}
			</Link> */}
			<Link
				to={includes(userRoles, "ARTIST") || includes(userRoles, "GALLERY") ? "/profile/received-orders" : "/profile/orders"}
				onClick={onItemClick}
			>
				{t("Orders")}
			</Link>
			{/* <Link to="/profile/settings" onClick={onItemClick}>
				Settings
			</Link> */}
			{/* {allowSellArtwork && (
				<Link to="#">
					<span
						onClick={() => {
							if (checkAddProductEligibilityData && checkAddProductEligibilityData.isEligible) {
								let display = true;
								showModal(ModalTypes.sellAddArtworkModal, display);
							} else if (checkAddProductEligibilityData && !checkAddProductEligibilityData.isEligible) {
								showModal(ModalTypes.responseRedirectionModal,
									{
										data: checkAddProductEligibilityData,
										showButton: (checkAddProductEligibilityData && checkAddProductEligibilityData.messageCode === 2) || (checkAddProductEligibilityData && checkAddProductEligibilityData.messageCode === 4) ? false : true
									});
							}
							dismiss && dismiss();
						}}
					>
						Sell Your Artwork
					</span>
				</Link>
			)} */}
			<Link
				to="/"
				onClick={async (e) => {
					e.preventDefault();
					// if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
					// 	setIsWalletModelOpen(true);
					// } else {
					let token = await SDK.UserManagement.getRefreshToken();
					logoutUser(token);
					toast(t(toastMessage.loggedOut), "success");
					// }
				}}
			>
				{t("Logout")}
			</Link>

			<DialogBox
				title={t("Wallet Connect Confirmation")}
				centered
				open={isWalletModelOpen}
				closable={true}
				maskClosable={true}
				onCancel={onCancelModal}
			>
				<LogoutPopWrapper
					status="warning"
					title="Do you want to disconnect wallet?"
					extra={[
						<StyledButton variant="invert" key="yes" width={"6rem"} margin={"0"} onClick={() => onClickConfirmBox("yes")}>
							Yes
						</StyledButton>,
						<StyledButton variant="invert" key="no" width={"6rem"} margin={"0"} onClick={() => onClickConfirmBox("no")}>
							No
						</StyledButton>,
					]}
				/>
			</DialogBox>
		</ProfileDropdownWrapper>
	);
};
