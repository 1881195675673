import styled from "styled-components";
import { Row, Col } from "antd";
import { Field } from "formik";
import StyledButton from "common/StyledButton";
import { Input } from "antd";
import DialogBox from "common/DialogBox";
import { InputWrapper } from "common/FloatingLabeledInput/FloatingLabeledInput.styled";
import assets from "assets";
import { ImgNextGen } from "common/NextGenImage";

const { Search } = Input;

const AddCollectionInputsWrapper = styled(Row)`
	padding: 8px;
	margin-bottom: 40px;
`;

const AddCollectionImageInput = styled.div`
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	height: 150px;
	width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 588px) {
		margin-bottom: 2rem;
	}
`;
const AddPhotoImg = styled(ImgNextGen)`
	width: 100%;
	/* width: -moz-available; WebKit-based browsers will ignore this. */
	/* width: -webkit-fill-available; Mozilla-based browsers will ignore this. */
	/* width: fill-available; */
	object-fit: contain;
`;

const AddCollectionTextarea = styled(Field)`
	color: #515151;
	border: 1px solid #e5e5e5;
	border-radius: 1px;
	height: 5.8rem;
	width: 100%;
	padding: 10px;
	outline: none !important;
`;

const AddCollectionInputCol = styled(Col)`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.form-control {
		display: flex;
		position: relative;
		margin-bottom: 1rem;
		label.description {
			position: absolute;
			background-color: #fff;
			left: 13px;
			font-size: 14px;
			top: 0;
			line-height: 0;
			padding: 1px 5px;
			color: #878787;
		}
		${InputWrapper} {
			margin-top: 0;
		}
	}
`;

const ArtworkPopupWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const AddArtworkInnerWrapper = styled.div`
	text-align: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	
`;

const CollectionModal = styled(DialogBox)`
	height: 90%;
	.ant-modal-content {
		height: 100%;
		.ant-modal-close {
			z-index: 1;
			top: 30px;
			right: 30px;
		}
		.ant-modal-body {
			height: 100%;
			padding-bottom: 0;
		}
	}
`;

const FixedAddButton = styled.div`
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const AddNewButton = styled(StyledButton)`
	margin: 0 !important;
`;
const HR = styled.hr`
	width: 100%;
	margin: 0;
	border-color: ${(props) => {
		return props.color || "#F6F6F6";
	}};
	border-top: 0;
	border-left: 0;
	border-width: ${(props) => {
		return props.width || "2px";
	}};
`;

const AddCollectionModalWrapper = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100% - 50px);
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
`;

const ProductName = styled.p`
	padding-left: 10px;
	text-align: left;
	margin-bottom: 5px;
	color: #000000;
`;

const ArtistName = styled.p`
	padding: 0;
	text-align: left;
	font-weight: 500;
	color: #000000;
`;

const ArtPrice = styled.p`
	padding: 0;
	text-align: right;
	padding-right: 10px;
	font-weight: 500;
	color: #000000;
`;

const StyledSearch = styled(Search)`
	margin: 50px 32% 50px 32%;
	width: 36%;
	height: 44px;
	.ant-input {
		border-radius: 0;
	}
	.ant-input-suffix {
		width: 22px;
		height: 22px;
		background: url(${assets.images.searchIcon}) no-repeat center;
	}
	.anticon {
		visibility: hidden;
	}
`;

const ViewMore = styled.h3`
	font-size: 13px;
	text-align: center;
	font-weight: bold;
	padding-bottom: 15px;
	cursor: pointer;
	margin-bottom: 0;
`;

const InvisibleInput = styled.input`
	display: none;
`;
const Img = styled.img`
	opacity: 0.6;
	margin-right: 6px;
	vertical-align: baseline;
`;

const ProductRow = styled(Row)`
	padding-left: 6px;
`;
const SelectedArtworkPopupWrapper = styled.div`
	display: flex;
	flex-direction: column;
	h4{
		
	}
`;
export {
	AddCollectionImageInput,
	AddCollectionInputsWrapper,
	AddPhotoImg,
	AddCollectionTextarea,
	AddCollectionInputCol,
	ArtworkPopupWrapper,
	AddArtworkInnerWrapper,
	CollectionModal,
	FixedAddButton,
	AddNewButton,
	HR,
	AddCollectionModalWrapper,
	ArtistName,
	ArtPrice,
	StyledSearch,
	ProductName,
	ViewMore,
	ProductRow,
	Img,
	InvisibleInput,
	SelectedArtworkPopupWrapper
};
