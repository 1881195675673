import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class ArtistServices {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get Gallery List
	static async getGalleryList() {
		return this.service.execute(this.API.getGalleryList, HTTP_METHOD.GET);
	}

	//Get Gallery List by UserId
	static async getGalleryListByUserId(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getGalleryListByUserId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Gallery
	static async addNewGallery(body) {
		return this.service.execute(this.API.addNewGallery, HTTP_METHOD.MULTIPART_POST, {
			body
		});
	}

	// Update Gallery
	static async updateGallery(galleryId, body) {
		const urlDirectParams = `/${galleryId}`;
		return this.service.execute(this.API.updateGallery, HTTP_METHOD.MULTIPART_PUT, {
			body,
			urlDirectParams
		});
	}

	// Delete From Gallery
	static async deleteFromGallery(galleryId) {
		const urlDirectParams = `/${galleryId}`;
		return this.service.execute(this.API.deleteFromGallery, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	// Get Artists
	static async getAllArtists(params) {
		return this.service.execute(this.API.getArtists, HTTP_METHOD.GET, { params });
	}

	//Get Event List
	static async getEventList() {
		return this.service.execute(this.API.getEventList, HTTP_METHOD.GET);
	}

	//Get Event List by UserId
	static async getEventListByUserId(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getEventListByUserId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Event
	static async addNewEvent(body) {
		return this.service.execute(this.API.addNewEvent, HTTP_METHOD.MULTIPART_POST, {
			body
		});
	}

	// Update Event
	static async updateEvent(body, eventId) {
		const urlDirectParams = `/${eventId}`;
		return this.service.execute(this.API.updateEvent, HTTP_METHOD.MULTIPART_PUT, {
			body,
			urlDirectParams
		});
	}

	// Delete From Event
	static async deleteFromEvent(_id) {
		const urlDirectParams = `/${_id}`;
		return this.service.execute(this.API.deleteFromEvent, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	//Get Award List
	static async getAwardList() {
		return this.service.execute(this.API.getAwardList, HTTP_METHOD.GET);
	}

	//Get Award List by UserId
	static async getAwardListByUserId(userId) {
		let params = { userId };
		return this.service.execute(this.API.getAwardListByUserId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Award
	static async addNewAward(Year, Award) {
		let body = { Year, Award };
		return this.service.execute(this.API.addNewAward, HTTP_METHOD.POST, { body });
	}

	// Update Award
	static async updateAward(Year, Award, awardId) {
		let urlDirectParams = `/${awardId}`;
		let body = { Year, Award };
		return this.service.execute(this.API.updateAward, HTTP_METHOD.PUT, { body, urlDirectParams });
	}

	// Delete Award
	static async deleteAward(awardId) {
		const urlDirectParams = `/${awardId}`;
		return this.service.execute(this.API.deleteAward, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	//Get Historical Data List
	static async getHistoricalDataList() {
		return this.service.execute(this.API.getHistoricalDataList, HTTP_METHOD.GET);
	}

	//Get Historical Data List by UserId
	static async getHistoricalDataListByUserId(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getHistoricalDataListByUserId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}

	// Add New Historical Data
	static async addNewHistorical(Year, Events) {
		let body = { Year, Events };
		return this.service.execute(this.API.addNewHistorical, HTTP_METHOD.POST, { body });
	}

	// Update Historical Data
	static async updateHistoricalData(Year, Events, historicalId) {
		let urlDirectParams = `/${historicalId}`;
		let body = { Year, Events };
		return this.service.execute(this.API.updateHistoricalData, HTTP_METHOD.PUT, { body, urlDirectParams });
	}

	// Delete Historical Data
	static async deleteHistoricalData(historicalId) {
		const urlDirectParams = `/${historicalId}`;
		return this.service.execute(this.API.deleteHistoricalData, HTTP_METHOD.DELETE, { urlDirectParams });
	}

	// Get My Blogs
	static async getMyBlogs(pageNum = 1, pageSize = "10") {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getMyBlogs, HTTP_METHOD.GET, { params });
	}

	// Get Blogs by UserId
	static async getBlogsByUser(pageNum = 1, pageSize = "10", UserId) {
		let params = { pageNum, pageSize, UserId };
		return this.service.execute(this.API.getBlogsByUser, HTTP_METHOD.GET, { params });
	}

	// Add Blog
	static async addBlog(body) {
		return this.service.execute(this.API.addBlog, HTTP_METHOD.POST, { body });
	}

	// Update Blog
	static async updateBlog(params, body) {
		return this.service.execute(this.API.updateBlog, HTTP_METHOD.PUT, { params, body });
	}

	// Delete Blog
	static async deleteBlog(params) {
		return this.service.execute(this.API.deleteBlog, HTTP_METHOD.DELETE, { params });
	}

	// Get Gallery Membership Requests
	static async getGalleryMembershipRequests(Status) {
		let params = { Status };
		return this.service.execute(this.API.getGalleryMembershipRequests, HTTP_METHOD.GET, { params });
	}

	// Approve Gallery Request
	static async updateGalleryMembershipRequest(Status, GalleryId) {
		let params = { Status, GalleryId };
		return this.service.execute(this.API.updateGalleryMembershipRequest, HTTP_METHOD.PUT, { params });
	}
}
