import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class AuthenticationService {
  static API = APIUrlConstants.API_URLS;
  static service = new BaseService();

  //Register [User]
  static async registerUser(body) {
    return this.service.execute(
      this.API.registerUser,
      HTTP_METHOD.MULTIPART_POST,
      {
        body
      }
    );
  }

  // Resend Confirmation OTP
  static async resendOTP(Email) {
    let params = { Email };
    return this.service.execute(this.API.resendOTP, HTTP_METHOD.POST, {
      params,
      isAuthorizedAPI: false
    });
  }

  // Send OTP
  static async sendOTPToMail(email) {
    let body = { Email: email };
    return this.service.execute(this.API.sendOtp, HTTP_METHOD.POST, {
      body
    });
  }

  // OTP Verification
  static async validateOTP(email, otp) {
    let body = { Email: email, Otp: otp };
    return this.service.execute(this.API.validateOtp, HTTP_METHOD.POST, {
      body
    });
  }

  // Reset Password
  static async resetPassword(email, password, resetPasswordToken) {
    let body = {
      Email: email,
      Password: password,
      resetPasswordToken: resetPasswordToken
    };
    return this.service.execute(this.API.resetPassword, HTTP_METHOD.POST, {
      body
    });
  }
}
