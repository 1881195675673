import * as types from "./ActionsTypes";
import { AddProductService } from "../services/AddProductService";

// Delete Product
export const deleteProductAction = stockId => {
	return {
		actionType: types.DELETE_PRODUCT_REQUEST,
		callAPI: () => AddProductService.deleteProduct(stockId),
		stateObject: "deleteProduct",
		isTransient: true
	};
};
