import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";


export class KYCDocumentService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();
	// Update or Add Kyc Document
	static async updateKycDocument(body) {
		return this.service.execute(this.API.updateKycDocument, HTTP_METHOD.MULTIPART_POST, {body});
    }

    //Get Own Kyc Document
	static async getOwnKycDocument() {
		return this.service.execute(this.API.getOwnKycDocument, HTTP_METHOD.GET, {});
	}
}

