const routes = {
	"/": "Home",
	"/art": "Artwork",
	"/profile": "My Dashboard",
	"/profile/general": "General Account Settings",
	"/profile/artworks": "Favorite Artworks",
	"/profile/following": "Following",
	"/profile/followers": "Followers",
	"/profile/quotes": "Quotes",
	"/profile/group-requests": "Group Requests",
	"/profile/signature-pending-orders": "Signature Pending Orders",
	"/profile/disclose-price": "Disclose Price",
	"/profile/address": "Address Book",
	"/profile/billing-address": "Billing Address",
	"/profile/pickup-address": "Pickup Address",
	"/notifications": "Notifications",
	"/profile/settings": "Settings",
	"/profile/blockchain-account": "Blockchain Accounts",
	"/artist": "Artist",
	"/buyer/dashboard": "My Profile",
	"/dashboard": "My Dashboard",
	"/artist/dashboard": "My Dashboard",
	"/dashboard/edit": "Edit Profile",
	"/dashboard/artworks-inventory": "Artwork Inventory",
	"/galleries": "Galleries",
	"/collections": "Collections",
	"/artist/dashboard/user-collections": "View Collections",
	"/dashboard/add-artwork": "Add Artwork",
	"/dashboard/add-artwork/basic": "Basic",
	"/dashboard/add-artwork/artist-details": "Artistic Details",
	"/dashboard/add-artwork/pricing": "Pricing",
	"/dashboard/add-artwork/images": "Images",
	"/dashboard/add-artwork/logistics": "Logistics",
	"/dashboard/add-artwork/signing": "Signature",
	"/dashboard/add-artwork/complete": "Complete",
	"/edit-artwork": "Edit and Update Artwork",
	"/edit-artwork/basic": "Basic",
	"/edit-artwork/pricing": "Pricing",
	"/edit-artwork/logistics": "Logistics",
	"/edit-artwork/images": "Images",
	"/edit-artwork/artist-details": "Artistic Details",
	"/cart": "Cart",
	"/cart/checkout": "Checkout",
	"/cart/checkout/choose-delivery-address": "Choose Delivery Address",
	"/cart/checkout/choose-billing-address": "Choose Billing Address",
	"/cart/checkout/blockchain-address": "Blockchain Address",
	"/search": "Search",
	"/artworks": "Artworks",
	"/profile/orders": "Orders",
	"/profile/orders/orderTrack": "Order Track",
	"/profile/received-orders": "Received Orders",
	"/profile/received-orders/orderStatus": "Order Status",
	"/articles-list": "Articles List",
	"/article-details": "Article Details",
	"/blog": "Blog",
	"/news": "News",
	"/legal-notices": "Legal Notices",
	"/faq": "FAQ",
	"/term-of-use": "General Terms and Conditions",
	"/about-us": "About Us",
	"/the-team": "The Team",
	"/bulk-upload": "Bulk Upload",
	"/bulk-upload/upload-file": "Upload File",
	"/bulk-upload/download-form": "Download Form",
	"/contact-us": "Contact Us",
	"/artists": "Artists",
	"/artists/new-artists": "New Artists",
	"/artists/popular-artists": "Popular Artists",
	"/artists/featured-artists": "Featured Artists",
	"/collector": "Collector",
	"/sellers": "Artist",
	"/eventList": "Event List",
};

export { routes };
