import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
  shippingAddress: {},
  addShippingAddress: {},
  updateShippingAddress: {},
  deleteShippingAddress: {},
  defaultShippingAddress: {}
};

const ShippingAddressReducer = createApiReducer(initialState, [
  types.GET_SHIPPING_ADDRESS_REQUEST,
  types.ADD_SHIPPING_ADDRESS_REQUEST,
  types.UPDATE_SHIPPING_ADDRESS_REQUEST,
  types.DELETE_SHIPPING_ADDRESS_REQUEST,
  types.SET_DEFAULT_SHIPPING_ADDRESS_REQUEST
]);

export default ShippingAddressReducer;
