import React, { useEffect } from "react";
import { Modal } from "common/PasswordResetSuccess/PasswordResetSuccess.styled";
import StyledButton from "common/StyledButton";
import ModalTypes from "utils/ModalTypes";
import useModal from "utils/useModal";
import { NfcResultWrapper } from "./NfcSkipModal.styled";
import { productDetailHooks } from "ecommerce-sdk/src/index";
import { errorToast } from "utils/toastMessages";
import { useHistory } from "react-router";

const NfcSkipModal = () => {
	const { useNFCBypass } = productDetailHooks;
	const [isShown, modalProps, , onCancel] = useModal(ModalTypes.nfcSkipModal);
	const [nfcBypass, nfcBypassData, nfcBypassError, nfcBypassLoading] = useNFCBypass();
	let history = useHistory();

	useEffect(() => {
		if (nfcBypassData) {
			onCancel();
			if (modalProps && modalProps.slugId) history.push(`/art/medium/${modalProps.slugId}`);
			else window.location.reload();
		} else if (nfcBypassError) {
			onCancel();
			errorToast(nfcBypassError, "error");
			if (modalProps && modalProps.slugId) history.push(`/art/medium/${modalProps.slugId}`);
		}
	}, [nfcBypassData, nfcBypassError]);

	const onConfirm = () => {
		if (modalProps && modalProps.id) nfcBypass(modalProps.id);
	};
	const handleClose = () => {
		onCancel();
	};
	const buttons = (
		<React.Fragment>
			<StyledButton
				variant="invert"
				key="ok"
				width={"6rem"}
				margin={"0"}
				loading={nfcBypassLoading}
				disabled={nfcBypassLoading}
				onClick={() => {
					onConfirm();
				}}
			>
				Confirm
			</StyledButton>
		</React.Fragment>
	);
	return (
		<Modal centered open={isShown} maskClosable={true} handleClose={handleClose}>
			<NfcResultWrapper status={"warning"} title={modalProps && modalProps.title} extra={buttons} />
		</Modal>
	);
};

export default NfcSkipModal;
