import styled from "styled-components";
import { Input } from "antd";
import { verticalScroll } from "styled";
import { SlideInFromTop } from "styled/common.styled";

export const NavbarSearchContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	z-index: 5;
	position: relative;
	top: 9px;

	/* NOTE : Below code is used to show search bar in smaller screens
	'Max width' value is when mobile menu starts appearing on screen
	*/
	.ant-input-affix-wrapper {
		z-index: 999;
	}
	@media screen and (max-width: 768px) {
		right: 0;
		.ant-input-affix-wrapper {
			position: absolute;
			min-width: 9rem;
			right: 1px;
			top: 2px;
		}
	}
	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
		/* right: 0;
		.ant-input-affix-wrapper {
			position: absolute;
			min-width: 9rem;
			right: 1px;
			top: 2px;
		} */
	}
`;

export const NavbarSearchInput = styled(Input)`
	animation: ${SlideInFromTop} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	.ant-input {
		/* border-radius: 15px; */
		color: #58514b;
		background: #fff;

		::placeholder {
			color: #ad9972;
		}
	}
	.ant-input:hover {
		border-color: #58514b !important;
		border-right-width: 1px !important;
	}
	&.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
		border-color: #58514b !important;
		border-right-width: 2px !important;
	}
	.ant-input:focus {
		border-right-width: 2px !important;
	}
`;

export const NavbarSearchPopover = styled.div`
	/* padding: 10px 60px; */
	background-color: #fff;
	color: #ad9972;
	/* box-shadow: 1px 1px 12px -4px #9e9e9e; */
	border: 0;
	border-radius: 7px;
	/* position: fixed; */
	width: 100%;
	top: 105px;
	left: 0;
	right: 0;
	animation: ${SlideInFromTop} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	z-index: 5;
	/* max-height: 17.5rem; */
	height: 85%;
	margin: 0 40px;
	@media only screen and (max-device-width: 768px) {
		margin: 0 5x;
	}
	@media only screen and (max-device-width: 480px) {
		margin: 0 5px;
	}
	${verticalScroll("2px")};
	.ant-spin {
		display: block;
		.ant-spin-dot-item {
			background-color: #58514b;
		}
	}

	/* width */
	::-webkit-scrollbar {
		width: 20px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 46px white;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #e1ae00;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #e1ae00;
	}

	/* width */
	::-webkit-scrollbar {
		width: 20px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 46px white;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #e1ae00;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #e1ae00;
	}

	@media screen and (max-width: 768px) {
		padding: 10px 5px;
	}
`;

export const NavbarSearchListItem = styled.a`
	display: block;
	font-size: 13px;
	color: #58514b;
	padding: 2px 0;
	border-bottom: 0.5px solid #f8f8f8;
	margin-bottom: 0px !important;
	cursor: pointer;
	&:hover {
		color: #a58954;
		font-weight: 500;
	}
`;
