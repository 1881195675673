import React from 'react';
import Countdown from 'react-countdown';

const CountDownDateTimer = ({ countDate, renderer }) => {
    // const renderer = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         // Render a completed state
    //         return <Completionist />;
    //     } else {
    //         // Render a countdown
    //         return <span>{days}d:&nbsp;{hours}h:&nbsp;{minutes}m:&nbsp;{seconds}s</span>;
    //     }

    // };

    return (
        <Countdown date={countDate} renderer={renderer} />
    )
};

export default CountDownDateTimer;