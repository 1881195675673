import styled, { css } from "styled-components";
import { Icon } from "antd";
import { Typography } from "antd";
import { ImgNextGen } from "common/NextGenImage";
const { Paragraph } = Typography;

export const DeleteButtonWrapper = styled.div`
	background-color: #ffffff;
	z-index: 50000;
	position: absolute;
	right: 30%;
	top: 80%;
	width: 20px;
	text-align: center;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 6rem;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
`;
export const StyledDeleteButtonWrapper = styled.div`
	position: absolute;
	left: 70%;
	z-index: 5;
	visibility: ${props => (props.visibility === true ? "visible" : "hidden")};
`;
export const DeleteIcon = styled.img`
	width: 20px;
	object-fit: contain;
	z-index: 6;
`;

export const HexagonImageForSliderWrapper = styled.div`
	cursor: pointer;
	position: relative;
`;
export const HexagonImageForSliderDetailsWrapper = styled.div`
	position: relative;
	top: 10px;
	left: 20px;
	text-align: center;
	/* width:200px; */
`;
export const HexagonImageForSliderName = styled.p`
	/* font-size: 16px; */
	color: #000000;
	/* font-weight: 700; */
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 21px;
	font: normal normal bold 16px/28px Cormorant Garamond;
`;

export const HexagonImageForSliderPlace = styled.p`
	font-size: 12px;
	color:rgb(36, 35, 35);
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 21px;
`;

export const HexagonPara = styled(Paragraph)`
	position: relative;
	top: -10px;
	left: 20px;
	width: 12rem;
	text-align: center;
	font-weight: 500;
	font-size: 13px;
	img {
		height: 11px;
		margin-right: 5px;
	}
`;

// =========================================== Hexagon Image ===================================== //

// -------------- Hexacard wrapper -------------- //
const HexagonImageWrapper = styled.div`
	display: inline-block;
	position: relative;
	outline: none;
	height: ${props => (props.size ? props.size : "252")}px;
	width: ${props => (props.size ? props.size : "252")}px;
	background: #ad9972;
	box-sizing: border-box;
	transform: rotate(152deg) !important;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	img {
		transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	&:hover {
		filter: none;
		transition: filter 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		img {
			${props => (props.noanimation === "true" ? null : "transform: scale(1.1) rotate(-92deg)")};
			transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}
	}
	${props =>
		props.selected === true &&
		css`
			filter: none;
			img {
				transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}
		`}
`;

// Selection Hexagon>>>>>>>>>>>>>>>

const SelectionHexagonWrapper = styled.div`
	display: flex;
	position: relative;
	outline: none;
	height: ${props => (props.size ? props.size : "252")}px;
	width: ${props => (props.size ? props.size : "252")}px;
	background: #ad9972;
	box-sizing: border-box;
	transform: rotate(152deg) !important;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	img {
		transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	&:hover {
		filter: none;
		transition: filter 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		img {
			${props => (props.noanimation === "true" ? null : "transform: scale(1.1) rotate(-92deg)")};
			transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}
	}
	${props =>
		props.selected === true &&
		css`
			filter: none;
			img {
				transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}
		`}
`;

const SelectionHexagonSubWrapper = styled.div`
	transform: rotate(-60deg) !important;
	width: ${props => (props.size ? props.size + 20 : "272")}px;
	height: ${props => (props.size ? props.size + 20 : "272")}px;
	position: relative;
	top: 13px;
	left: -20px;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
`;

const SelectionHexagonImage = styled(ImgNextGen)`
	position: relative;
	top: -17px; /* equal to border thickness */
	left: 37px; /*equal to border thickness */
	transform: rotate(268deg);
	width: ${props => (props.size ? props.size + 33 : "285")}px;
	height: ${props => (props.size ? props.size + 48 : "300")}px;
`;

const CheckIcon = styled(Icon)`
	font-size: 24px;
	color: #ffffff;
`;

const SelectButton = styled.div`
	min-width: 29px;
	min-height: 29px;
	max-width: 29px;
	max-height: 29px;
	border-radius: 50%;
	background: #000;
	top: -183px;
	left: 145px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	transform: rotate(-90deg);
	margin: 0;
	visibility: hidden;
	${props =>
		props.selected === true &&
		css`
			visibility: visible;
		`}
`;
// <<<<<<<<<<<<<<<Selection Hexagon

// -------------- Hexacard Inner wrapper -------------- //
const SingleHexagonImage = styled.div`
	transform: rotate(-60deg) !important;
	width: ${props => (props.size ? props.size + 20 : "272")}px;
	height: ${props => (props.size ? props.size + 20 : "272")}px;
	position: relative;
	top: 13px;
	left: -20px;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
`;

// -------------- Hexacard Image -------------- //
const HexaImage = styled(ImgNextGen)`
	position: relative;
	top: -17px; /* equal to border thickness */
	left: 37px; /*equal to border thickness */
	transform: rotate(268deg);
	width: ${props => (props.size ? props.size + 33 : "285")}px;
	height: ${props => (props.size ? props.size + 48 : "300")}px;
`;

// -------------- Hexacard wrapper -------------- //
const HexagonBorderWrapper = styled.div`
	display: inline-block;
	position: relative;
	width: ${props => (props.size ? props.size : "244")}px;
	height: ${props => (props.size ? props.size : "252")}px;
	box-sizing: border-box;
	background: #fff;
	transform: rotate(152deg) !important;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);

	&:hover {
		background: #fff;
		filter: none;
	}
`;

// -------------- Hexacard Inner wrapper -------------- //
const SingleImageBorder = styled.div`
	transform: rotate(-60deg) !important;
	width: ${props => (props.size ? props.size + 28 : "272")}px;
	height: ${props => (props.size ? props.size + 10 : "242")}px;
	position: relative;
	top: 13px;
	left: -20px;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
`;

// -------------- Hexacard Image -------------- //
const Imageborder = styled.img`
	position: relative;
	top: -27px;
	left: 22px;
	transform: rotate(268deg);
	width: ${props => (props.secondsize ? props.size + 32 : "304")}px;
	height: ${props => (props.secondsize ? props.size + 75 : "317")}px;
`;

// -------------- Hexacard wrapper -------------- //
const HexagonNormalWrapper = styled.div`
	display: inline-block;
	position: relative;
	height: ${props => (props.size ? props.size : "252px")};
	width: ${props => (props.size ? props.size : "244px")};
	box-sizing: border-box;
	background: #fff;
	transform: rotate(152deg) !important;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);

	img {
		transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	&:hover {
		background: #ad9972;
		filter: none;
		transition: filter 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		img {
			${props => (props.noanimation === "true" ? null : "transform: scale(1.1) rotate(-92deg)")};
			transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}
	}

	div {
		width: ${props => (props.size ? props.size + 28 : "272")}px;
		height: ${props => (props.size ? props.size + 10 : "242")}px;
		position: relative;
		top: 22px;
		left: -25px;
	}
`;

// -------------- Hexacard Inner wrapper -------------- //
const HexagonNormalImage = styled.div`
	transform: rotate(-60deg) !important;
	width: ${props => (props.size ? props.size + 28 : "272")}px;
	height: ${props => (props.size ? props.size + 10 : "242")}px;
	position: relative;
	top: 13px;
	left: -20px;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
`;

// -------------- Hexacard Image -------------- //
const HexagonNormal = styled.img`
	position: relative;
	top: -27px;
	left: 32px;
	transform: rotate(268deg);
	width: ${props => (props.size ? props.size + 33 : "285")}px;
	height: ${props => (props.size ? props.size + 48 : "300")}px;
`;

export {
	HexagonImageWrapper,
	SelectionHexagonWrapper,
	SelectionHexagonSubWrapper,
	SelectionHexagonImage,
	CheckIcon,
	SelectButton,
	SingleHexagonImage,
	HexaImage,
	Imageborder,
	HexagonBorderWrapper,
	SingleImageBorder,
	HexagonNormalWrapper,
	HexagonNormalImage,
	HexagonNormal
};
