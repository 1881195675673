import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const MenuContainer = styled.div`
	display: flex;
	padding-top: 10px;
	justify-content: flex-start;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const MenuItemStyles = css`
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 5px 10px 10px;
	font: normal normal normal 16px/24px Lato !important;
	cursor: pointer;
	letter-spacing: 0.33px;
	color: rgb(0, 0, 0);
	text-transform: uppercase;
	opacity: 1;
	&:hover {
		color: rgb(88, 81, 75);
	}
	@media screen and (max-width: 1366px) {
		justify-content: space-around;
		font-size: 14px;
	}
	@media screen and (max-width: 1280px) {
		font-size: small;
	}
	@media screen and (max-width: 1024px) {
		font-size: small;
	}
	@media screen and (max-width: 768px) {
		font-size: smaller;
	}
`;

const MenuItemLink = styled(Link)`
	${MenuItemStyles}
`;
const MenuItem = styled.div`
	${MenuItemStyles}
	.anticon {
		margin-left: 3px;
	}
`;

const ArtworkDropdownWrapper = styled.div`
	display: flex;
	min-height: 20rem;
`;

const ArtworkDropdownMainWrapper = styled.div`
	background-color: black;
	min-width: 16rem;
	max-height: 400px;
	padding: 2.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;

const ArtworkDropdownMainItem = styled.div`
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 1rem;
	cursor: pointer;
	&:hover {
		color: #ad9972;
	}
`;

const ArtworkDropdownSubWrapper = styled.div`
	background-color: #fff;
	padding: 2.5rem 1.5rem;
	min-width: 16rem;
	max-height: 400px;
	overflow-y: scroll;
	border-right: solid 0.6px #f6f6f6;
	::-webkit-scrollbar {
		display: none;
	}
	::before {
		border-right: solid 0.6px #ffff;
	}
`;

const ArtworkDropdownSubItemTitle = styled.div`
	color: #d2502d;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 1rem;
	cursor: default;
`;

const ArtworkDropdownSubItem = styled.div`
	text-align: left;
	font: normal normal 400 15px/35px Lato !important;
	letter-spacing: 0.38px;
	color: rgb(0, 0, 0);
	text-transform: capitalize;
	margin-bottom: 5px;
	cursor: pointer;
	&:hover {
		color: #ad9972;
	}
`;

const ArtworkDropdownLink = styled(Link)`
	width: 100%;
	display: block;
	color: rgb(0, 0, 0);
	font: normal normal medium 15px/35px Lato !important;
	margin-bottom: 1rem;
	margin-top: 1rem;
	font-style: italic;
	font-weight: bold;
	&:hover {
		color: #ad9972;
	}
`;
export const MainArtworkDropdownLink = styled(Link)`
	width: 100%;
	display: block;
	color: rgb(0, 0, 0);
	margin-bottom: 1rem;
	letter-spacing: 0.33px;
	text-align: left;
	font: normal normal bold 13px/24px Lato;
	text-transform: uppercase;

	&:hover {
		color: #ad9972;
	}
`;
export const MainArtworkDropdownMainTitile = styled.p`
	width: 100%;
	display: block;
	color: rgb(0, 0, 0);
	margin-bottom: 1rem;
	letter-spacing: 0.33px;
	text-align: left;
	font: normal normal bold 13px/24px Lato;
	text-transform: uppercase;
	font-weight: bold !important;

	&:hover {
		color: #ad9972;
	}
`;
const BlinkingMenu = styled.img`
	margin-top: -3px;
	@media only screen and (device-width: 768px) {
		margin: 5px 0 20px;
	}
	@media only screen and (max-device-width: 480px) {
		margin: 5px 0 20px;
	}
`;
export {
	MenuContainer,
	MenuItem,
	MenuItemLink,
	ArtworkDropdownWrapper,
	ArtworkDropdownMainWrapper,
	ArtworkDropdownMainItem,
	ArtworkDropdownSubWrapper,
	ArtworkDropdownSubItemTitle,
	ArtworkDropdownSubItem,
	ArtworkDropdownLink,
	BlinkingMenu,
};
