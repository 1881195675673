import * as types from "./ActionsTypes";
import { RecommendationsManagementService } from "../services/RecommendationsManagementService";
import { AddProductService } from "../services/AddProductService";

// ==================================== Get similiar Products ==================================== //
export const getSimilarProductsAction = (productId) => {
	return {
		actionType: types.GET_SIMILAR_PRODUCTS_REQUEST,
		callAPI: () => RecommendationsManagementService.getSimilarProducts(productId),
		stateObject: "similarProducts"
	};
};

// ==================================== Get suggested artworks ==================================== //
export const getSuggestedArtworksAction = () => {
	return {
		actionType: types.GET_SUGGESTED_ARTWORKS_REQUEST,
		callAPI: () => RecommendationsManagementService.getSuggestedArtworks(),
		stateObject: "suggestedArtworks"
	};
};

// ==================================== Continue your search ==================================== //
export const getContinueYourSearchAction = (num, size) => {
	return {
		actionType: types.GET_CONTINUE_YOUR_SEARCH_REQUEST,
		callAPI: () => RecommendationsManagementService.getContinueYourSearch(num, size),
		stateObject: "continueYourSearch"
	};
};

// -------------------- Get Recommended Categories -------------------- //
export const getRecommendedCategories = () => {
	return {
		actionType: types.GET_RECOMMENDED_CATEGORIES_REQUEST,
		callAPI: () => RecommendationsManagementService.getRecommendedCategories(),
		stateObject: "recommendedCategories"
	};
};

// -------------------- Get Trending Categories -------------------- //
export const getTrendingCategories = () => {
	return {
		actionType: types.GET_TRENDING_CATEGORIES_REQUEST,
		callAPI: () => RecommendationsManagementService.getTrendingCategories(),
		stateObject: "trendingCategories"
	};
};
// ==================================== Artworks on Offer ==================================== //
export const getArtworksOnOfferAction = () => {
	return {
		actionType: types.GET_ARTWORKS_ON_OFFER_REQUEST,
		callAPI: () => RecommendationsManagementService.getArtworksOnOffer(),
		stateObject: "artworksOnOffer"
	};
};

// ==================================== Featured Artists ==================================== //
export const getFeaturedArtistsAction = () => {
	return {
		actionType: types.GET_FEATURED_ARTISTS_REQUEST,
		callAPI: () => RecommendationsManagementService.getFeaturedArtists(),
		stateObject: "featuredArtists"
	};
};


// ========================================== Get Featured Galley Slider Items ========================================== //
export const getFeaturedGalleryAction = () => {
	return {
		actionType: types.GET_FEATURED_GALLERY_REQUEST,
		callAPI: () => RecommendationsManagementService.getFeaturedGallery(),
		stateObject: "getFeaturedGallery"
	};
};

// ========================================== Get Original Artworks ========================================== //
export const getOriginalArtworksAction = () => {
	return {
		actionType: types.GET_ORIGINAL_ARTWORKS_REQUEST,
		callAPI: () => AddProductService.getOriginalArtworks(),
		stateObject: "getOriginalArtworks"
	};
};


// ========================================== Get Latest Artworks ========================================== //
export const getLatestArtworksAction = () => {
	return {
		actionType: types.GET_LATEST_ARTWORKS_REQUEST,
		callAPI: () => RecommendationsManagementService.getLatestArtworks(),
		stateObject: "getLatestArtworks"
	};
};


// ========================================== Get Like And WishList Artworks ========================================== //
export const getLikeAndWishListAction = () => {
	return {
		actionType: types.GET_LIKE_WISHLIST_REQUEST,
		callAPI: () => RecommendationsManagementService.getLikeAndWishList(),
		stateObject: "getLikeAndWishList"
	};
};

// ========================================== Get Popular Artworks ========================================== //
export const getPopularArtworksAction = () => {
	return {
		actionType: types.GET_POPULAR_ARTWORKS_REQUEST,
		callAPI: () => RecommendationsManagementService.getPopularArtworks(),
		stateObject: "getPopularArtworks"
	};
};

// ========================================== Get Featured Artworks ========================================== //
export const getFeaturedArtworksAction = () => {
	return {
		actionType: types.GET_FEATURED_ARTWORKS_REQUEST,
		callAPI: () => RecommendationsManagementService.getFeaturedArtworks(),
		stateObject: "getFeaturedArtworks"
	};
};
