import { ArtistBasicProfileService } from "../services/ArtistBasicProfileService";
import * as types from "./ActionsTypes";

// ========================================== Get Artist Basic Description ========================================== //
export const getBasicDescription = () => {
	return {
		actionType: types.GET_ARTIST_BASIC_DESCRIPTION_REQUEST,
		callAPI: () => ArtistBasicProfileService.getBasicDescription(),
		stateObject: "getBasicDescription"
	};
};
// ========================================== Get Artist Basic Description Using SlugId ========================================== //
export const getBasicDescriptionByIdAction = (SlugId, loginStatus) => {
	return {
		actionType: types.GET_ARTIST_BASIC_DESCRIPTION_REQUEST,
		callAPI: () => ArtistBasicProfileService.getBasicDescriptionById(SlugId, loginStatus),
		stateObject: "getBasicDescription"
	};
};
// ========================================== Update ArtistBasicProfile ========================================== //
export const updateArtistProfile = infoData => {
	return { 
		actionType: types.UPDATE_ARTIST_PROFILE_REQUEST,
		callAPI: () => ArtistBasicProfileService.updateArtistProfile(infoData), 
		stateObject: "updateArtistProfile",
		isTransient: true
	};
};
// ========================================= Update Social Media Links ==============================================//
export const updateSocialLinks = infoData => {
	return {
		actionType: types.UPDATE_SOCIAL_LINKS_REQUEST,
		callAPI: () => ArtistBasicProfileService.updateArtistProfile(infoData),
		stateObject: "updateSocialLinks",
		isTransient: true
	};
};
// ========================================== Update ArtistBasicProfileImages ========================================== //
export const updateArtistProfileImages = data => {
	return {
		actionType: types.UPDATE_ARTIST_PROFILE_IMAGES_REQUEST,
		callAPI: () => ArtistBasicProfileService.updateArtistProfileImages(data),
		stateObject: "updateArtistProfileImages",
		isTransient: true
	};
};
