import { useDispatch, useSelector } from "react-redux";
import Util from "../util/Util";
import {
	getGalleryListAction,
	addNewGalleryAction,
	updateGalleryAction,
	deleteFromGalleryAction,
	getEventListAction,
	addNewEventAction,
	getAllArtistsList,
	deleteFromEventAction,
	updateEventAction,
	getAwardListAction,
	addNewAwardAction,
	updateAwardAction,
	deleteAwardAction,
	getHistoricalDataListAction,
	addNewHistoricalAction,
	updateHistoricalDataAction,
	deleteHistoricalDataAction,
	getMyBlogsAction,
	getBlogsByUserAction,
	addBlogAction,
	updateBlogAction,
	deleteBlogAction,
	getAwardListByUserIdAction,
	getHistoricalDataListByUserIdAction,
	getGalleryListByUserIdAction,
	getEventListByUserIdAction,
	updateGalleryMembershipRequestAction,
	getGalleryMembershipRequestsAction
} from "../actions/ArtistAction";

// Get List of Gallery
export const useGetGalleryList = () => {
	const dispatch = useDispatch();
	const getGalleryList = userId => {
		if (userId) dispatch(getGalleryListByUserIdAction(userId));
		else dispatch(getGalleryListAction());
	};
	const getGalleryListResult = useSelector(state => state.artistReducer.getGalleryListResult);
	return [getGalleryList, getGalleryListResult.data, getGalleryListResult.error, getGalleryListResult.loading];
};

// Add new Gallery
export const useAddNewGallery = () => {
	const dispatch = useDispatch();
	const addNewGallery = body => {
		const formData = Util.objectToFormData(body);
		dispatch(addNewGalleryAction(formData));
	};
	const addNewGalleryResult = useSelector(state => state.artistReducer.addNewGalleryResult);
	return [addNewGallery, addNewGalleryResult.data, addNewGalleryResult.error,addNewGalleryResult.loading];
};

// Update Gallery
export const useUpdateGallery = () => {
	const dispatch = useDispatch();
	const updateGallery = (galleryId, body) => {
		const formData = Util.objectToFormData(body);
		dispatch(updateGalleryAction(galleryId, formData));
	};
	const updateGalleryResult = useSelector(state => state.artistReducer.updateGalleryResult);
	return [updateGallery, updateGalleryResult.data, updateGalleryResult.error,updateGalleryResult.loading];
};

// Delete from Gallery
export const useDeleteFromGallery = () => {
	const dispatch = useDispatch();
	const deleteFromGallery = galleryId => {
		dispatch(deleteFromGalleryAction(galleryId));
	};
	const deleteFromGalleryResult = useSelector(state => state.artistReducer.deleteFromGalleryResult);
	return [deleteFromGallery, deleteFromGalleryResult.data, deleteFromGalleryResult.error, deleteFromGalleryResult.loading];
};

// To get the list of all Artists
export const useGetAllArtists = () => {
	const dispatch = useDispatch();

	// Get artists
	// params passed to this actions should be an object
	// Params not mandatory [pass params from rtistiq]
	// let params = { pageNum = 1, pageSize=2 }
	const getAllArtists = params => {
		dispatch(getAllArtistsList(params));
	};

	// Reducer states
	const getArtistsListData = useSelector(state => state.artistReducer.getArtistsListData);
	return [getAllArtists, getArtistsListData.data, getArtistsListData.error, getArtistsListData.loading];
};

// Get List of Events
export const useGetEventList = () => {
	const dispatch = useDispatch();
	const getEventList = userId => {
		if (userId) dispatch(getEventListByUserIdAction(userId));
		else dispatch(getEventListAction());
	};
	const getEventListResult = useSelector(state => state.artistReducer.getEventListResult);
	return [getEventList, getEventListResult.data, getEventListResult.error, getEventListResult.loading];
};

// Add new Event
export const useAddNewEvent = () => {
	const dispatch = useDispatch();
	const addNewEvent = (body, isMobile = false) => {
		if (isMobile) {
			dispatch(addNewEventAction(body));
		} else {
			const formData = Util.objectToFormData(body);
			dispatch(addNewEventAction(formData));
		}
	};
	const addNewEventResult = useSelector(state => state.artistReducer.addNewEventResult);
	return [addNewEvent, addNewEventResult.data, addNewEventResult.error, addNewEventResult.loading];
};

//Update new Event
export const useUpdateEvent = () => {
	const dispatch = useDispatch();
	const updateEvent = (body, eventId, isMobile = false) => {
		if (isMobile) {
			dispatch(updateEventAction(body, eventId));
		} else {
			const formData = Util.objectToFormData(body);
			dispatch(updateEventAction(formData, eventId));
		}
	};
	const updateEventResult = useSelector(state => state.artistReducer.updateEventResult);
	return [updateEvent, updateEventResult.data, updateEventResult.error, updateEventResult.loading];
};

//Delete from Event
export const useDeleteFromEvent = () => {
	const dispatch = useDispatch();
	const deleteFromEvent = _id => {
		dispatch(deleteFromEventAction(_id));
	};
	const deleteFromEventResult = useSelector(state => state.artistReducer.deleteFromEventResult);
	return [deleteFromEvent, deleteFromEventResult.data, deleteFromEventResult.error, deleteFromEventResult.loading];
};

// Get List of Awards
export const useGetAwardList = () => {
	const dispatch = useDispatch();
	const getAwardList = userId => {
		if (userId) dispatch(getAwardListByUserIdAction(userId));
		else dispatch(getAwardListAction());
	};
	const awardListResult = useSelector(state => state.artistReducer.awardListResult);
	return [getAwardList, awardListResult.data, awardListResult.error, awardListResult.loading];
};

// Add New Award
export const useAddAward = () => {
	const dispatch = useDispatch();
	const addNewAward = (year, award) => {
		dispatch(addNewAwardAction(year, award));
	};
	const addAwardResult = useSelector(state => state.artistReducer.addAwardResult);
	return [addNewAward, addAwardResult.data, addAwardResult.error,addAwardResult.loading];
};

// Update Award
export const useUpdateAward = () => {
	const dispatch = useDispatch();
	const updateAward = (year, award, awardId) => {
		dispatch(updateAwardAction(year, award, awardId));
	};
	const updateAwardResult = useSelector(state => state.artistReducer.updateAwardResult);
	return [updateAward, updateAwardResult.data, updateAwardResult.error,updateAwardResult.loading];
};

// Delete Award
export const useDeleteAward = () => {
	const dispatch = useDispatch();
	const deleteAward = awardId => {
		dispatch(deleteAwardAction(awardId));
	};
	const deleteAwardResult = useSelector(state => state.artistReducer.deleteAwardResult);
	return [deleteAward, deleteAwardResult.data, deleteAwardResult.error, deleteAwardResult.loading];
};

// Get List of Historical Data
export const useGetHistoricalDataList = () => {
	const dispatch = useDispatch();
	const getHistoricalDataList = userId => {
		if (userId) dispatch(getHistoricalDataListByUserIdAction(userId));
		else dispatch(getHistoricalDataListAction());
	};
	const historicalDataListResult = useSelector(state => state.artistReducer.historicalDataListResult);
	return [getHistoricalDataList, historicalDataListResult.data, historicalDataListResult.error, historicalDataListResult.loading];
};

// Add New Historical Data
export const useAddHistoricalData = () => {
	const dispatch = useDispatch();
	const addNewHistoricalData = (year, events) => {
		dispatch(addNewHistoricalAction(year, events));
	};
	const addHistoricalDataResult = useSelector(state => state.artistReducer.addHistoricalDataResult);
	return [addNewHistoricalData, addHistoricalDataResult.data, addHistoricalDataResult.error,addHistoricalDataResult.loading];
};

// Update Historical Data
export const useUpdateHistoricalData = () => {
	const dispatch = useDispatch();
	const updateHistoricalData = (year, events, historicalId) => {
		dispatch(updateHistoricalDataAction(year, events, historicalId));
	};
	const updateHistoricalDataResult = useSelector(state => state.artistReducer.updateHistoricalDataResult);
	return [updateHistoricalData, updateHistoricalDataResult.data, updateHistoricalDataResult.error,updateHistoricalDataResult.loading];
};

// Delete Historical Data
export const useDeleteHistoricalData = () => {
	const dispatch = useDispatch();
	const deleteHistoricalData = historicalId => {
		dispatch(deleteHistoricalDataAction(historicalId));
	};
	const deleteHistoricalDataResult = useSelector(state => state.artistReducer.deleteHistoricalDataResult);
	return [deleteHistoricalData, deleteHistoricalDataResult.data, deleteHistoricalDataResult.error, deleteHistoricalDataResult.loading];
};

// To get the blogs of the logged-in user
export const useGetMyBlogs = () => {
	const dispatch = useDispatch();
	const getMyBlogs = (pageNum, pageSize) => {
		dispatch(getMyBlogsAction(pageNum, pageSize));
	};

	// Reducer states
	const getMyBlogsResult = useSelector(state => state.artistReducer.getMyBlogsResult);
	return [getMyBlogs, getMyBlogsResult.data, getMyBlogsResult.error, getMyBlogsResult.loading];
};

// To get the articles of any artist using his userId
export const useGetBlogsByUserId = () => {
	const dispatch = useDispatch();
	const getBlogsbyUserId = (pageNum, pageSize, UserId) => {
		dispatch(getBlogsByUserAction(pageNum, pageSize, UserId));
	};

	// Reducer states
	const getBlogsbyUserIdResult = useSelector(state => state.artistReducer.getBlogsByUserResult);
	return [getBlogsbyUserId, getBlogsbyUserIdResult.data, getBlogsbyUserIdResult.error, getBlogsbyUserIdResult.loading];
};

// Add Blog
export const useAddBlog = () => {
	const dispatch = useDispatch();
	const addBlog = body => {
		const formData = Util.objectToFormData(body);
		dispatch(addBlogAction(formData));
	};
	const addBlogResult = useSelector(state => state.artistReducer.addBlogResult);
	return [addBlog, addBlogResult.data, addBlogResult.error, addBlogResult.loading];
};

// Update Blog
export const useUpdateBlog = () => {
	const dispatch = useDispatch();
	const updateBlog = (params, body) => {
		const formData = Util.objectToFormData(body);
		dispatch(updateBlogAction(params, formData));
	};
	const updateBlogResult = useSelector(state => state.artistReducer.updateBlogResult);
	return [updateBlog, updateBlogResult.data, updateBlogResult.error, updateBlogResult.loading];
};

// Delete Blog
export const useDeleteBlog = () => {
	const dispatch = useDispatch();
	const deleteBlog = params => {
		dispatch(deleteBlogAction(params));
	};
	const deleteBlogResult = useSelector(state => state.artistReducer.deleteBlogResult);
	return [deleteBlog, deleteBlogResult.data, deleteBlogResult.error,deleteBlogResult.loading];
};

// Get Gallery Membership Requests
export const useGetGalleryMembershipRequests = () => {
	const dispatch = useDispatch();
	const getGalleryMembershipRequests = status => {
		dispatch(getGalleryMembershipRequestsAction(status));
	};
	const { data, error, loading } = useSelector(state => state.artistReducer.getGalleryMembershipRequests);
	return [getGalleryMembershipRequests, data, error, loading];
};

// Approve Gallery Request
export const useUpdateGalleryMembershipRequest = () => {
	const dispatch = useDispatch();
	const updateGalleryMembershipRequest = (status, galleryId) => {
		dispatch(updateGalleryMembershipRequestAction(status, galleryId));
	};
	const { data, error, loading } = useSelector(state => state.artistReducer.updateGalleryMembershipRequest);
	return [updateGalleryMembershipRequest, data, error, loading];
};
