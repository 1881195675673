import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class BulkUploadService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Bulk upload
	static async bulkUpload(body) {
		return this.service.execute(this.API.bulkUpload, HTTP_METHOD.MULTIPART_POST, {
			body,
		});
	}

	// Bulk form download
	static async bulkFormDownload() {
		// const params = { CategoryId, ArtistId };
		return this.service.execute(this.API.getExcelReport, HTTP_METHOD.DOWNLOAD, {});
	}
}
