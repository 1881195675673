
import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetUserAccountAction } from "../actions/UserManagementAction";


const useResetUserAccount = () => {
    const resetAccount = () => {
        dispatch(resetUserAccountAction())
    }
    const resetAccountResult = useSelector(state => state.userManagement.resetAccountResult);
    const dispatch = useDispatch();
    return [resetAccount, resetAccountResult.data, resetAccountResult.error, resetAccountResult.loading];
};
export default useResetUserAccount;
