import {
  LOGOUT_USER,
  ADD_ROUTE,
  REMOVE_ROUTE,
  REFRESH_BADGES
} from "redux/actions/ActionTypes";
import { routes } from "routes";
const initialState = {
  shouldLogout: false,
  routes,
  refreshBadges:false
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        shouldLogout: action.payload
      };
    case ADD_ROUTE:
      return {
        ...state,
        routes: { ...state.routes, [action.payload.path]: action.payload.title }
      };
    case REMOVE_ROUTE:
      let newRoutes = { ...state.routes };
      delete newRoutes[action.payload.path];
      return {
        ...state,
        routes: newRoutes
      };
      case REFRESH_BADGES:
        return {
          ...state,
          refreshBadges: action.payload
        };

    default:
      return state;
  }
};

export default general;
