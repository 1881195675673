import { useDispatch, useSelector } from "react-redux";
import Util from "../util/Util";

import {
    UpdateKycDocumentAction,
    GetOwnKycDocuments
}from '../actions/KYCDocumentAction';


// Update Or Add Kyc Document  
export const useUpdateKycDocument = () => {
    const dispatch = useDispatch();
	const updateKycDocument = body => {
	
		dispatch(UpdateKycDocumentAction(body));
	};
	const updateKycDocumentsResult = useSelector(state => state.kycDocumentReducer.updateKycDocumentsResult);
	return [updateKycDocument, updateKycDocumentsResult.data, updateKycDocumentsResult.error,updateKycDocumentsResult.loading];
};


// Get Own KYC Doc
export const useGetOwnKycDocuments = () => {
	const dispatch = useDispatch();
	const getKycDocument = () => {
		dispatch(GetOwnKycDocuments());
		
	};
	const ownKycDocumentResult = useSelector(state => state.kycDocumentReducer.ownKycDocumentResult);
	return [getKycDocument, ownKycDocumentResult.data, ownKycDocumentResult.error, ownKycDocumentResult.loading];
};