import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getCurrencies,
	convertCurrency,
	stripePaymentForOrderAction,
	paypalPaymentForOrderAction,
	paypalPaymentFailureAction,
	getPaypalIDForOrderAction,
	stripePaymentIntentForOrderAction,
	stripePaymentOrderConfirmationAction,
} from "../actions/PaymentAction";

//----------------useGetCurrencies------------------//
export const useGetCurrencies = () => {
	const dispatch = useDispatch();
	const get = () => {
		dispatch(getCurrencies());
	};
	const result = useSelector((state) => state.payment.currencies);
	return [get, result.data, result.error, result.loading];
};

//----------------useConvertCurrency------------------//
export const useConvertCurrency = () => {
	const dispatch = useDispatch();
	const convert = (Amount, CurrencyName) => {
		dispatch(convertCurrency(Amount, CurrencyName));
	};
	const result = useSelector((state) => state.payment.convertCurrency);
	return [convert, result.data, result.error, result.loading];
};

// ------------------ Stripe Payment ------------------ //
export const useStripePaymentForOrder = () => {
	const dispatch = useDispatch();
	const stripePaymentForOrder = (StripeToken, OrderId) => {
		dispatch(stripePaymentForOrderAction(StripeToken, OrderId));
	};
	const { data, error, loading } = useSelector((state) => state.payment.stripePayment);
	return [stripePaymentForOrder, data, error, loading];
};

export const useStripePaymentIntent = () => {
	const dispatch = useDispatch();
	const stripePaymentIntentForOrder = (OrderId, Type) => {
		dispatch(stripePaymentIntentForOrderAction(OrderId, Type));
	};
	const { data, error, loading } = useSelector((state) => state.payment.stripePaymentIntent);
	return [stripePaymentIntentForOrder, data, error, loading];
};

export const useStripePaymentConfirmation = () => {
	const dispatch = useDispatch();
	const stripePaymentOrderConfirmation = (OrderId, IntentId) => {
		dispatch(stripePaymentOrderConfirmationAction(OrderId, IntentId));
	};
	const { data, error, loading } = useSelector((state) => state.payment.stripePaymentConfirmation);
	return [stripePaymentOrderConfirmation, data, error, loading];
};

// ------------------ Paypal Client ID ------------------ //
export const useGetPaypalIDForOrder = () => {
	const dispatch = useDispatch();
	const getPaypalIDForOrder = () => {
		dispatch(getPaypalIDForOrderAction());
	};
	const { data, error, loading } = useSelector((state) => state.payment.getPaypalIDForOrder);
	return [getPaypalIDForOrder, data, error, loading];
};

// ------------------ Paypal Payment ------------------ //
export const usePaypalPaymentForOrder = () => {
	const dispatch = useDispatch();
	const paypalPaymentForOrder = (rtistIQOrderId, PaypalOrderId) => {
		dispatch(paypalPaymentForOrderAction(rtistIQOrderId, PaypalOrderId));
	};
	const { data, error, loading } = useSelector((state) => state.payment.paypalPayment);
	return [paypalPaymentForOrder, data, error, loading];
};

// ------------------- Paypal Payment Failure ----------------- //
export const usePaypalFailurePayment = () => {
	const dispatch = useDispatch();
	const paypalFailurePayment = (rtistIQOrderId) => {
		dispatch(paypalPaymentFailureAction(rtistIQOrderId));
	};
	const { data, error, loading } = useSelector((state) => state.payment.paypalFailurePayment);
	return [paypalFailurePayment, data, error, loading];
};
