import { useEffect, useState } from "react";
import SDK, { blockchainHooks } from "ecommerce-sdk/src/index";
import { isEmpty, isNil } from "lodash";
import { authorizePost, authorizePostFormData, authorizePostForTorus, postFormData } from "utils/apiConfig";
import { getTorusWallet, getTorusWalletForNewBuyer } from "utils/torusLogin";
import { Util } from "./util";
import toast from "common/Toast";
import { useTranslation } from "react-i18next";
import { toastMessage, warningMessage } from "./toastMessages";

const useCustomWalletConnect = () => {
	const { t } = useTranslation();
	const { useCreateBlockchainAccount } = blockchainHooks;
	const [createAccount] = useCreateBlockchainAccount();
	const [isWalletConnected, setIsWalletConnected] = useState(false);
	const [walletAddress, setWalletAdress] = useState("");
	const [walletNameLocal, setWalletName] = useState("");

	useEffect(() => {
		setWalletAttributes();
	}, []);

	const setWalletAttributes = async () => {
		let isLoggedIn = await getIsUserLoggedIn();
		/* Todo: simplify the logic */
		if (
			isLoggedIn === true &&
			(localStorage.getItem("isCustomeWalletConnected") === "true" || localStorage.getItem("isCustomeWalletConnected") === "false")
		) {
			setIsWalletConnected(true);
			if (localStorage.getItem("isCustomeWalletConnected") === "true") {
				setWalletAdress(localStorage.getItem("torusWalletAddress"));
				setWalletName("Torus");
			} else if (localStorage.getItem("isCustomeWalletConnected") === "false") {
				setWalletAdress(localStorage.getItem("walletAddress"));
				setWalletName("Metamask");
			}
		} else {
			clearWalletLocalStorageData();
		}
	};

	const getIsUserLoggedIn = async () => {
		return await SDK.UserManagement.isLoggedIn();
	};

	// useEffect(() => {
	// 	async function fetchUserToken() {
	// 		let token = await SDK.UserManagement.getAuthToken();
	// 		setUserToken(token);
	// 	}
	// 	fetchUserToken();
	// }, []);

	// useEffect(() => {
	// 	//&& !isLoggedIn&& !localStorage.getItem("isCustomeWalletConnected")
	// 	if (userToken !== "" && !isEmpty(userStates) && isNil(localStorage.getItem("isCustomeWalletConnected"))) {
	// 		connectTorusAccount();
	// 	}
	// }, [userToken, userStates]);

	const connectTorusAccount = async (loginRespone) => {
		/* set loggedIn email address to local storage. we need this for furthur signwith wallet fun. */
		/* todo-find alternative solution to store email */
		localStorage.setItem("userName", loginRespone.email);
		let torusLoginResponse = await getTorusPublicKey();
		if (torusLoginResponse) {
			setWalletAdress(torusLoginResponse.publicAddress);
			setWalletName("Torus");
			setIsWalletConnected(true);
			//toast("Torus Wallet connected", "info");

			/* todo: move the method to respective files */
			/* save user wallet address loged in through torus */
			let data = {
				Address: torusLoginResponse.publicAddress,
				AccountName: "Torus",
				AccountType: 1,
			};
			createAccount(data);
		}
	};

	const getTorusPublicKey = async () => {
		try {
			let accessToken = await SDK.UserManagement.getAuthToken();
			if (!isEmpty(accessToken)) {
				let tokenResponse = await authorizePostForTorus("UserManagment/api/UserManagement/TorusLogin", {}, accessToken);
				if (tokenResponse && tokenResponse.resultCode === 1) {
					let jwtToken = tokenResponse.response.data.access_token;
					let email = localStorage.getItem("userName");
					let torusSignResult = await getTorusWallet(jwtToken, email);
					return torusSignResult;
				} else if (tokenResponse.resultCode === 0) {
					toast(tokenResponse.error.message, "error");
					return null;
				}
			} else {
				return null;
			}
		} catch (error) {
			if (error && error.message) {
				toast(error.message, "error");
			} else {
				toast(error, "error");
			}
		}
	};

	const verifyEthAccCheck = (key, ownerEthAddress) => {
		let verifyResponse = Util.verifyEthereumAccountOwnership(key, ownerEthAddress);
		if (verifyResponse) {
			// toast(t(toastMessage.privateKeyMatch), "success");
		} else {
			toast(t(toastMessage.privateKeyNotMatch), "error");
			//setIsVerifying(false);
			return;
		}
		return verifyResponse;
	};

	const clearWalletLocalStorageData = () => {
		localStorage.removeItem("torusWalletAddress");
		localStorage.removeItem("isCustomeWalletConnected");
		localStorage.removeItem("walletName");
		localStorage.removeItem("walletAddress");
		localStorage.removeItem("userName");
	};

	/*************** This is For Transfer Ownership - Externly Sold ARtwork  */

	const connectTorusAccountForNewBuyer = async (rseponseData) => {
		/* set loggedIn email address to local storage. we need this for furthur signwith wallet fun. */
		/* todo-find alternative solution to store email */
		let token = rseponseData && rseponseData.token && rseponseData.token.access_token;
		let email = rseponseData && rseponseData.token && rseponseData.token.email;

		let torusLoginResponse = await getTorusPublicKeyForNewBuyer(token, email);
		console.log("🚀 ~ file: useCustomWalletConnect.js ~ line 141 ~ connectTorusAccountForNewBuyer ~ torusLoginResponse", torusLoginResponse);
		if (torusLoginResponse) {
			let data = {
				Address: torusLoginResponse.publicAddress,
				AccountName: "Torus",
				AccountType: 1,
			};
			var formData = new FormData();
			formData.append("Address", torusLoginResponse.publicAddress);
			formData.append("AccountName", "Torus");
			formData.append("AccountType", 1);

			let createEthereumAccount = await postFormData(`eCommerce/api/Blockchain/CreateEthereumAccount`, formData, token);
			console.log("🚀 ~ file: useCustomWalletConnect.js ~ line 149 ~ connectTorusAccountForNewBuyer ~ data", createEthereumAccount);

			return createEthereumAccount;
			// createAccount(data);
		}
	};
	const getTorusPublicKeyForNewBuyer = async (accessToken, email) => {
		try {
			if (!isEmpty(accessToken)) {
				let tokenResponse = await authorizePost("UserManagment/api/UserManagement/TorusLogin", {}, accessToken);
				if (tokenResponse && tokenResponse.resultCode === 1) {
					let jwtToken = tokenResponse.response.data.access_token;
					let torusSignResult = await getTorusWalletForNewBuyer(jwtToken, email);
					return torusSignResult;
				} else if (tokenResponse.resultCode === 0) {
					toast(tokenResponse.error.message, "error");
					return null;
				}
			} else {
				return null;
			}
		} catch (error) {
			console.log("🚀 ~ file: LoginPage.js ~ line 49 ~ .then ~ errro", error);
			if (error && error.message) {
				toast(error.message, "error");
			} else {
				toast(error, "error");
			}
		}
	};

	return {
		isWalletConnected,
		walletAddress,
		walletNameLocal,
		getTorusPublicKey,
		verifyEthAccCheck,
		getIsUserLoggedIn,
		connectTorusAccount,
		//For New Buyer section
		connectTorusAccountForNewBuyer,
	};
};

export default useCustomWalletConnect;
