let userManagementUrl = "UserManagment/api/UserManagement/";
let categoryManagementUrl = "CategoryManagement/api/CategoryManagement/";
let categoryPropertyUrl = "CategoryManagement/api/CategoryManagement/Property/";
let productManagementUrl = "ProductManagement/api/ProductManagement/";
let cartManagementUrl = "OrderManagement/api/Cart/";
let wishlistManagementUrl = "ProductManagement/api/WishList/";
let orderManagementurl = "OrderManagement/api/OrderManagement/";
let orderShipmentUrl = "OrderManagement/api/Shipping/";
let authenticationUrl = "AuthServer/api/auth/";
let productsBrandUrl = "ProductManagement/api/Brands/";
let productReviewUrl = "ProductManagement/api/Review/";
let homeUrl = "eCommerce/api/Home/";
let reportUrl = "eCommerce/api/Reports/";
let recommendationsUrl = "eCommerce/api/Reccomendation/";
let artistsUrl = "UserManagment/api/Artists/";
let newsUrl = "eCommerce/api/news/";
let blogUrl = "eCommerce/api/Blogs/";
let collectionUrl = "eCommerce/api/Collection/";
let galleryUrl = "UserManagment/api/Gallery/";
let blockchainUrl = "eCommerce/api/Blockchain/";
let bulkUploadUrl = "ProductManagement/api/ProductBulkUpload/";
let paymentUrl = "OrderManagement/api/Payment/";
let generalUrl = "eCommerce/api/General/";
export class APIUrlConstants {
	static BASE_WEB_URL = process.env.REACT_APP_API_URI;

	static API_URLS = {
		// ------------------- USER Management------------------- //
		login: userManagementUrl + "Login",
		walletLogin: userManagementUrl + "WalletLogin",
		logout: userManagementUrl + "Logout",
		loginRefreshToken: userManagementUrl + "LoginRefreshToken",
		getMyInfo: userManagementUrl + "getMyInfo",
		searchBuyers: userManagementUrl + "SearchBuyer",
		searchSeller: userManagementUrl + "SearchSeller",
		updateSeller: userManagementUrl + "UpdateSeller",
		updateUser: userManagementUrl + "UpdateUser",
		getSellerInfo: userManagementUrl + "getSellerInfo?userid={?}",
		getBuyerInfo: userManagementUrl + "getBuyerInfo?userid={?}",
		blockUser: userManagementUrl + "LockUser",
		unBlockUser: userManagementUrl + "UnlockUser",
		validate2FOtp: userManagementUrl + "Validate2FAOTP",
		googleLogin: userManagementUrl + "GoogleLogin",
		facebookLogin: userManagementUrl + "FbLogin",
		roleChange: userManagementUrl + "RoleChange",
		changePassword: userManagementUrl + "ChangePassword",
		toggleTwoFactor: userManagementUrl + "Toggel2FA",
		togglePushNotification: userManagementUrl + "TogglePushNotification",
		resetUserHistory: userManagementUrl + "ResetUserHistory",
		initialUpdate: userManagementUrl + "UserInitialUpdate",
		getCountriesList: userManagementUrl + "CountryList",
		createNewArtist: userManagementUrl + "CreateNewArtistByGallery",

		getMyFollowingUserList: userManagementUrl + "GetMyFollowingUserList",
		getFollowingList: userManagementUrl + "GetFollowingUserList",
		getMyFollowersList: userManagementUrl + "GetMyFollowersList",
		getFollowersList: userManagementUrl + "GetFollowersList",

		followUser: userManagementUrl + "UserFollow",
		unfollowUser: userManagementUrl + "UnFollow",
		removeFollower: userManagementUrl + "RemoveFollower",
		getHeaderCount: userManagementUrl + "HeaderCount",
		getMyArtistFollowing: userManagementUrl + "GetMyFollowingArtistList",

		getNotifications: userManagementUrl + "getNotifications",
		validateToken: userManagementUrl + "ValidateToken",
		appleLogin: userManagementUrl + "AppleLogin",
		emailOTPVerification: userManagementUrl + "EmailOTPVerification",
		blockchainAnonymous: userManagementUrl + "BlockchainAnonymous",

		// ------------------- CATEGORY Management------------------- //
		getCategoryList: categoryManagementUrl + "GetCategoryList",
		getFeaturedCategories: categoryManagementUrl + "GetFeaturedCategories",
		getParentsByCategoryId: categoryManagementUrl + "getParantsByCategoryId",
		getCategoryDetails: categoryManagementUrl + "GetCategoryById?categoryId=",
		createCategory: categoryManagementUrl + "CreateCategory",
		deleteCategory: categoryManagementUrl + "DeleteCategory?",
		updateCategory: categoryManagementUrl + "EditCategory",
		getCategoryById: categoryManagementUrl + "GetCategoryById",
		// Property
		getCategoryProperty: categoryPropertyUrl + "GetCategoryProperties",
		getCategoryFilters: categoryPropertyUrl + "GetCategoryFIlters",
		createProperty: categoryPropertyUrl + "CreateProperty",
		updateProperty: categoryPropertyUrl + "UpdateProperty",
		deleteProperty: categoryPropertyUrl + "DeleteCategoryProperty?CategoryPropertyId=",

		// ------------------- PRODUCT Management------------------- //
		getProductDetail: productManagementUrl + "GetProduct",
		getProductBySlug: productManagementUrl + "GetProductBySlug",
		getSoldArtWorkDetails: productManagementUrl + "GetSoldArtWorkDetails",
		getProductList: productManagementUrl + "GetProductList",
		createProduct: productManagementUrl + "AddProduct",
		uploadProductImages: productManagementUrl + "UpdateProductImages",
		updateProduct: productManagementUrl + "UpdateProduct",
		deleteProduct: productManagementUrl + "DeleteProduct",
		getProductByCategory: productManagementUrl + "FilterMyProduct",
		quoteRate: productManagementUrl + "QuoteRate",
		disclosePrice: productManagementUrl + "DisclosePrice",
		searchArtwork: productManagementUrl + "SearchProductV2",
		searchArtworkV2: productManagementUrl + "SearchProductV2",

		getSellerProducts: productManagementUrl + "FilterMyProduct",
		getMySoldProducts: productManagementUrl + "GetMySoldProducts",
		getSoldProducts: productManagementUrl + "GetSoldProducts_V2",
		getProductOwnershipHistory: productManagementUrl + "GetProductOwnershipHistory",
		getSellerQuotes: productManagementUrl + "GetMyQuotesBySeller",
		updateSellerQuoteStatus: productManagementUrl + "UpdateQuotePriceStatusBySeller",
		getSellerPriceRequest: productManagementUrl + "GetMyPriceRequestBySeller",
		updateSellerPriceStatus: productManagementUrl + "UpdatePriceRequest",
		getMyQuotes: productManagementUrl + "GetMyQuotes",
		deleteMyQuotes: productManagementUrl + "DeleteQuote",
		bulkUpload: bulkUploadUrl + "ReadFromExcel",
		getExcelReport: bulkUploadUrl + "Template",
		getSignaturePendingArtworks: orderManagementurl + "GetAllSigningRequestsByUserId",
		doCheckRFID: productManagementUrl + "DoesRFIDExists",
		updateProductStatus: productManagementUrl + "UpdateProductStatus",
		checkAddProductEligibility: productManagementUrl + "CheckAddProductEligibility",
		getGalleryAddedProducts: productManagementUrl + "GetGalleryAddedProducts",
		getArtistProducts: productManagementUrl + "GetArtistsProducts",
		getGalleryView: productManagementUrl + "GetGalleryView",
		getInventory: productManagementUrl + "GetInventory",
		getOwnedArtworks: productManagementUrl + "boughtArtworks",
		getOriginalArtworks: productManagementUrl + "GetOriginalArtworks",
		bypassNfc: productManagementUrl + "BypassNfc",
		updatePrice: productManagementUrl + "UpdatePrice",
		getArtworkPropertyValues: productManagementUrl + "ArtworkPropertyValues",

		// ------------------- CART Management------------------- //
		getCartList: cartManagementUrl + "GetCart",
		addToCart: cartManagementUrl + "AddCart",
		editCart: cartManagementUrl + "UpdateCart",
		deleteCart: cartManagementUrl + "DeleteCart",

		// ------------------- Wishlist Management ------------------- //
		getWishlist: wishlistManagementUrl + "GetWishList",
		addToWishlist: wishlistManagementUrl + "AddWishlist",
		deleteFromWishlist: wishlistManagementUrl + "DeleteWishList",

		// ------------------- ORDER Management------------------- //
		checkoutCart: orderManagementurl + "CheckoutCart",
		getPlacedOrders: orderManagementurl + "GetPlacedOrders",
		getOrderDetails: orderManagementurl + "GetOrderDetails_V2",
		getOrderHistory: orderManagementurl + "GetOrderHistory",
		getUserOrderHistory: orderManagementurl + "GetUserOrderHistory",
		updateOrderStatus: orderManagementurl + "UpdateOrderStatus",
		orderBuyNow: orderManagementurl + "BuyNow",
		cancelOrder: orderManagementurl + "CancelOrder",
		returnOrder: orderManagementurl + "ReturnOrder",
		orderInvoice: reportUrl + "GetInvoice_V3",
		getOrderDetailsById: orderManagementurl + "GetOrderById",
		getSignaturePendingOrders: orderManagementurl + "GetAllSigningRequestsByUserId",
		getOrderSummary: orderManagementurl + "GetOrderSummary",
		needHelp: orderManagementurl + "NeedHelp",
		createShipment: orderManagementurl + "CreateShipment",
		getWaybillNumber: reportUrl + "GetWaybillNumber",
		pickupDetails: orderManagementurl + "PickupDetails",

		// ------------------- Address Book ------------------- //
		getAddress: orderShipmentUrl + "GetShippingAdd",
		addAddress: orderShipmentUrl + "AddAddress",
		updateAddress: orderShipmentUrl + "UpdateAddress",
		deleteAddress: orderShipmentUrl + "DeleteAddress",

		// ------------------- Order Billing ------------------- //
		getBillingAddress: orderShipmentUrl + "GetBillingAdd",
		addBillingAddress: orderShipmentUrl + "AddBillingAddress",
		updateBillingAddress: orderShipmentUrl + "UpdateBillingAddress",
		deleteBillingAddress: orderShipmentUrl + "DeleteBillingAddress",
		setDefaultBillingAddress: orderShipmentUrl + "SetDefaultBillingAddress",

		// ------------------- PickUp Address ------------------- //
		getPickUpAddress: orderShipmentUrl + "GetPickupAdd",
		addPickUpAddress: orderShipmentUrl + "AddPickupAddress",
		updatePickUpAddress: orderShipmentUrl + "UpdatePickupAddress",
		deletePickUpAddress: orderShipmentUrl + "DeletePickupAddress",
		setDefaultPickupAddress: orderShipmentUrl + "SetDefaultPickupAddress",

		// ------------------- Authentication------------------- //
		registerSeller: authenticationUrl + "RegisterSeller",
		registerUser: authenticationUrl + "RegisterUser",
		resendOTP: authenticationUrl + "ResendVerificationOTP",
		sendOtp: authenticationUrl + "SendOtpToEmail",
		validateOtp: authenticationUrl + "ValidateOTP",
		resetPassword: authenticationUrl + "ResetPassword",

		// ------------------- Products BRAND Management------------------- //
		getCategoryBrands: productsBrandUrl + "GetBrand?categoryId=",
		getBrands: productsBrandUrl + "GetBrand?categoryId={?}&Status={?}&brandPriority={?}&BrandName={?}",
		getMyBrands: productsBrandUrl + "GetMyBrand?categoryId={?}&Status={?}&brandPriority={?}&BrandName={?}",
		createBrand: productsBrandUrl + "AddBrand",
		deleteBrand: productsBrandUrl + "DeleteBrand",
		updateBrand: productsBrandUrl + "UpdateBrand",

		getAllComments: productReviewUrl + "GetComments",
		addComment: productReviewUrl + "AddComment",
		updateComment: productReviewUrl + "UpdateComment",
		deleteComment: productReviewUrl + "DeleteComment",
		likeProduct: productReviewUrl + "Like",
		dislikeProduct: productReviewUrl + "Dislike",

		// ------------------- HOME Management------------------- //
		getHomeList: homeUrl + "GetHomeList",
		getAllHomeList: homeUrl + "GetAllHomeList",
		createHomeList: homeUrl + "AddHomeList",
		updateHomeList: homeUrl + "UpdateHomeList",
		deleteHomeList: homeUrl + "DeleteHomeList?listId=",
		createSlider: homeUrl + "AddHomeListItem",
		updateSlider: homeUrl + "UpdateHomeListItem",
		deleteSlider: homeUrl + "DeleteHomeListItem?itemId=",
		searchProducts: homeUrl + "Search?Keyword={?}&searchItemTypes=0",
		getLatestArtists: homeUrl + "GetLatestArtists",
		getPopularArtists: homeUrl + "GetPopularArtists",
		getHomeScreenBanner: homeUrl + "GetHomeScreenBanner",
		getNewsList: newsUrl + "GetNews",
		getNewsById: newsUrl + "GetNewsById",
		getNewsBySlugId: newsUrl + "GetNewsBySlugId",
		getBlogsList: blogUrl + "GetBlogs",
		featuredSlider: recommendationsUrl + "GetFeaturedGalleries",
		getSelectedCollections: collectionUrl + "GetSelectedCollections",
		getSearchResult: homeUrl + "Search_V2",
		saveSearch: homeUrl + "SaveSearch",

		// ------------------- Reports/ Dashboard ------------------- //
		getDashboardInfo: reportUrl + "GetSellerDashboard",
		getDashboardDetails: reportUrl + "GetAdminDashboard",

		//------------------- User ARTISTS -------------------------//
		getArtistTypesById: artistsUrl + "GetArtistTypesById",
		getArtistTypes: artistsUrl + "GetArtistTypes",
		addArtistTypes: artistsUrl + "CreateArtistTypes",
		updateArtistTypes: artistsUrl + "UpdateArtistTypes",
		deleteArtistTypes: artistsUrl + "DeleteArtistTypes",
		filterArtistByTypes: artistsUrl + "FilterArtists",
		filterAdminArtistByTypes: artistsUrl + "FilterArtistsAdmin",

		getMyArtistTypes: artistsUrl + "GetMyArtistTypes",
		getArtistTypesBySlugId: artistsUrl + "GetArtistTypesBySlugId",
		updateArtistUserTypes: artistsUrl + "UpdateArtistUserTypes",

		getBasicDescription: artistsUrl + "GetArtistProfile",
		getBasicDescriptionById: artistsUrl + "GetArtistProfileById",
		updateArtistProfile: artistsUrl + "UpdateArtistProfile",
		updateArtistProfileImages: artistsUrl + "UpdateArtistProfileImage",

		getArtistInterests: artistsUrl + "GetArtistInterests",
		getArtistInterestsById: artistsUrl + "GetArtistInterestsById",
		getMyInterests: artistsUrl + "GetMyInterests",
		addArtistInterests: artistsUrl + "CreateArtistInterests",
		getArtistInterestsBySlugId: artistsUrl + "GetArtistInterestsBySlugId",
		updateArtistInterests: artistsUrl + "UpdateArtistInterests",
		deleteArtistInterests: artistsUrl + "DeleteArtistInterests",

		getGalleryList: artistsUrl + "GetGalleryList",
		getGalleryListByUserId: artistsUrl + "GetGalleryListByUserId",
		addNewGallery: artistsUrl + "AddNewGallery",
		updateGallery: artistsUrl + "UpdateGallery",
		deleteFromGallery: artistsUrl + "DeleteGallery",
		getArtists: artistsUrl + "GetArtists",

		getEventList: artistsUrl + "GetEventList",
		getEventListByUserId: artistsUrl + "GetEventListByUserId",
		addNewEvent: artistsUrl + "AddEvents",
		updateEvent: artistsUrl + "UpdateEvents",
		deleteFromEvent: artistsUrl + "DeleteEvent",

		getAwardList: artistsUrl + "GetAwardList",
		getAwardListByUserId: artistsUrl + "GetAwardListByUserId",
		addNewAward: artistsUrl + "AddNewAward",
		updateAward: artistsUrl + "UpdateAward",
		deleteAward: artistsUrl + "DeleteAward",

		getHistoricalDataList: artistsUrl + "GetHistoricalDataList",
		getHistoricalDataListByUserId: artistsUrl + "GetHistoricalDataListByUserId",
		addNewHistorical: artistsUrl + "AddNewHistorical",
		updateHistoricalData: artistsUrl + "UpdateHistoricalData",
		deleteHistoricalData: artistsUrl + "DeleteHistoricalData",
		getGalleryMembershipRequests: artistsUrl + "GetGalleryMembershipRequests",
		updateGalleryMembershipRequest: artistsUrl + "UpdateGalleryMembershipRequest",

		getMyBlogs: blogUrl + "GetMyBlogs",
		getBlogsByUser: blogUrl + "GetBlogsByUser",
		getBlogsById: blogUrl + "GetBlogsById",
		addBlog: blogUrl + "AddBlogs",
		updateBlog: blogUrl + "UpdateBlogs",
		deleteBlog: blogUrl + "DeleteBlogs",

		getCollectionByArtist: collectionUrl + "GetCollectionListByUser",
		getCollectionListByUserId: collectionUrl + "GetCollectionListByUserId",
		addCollectionByArtist: collectionUrl + "AddCollection",
		updateCollection: collectionUrl + "UpdateCollection",
		deleteCollection: collectionUrl + "DeleteCollection",
		filterMyProduct: productManagementUrl + "FilterMyProduct",
		getCollectionById: collectionUrl + "GetCollectionById",
		deleteItemFromCollection: collectionUrl + "DeleteItemFromCollection",
		getAllCollections: collectionUrl + "GetAllCollections",

		// ------------------- Recommendations Management------------------- //
		getSimilarProducts: recommendationsUrl + "GetSimilarProducts",
		getSuggestedProducts: recommendationsUrl + "GetSuggestedProducts",
		continueSearch: recommendationsUrl + "ContinueYourSearch",
		getRecommendedCategories: recommendationsUrl + "GetSuggestedCategories",
		getTrendingCategories: recommendationsUrl + "TrendingCategories",
		artworksOnOffer: recommendationsUrl + "ProductsOnOffer",
		getFeaturedArtists: recommendationsUrl + "GetFeaturedArtists",
		getLatestArtworks: recommendationsUrl + "LatestArtworks",
		getLikeAndWishList: recommendationsUrl + "LikeAndWishList",
		getPopularProducts: recommendationsUrl + "GetPopularProducts",
		getFeaturedArtworks: recommendationsUrl + "GetFeaturedArtworks",

		//---------------------- User Gallery --------------------------------//
		updateGalleryProfile: galleryUrl + "UpdateGalleryProfile",
		updateGalleryProfileImages: galleryUrl + "UpdateGalleryProfileImage",
		getMyGalleryProfile: galleryUrl + "GetGalleryProfile",
		getGalleryProfileById: galleryUrl + "GetGalleryProfileById",
		getMyGalleryFollowing: userManagementUrl + "GetMyFollowingGalleryList",
		getMyFollowers: userManagementUrl + "GetMyFollowersList",
		getMyFollowing: userManagementUrl + "GetMyFollowingUserList",
		getArtistListByGallery: galleryUrl + "GetArtistListByGallery",
		getArtistListByUserId: galleryUrl + "GetArtistListByUserId",
		addNewArtistToGallery: galleryUrl + "AddNewArtistToGallery",
		deleteArtistFromGallery: galleryUrl + "DeleteArtistFromGallery",

		getHistoricalDataListGallery: galleryUrl + "GetHistoricalDataList",
		getHistoricalDataListByUserIdGallery: galleryUrl + "GetHistoricalDataListByUserId",
		addNewHistoricalGallery: galleryUrl + "AddNewHistorical",
		updateHistoricalDataGallery: galleryUrl + "UpdateHistoricalData",
		deleteHistoricalDataGallery: galleryUrl + "DeleteHistoricalData",

		getEventListGallery: galleryUrl + "GetEventList",
		getEventListByUserIdGallery: galleryUrl + "GetEventListByUserId",
		addNewEventGallery: galleryUrl + "AddEvents",
		updateEventGallery: galleryUrl + "UpdateEvents",
		deleteFromEventGallery: galleryUrl + "DeleteEvent",

		getAwardListGallery: galleryUrl + "GetAwardList",
		getAwardListByUserIdGallery: galleryUrl + "GetAwardListByUserId",
		addNewAwardGallery: galleryUrl + "AddNewAward",
		updateAwardGallery: galleryUrl + "UpdateAward",
		deleteAwardGallery: galleryUrl + "DeleteAward",

		filterGalleries: galleryUrl + "FilterGalleries",
		updateGalleryAddress: galleryUrl + "UpdateGalleryAddress",
		searchForArtists: galleryUrl + "SearchforArtist",

		//----------------------Blockchain Management--------------------------//
		createBlockchainAccount: blockchainUrl + "CreateEthereumAccount",
		getBlockchainAccount: blockchainUrl + "GetMyEthereumAccount",
		buyArtWorksTransaction: blockchainUrl + "BuyArtWorksTransaction",
		addSignedMessage: blockchainUrl + "AddSignedMessage",
		metaDataUpdates: blockchainUrl + "MetadataUpdateSignature",
		getCertificate: blockchainUrl + "GetCoA",

		//----------------------Payment--------------------------//
		getCurrencies: paymentUrl + "GetCurrencies",
		convertCurrency: paymentUrl + "CurrencyConversion",
		stripePayment: paymentUrl + "StripePayment",
		stripeIntent: paymentUrl + "StripeIntent",
		stripeConfirmation: paymentUrl + "StripePaymentConfirmation",
		cancelPayment: paymentUrl + "CancelPayment",
		paypalPayment: paymentUrl + "PaypalPayment",
		getPaypalClientCode: paymentUrl + "PaypalClientId",
		paypalBrainTreePayment: paymentUrl + "PaypalBraintreePayment",
		paypalFailedPayment: paymentUrl + "PaymentFailed",

		//---------------Contact--------------------//
		sendContactUs: generalUrl + "ContactUs",

		//---------------KYC Document--------------------//
		updateKycDocument: userManagementUrl + "UpdateKYC",
		getOwnKycDocument: userManagementUrl + "getMyKYC'",
	};

	static getApiUrl(key) {
		return this.BASE_WEB_URL + this.API_URLS[key];
	}

	static getApiFullUrl(url, urlDirectParams) {
		return this.BASE_WEB_URL + url + urlDirectParams;
	}
}
