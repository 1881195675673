import styled, {
	css
} from "styled-components";
import StyledButton from "common/StyledButton";
import {
	wrapperPadding
} from "styled";
import {
	Row
} from "antd";

const UpperWrapper = styled.div`
	position: relative;
	margin-top: -86px;
	@media screen and (min-width: 576px) {
		margin-top: -112px;
	}
`;
const LowerWrapper = styled.div``;
const CreationStyleAndFollowersRow = styled(Row)
	`
	margin-left: 25px;

	@media (max-width: 576px) {
		margin-left: 0;
	}
`;
const BriefIntroRow = styled(Row)
	`
	margin-left: 25px;
	position: relative;
	/* padding-top:30px; */

	@media (max-width: 576px) {
		margin-left: 0;
	}
`;
const ArtistIntroWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
`;

const Line = styled.hr`
	border-top: 1px solid #efefef;
	margin: 0 1em;
`;

const ArtistInfoContainer = styled.div`
	${wrapperPadding}
	position:relative;
`;
const ActionBox = styled.div`
	width: 38px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
	border-radius: 6rem;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
	img {
		width: 17px;
	}
`;

// ---- FollowButton ----//
const FollowButton = styled(StyledButton)
	`
background-color: #fff !important;
border: 1px solid #D9D9D9 !important;
	width: 180px;
height: 40px;
text-align: center;
font: normal normal normal 12px/25px Lato !important;
letter-spacing: 0.3px;
color: #000 !important;
text-transform: uppercase;

`;
const CreationStyleButton = styled(StyledButton)
	`
	border-color: #d6d6d6 !important;
	height: auto !important;
	font-weight: 500 !important;
	color: #000000 !important;
	font-size: 12px !important;
	white-space: normal;
	word-wrap: break-word;
`;

const ArtistIntroInnerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const ArtistInnerWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
`;

const ArtistIntroInnerText = styled.div`
	display: flex;
	align-items: center;
	/* margin-left: 14.5rem; */
	@media screen and (max-width: 576px) {
		padding-top: 50px;
	}
`;

const FollowerBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	flex-direction: column;

	span {
		${(props) => (props.isMyProfile ? "cursor: pointer" : null)};
		font-weight: 500;
	}
`;

// ----- MainBoxContainer -----//
const MainBoxContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 86%;
	margin-left: 16.8%;
`;

// ----- LargeBoxContainer -----//
const BoxContainer = styled.div`
	padding: 16px 0;
	min-height: 5.5rem;

	/* width: 55%; */
	height: fit-content;
	width:-webkit-fill-available;
	border: ${(props) => {
		return props.border;
	}};
	display: flex;
	flex-direction: column;
	align-content: center;
	margin-bottom: ${(props) => {
		return props.marginbottom;
	}};
	h3 {
		text-align: center;
	}

	@media(max-width: 576px) {
		margin-bottom: 1rem;
	}
`;
// ----- SmallBoxContainer -----//
const SmallBoxContainer = styled.div`
	padding: 16px 0;
	display: flex;
	flex-direction: column;
	min-height: 5.5rem;
	height: fit-content;
	align-self: flex-start;
	/* width: 26%; */
	border: ${(props) => {
		return props.border;
	}};
	padding-left: 1rem;
	align-content: center;
	margin: 0 11% 1em 5px;

	p {
		text-align: center;
	}

	@media(max-width: 576px) {
		margin-right: 0;
	}
`;
const Head = styled.h3`
	/* color: ${(props) => {
		return props.color;
	}};
	text-align: ${(props) => {
		return props.textalign;
	}}; */
	/* margin-bottom: 1.5rem; */
	text-align: left;
	font: normal normal normal 15px/59px Lato !important;
	letter-spacing: 0.38px;
	color: #000000;
	text-transform: uppercase;
`;

// ----- AddButton -----//
const AddButton = styled.div`
	font-weight: bold;
	color: #58514b;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 20px;
		padding-right: 5px;
		font-weight: 200;
	}
	&:hover {
		cursor: pointer;
	}
`;
const EditPill = styled.div`
	display: flex;
	align-items: center;
	align-self: flex-end;
	padding: 5px 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
	border-radius: 6rem;
	margin-left: 10px;
	width: fit-content;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
	img {
		width: 17px;
	}
`;

const ArtistMainBannerImg = styled.div`
 height: 297px;
width: 100%;
background-image: url(${props => props.bgImg});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
@media (max-width: 768px) {
	height: 156px;
}
h1 {
            text-align: center;
            font: normal normal 300 31px/43px Lato !important;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
			@media (max-width: 768px) {
				font: normal normal 300 20px/23px Lato !important;

			}
        }
		div{
			width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.5;
		}
`;
export {
	ArtistIntroWrapper,
	ActionBox,
	FollowButton,
	UpperWrapper,
	LowerWrapper,
	BriefIntroRow,
	CreationStyleButton,
	ArtistIntroInnerWrapper,
	ArtistInnerWrapper,
	BoxContainer,
	MainBoxContainer,
	AddButton,
	SmallBoxContainer,
	Head,
	FollowerBlock,
	ArtistIntroInnerText,
	EditPill,
	ArtistInfoContainer,
	Line,
	CreationStyleAndFollowersRow,
	ArtistMainBannerImg
};

// Component: ArtistProfileSectionTabs
export const ArtistProfileSectionTabsWrapper = styled.div`
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 1rem;
width: 100 %;
`;