import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, FastField, Field } from "formik";
import * as Yup from "yup";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";
import { useTranslation } from "react-i18next";
import toast from "common/Toast";
import { Link } from "react-router-dom";
import StyledButton from "common/StyledButton";
import assets from "assets";

import "./scss/DigtalCertificateAuthenticity.scss";
function DigtalCertificateAuthenticity({ onSubmitAction, selectedPage, isLoading }) {
	const { t } = useTranslation();

	const renderPage = () => {
		if (selectedPage === "welcome-page") {
			return (
				<div className="welcome-page">
					<h4>Digital Certificate of Authenticity</h4>
					<div>
						<p>
							Do you want to send a Complimentary Digital Certificate to the New Owner <br />
							<a
								hfre="https://help.rtistiq.com/en/article/digital-certificate-of-authenticity-14wcowu/?bust=1657349157894"
								traget="_blank"
							>
								(Know more about it?){" "}
							</a>
						</p>
					</div>
					<div className="digital-sign-img-container">
						<img src={assets.images.digitalSign} alt="Digital Sign" />
					</div>
					<div className="button-container">
						<button
							className="no-btn"
							onClick={() => {
								let data = {
									userOption: "no",
								};
								onSubmitAction(selectedPage, data);
							}}
						>
							No
						</button>

						<button
							className="yes-btn"
							onClick={() => {
								let data = {
									userOption: "yes",
								};
								onSubmitAction(selectedPage, data);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			);
		} else if (selectedPage === "entry") {
			return (
				<>
					<Formik
						initialValues={{
							ownerName: "",
							emailId: "",
							message: "",
						}}
						validationSchema={Yup.object().shape({
							ownerName: Yup.string().required(t("Please enter the owner name")),
							emailId: Yup.string().email(t("The email is incorrect")).required(t("Please enter your email")),
						})}
						onSubmit={(values, actions) => {
							onSubmitAction(selectedPage, values);
						}}
					>
						{(props) => {
							return (
								<Form name="registration" method="post">
									<h4>Digital Certificate of Authenticity</h4>
									<div className="form-group">
										<p>Owner Name</p>
										<Field
											type="text"
											name="ownerName"
											render={({ field, form: { touched, errors } }) => (
												<div>
													<input {...field} type="text" placeholder="" />
													{touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
												</div>
											)}
											placeholder=""
										/>
									</div>
									<div className="form-group">
										<p>Email Id</p>
										<Field
											type="text"
											name="emailId"
											render={({ field, form: { touched, errors } }) => (
												<div>
													<input {...field} type="text" placeholder="" />
													{touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
												</div>
											)}
											placeholder=""
										/>
									</div>
									<div className="form-group">
										<p>Address</p>
										<Field
											type="text"
											name="message"
											render={({ field, form: { touched, errors } }) => (
												<div>
													<input {...field} type="text" placeholder="" />
													{touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
												</div>
											)}
											placeholder=""
										/>
									</div>

									<div className="button-container">
										<Link onClick={""}>CANCEL</Link>

										<StyledButton
											htmlType="submit"
											variant="invert"
											width="25%"
											loading={isLoading}
											disabled={isLoading}
											margin="0px"
										>
											{isLoading ? t("LOADING..") : t("NEXT")}
										</StyledButton>
									</div>
								</Form>
							);
						}}
					</Formik>
				</>
			);
		} else if (selectedPage === "success") {
			return (
				<div>
					<h4>Digital Certificate of Authenticity</h4>
					<div className="success-container">
						<p>
							Token creation has been initiated. Once the minting is completed you will receive a notification to provide signature for
							transferring the ownership to the new buyer. After that an email will be send to the buyer of this artwork and they have
							to signup into RtistiQ to collect their digital certificate.
						</p>
						<img src={assets.images.doneGreenTick} alt="Done Green btn" />
					</div>
					<div className="button-container-cancel">
						<button className="done-btn" onClick={() => onSubmitAction(selectedPage)}>
							Done
						</button>
					</div>
				</div>
			);
		}
	};
	return <div className="digital-maincontainer">{renderPage()}</div>;
}

export default DigtalCertificateAuthenticity;
