import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class AddProductService {
    static API = APIUrlConstants.API_URLS;
    static service = new BaseService();

    // Create Product
    static async createProduct(body) {
        return this.service.execute(this.API.createProduct, HTTP_METHOD.POST, { body });
    }

    // Update Product
    static async updateProduct(id, body) {
        let urlDirectParams = `/${id}`;
        return this.service.execute(this.API.updateProduct, HTTP_METHOD.PUT, { urlDirectParams, body });
    }

    //Delete product
    static async deleteProduct(stockId) {
        let params = { stockId: stockId };
        return this.service.execute(this.API.deleteProduct, HTTP_METHOD.DELETE, { params });
    }

    // Update Images and Videos
    static async updateImagesAndVideos(body) {
        return this.service.execute(this.API.uploadProductImages, HTTP_METHOD.MULTIPART_POST, { body });
    }

    //Get Original Artworks
    static async getOriginalArtworks() {
        return this.service.execute(this.API.getOriginalArtworks, HTTP_METHOD.GET);
    }
    //Get Artwork Property
    static async getAllArtWorkProperty(body) {
        return this.service.execute(this.API.getArtworkPropertyValues, HTTP_METHOD.POST, { body })
    }
}
