/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { FrameWrapper } from "./SafeHtmlRenderer.styled";

const SafeHtmlRenderer = ({ body }) => {
	return (
		<FrameWrapper>{ReactHtmlParser(body)}</FrameWrapper>
	);
};

export default SafeHtmlRenderer;
