/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, lazy } from "react";
import { useDispatch } from "react-redux";
import { Spin, Icon, Affix, Tooltip } from "antd";
import { includes } from "lodash";
import { withRouter } from "react-router";
import SDK, { homeHooks, userManagementHooks, productDetailHooks, categoryHooks, artistBasicProfileHooks, LoginHooks } from "ecommerce-sdk/src/index";
import assets from "../../assets";
import { showModal } from "redux/actions/ModalActions";
import ModalTypes from "utils/ModalTypes";
import useAutoLogout from "utils/useAutoLogout";
import useRefreshBadge from "utils/useRefreshBadge";
import { MainContainerWrapper, NoInternetImage, NoInternetImageWrapper } from "./MainContainer.styled";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

//For Mobile Nav Bar
import { Col } from "antd";
import {
	NavbarWrapper,
	HamburgerButton,
	MobileAction3Dot,
	FullWidthRow,
	LanguageWrapper,
	LanguageSelect,
	SearchWrapperMobile,
	SearchWrapper,
	WalletButtonContainer,
	WalletPopUpContainer,
	WalletMainContainer,
	PopUpInnerDiv,
	ETHContinare,
} from "../Navbar/Navbar.styled";
import { BlinkingMenu } from "../Navbar/NavbarMenu/NavbarMenu.styled";

import Logo from "common/Logo/Logo";
import IconContainer from "common/IconContainer";
import { Input, Space, Popover } from "antd";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import "../TopMenu/TopMenu.style.css";

// Components
import SearchComponet from "common/Search";
import NetworkDetector from "common/NetworkDetector";
import ProfileDropdown from "common/Navbar/ProfileDropdown";
import Navbar from "common/Navbar";
import NfcSkipModal from "common/NfcSkipModal";
import { Helmet } from "react-helmet";
import toast from "common/Toast";
import { toastMessage, errorToast, warningMessage } from "utils/toastMessages";
import lodash from "lodash";

import {
	MobileMenuContentWrapper,
	MobileMenuContentClose,
	MobileMenuContentLink,
	MobileMenuContentDrop,
	MobileMenuArrow,
	MobileMenuLevels,
	MobileMenuWrapMask,
	MobileMenuMask,
	ProfileSectionMobile,
	ProfileTextSection,
	ProfileNameContainerMobile,
	SingleMobileMenu,
	SocialMediaIconMobile,
} from "../Navbar/MobileMenu/MobileMenu.styled";
import MobileMenuForArtworks from "../Navbar/MobileMenu/MobileMenuForArtworks";

import { isEmpty, isUndefined } from "lodash";
import { useMediaQuery } from "react-responsive";
import TopMenu from "common/TopMenu/TopMenu";
import { ProfileDropDownMobile } from "common/Navbar/ProfileDropdown/ProfileDropDownMobile";
import { SBadge } from "common/IconContainer/IconContainer.styled";
import { connectWallet } from "utils/walletCommonFunction";
import { TORUS_NETWORK } from "@toruslabs/customauth";
import useCustomWalletConnect from "utils/useCustomWalletConnect";

const Footer = lazy(() => import("common/Footer"));
const PageBanner = lazy(() => import("common/PageBanner"));
const BreadCrumbNav = lazy(() => import("common/BreadcrumbNav"));

const LoginPage = lazy(() => import("pages/login"));
const Registrationpage = lazy(() => import("pages/registration"));
const ResetPasswordComponent = lazy(() => import("common/ResetPasswordComponent"));
const ForgotpasswordComponent = lazy(() => import("common/ForgotpasswordComponent"));
const VerificationComponent = lazy(() => import("common/VerificationComponent"));
const PasswordResetSuccess = lazy(() => import("common/PasswordResetSuccess"));
const TwoFactorAuth = lazy(() => import("common/TwoFactorAuth"));
const UserTypeSelect = lazy(() => import("pages/Profile/UserTypeSelect"));
const YouAreHereModal = lazy(() => import("common/YouAreHereModal"));
const CategoryOfInterest = lazy(() => import("common/CategoryOfInterest"));
const SellYourArtModal = lazy(() => import("common/SellYourArtModal"));
const CreateBlockchainAccount = lazy(() => import("common/CreateBlockchainAccount"));
const BlockchainVerificationModal = lazy(() => import("common/BlockchainVerificationModal"));
const ResendEmailModal = lazy(() => import("common/ResendEmailModal"));
const BlockchainPendingModal = lazy(() => import("pages/Profile/BlockchainPendingArtworks/BlockchainPendingModal/BlockchainPendingModal"));
const TwoFactorConfirmPopup = lazy(() => import("common/TwoFactorConfirmPopup"));
const AddOverviewModal = lazy(() => import("pages/Artist/Overview/AddOverviewModal"));
const ChooseCountryModal = lazy(() => import("common/ChooseCountryModal"));
const SuccessResponseModal = lazy(() => import("common/SuccessResponseModal"));
const ResponseRedirectionModal = lazy(() => import("common/ResponseRedirectionModal"));
const SellAddArtworkModal = lazy(() => import("common/SellAddArtworkModal"));
const SellerAgreementModal = lazy(() => import("common/SellerAgreementModal"));
const KycDocumentModel = lazy(() => import("common/KYCDocumentModal"));

let isVisibleChange = false;
const { Search } = Input;
const MainContainer = ({
	hideBreadcrumbsBar = false,
	spin = false,
	children,
	history,
	isDisconnected,
	showBanner,
	bannerImage,
	bannerHeading,
	hideNavbarOptions = false,
	hideNavBarFull = false,
	isHideFooterTop = false,
	isHideFooterBottom = false,
	...props
}) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { useLogout } = LoginHooks;
	const { t } = useTranslation();

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isProfilePopoverVisible, setIsProfilePopoverVisible] = useState(false);
	const [isGlobalSearchDisable, setIsGlobalSearchDisable] = useState(false);
	const [nftMenuImages, setNftMenuImages] = useState([assets.images.nftMenuIdel, assets.images.nftMenuBliker]);
	const [currentImg, setCurrentImg] = useState(1);
	const location = useLocation();
	const switchImage = () => {
		if (currentImg < nftMenuImages.length - 1) {
			setCurrentImg(currentImg + 1);
		} else {
			setCurrentImg(0);
		}
		return currentImg;
	};

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		switchImage();
	// 	}, 1000);
	// });
	// ======================== Search ======================== //
	const [isSearch, setSearch] = useState(false);
	const { useSaveSearch } = homeHooks;
	const { useCheckAddProductEligibility } = productDetailHooks;
	const [saveSearch] = useSaveSearch();
	const handleBlur = (searchText) => {
		setSearch(!isSearch);
		setIsProfilePopoverVisible(false);
		setSearchText("");
		if (searchText && searchText.length > 3) saveSearch("", searchText);
	};
	// ======================== Icon Counts ======================== //
	const { useHeaderCount } = homeHooks;
	const [refreshBadges, shouldRefresh] = useRefreshBadge();
	const [getHeaderCount, getHeaderCountResult, getHeaderCountError] = useHeaderCount();
	const { isWalletConnected, walletAddress, walletNameLocal, getIsUserLoggedIn } = useCustomWalletConnect();


	const handleHeaderCountCall = () => {
		if (isLoggedIn) getHeaderCount();
	};

	const isTabletOrMobileDevice = useMediaQuery({
		//updated for tab 
		query: "(max-device-width: 1020px)",
	});
    console.log("🚀 ~ file: MainContainer.js ~ line 178 ~ isTabletOrMobileDevice", isTabletOrMobileDevice)
	const toggleSideBar = () => {
		setIsOpenSidebar(!isOpenSidebar);
	};
	useEffect(() => {
		const checkLoggedIn = async () => {
			const loggedIn = await SDK.UserManagement.isLoggedIn();
			setIsLoggedIn(loggedIn);
			if (loggedIn) {
				getHeaderCount();
				// if (isEmpty(checkAddProductEligibilityData)) {
				// getCheckAddProductEligibility();
				// }
			} else {
				const query = new URLSearchParams(history.location.search);
				const page = query.get("page");
				if (history.location && history.location !== "") {
					if (history.location.pathname === "/user/login") {
						// dispatch(showModal(ModalTypes.login));
					} else if (history.location.pathname === "/user/register") {
						// dispatch(showModal(ModalTypes.register));
					}
				}
			}
		};
		checkLoggedIn();
		SDK.UserManagement.getUserRoles((result) => setUserRole(result));
	}, []);

	useEffect(() => {
		SDK.UserManagement.getFirstSocialLogin((result) => {
			if (result === true) {
				history.push("/user/register/choose-country");
				// dispatch(showModal(ModalTypes.chooseCountryModal));
			}
		});
	}, []);

	useEffect(() => {
		if (shouldRefresh) {
			handleHeaderCountCall();
			refreshBadges(false);
		}
	}, [shouldRefresh]);

	useEffect(() => {
		if (pathname.includes("/artists") && isTabletOrMobileDevice) {
			setIsGlobalSearchDisable(true);
		} else if (pathname.includes("/lots") && isTabletOrMobileDevice) {
			setIsGlobalSearchDisable(true);
		} else {
			setIsGlobalSearchDisable(false);
		}

		//This is for checkout page blockchain & coupon state management
		if (!pathname.includes("checkout")) {
			localStorage.removeItem("isCheckOutBlockChainVerified");
			localStorage.removeItem("blockChainData");
			localStorage.removeItem("appliedCouponCode");
		}
	}, [pathname]);

	const dismissProfileDropDown = () => setIsProfilePopoverVisible(false);

	// ======================== Profile Popover ======================== //
	const { useGetUserInfo } = userManagementHooks;
	const [getUserInfo, userStates, , userInfoLoading] = useGetUserInfo();
	const [userRoles, setUserRole] = useState();
	const [isClickWallet, setIsClickWallet] = useState(false);

	useEffect(() => {
		if (userRoles && includes(userRoles, "ARTIST") && isEmpty(userStates) && !userInfoLoading) getUserInfo();
	}, [userRoles]);

	const showSellArtwork = userRoles && (includes(userRoles, "ARTIST") || includes(userRoles, "GALLERY"));

	const isArtistBlockedToSell = () => {
		if (includes(userRoles, "ARTIST")) return userStates && userStates.exclusivity;
		else if (includes(userRoles, "GALLERY")) return false;
	};

	//================= Fro Google Analytics =======//
	useEffect(() => {
		if (!isEmpty(userStates)) {
			ReactGA.event({
				category: "PageView",
				action: `${userStates.userId} visited the page ${pathname} on ${new Date()}`,
				label: `UserName: ${userStates.name}  Email : ${userStates.email} `,
			});

			console.log("🚀 ~ file: MainContainer.js ~ line 185 ~ pathname", {
				category: "PageView",
				action: `${userStates.name} visited the page ${pathname} on ${new Date()}`,
				label: `UserName: ${userStates.name}  Email : ${userStates.email} `,
			});
		}
	}, [pathname]);

	//================= Fro Google Analitics =======//
	useEffect(() => {
		if (!isEmpty(userStates)) {
			ReactGA.event({
				category: "PageView",
				action: `${userStates.userId} visited the page ${pathname} on ${new Date()}`,
				label: `UserName: ${userStates.name}  Email : ${userStates.email} `,
			});
		}
	}, [pathname]);
	const [isCopied, setIsCopied] = useState(false);

	const onCopyToClipboard = (text) => {
		setIsCopied(false);
		navigator.clipboard.writeText(text);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	};
	const walletContent = isClickWallet && isWalletConnected && (
		<WalletPopUpContainer>
			<Tooltip title={isCopied ? "Copied" + "!" : "Copy to clipboard"}>
				<PopUpInnerDiv>
					<p className="wallet-address-text">{walletAddress}</p>
					<img src={assets.images.copyIcon} onClick={() => onCopyToClipboard(walletAddress)} />
				</PopUpInnerDiv>
			</Tooltip>

			<PopUpInnerDiv>
				<img src={assets.images.ethBalnce} />
				<ETHContinare>
					<span className="balance-text">{"Connected using"}</span>
					<span className="eth-text">{walletNameLocal}</span>
				</ETHContinare>
			</PopUpInnerDiv>
		</WalletPopUpContainer>
	);
	const topMenuLoggedData = {
		popoverContent: isTabletOrMobileDevice ? (
			<ProfileDropDownMobile
				onHandleSideBar={toggleSideBar}
				allowSellArtwork={showSellArtwork && isArtistBlockedToSell() === false}
				dismiss={dismissProfileDropDown}
				userInfo={userStates && userStates}
			/>
		) : (
			<ProfileDropdown
				allowSellArtwork={showSellArtwork && isArtistBlockedToSell() === false}
				dismiss={dismissProfileDropDown}
				userInfo={userStates && userStates}
			/>
		),
		count: getHeaderCountResult ? getHeaderCountResult : null,
		isVisible: isProfilePopoverVisible,
		customClassNameForPopover: "profile-popover",
	};

	const iconDataWeb = isLoggedIn
		? [
				{
					id: "wishlist",
					path: assets.images.saveIconForMenu,
					size: "s",
					count: getHeaderCountResult ? getHeaderCountResult.wishListCount : null,
					allowPopover: false,
				},
				{
					id: "notification",
					path: assets.images.notificationIcon,
					size: "m",
					count: getHeaderCountResult ? getHeaderCountResult.notificationCount : null,
					allowPopover: false,
				},

				{
					id: "cart",
					path: assets.images.cartIcon,
					size: "m",
					count: getHeaderCountResult ? getHeaderCountResult.cartCount : null,
					allowPopover: false,
				},
		  ]
		: [
				{
					id: "notification",
					path: assets.images.notificationIcon,
					size: "m",
					count: getHeaderCountResult ? getHeaderCountResult.notificationCount : null,
					allowPopover: false,
				},

				{
					id: "cart",
					path: assets.images.cartIcon,
					size: "m",
					count: getHeaderCountResult ? getHeaderCountResult.cartCount : null,
					allowPopover: false,
				},
		  ];
	// {
	// 	id: "wishlist",
	// 	path: assets.images.wishListIcon,
	// 	size: "m",
	// 	count: getHeaderCountResult ? getHeaderCountResult.wishListCount : null,
	// 	allowPopover: false
	// }
	const iconDataMobile = isLoggedIn
		? [
				{
					id: "wishlist",
					path: assets.images.saveIconForMenu,
					size: "s",
					count: getHeaderCountResult ? getHeaderCountResult.wishListCount : null,
					allowPopover: false,
				},
				{
					id: "notification",
					path: assets.images.notificationIcon,
					size: "m",
					count: getHeaderCountResult ? getHeaderCountResult.notificationCount : null,
					allowPopover: false,
				},
				{
					id: "wallet",
					path: isWalletConnected ? assets.images.walletActive : assets.images.walletIcon,
					size: "m",
					count: 0,
					allowPopover: true,
					popoverContent: walletContent,
					customClassNameForPopover: isClickWallet ? "wallet-popup-content" : "wallet-diconnected-popup",
					isVisible: isClickWallet,
				},
		  ]
		: [
				// {
				// 	id: "wishlist",
				// 	path: assets.images.wishListIcon,
				// 	size: "m",
				// 	count: getHeaderCountResult ? getHeaderCountResult.wishListCount : null,
				// 	allowPopover: false
				// },

				// {
				// 	id: "cart",
				// 	path: assets.images.cartIcon,
				// 	size: "m",
				// 	count: getHeaderCountResult ? getHeaderCountResult.wishListCount : null,
				// 	allowPopover: false,
				// },

				{
					id: "notification",
					path: assets.images.notificationIcon,
					size: "m",
					count: getHeaderCountResult ? getHeaderCountResult.notificationCount : null,
					allowPopover: false,
				},
				// {
				// 	id: "wallet",
				// 	path: localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") ? assets.images.walletActive : assets.images.walletIcon,
				// 	size: "m",
				// 	count: getHeaderCountResult ? getHeaderCountResult.wishListCount : null,
				// 	allowPopover: false,
				// },];
		  ];

	const iconData = isTabletOrMobileDevice ? iconDataMobile : iconDataWeb;

	const handleVisibleChange = (state) => {
		isVisibleChange = true;
		setIsProfilePopoverVisible(state);
	};
	const onChangeSerachText = (event) => {
		// setSearchText(event.target.value || "");
		console.log("🚀 ~ file: Navbar.js ~ line 118 ~ onClickSearch ~ event", event.target.value);
		// if (event.target.value !== "") {
		// 	if (location.pathname !== "/search") {
		// 		localStorage.setItem("searchBeforePage", location.pathname);
		// 	}

		// 	history.push("/search?q=" + event.target.value);
		// }
	};

	const onSearch = (value) => {
		setSearchText(value);
		if (value !== "") {
			if (location.pathname !== "/search") {
				localStorage.setItem("searchBeforePage", location.pathname);
			}

			history.push("/search?q=" + value);
		}

		console.log(value);
	};

	const onIconClick = useCallback(
		async (id) => {
			const loggedIn = await SDK.UserManagement.isLoggedIn();
			switch (id) {
				case "cart":
					if (loggedIn) {
						history.push("/cart");
					} else {
						dispatch(showModal(ModalTypes.login));
					}
					break;
				case "profile":
					if (loggedIn) {
						setIsProfilePopoverVisible(!isProfilePopoverVisible);
						setSearch(id === "search");
					} else {
						dispatch(showModal(ModalTypes.login));
					}
					break;
				case "search":
					handleBlur();
					break;
				case "wishlist":
					if (loggedIn) {
						history.push("/profile/artworks");
					} else {
						dispatch(showModal(ModalTypes.login));
					}
					break;
				case "notification":
					if (loggedIn) {
						history.push("/notifications");
					} else {
						dispatch(showModal(ModalTypes.login));
					}
					break;
				case "register":
					if (isTabletOrMobileDevice) {
						setIsOpenSidebar(false);
					}
					// dispatch(showModal(ModalTypes.register));
					history.push("/user/register");

					break;
				case "login":
					if (isTabletOrMobileDevice) {
						setIsOpenSidebar(false);
					}
					history.push("/user/login");
					// dispatch(showModal(ModalTypes.login));
					break;
				case "wallet":
					if (isTabletOrMobileDevice) {
						if (isClickWallet) {
							setIsClickWallet(false);
						} else {
							setIsClickWallet(true);
						}
					}
					// dispatch(showModal(ModalTypes.login));
					break;
				default:
					break;
			}
		},
		[isLoggedIn]
	);

	// ======================== Logout ======================== //
	const [shouldLogout] = useAutoLogout();
	useEffect(() => {
		if (shouldLogout) {
			SDK.UserManagement.logout();
			history.push("/");
			// window.location.reload();
			setIsProfilePopoverVisible(false);
		}
	}, [shouldLogout]);

	const loadingIcon = <Icon type="loading" style={{ fontSize: 50, color: "#58514B" }} spin />;
	//==============Menu Test ====== ///

	const defaultArtistTypes = [
		{
			_id: "0",
			artistType: "All Artists",
			path: "/artists/all/1",
		},
		{
			_id: "1",
			artistType: "New Artists",
			path: "/artists/new/1",
		},
		{
			_id: "2",
			artistType: "Popular Artists",
			path: "/artists/popular/1",
		},
		{
			_id: "3",
			artistType: "Featured Artists",
			path: "/artists/featured/1",
		},
	];

	const [artistTypes, setArtistTypes] = useState(defaultArtistTypes);
	const [isArtistsOpen, setIsArtistsOpen] = useState(false);
	const [isOpenSidebar, setIsOpenSidebar] = useState(false);
	const { useGetCategory } = categoryHooks;
	const [getCategoryList, getCategoryListResult, , getCategoryListLoader] = useGetCategory();
	const [searchText, setSearchText] = useState("");
	const { useGetBasicDescription } = artistBasicProfileHooks;
	const [getDescription, gotDescriptionData, , getLoading] = useGetBasicDescription();
	const [logoutUser, logoutData, logoutError] = useLogout();
	const [sellerType, setSellerType] = useState(false);

	useEffect(() => {
		if (isEmpty(gotDescriptionData) && isLoggedIn) {
			getDescription();
		}
		getCategoryList();

		SDK.UserManagement.getUserRoles((result) => {
			const isSeller = lodash.includes(result, "GALLERY") || lodash.includes(result, "ARTIST");
			if (isSeller) {
				setSellerType(lodash.includes(result, "ARTIST") ? "ARTIST" : "GALLERY");
			}
		});
	}, []);

	useEffect(() => {
		if (logoutError) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		} else if (logoutData) {
			SDK.UserManagement.logout();
			window.location.href = "/";
		}
	}, [logoutData, logoutError]);
	const onLogout = async () => {
		let token = await SDK.UserManagement.getAuthToken();

		logoutUser(token);
		toast(t(toastMessage.loggedOut), "success");
	};
	const onNavgigateDashBoard = () => {
		if (sellerType === "ARTIST") {
			history.push("/artist/dashboard");
		} else {
			history.push("/profile/genaral");
		}
	};
	const MobileMenuForArtists = () => (
		<MobileMenuLevels level={1} open={isArtistsOpen && !isEmpty(artistTypes)}>
			<MobileMenuContentDrop onClick={() => setIsArtistsOpen(!isArtistsOpen)}>
				Artists
				<MobileMenuArrow type="down" dorotate={isArtistsOpen ? "true" : "false"} />
			</MobileMenuContentDrop>
			{isArtistsOpen &&
				!isEmpty(artistTypes) &&
				artistTypes.map((item) => (
					<MobileMenuContentLink
						key={item._id}
						level={4}
						onClick={() => {
							toggleSideBar();
							history.push(item.path);
						}}
					>
						{item.artistType}
					</MobileMenuContentLink>
				))}
		</MobileMenuLevels>
	);

	return (
		<div className="root-wrapper">
			<div>
				{isOpenSidebar ? (
					<MobileMenuWrapMask>
						<>
							<ProfileSectionMobile>
								<ProfileTextSection width={isLoggedIn}>
									<img src={assets.images.profile_white} alt="profile" width="18" height="17" title="profile" />
									{isLoggedIn ? (
										<ProfileNameContainerMobile>
											<Link to={sellerType === "ARTIST" ? "/artist/dashboard" : "/profile/genarel"}>
												<span>{userStates && userStates.name} </span>
											</Link>
											<img
												src={assets.images.logout}
												alt="Logout btn"
												className="logout-btn"
												width="15"
												height="15"
												onClick={() => onLogout()}
											/>
										</ProfileNameContainerMobile>
									) : (
										<>
											<Link to="#" onClick={() => onIconClick("login")}>
												{" "}
												<span>Login</span>
											</Link>
											<div className="vertical-line"></div>
											<Link to="#" onClick={() => onIconClick("register")}>
												{" "}
												<span>Register</span>
											</Link>
										</>
									)}
								</ProfileTextSection>
							</ProfileSectionMobile>
							<SocialMediaIconMobile>
								<span>Follow us on</span>
								<a href="https://www.facebook.com/RtistIQ.ART/" rel="noopener noreferrer" target="_blank">
									{" "}
									<img src={assets.images.fb_mobile} alt="Social Media Icon" title="Social Media Icon" width="15" height="15" />
								</a>
								<a href="https://www.instagram.com/rtistiq.art/" rel="noopener noreferrer" target="_blank">
									{" "}
									<img src={assets.images.insta_mobile} alt="Social Media Icon" title="Social Media Icon" width="15" height="15" />
								</a>
								<a href="https://twitter.com/rtistiq_art" rel="noopener noreferrer" target="_blank">
									{" "}
									<img src={assets.images.twiter_mobile} alt="Social Media Icon" title="Social Media Icon" width="15" height="15" />
								</a>
								<a href="https://www.linkedin.com/company/rtistiq" rel="noopener noreferrer" target="_blank">
									{" "}
									<img
										src={assets.images.linkedin_mobile}
										alt="Social Media Icon"
										title="Social Media Icon"
										width="15"
										height="15"
									/>
								</a>
							</SocialMediaIconMobile>
							<MobileMenuContentWrapper>
								{/* Close */}

								{/* <MobileMenuContentClose type="close" onClick={() => setIsOpenSidebar(false)} /> */}
								{/* For artworks */}
								<MobileMenuForArtworks list={getCategoryListResult} onHandleSideBar={toggleSideBar} />
								{/* For artists */}
								<MobileMenuForArtists />
								<MobileMenuContentLink pt="1" onClick={(e) => history.push("/collections")}>
									Collections
								</MobileMenuContentLink>
								<MobileMenuContentLink onClick={(e) => history.push("/blog")}>Blog</MobileMenuContentLink>
								<MobileMenuContentLink onClick={(e) => history.push("/news")}>News</MobileMenuContentLink>
								<Link to="/nftdrop" className="mobile-nft-menu">
									<BlinkingMenu src={nftMenuImages[currentImg]} alt="menu" className="" />
								</Link>
								<div
									style={{
										width: "100%",
										height: 5,
										backgroundColor: "#A8A8A8",
									}}
								></div>
								<SingleMobileMenu
									onClick={() => {
										setIsOpenSidebar(false);
										onIconClick("notification");
									}}
								>
									<div>Notification</div>
									<SBadge count={getHeaderCountResult ? getHeaderCountResult.notificationCount : null}></SBadge>
								</SingleMobileMenu>

								<SingleMobileMenu>
									<div className="language-wrapper">
										<img src={assets.images.language_black} alt="language" width="19" height="19" title="language" />
										<span>ENG</span>
									</div>
									<div className="currency-wrapper">
										<span> $ USD</span>
									</div>
									{/* <span>ENG</span> */}
								</SingleMobileMenu>
								<SingleMobileMenu
									onClick={(e) => {
										setIsOpenSidebar(false);
										history.push("/sellers");
									}}
								>
									<div>Artist</div>
								</SingleMobileMenu>
								<SingleMobileMenu
									onClick={(e) => {
										setIsOpenSidebar(false);
										history.push("/collector");
									}}
								>
									<div>Collector</div>
								</SingleMobileMenu>
							</MobileMenuContentWrapper>
						</>
					</MobileMenuWrapMask>
				) : null}
			</div>
			<Helmet
				defaultTitle="RtistiQ | Buy Art online, Paintings & more | Original Art for Sale"
				// titleTemplate="%s - RtistiQ | Buy Art online, Paintings & more | Original Art for Sale"
			/>

			{showBanner ? (
				// Only navbar affixed
				isTabletOrMobileDevice ? (
					<NavbarWrapper>
						<FullWidthRow>
							<Col xs={12} sm={12} className="first-head-wrapper">
								{/* ========== Logo ========== */}
								<Logo />
								{/* ========== Mobile Menu [Hamburger Menu] ========== */}
								{isOpenSidebar ? (
									<HamburgerButton
										src={assets.images.menuClose}
										onClick={() => setIsOpenSidebar(false)}
										alt="menu"
										width="30"
										height="30"
									/>
								) : (
									<HamburgerButton
										src={assets.images.MobileMenuListIcon}
										onClick={toggleSideBar}
										alt="Menu"
										width="30"
										height="30"
									/>
								)}
							</Col>
							<Col xs={12} sm={12}>
								<LanguageWrapper>
									<IconContainer iconList={iconData} onIconClick={onIconClick} handleVisibleChange={handleVisibleChange} />
								</LanguageWrapper>
							</Col>

							<Col xs={24} sm={24}>
								<SearchWrapperMobile>
									<Search placeholder="Global Search" value={searchText} onSearch={onChangeSerachText} allowClear />
								</SearchWrapperMobile>
							</Col>
						</FullWidthRow>

						<FullWidthRow>{searchText !== "" && <SearchComponet handleBlur={handleBlur} searchTextValue={searchText} />}</FullWidthRow>
					</NavbarWrapper>
				) : (
					<>
						<TopMenu
							onIconClick={onIconClick}
							isLoggedIn={isLoggedIn}
							loggedData={topMenuLoggedData}
							handleVisibleChange={handleVisibleChange}
						/>
						<Affix>
							{/* Navbar */}
							{!hideNavBarFull && (
								<Navbar
									iconData={iconData}
									onIconClick={onIconClick}
									isSearch={isSearch}
									handleBlur={handleBlur}
									handleVisibleChange={handleVisibleChange}
									hideAllOptions={hideNavbarOptions}
									onClickHamburgerButton={toggleSideBar}
								/>
							)}
						</Affix>

						{/* Page Banner */}
						<PageBanner bannerImage={bannerImage} bannerHeading={bannerHeading} />

						{/* Breadcrumbs Subnav */}
						{!hideBreadcrumbsBar && <BreadCrumbNav />}
					</>
				)
			) : (
				// Navbar and breadcrumb bar affixed
				<Affix>
					{/* TopMenu */}
					{/* Navbar */}
					<div className="nav-bar">
						{isTabletOrMobileDevice ? (
							<NavbarWrapper isGlobalSearchDisable={isGlobalSearchDisable}>
								<FullWidthRow>
									<Col xs={12} sm={12} className="first-head-wrapper">
										{/* ========== Logo ========== */}
										<Logo />
										{/* ========== Mobile Menu [Hamburger Menu] ========== */}
										{isOpenSidebar ? (
											<HamburgerButton
												src={assets.images.menuClose}
												onClick={() => setIsOpenSidebar(false)}
												alt="Menu"
												width="30"
												height="30"
											/>
										) : (
											<HamburgerButton
												src={assets.images.MobileMenuListIcon}
												onClick={toggleSideBar}
												alt="Menu"
												width="30"
												height="30"
											/>
										)}
									</Col>
									<Col xs={12} sm={12}>
										<LanguageWrapper>
											<IconContainer iconList={iconData} onIconClick={onIconClick} handleVisibleChange={handleVisibleChange} />
										</LanguageWrapper>
									</Col>
									{!isGlobalSearchDisable && (
										<Col xs={24} sm={24}>
											<SearchWrapperMobile>
												<Search placeholder="Search" allowClear onSearch={onSearch} />
											</SearchWrapperMobile>
										</Col>
									)}
								</FullWidthRow>

								<FullWidthRow>
									{searchText !== "" && <SearchComponet handleBlur={handleBlur} searchTextValue={searchText} />}
								</FullWidthRow>
							</NavbarWrapper>
						) : (
							<>
								<TopMenu
									onIconClick={onIconClick}
									isLoggedIn={isLoggedIn}
									loggedData={topMenuLoggedData}
									handleVisibleChange={handleVisibleChange}
								/>
								{!hideNavBarFull && (
									<Navbar
										iconData={iconData}
										onIconClick={onIconClick}
										isSearch={isSearch}
										handleBlur={handleBlur}
										handleVisibleChange={handleVisibleChange}
										hideAllOptions={hideNavbarOptions}
										onClickHamburgerButton={toggleSideBar}
									/>
								)}
							</>
						)}
					</div>
					{/* Breadcrumbs Subnav */}
					{!hideBreadcrumbsBar && <BreadCrumbNav />}
				</Affix>
			)}
			{/* Body Wrapper */}
			<MainContainerWrapper spin={spin} {...props}>
				<NoInternetImageWrapper isDisconnected={isDisconnected}>
					<NoInternetImage
						srcWebp={assets.images.noInternetImageWebP}
						fallback={assets.images.noInternetImage}
						alt="No Internet"
						width="800"
						height="600"
					/>
				</NoInternetImageWrapper>

				{!isDisconnected && (
					<Spin spinning={spin} indicator={loadingIcon}>
						{children}
					</Spin>
				)}
			</MainContainerWrapper>

			<Footer isHideFooterBottom={isHideFooterBottom} isHideFooterTop={isHideFooterTop} />

			{/* Modals */}
			<LoginPage />
			<Registrationpage />
			<ForgotpasswordComponent />
			<VerificationComponent />
			<ResetPasswordComponent />
			<PasswordResetSuccess />
			<TwoFactorAuth />
			<UserTypeSelect />
			<YouAreHereModal />
			<CategoryOfInterest />
			<SellYourArtModal />
			{isLoggedIn && (
				<React.Fragment>
					<CreateBlockchainAccount />
					<BlockchainVerificationModal />
					<BlockchainPendingModal />
					<SellAddArtworkModal />
					<NfcSkipModal />
				</React.Fragment>
			)}
			<ResendEmailModal />
			<TwoFactorConfirmPopup />
			<AddOverviewModal />
			<ChooseCountryModal />
			<SuccessResponseModal />
			<ResponseRedirectionModal />
			<SellerAgreementModal />
			<KycDocumentModel />
		</div>
	);
};
export default NetworkDetector(withRouter(MainContainer));
