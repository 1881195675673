import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShippingAddress, addShippingAddress, updateShippingAddress, deleteShippingAddress, defaultShippingAddressAction } from "../actions/ShippingAddressAction";

export const useGetShippingAddress = () => {
	const dispatch = useDispatch();

	// Get ShippingAddress
	const getAddress = () => {
		dispatch(getShippingAddress());
	};
	// Reducer states
	const result = useSelector(state => state.shippingAddress.shippingAddress);

	return [getAddress, result.data, result.error, result.loading];
};
export const useAddShippingAddress = () => {
	const dispatch = useDispatch();

	// Add ShippingAddress
	const addAddress = shippingAddressData => {
		dispatch(addShippingAddress(shippingAddressData));
	};

	// Reducer states
	const result = useSelector(state => state.shippingAddress.addShippingAddress);

	return [addAddress, result.data, result.error, result.loading];
};
export const useUpdateShippingAddress = () => {
	const dispatch = useDispatch();

	// Update ShippingAddress
	const updateAddress = (shippingAddressData, shippingAddressId) => {
		dispatch(updateShippingAddress(shippingAddressData, shippingAddressId));
	};

	// Reducer states
	const result = useSelector(state => state.shippingAddress.updateShippingAddress);

	return [updateAddress, result.data, result.error, result.loading];
};
export const useDeleteShippingAddress = () => {
	const dispatch = useDispatch();

	// Delete ShippingAddress
	const deleteAddress = shippingAddressId => {
		dispatch(deleteShippingAddress(shippingAddressId));
	};
	// Reducer states
	const result = useSelector(state => state.shippingAddress.deleteShippingAddress);

	return [deleteAddress, result.data, result.error, result.loading];
};

export const useDefaultShippingAddress = () => {
	const dispatch = useDispatch();

	// Set Default ShippingAddress
	const defaultShippingAddress = (shippingAddressId) => {
		dispatch(defaultShippingAddressAction(shippingAddressId));
	};

	// Reducer states
	const result = useSelector(state => state.shippingAddress.defaultShippingAddress);

	return [defaultShippingAddress, result.data, result.error, result.loading];
};
