import React from "react";
import { IconContainerBox, IconImage, SBadge, IconImageBox } from "./IconContainer.styled";
import { Popover } from "antd";

const Sizes = {
	s: "16px",
	m: "24px",
	l: "32px",
};

// Navbar Right Icon Menus
const IconContainer = ({ iconList = [], isLoading = false, onIconClick, handleVisibleChange, closeMobile3DotMenu, isMobile3Dot }) => {
	const Icon = ({ count, path, size, onClick }) => (
		<IconImageBox>
			<SBadge count={count}>
				<IconImage
					src={path}
					alt="Navbar Icons"
					style={{ width: Sizes[size] }}
					onClick={onClick}
					width="30"
					height="30"
					title="Navbar Icons"
				/>
			</SBadge>
		</IconImageBox>
	);

	const iconItems = iconList.map((val, i) => {
		return val.allowPopover ? (
			// --------------------- Icon with Popover --------------------- //
			<Popover
				key={i}
				content={val.popoverContent}
				trigger="hover"
				overlayClassName={val.customClassNameForPopover}
				placement="bottom"
				onClick={() => onIconClick(val.id)}
				visible={val.isVisible}
				defaultVisible={false}
				onVisibleChange={handleVisibleChange}
			>
				<Icon key={val.id} path={val.path} size={val.size} count={val.count} />
			</Popover>
		) : (
			// --------------------- Icon without Popover --------------------- //
			<Icon
				key={val.id}
				path={val.path}
				size={val.size}
				count={val.count}
				onClick={() => {
					if (onIconClick) onIconClick(val.id);
					if (isMobile3Dot) closeMobile3DotMenu();
				}}
			/>
		);
	});
	return <IconContainerBox view={isMobile3Dot}>{isLoading ? null : iconItems}</IconContainerBox>;
};

export default IconContainer;
