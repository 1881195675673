import * as types from "./ActionsTypes";
import { AddProductService } from "../services/AddProductService";
import { CategoryManagementService } from "../services/CategoryManagementService";

// ============================= Save Details ============================== //
// 3rd attribute - To handle Image Upload for Mobile by creating Form Data in client side, instead of creating in SDK
export const onSaveDetailsToReducer = (reducerType, payload, isMobile = false, hitCreateProductAPI = false) => {
	console.log("🚀 ~ file: AddProductAction.js ~ line 36 ~ onSaveDetailsToReducer ~ payload", payload)
	return async dispatch => {
		await dispatch({ type: types[reducerType], payload });
		// if (reducerType === "SET_BLOCKCHAIN_DETAILS") await dispatch(createProductAction(isMobile));

		// // for GALLERY USER, there will be no blockchain signature, so hit create api
		// if (reducerType === "SET_FEATURES_DETAILS" && hitCreateProductAPI) await dispatch(createProductAction(isMobile));
		if (reducerType === "SET_LOGISTIC_DETAILS") await dispatch(createProductAction(isMobile));

		// if (reducerType === "SET_BLOCKCHAIN_DETAILS") await dispatch(onUpdateImagesAndVideos());

		// for GALLERY USER, there will be no blockchain signature, so hit create api
		// if (reducerType === "SET_IV_DETAILS") await dispatch(onUpdateImagesAndVideos());

	};
};

// ============================= Clear reducer states ============================= //
export const setReducerStates = (reducerType, payload) => {
	return dispatch => {
		dispatch({ type: types[reducerType], payload });
	};
};

// ============================= Get Category Properties ============================= //
export const getCategoryPropertyAction = categoryId => {
	return dispatch => {
		dispatch(request());
		CategoryManagementService.getCategoryProperty(categoryId).then(
			response => {
				if (response) {
					let newPropArray = response.map((item, index) => {
						const { justFilter = false, propertySuggestions = [] } = item;
						if (!justFilter) {
							if (propertySuggestions.length > 0) {
								return {
									isSelectDropdown: true,
									propertyId: item._id,
									propertyName: item.propertyName,
									dataType: item.dataType,
									propertyUnit: item.unit,
									value: item.dataType === 4 ? [] : item.dataType === 3 ? false : "",
									isMandatory: item.manadatory,
									propertySuggestions: item.propertySuggestions,
									chooseFromSuggestion: item.chooseFromSuggestion
								};
							} else {
								return {
									isSelectDropdown: false,
									propertyId: item._id,
									propertyName: item.propertyName,
									dataType: item.dataType,
									propertyUnit: item.unit,
									value: item.dataType === 4 ? [] : item.dataType === 3 ? false : "",
									isMandatory: item.manadatory
								};
							}
						}
					});
					dispatch(success(newPropArray));
				} else dispatch(failure(response));
			},
			error => dispatch(failure(error))
		);
	};

	function request() {
		return { type: types.GET_CATEGORY_PROPERTIES_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_CATEGORY_PROPERTIES_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_CATEGORY_PROPERTIES_FAILURE, error };
	}
};

// ============================= Add Product ============================= //
export const createProductAction = isMobile => {
	return async (dispatch, getState) => {
		dispatch(request());

		let reducerValues = getState().addProductManagement;

		// TODO : Issue with this method is, the original object in reducer that is mapped to component also changes
		// This should be prevented
		// Keep an array to save in reducer and an array to pass over to api
		// Unless stringify error gets multiplied
		// await reducerValues.productProperties.map((item, index) => {
		// 	if (item.dataType === 4) {
		// 		// item.value = typeof item.value !== 'string' ? JSON.stringify(compact(item.value)) : item.value;
		// 		item.value = typeof item.value !== "string" ? JSON.stringify(psuedoCompactFunction(item.value)) : item.value;
		// 		return item;
		// 	}
		// 	if (item.dataType === 3) {
		// 		item.value = typeof item.value !== "string" ? JSON.stringify(item.value) : item.value;
		// 		return item;
		// 	} else return item;
		// });

		let requestObj = {
			...reducerValues,
			dimensions: {
				height: reducerValues.height,
				width: reducerValues.width,
				depth: reducerValues.depth || 0
			},
			packageDimensions: {
				height: reducerValues.packageHeight,
				width: reducerValues.packageWidth,
				depth: reducerValues.packageDepth || 0
			}
		};

		AddProductService.createProduct(requestObj).then(
			response => {
				if (response) {
					if (response._id) {
						dispatch(success(response));
						// else {
						// 	let filesListObj = new FormData();
						// 	filesListObj.append("ProductId", response._id);
						// 	if (reducerValues.displayPictureArray && reducerValues.displayPictureArray.media)
						// 		filesListObj.append("DiplayImage", reducerValues.displayPictureArray.media);
						// 	else filesListObj.append("DiplayImage", reducerValues.imagesArray[0].media);
						// 	// NEW IMAGES
						// 	for (let index = 0; index < reducerValues.imagesArray.length; index++) {
						// 		filesListObj.append("ProductImage", reducerValues.imagesArray[index].media);
						// 	}
						// 	// // ROOM IMAGES
						// 	// if (reducerValues.roomImageArray && reducerValues.roomImageArray.length > 0)
						// 	// 	for (let index = 0; index < reducerValues.roomImageArray.length; index++) {
						// 	// 		filesListObj.append("RoomViewImage", reducerValues.roomImageArray[index].media);
						// 	// 	}
						// 	dispatch(onUpdateImagesAndVideos(filesListObj, response));
						// }
					} else {
						// TODO : Even if images are not able to upload, the ID after first API hit is returned to UI - For routing
						dispatch(failure({ data: "Product details not able to fetch. Please try again!", productResponse: response }));
						dispatch({ type: types.CLEAR_TOASTS });
					}
				} else {
					dispatch(failure(response));
					dispatch({ type: types.CLEAR_TOASTS });
				}
			},
			error => {
				dispatch(failure(error));
				dispatch({ type: types.CLEAR_TOASTS });
			}
		);
	};

	function request() {
		return { type: types.CREATE_PRODUCT_REQUEST };
	}
	function success(payload) {
		return { type: types.CREATE_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.CREATE_PRODUCT_FAILURE, error };
	}
};

// ============================= Update images and videos ============================= //
export function onUpdateImagesAndVideos(req, productResponse = null, isUpdate = false) {
	return (dispatch, getState) => {
		AddProductService.updateImagesAndVideos(req).then(
			response => {
				if (response) {
					dispatch(success(productResponse));
					dispatch({ type: types.CLEAR_TOASTS });
				} else {
					let errorObj = {
						...response,
						productResponse
					};
					dispatch(failure(errorObj));
					dispatch({ type: types.CLEAR_TOASTS });
				}
			},
			error => {
				let errorObj = {
					...error,
					productResponse
				};
				dispatch(failure(errorObj));
				dispatch({ type: types.CLEAR_TOASTS });
			}
		);
	};

	function success(payload) {
		if (isUpdate) return { type: types.UPDATE_PRODUCT_SUCCESS, payload };
		else return { type: types.CREATE_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		if (isUpdate) return { type: types.UPDATE_PRODUCT_FAILURE, error };
		return { type: types.CREATE_PRODUCT_FAILURE, error };
	}
}

// ============================= Access Reducer states through this action ============================= //
export const setAddProductsReducerStates = (stateName, value) => {
	return dispatch => {
		let data = {
			state: stateName,
			value: value
		}
		dispatch({ type: types.SET_ADD_PRODUCT_REDUCER_STATES, data });
	}
}

function psuedoCompactFunction(array) {
	let filteredArr = array.filter(el => {
		return el && el.toString().trim();
	});
	return filteredArr;
}
