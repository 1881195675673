import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
    getBlockchainAccountList: {},
    createBlockchainAccount: {},
    buyArtworkTransaction: {},
    addSignedMessage: {},
    getCertificate: {}
};
const blockchainReducer = createApiReducer(initialState, [
    types.GET_BLOCKCHAIN_ACCOUNTS_REQUEST,
    types.CREATE_BLOCKCHAIN_ACCOUNT_REQUEST,
    types.BUY_ARTWORK_TRANSACTION_REQUEST,
    types.ADD_SIGNED_MESSAGE_REQUEST,
    types.GET_CERTIFICATE_REQUEST
]);

export default blockchainReducer;
