import React, { useState } from "react";
import { includes, isEmpty } from "lodash";
import { MobileMenuArrow, MobileMenuContentLink, MobileMenuContentDrop, MobileMenuLevels } from "../MobileMenu.styled";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const MobileMenuForArtworks = ({ list = [], onHandleSideBar }) => {
	const { t } = useTranslation();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [openedMenus, setOpenedMenus] = useState([]);
	let history = useHistory();

	const onHandleToggleClick = id => {
		const openedArr = toggledMenus(id, openedMenus);
		setOpenedMenus(openedArr);
	};

	const isOpen = id => includes(openedMenus, id);

	const toggledMenus = (togglingItem, completeArray = []) => {
		let arr = [];
		if (includes(completeArray, togglingItem)) {
			arr = completeArray.filter(x => x !== togglingItem);
		} else {
			arr = [...completeArray, togglingItem];
		}
		return arr;
	};

	return (
		<MobileMenuLevels level={1} open={isMenuOpen && !isEmpty(list)}>
			{/* Parent Menu */}
			<MobileMenuContentDrop onClick={() => setIsMenuOpen(!isMenuOpen)}>
				{t("Artworks")}
				<MobileMenuArrow type="down" dorotate={isMenuOpen ? "true" : "false"} />
			</MobileMenuContentDrop>

			{/* Menu */}
			{isMenuOpen &&
				!isEmpty(list) &&
				list.map(item => (
					<MobileMenuLevels key={item._id} open={isOpen(item._id) && !isEmpty(item.subCategories)} level={2}>
						{/* Categories Listing */}
						<MobileMenuContentDrop onClick={() => onHandleToggleClick(item._id)} level={2}>
							<div onClick={() => {
								onHandleSideBar();
								history.push(`/artworks/${item.slugPath}`)
							}}>{item.categoryName}</div>
							{!isEmpty(item.subCategories) && <MobileMenuArrow type="down" dorotate={isOpen(item._id).toString()} />}
						</MobileMenuContentDrop>

						{/* Subcategories listing */}
						{isOpen(item._id) &&
							item.subCategories.map(data => (
								<MobileMenuContentLink key={data._id} level={3} onClick={() => {
									onHandleSideBar();
									history.push(`/artworks/${data.slugPath}`)
								}}>
									{data.categoryName}
								</MobileMenuContentLink>
							))}
					</MobileMenuLevels>
				))}
		</MobileMenuLevels>
	);
};
export default MobileMenuForArtworks;
