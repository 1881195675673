import * as types from "../actions/ActionsTypes";
import createApiReducer from "../util/createApiReducer";

const initialState = {
  getCommentsData: {},
  addCommentsData: {},
  updateCommentsData: {},
  deleteCommentsData: {}
};
const commentsReducer = createApiReducer(initialState, [
  types.GET_COMMENTS_REQUEST,
  types.ADD_COMMENTS_REQUEST,
  types.UPDATE_COMMENTS_REQUEST,
  types.DELETE_COMMENTS_REQUEST
]);

export default commentsReducer;
