import styled from "styled-components";
import { Row } from "antd";
import { ImgNextGen } from "common/NextGenImage";

export const CartWrapper = styled.div`
	padding: 2rem 10% 0;
	@media screen and (max-width: 1288px) {
		padding: 2rem 8% 0;
	}
	@media screen and (max-width: 1156px) {
		padding: 2rem 5% 0;
	}
	@media screen and (max-width: 952px) {
		padding: 2rem 3% 0;
	}
	@media screen and (max-width: 800px) {
		padding: 2rem 1% 0;
	}
`;

export const CartHeader = styled.h1`
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 2rem;
`;

export const CartHeadRow = styled.div`
	display: flex;
	border-bottom: 1px solid #f5f5f5;
	padding-bottom: 10px;
	font-size: 13px;
	/* font-weight: 500; */
	color: #000000;
	justify-content: space-between;
	@media (max-width: 980px) {
		display: none;
	}
`;

export const CartBodyRow = styled.div`
	display: flex;
	padding: 1rem 3px;
	border-bottom: 1px solid #f5f5f5;
	justify-content: space-between;
	&:hover {
		cursor: pointer;
		background: #f5f5f5;
	}
	@media (max-width: 980px) {
		flex-wrap: wrap;
		border-top: 1px solid #d9d9d9;
		padding-bottom: 0;
	}
`;

export const ProductCol = styled.div`
	display: flex;
	align-items: center;
	.image-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 120px;
		border: 1px solid #f5f5f5;
		margin-right: 2rem;
		@media screen and (max-width: 660px) {
			margin-right: 1rem;
		}
	}
	@media (max-width: 980px) {
		flex-direction: row;
		flex: none;
		width: 100%;
		margin-bottom: 5px;
	}
	@media (max-width: 576px) {
		flex-direction: column;
		width: 100%;
		margin-bottom: 5px;
	}
`;
export const ProductImage = styled(ImgNextGen)`
	max-width: 119px;
	max-height: 119px;
`;
export const PriceCol = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@media (max-width: 980px) {
		align-items: center;
		justify-content: space-between;
		height: 60px;
		width: 100%;
	}
`;

export const ActionCol = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 2;
	@media (max-width: 980px) {
		height: 60px;
		border-top: 1px solid #f5f5f5;
	}
`;

export const ProductDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	@media (max-width: 980px) {
		width: calc(100% - 135px);
	}
	@media (max-width: 576px) {
		width: 100%;
		padding-top: 15px;
	}
`;

export const ProductName = styled.p`
	font-size: 18px;
	font-weight: 700;
	color: #000;
	margin-bottom: 5px;
	word-break: break-word;
	@media (max-width: 980px) {
		text-align: left;
		width: 100%;
	}
`;

export const ProductDescription = styled.p`
	font-size: 14px;
	color: #000;
	margin: 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	@media (max-width: 980px) {
		text-align: left;
		width: 100%;
		word-break: break-all;
	}
	@media (max-width: 400px) {
		word-break: unset;
	}
`;

export const ProductStock = styled.p`
	font-size: 13px;
	font-weight: 700;
	color: #d2502d;
	margin: 0;
	overflow: hidden;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	margin-top: 3px;
	span {
		color: #000;
		font-weight: 500;
	}
	@media (max-width: 980px) {
		text-align: left;
		width: 100%;
		word-break: break-all;
	}
`;

export const PriceTitle = styled.span`
	display: none;
	font-size: 12px;
	font-weight: 500;
	@media (max-width: 980px) {
		display: unset;
	}
`;

export const PriceText = styled.p`
	font-size: 22px;
	font-weight: 700;
	color: #000;
	margin: 0;
`;

export const ActionBox = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
	border-radius: 6rem;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
	img {
		width: 14px;
	}
`;

export const CartFooterWrapper = styled.div`
	display: flex;
	background-color: #58514b;
	padding-left: calc(120px + 2rem);
	padding-right: 2rem;
	justify-content: flex-end;
	align-items: center;
	height: 9rem;
	margin-bottom: 2rem;
	@media screen and (max-width: 660px) {
		padding-left: 2rem;
	}
	@media screen and (max-width: 430px) {
		flex-direction: column;
		justify-content: center;
	}
	.text-price-title {
		color: #fff;
		font-size: 14px;
		margin-bottom: 3px;
	}
	.text-price {
		color: #ad9972;
		font-size: 22px;
		font-weight: 700;
		margin: 0;
	}
`;
export const EachCartItemDetailsWrapper = styled.div`
	border: 1px solid #EEEEEE;
	padding: 10px;
    margin-top: 9px;
    margin-bottom: 20px;

`;
export const StyledText = styled.p`
    margin: ${props => props.margin} !important;
	padding: ${props => props.padding} !important;
	font-size:${props => props.fontSize};
	color: ${props => props.color} !important;
    float: ${props => props.float} !important;
	text-align:${props => props.textalign} !important;
    font-weight:${props => props.fontWeight} !important;
`;
export const CheckoutWrapper = styled.div`
	display: flex;
    justify-content: center;
`;
export const EndRow = styled(Row)`
    justify-content: space-between;
`;
export const EndWrapper = styled.div`
	display: flex;
    flex-direction: column;
    padding-top: 10px;
`;
export const EndRowWrapper = styled.div`
	display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E5E5E5;
`;
export const PriceWrapper = styled.div`
	display: flex;
    align-items: center;
    padding-right: 3rem;
`;

export const CheckBoxDiv = styled.div`
	display: flex;
`;

export const MessageDiv = styled.div`
	display: flex;
	padding-bottom: 1rem;
`;

export const FormLabel = styled.label`
	font-size: 13px;
	margin-bottom: 5px;
	font-weight: 500;
	color: #58514b;
	padding-left: 5px;
`;
