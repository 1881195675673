import { ArtistInterestsService } from "../services/ArtistInterestsService";
import * as types from "./ActionsTypes";

// ========================================== Get ArtistInterests ========================================== //
export const getMyInterests = () => {
    return {
        actionType: types.GET_MY_INTERESTS_REQUEST,
        callAPI: () => ArtistInterestsService.getMyInterests(),
        stateObject: "getMyInterests"
      };

};

// ========================================== Get ArtistInterests By Slug Id ========================================== //
export const getArtistInterestsBySlugIdAction = SlugId => {
			return {
				actionType: types.GET_MY_INTERESTS_REQUEST,
				callAPI: () => ArtistInterestsService.getArtistInterestsBySlugId(SlugId),
				stateObject: "getMyInterests"
			};
		};

// ========================================== Update ArtistInterests ========================================== //
export const updateArtistInterests = (artistInterestsData) => {
    return {
		actionType: types.UPDATE_ARTIST_INTERESTS_REQUEST,
		callAPI: () => ArtistInterestsService.updateArtistInterests(artistInterestsData),
		stateObject: "updateArtistInterests",
		isTransient: true
	};

};

