import { LOGOUT_USER, ADD_ROUTE, REMOVE_ROUTE, REFRESH_BADGES } from "./ActionTypes";
export const logoutUser = (logout = true) => {
  return {
    type: LOGOUT_USER,
    payload: logout
  };
};

export const addRoute = (path, title) => {
  return {
    type: ADD_ROUTE,
    payload: { path, title }
  };
};

export const removeRoute = path => {
  return {
    type: REMOVE_ROUTE,
    payload: { path }
  };
};

export const refreshBadges = (shouldRefresh = true) => {
  return {
    type: REFRESH_BADGES,
    payload: shouldRefresh
  };
};
