import { APIService } from "../util/APIService";

export const HTTP_METHOD = {
	GET: 1,
	POST: 2,
	PUT: 3,
	DELETE: 4,
	MULTIPART_PUT: 5,
	MULTIPART_POST: 6,
	DOWNLOAD: 7,
	PDFDOWNLOAD: 8
};

export class BaseService {
	execute = async (
		fullUrl,
		method,
		attrs = {
			body: {},
			params: {},
			urlDirectParams: "",
			headers: {},
			isAuthorizedAPI: true
		}
	) => {
		let apiCall = this.getApi(method);
		return apiCall({ fullUrl, ...attrs }).then(
			response => {

				let code = response && response.data && response.data.result ? response.data.result : 400;
				let data = response && response.data && response.data.data;
				if (code === 200 && typeof data != undefined) {
					if (data === null || typeof data === "undefined") {
						console.error(`Response data is null for ${fullUrl}. Please inform backend team.`);
						data = { success: true };
					}
					return Promise.resolve(data);
				} else {
					return Promise.reject({
						response: code,
						data: data,
						message: response && response.data && response.data.message
					});
				}
			},
			error => {
				return Promise.reject(error);
			}
		);
	};

	getApi = method => {
		let apiCall = null;
		switch (method) {
			case HTTP_METHOD.GET:
			default:
				apiCall = APIService.Get;
				break;
			case HTTP_METHOD.POST:
				apiCall = APIService.Post;
				break;
			case HTTP_METHOD.PUT:
				apiCall = APIService.Put;
				break;
			case HTTP_METHOD.DELETE:
				apiCall = APIService.Delete;
				break;
			case HTTP_METHOD.MULTIPART_POST:
				apiCall = APIService.PostMultipart;
				break;
			case HTTP_METHOD.MULTIPART_PUT:
				apiCall = APIService.PutMultipart;
				break;
			case HTTP_METHOD.DOWNLOAD:
				apiCall = APIService.doDownload;
				break;
			case HTTP_METHOD.PDFDOWNLOAD:
				apiCall = APIService.doPDFDownload;
				break;
		}
		return apiCall;
	};
}
