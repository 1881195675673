import styled from "styled-components";
import { Row, Select } from "antd";
import { wrapperPadding } from "styled";

export const NavbarWrapper = styled.nav`
	display: flex;
	justify-content: space-between;
	z-index: 100;
	height: 65px;
	background-color: #fff;
	/* box-shadow: 0 2px 2.5px 0px #f5f5f5; */
	${wrapperPadding}
	@media screen and (max-width: 768px) {
		width: 100%;
		${(props) => {
			if (props.isGlobalSearchDisable) {
				return "height:60px";
			} else {
				return "height:110px";
			}
		}}/* height: 110px; */
	}
	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
		/* width: 100%;
		height: 110px; */
	}
`;

export const FullWidthRow = styled(Row)`
	width: 100%;
`;

export const HamburgerButton = styled.img`
	display: none;
	position: relative;
	bottom: 43px;
	padding-right: 10px;
	width: 45px;
	cursor: pointer;
	@media screen and (max-width: 768px) {
		display: block;
		cursor: pointer;
	}
	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
		/* display: block;
		cursor: pointer; */
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1020px) {
		display: block;
		cursor: pointer;
		bottom: 40px;
		width: 45px;
	}
`;

export const MobileAction3Dot = styled.img`
	display: none;
	margin-top: 12px;
	cursor: pointer;
	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
		/* display: block;
		margin-left: 120px; */
	}
	@media screen and (max-width: 768px) {
		display: block;
		margin-left: 120px;
	}
	@media screen and (max-width: 667px) {
		margin-left: 94px;
	}
	@media screen and (max-width: 564px) {
		margin-left: 80px;
	}
	@media screen and (max-width: 473px) {
		margin-left: 60px;
	}
	@media screen and (max-width: 425px) {
		margin-left: 46px;
	}
	@media screen and (max-width: 320px) {
		margin-left: 33px;
	}
`;
export const LanguageWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;
export const SearchWrapper = styled.div`
	.ant-input {
	}
	.ant-btn-primary {
		background-color: black;
		color: white;
		border: none;
	}
	.ant-input:hover {
		border-color: #efefef !important;
		border-right-width: 1px !important;
		color: #000000;
	}

	.ant-input-search-icon {
		color: white;
	}
	display: flex;
`;
export const SearchWrapperMobile = styled.div`
	margin-bottom: 3px;
	.ant-input {
		background-color: black;
		height: 42px;
		color: white;
		border-radius: 0 !important;
	}
	.ant-btn {
		border-radius: 0 !important;
	}
	.ant-btn-primary {
		height: 40px;
		color: white;
		border: none;
		background-color: black;
	}
	.ant-input:hover {
		border-color: white !important;
		border-right-width: 1px !important;
	}
	.ant-input-clear-icon {
		color: white !important;
	}
	.ant-input-search-icon {
		color: white;
	}
	display: flex;
`;
export const LanguageSelect = styled(Select)`
	.ant-select-selection--single {
		margin-top: 15px;
	}
	.ant-select-selection:hover {
		border-color: #d9d9d9;
	}
	.ant-select-selection:focus,
	.ant-select-selection:active {
		border-color: #d9d9d9;
		box-shadow: 0 0 0 1px #d9d9d9;
	}
`;
export const WalletMainContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
export const WalletButtonContainer = styled.div`
	/* width: 200px; */
	background-color: ${(props) => (props.isActive ? "white" : "black")};
	color: ${(props) => (props.isActive ? "black" : "white")};
	border: 1px solid #e5e5e5;

	padding: 0 10px;
	cursor: pointer;
	display: flex;
	margin: 10px;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	border: solid 1px gray;
	height: 40px;
	width: ${(props) => (props.isActive ? "130px" : "160px")};
	p {
		margin: 0 5px;
		text-align: left;
		font: normal normal normal 13px/24px Lato;
		letter-spacing: 0.33px;
		color: ${(props) => (props.isActive ? "black" : "white")};
	}
`;
export const WalletPopUpContainer = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	/* box-shadow: 0px 3px 7px #00000017; */
	border-radius: 0px 0px 7px 7px;
	width: 200px;
	/* height: 163px; */
	/* padding: 15px; */
	display: flex;
	flex-direction: column;
	p {
		text-align: left;
		font: normal normal 900 16px/30px Lato;
		letter-spacing: 0.4px;
		color: black;
		padding: 10px 5px;
		color: black;
	}
`;
export const PopUpInnerDiv = styled.div`
	display: flex;
	border-bottom: 1px solid #e5e5e5;

	p {
		margin: 2px;
	}
`;
export const ETHContinare = styled.div`
	margin: 5px 5px;
	text-align: center;
	display: flex;
	flex-direction: column;
`;
