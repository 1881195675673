export const MoeTrackEvent = (event, data) => {
	window.Moengage.track_event(event, { ...data })
};

export const MoeLogin = (uniqueId) => {
	window.Moengage.add_unique_user_id(uniqueId)
};

export const MoeNewUser = (data) => {
	if (data) {
		window.Moengage.add_unique_user_id(data.UserId);
		window.Moengage.add_first_name(data.FullName);
		window.Moengage.add_last_name(data.FullName);
		window.Moengage.add_email(data.Email);
		window.Moengage.add_mobile(data.PhoneNumber);
		window.Moengage.add_user_name(data.FullName || data.Email);
	}
};

export const MoeLogout = () => {
	window.Moengage.destroy_session();
};
