import { useDispatch, useSelector } from "react-redux";
import {
  getSimilarProductsAction,
  getRecommendedCategories,
  getTrendingCategories,
  getSuggestedArtworksAction,
  getContinueYourSearchAction,
  getArtworksOnOfferAction,
  getFeaturedArtistsAction,
  getFeaturedGalleryAction,
  getOriginalArtworksAction, getLatestArtworksAction, getLikeAndWishListAction, getPopularArtworksAction, getFeaturedArtworksAction
} from "../actions/RecommendationsAction";

// ==================================== Get similiar Products ==================================== //
export const useGetSimilarProducts = () => {
  const dispatch = useDispatch();
  const getSimilarProducts = (productId) => {
    dispatch(getSimilarProductsAction(productId));
  };
  const { data, error } = useSelector(state => state.recommendations.similarProducts);
  return [getSimilarProducts, data, error];
};

// ==================================== Get suggested artworks ==================================== //
export const useGetSuggestedArtworks = () => {
  const dispatch = useDispatch();

  const getSuggestedArtworks = () => {
    dispatch(getSuggestedArtworksAction());
  };

  const { data, error, loading } = useSelector(state => state.recommendations.suggestedArtworks);

  return [getSuggestedArtworks, data, error, loading];
};

// ==================================== Continue your search ==================================== //
export const useContinueYourSearch = () => {
  const dispatch = useDispatch();

  const getContinueYourSearch = (pageNum, pageSize) => {
    dispatch(getContinueYourSearchAction(pageNum, pageSize));
  };

  const { data, error, loading } = useSelector(state => state.recommendations.continueYourSearch);

  return [getContinueYourSearch, data, error, loading];
};

// ==================================== Artworks on Offer ==================================== //
export const useArtworksOnOffer = () => {
  const dispatch = useDispatch();

  const getArtworksOnOffer = () => {
    dispatch(getArtworksOnOfferAction());
  };

  const { data, error } = useSelector(state => state.recommendations.artworksOnOffer);

  return [getArtworksOnOffer, data, error];
};

export const useGetRecommendedCategories = () => {
  const dispatch = useDispatch();

  // Get ShippingAddress
  const getRecommendation = () => {
    dispatch(getRecommendedCategories());
  };
  // Reducer states
  const result = useSelector(state => state.recommendations.recommendedCategories);

  return [getRecommendation, result.data, result.error, result.loading];
};
export const useGetTrendingCategories = () => {
  const dispatch = useDispatch();
  const getTrend = () => {
    dispatch(getTrendingCategories());
  };
  // Reducer states
  const result = useSelector(state => state.recommendations.trendingCategories);

  return [result.data, result.error, getTrend];
};
// ==================================== Get Featured Artists ==================================== //
export const useGetFeaturedArtists = () => {
  const dispatch = useDispatch();

  const getFeaturedArtists = () => {
    dispatch(getFeaturedArtistsAction());
  };

  const { data, error } = useSelector(state => state.recommendations.featuredArtists);

  return [getFeaturedArtists, data, error];
};

// ==================================== Get Featured Gallery ==================================== //
export const useGetFeaturedGalleryAction = () => {
  const dispatch = useDispatch();
  // Get user info []
  const getFeaturedGallery = () => {
    dispatch(getFeaturedGalleryAction());
  };

  // Reducer states
  const { data, error, loading } = useSelector(state => state.recommendations.getFeaturedGallery);

  return [getFeaturedGallery, data, error, loading];
};

// ==================================== Get Original Artworks ==================================== //
export const useGetOriginalArtworks = () => {
  const dispatch = useDispatch();
  // Get Original Artworks
  const getOriginalArtworks = () => {
    dispatch(getOriginalArtworksAction());
  };

  // Reducer states
  const { data, error, loading } = useSelector(state => state.recommendations.getOriginalArtworks);

  return [getOriginalArtworks, data, error, loading];
};


// ==================================== Get Latest Artworks ==================================== //
export const useGetLatestArtworks = () => {
  const dispatch = useDispatch();
  // Get Latest Artworks
  const getLatestArtworks = () => {
    dispatch(getLatestArtworksAction());
  };

  // Reducer states
  const { data, error, loading } = useSelector(state => state.recommendations.getLatestArtworks);

  return [getLatestArtworks, data, error, loading];
};


// ==================================== Get Like And WishList Artworks ==================================== //
export const useGetLikeAndWishList = () => {
  const dispatch = useDispatch();
  // Get Like And WishList Artworks
  const getLikeAndWishList = () => {
    dispatch(getLikeAndWishListAction());
  };

  // Reducer states
  const { data, error, loading } = useSelector(state => state.recommendations.getLikeAndWishList);

  return [getLikeAndWishList, data, error, loading];
};

// ==================================== Get Popular Artworks ==================================== //
export const useGetPopularArtworks = () => {
  const dispatch = useDispatch();
  // Get Popular Artworks
  const getPopularArtworkss = () => {
    dispatch(getPopularArtworksAction());
  };

  // Reducer states
  const { data, error, loading } = useSelector(state => state.recommendations.getPopularArtworks);

  return [getPopularArtworkss, data, error, loading];
};

// ==================================== Get Featured Artworks ==================================== //
export const useGetFeaturedArtworks = () => {
  const dispatch = useDispatch();
  // Get Featured Artworks
  const getFeaturedArtworks = () => {
    dispatch(getFeaturedArtworksAction());
  };

  // Reducer states
  const { data, error, loading } = useSelector(state => state.recommendations.getFeaturedArtworks);

  return [getFeaturedArtworks, data, error, loading];
};
