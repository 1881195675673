import createApiReducer from "../util/createApiReducer";
import * as types from "../actions/ActionsTypes";

const initialState = {
	getMyInterests: {},
	updateArtistInterests: {}
};

const ArtistInterestsReducer = createApiReducer(initialState, [types.GET_MY_INTERESTS_REQUEST, types.UPDATE_ARTIST_INTERESTS_REQUEST]);

export default ArtistInterestsReducer;
