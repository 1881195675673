import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";

export class CategoryManagementService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get Category List
	static async getCategoryList(parentId) {
		let params = { parentId };
		return this.service.execute(this.API.getCategoryList, HTTP_METHOD.GET, { params });
	}

	// Get Category Property
	static async getCategoryProperty(CategoryId) {
		let params = { CategoryId };
		return this.service.execute(this.API.getCategoryProperty, HTTP_METHOD.GET, { params });
	}

	// Get Category BY ID & Slug
	static async getCategoryById(slugId, categoryId) {
		let params = { categoryId, slugId };
		return this.service.execute(this.API.getCategoryById, HTTP_METHOD.GET, { params });
	}


	// Get Category Property [Search artworks/Filter]
	static async getCategoryPropertyForFilters(slugId) {
		let params = { slugId };
		return this.service.execute(this.API.getCategoryProperty, HTTP_METHOD.GET, { params }).then(response => {
			let newPropArray = response.map((item, i) => {
				return {
					...item,
					filters: item.filters.map((fil, index) => {
						return {
							filterId: this.generateFilterKey(item._id, fil.value, fil.condition),
							propertyId: item._id,
							...fil
						};
					})
				};
			});
			return newPropArray;
		});
	}

	// Get Category Property and Filter Products
	static async getCategoryPropertyAndFilterProducts(searchData, filtersArray) {
		let params = { SlugId: searchData.CategorySlugId };
		return this.service.execute(this.API.getCategoryFilters, HTTP_METHOD.GET, { params }).then(response => {
			let extractedFilterArray = response
				.map((item, i) => {
					return {
						...item,
						filters: item.filters.map((fil, index) => {
							return {
								filterId: this.generateFilterKey(item._id, fil.value, fil.condition),
								propertyId: item._id,
								...fil
							};
						})
					};
				})
				.map((item, i) => item.filters);

			// TODO : Flatten the array of arrays to arrays
			// extractedFilterArray = flatten(extractedFilterArray);
			extractedFilterArray = extractedFilterArray.flat();

			// TODO : Return the checked filter objects data array
			// const mappedFiltersArray = extractedFilterArray.filter((fil, i) => includes(filtersArray, fil.filterId));
			const mappedFiltersArray = extractedFilterArray.filter((fil, i) => filtersArray.includes(fil.filterId));

			// Inject it into the search data object
			searchData = {
				...searchData,
				Filter: mappedFiltersArray
			};
			return this.service.execute(this.API.searchArtwork, HTTP_METHOD.POST, { body: searchData }).then(response => {
				return response;
			});
		});
	}
	// Get Search products without filter [WEB]
	static async getSearchProductsWithoutFilter(body) {
		return this.service.execute(this.API.searchArtworkV2, HTTP_METHOD.POST, { body });
	}

	// Load Breadcrumbs
	static async getParentsBySlugId(slugId) {
		let params = { slugId };
		return this.service.execute(this.API.getParentsByCategoryId, HTTP_METHOD.GET, { params });
	}

	static async getParentsByCategoryId(CategoryId) {
		let params = { CategoryId };
		return this.service.execute(this.API.getParentsByCategoryId, HTTP_METHOD.GET, { params });
	}

	// Generate Unique Key
	static generateFilterKey(id, value, condition) {
		return `${id}_${value}_${condition}`;
	}
}
