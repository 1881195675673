import styled, { css } from "styled-components";

export const ArtistMainBannerImg = styled.div`
	height: 297px;
	width: 100%;
	background-image: url(${(props) => props.bgImg});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
	@media (max-width: 768px) {
		height: 156px;
	}
	div {
		display: flex;
	}
	h1 {
		font-size: 24px !important;
		margin: 0 10px;
		@media (max-width: 768px) {
			font-size: 18px !important;
		}
	}
	div {
	}
`;
