import * as types from "./ActionsTypes";
import { CategoryManagementService } from "../services/CategoryManagementService";
import { ProductManagementService } from "../services/ProductManagementService";
import { AddProductService } from "../services/AddProductService";

// ============================= Get Category List ============================= //
export const getCategoryListAction = parentId => {
	return {
		actionType: types.CATEGORY_LIST_REQUEST,
		callAPI: () => CategoryManagementService.getCategoryList(parentId),
		stateObject: "getCategoryList"
	};
};

// ========================================== Search Product ========================================== //
export const getProductSearchAction = body => {
	return {
		actionType: types.GET_PRODUCT_SEARCH_REQUEST,
		callAPI: () => ProductManagementService.searchArtworks(body),
		stateObject: "productSearch"
	};
};



// ==========================================GET Artwork Property Values ========================================== //
export const getAllArtWorkPropertyAction = body => {
	return {
		actionType: types.GET_PROPERTY_VALUES,
		callAPI: () => AddProductService.getAllArtWorkProperty(body),
		stateObject: "artworkPropertyValuesList"
	};
}


//========Get Category By Id ========//
export const getCategoryBySlugAndIdAction = (slugId, CategoryId) => {
	return {
		actionType: types.GET_CATEGORY_BY_ID_SLUG_SUCCESS,
		callAPI: () => CategoryManagementService.getCategoryById(slugId, CategoryId),
		stateObject: "getCategoryDetailsById"
	};
}
