import { AuthenticationService } from "../services/AuthenticationService";
import * as types from "./ActionsTypes";

// <=========================Action function for user registration=====>
export const registerUserAction = userInfo => {
  return {
    actionType: types.USER_REGISTER_REQUEST,
    callAPI: () => AuthenticationService.registerUser(userInfo),
    stateObject: "userRegisterResult",
    isTransient: true
  };
};

// <=========================Action function for resend OTP=====>
export const resendCofirmationOTPAction = userId => {
  return {
    actionType: types.GET_MAIL_REQUEST,
    callAPI: () => AuthenticationService.resendOTP(userId),
    stateObject: "getMailResult",
    isTransient: true
  };
};

//------------------------ Action to Send OTP to Mail ------------------------//
export const sendOtpAction = email => {
  return {
    actionType: types.SEND_OTP_MAIL_REQUEST,
    callAPI: () => AuthenticationService.sendOTPToMail(email),
    stateObject: "otpSendtoMailResult",
    isTransient: true
  };
};

//------------------------ Action to Validate OTP ------------------------//
export const validateOtpAction = (email, otp) => {
  return {
    actionType: types.VALIDATE_OTP_REQUEST,
    callAPI: () => AuthenticationService.validateOTP(email, otp),
    stateObject: "validateOtpResult",
    isTransient: true
  };
};

//-------------------------- Action to Reset/Forgot Password --------------------------//
export const resetPasswordAction = (email, password, resetPasswordToken) => {
  return {
    actionType: types.RESET_PASSWORD_REQUEST,
    callAPI: () =>
      AuthenticationService.resetPassword(email, password, resetPasswordToken),
    stateObject: "resetPasswordResult",
    isTransient: true
  };
};
