import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getLatestArtists,
    getPopularArtists,
    getHomeScreenBannerAction,
    getHeaderCountAction,
    getBlogsByIdAction,
    getBlogsListAction,
    getNewsListAction,
    getNewsByIdAction,
    getNewsBySlugIdAction,
    getSelectedCollectionAction,
    getSearchAction,
    saveSearchAction
} from "../actions/HomeAction";
import Util from "../util/Util";

const useGetLatestArtists = () => {
    const dispatch = useDispatch();

    // Get Latest Artists
    const getLatestArtistsList = (pageNum, pageSize) => {
        dispatch(getLatestArtists(pageNum, pageSize));
    };

    // Reducer states
    const { data, error } = useSelector(state => state.homeReducer.latestArtists);

    return [getLatestArtistsList, data, error];
};

const useGetPopularArtists = () => {
    const dispatch = useDispatch();

    // Get Popular Artists
    const getPopularArtistsList = (pageNum, pageSize) => {
        dispatch(getPopularArtists(pageNum, pageSize));
    };

    // Reducer states
    const { data, error } = useSelector(state => state.homeReducer.popularArtists);

    return [getPopularArtistsList, data, error];
};

export { useGetLatestArtists, useGetPopularArtists };

// HomeScreen Banner
export const useGetHomeScreenBanner = () => {
    const dispatch = useDispatch();
    const getHomeScreenBanner = () => {
        dispatch(getHomeScreenBannerAction());
    };

    //Reducer States
    const result = useSelector(state => state.homeReducer.getHomeScreenBannerResult);
    return [getHomeScreenBanner, result.data, result.error, result.loading];
};

// HomeScreen Banner
export const useHeaderCount = () => {
    const dispatch = useDispatch();
    const getHeaderCount = () => {
        dispatch(getHeaderCountAction());
    };

    //Reducer States
    const result = useSelector(state => state.homeReducer.getHeaderCountResult);
    return [getHeaderCount, result.data, result.error, result.loading];
};

// ========================================== Get Blogs By Id API Hook ========================================== //
export const useGetBlogsById = () => {
    const dispatch = useDispatch();
    const blogDetail = slugId => {
        dispatch(getBlogsByIdAction(slugId));
    };
    const result = useSelector(state => state.homeReducer.getBlogsById);
    return [blogDetail, result.data, result.error, result.loading];
};

// ========================================== Get Blogs API Hook ========================================== //
export const useBlogs = () => {
    const dispatch = useDispatch();
    const getBlogs = (pageNum, pageSize) => {
        dispatch(getBlogsListAction(pageNum, pageSize));
    };
    const blogsList = useSelector(state => state.homeReducer.blogsList);
    return [getBlogs, blogsList.data, blogsList.error, blogsList.loading];
};

// ========================================== Get News API Hook ========================================== //
export const useNews = () => {
    const dispatch = useDispatch();
    const getNews = (pageNum, pageSize) => {
        dispatch(getNewsListAction(pageNum, pageSize));
    };
    const newsList = useSelector(state => state.homeReducer.newsList);
    return [getNews, newsList.data, newsList.error, newsList.loading];
};

// ========================================== Get News By Id API Hook ========================================== //
export const useNewsById = () => {
    const dispatch = useDispatch();
    const getNewsById = NewsId => {
        dispatch(getNewsByIdAction(NewsId));
    };
    const newsById = useSelector(state => state.homeReducer.newsById);
    return [getNewsById, newsById.data, newsById.error, newsById.loading];
};

// ========================================== Get News By Slug Id API Hook ========================================== //
export const useNewsBySlugId = () => {
    const dispatch = useDispatch();
    const getNewsBySlugId = SlugId => {
        dispatch(getNewsBySlugIdAction(SlugId));
    };
    const newsBySlugId = useSelector(state => state.homeReducer.newsBySlugId);
    return [getNewsBySlugId, newsBySlugId.data, newsBySlugId.error, newsBySlugId.loading];
};

// ====================================== Get Selected Collection Hook ===================================================//
export const useGetSelectedCollection = () => {
    const dispatch = useDispatch();
    const getSelectedCollection = () => {
        dispatch(getSelectedCollectionAction());
    };

    //Reducer States
    const result = useSelector(state => state.homeReducer.selectedCollectionList);
    return [getSelectedCollection, result.data, result.error, result.loading];
};

// ========================================== Get Search API Hook ========================================== //
export const useSearch = () => {
    const dispatch = useDispatch();
    const getSearch = keyword => {
        dispatch(getSearchAction(keyword));
    };
    const result = useSelector(state => state.homeReducer.searchResult);
    return [getSearch, result.data, result.error, result.loading];
};

//Save Search Hook
export const useSaveSearch = () => {
    const dispatch = useDispatch();
    const saveSearch = (searchIndexId, keyword = "") => {
        let body = { SearchIndexId: searchIndexId || "", Keyword: keyword || "" };
        let formData = Util.objectToFormData(body);
        dispatch(saveSearchAction(formData));
    };
    // Reducer states
    const result = useSelector(state => state.homeReducer.saveSearchResult);

    return [saveSearch, result.data, result.error, result.loading];
};
