import { APIService } from "../util/APIService";
import { BaseService, HTTP_METHOD } from "./BaseService";
import { APIUrlConstants } from "../util/APIConstants";
export class ArtistCollectionService {
	static API = APIUrlConstants.API_URLS;
	static service = new BaseService();

	// Get CollectionByArtist
	static async getCollectionByArtist() {
		return this.service.execute(this.API.getCollectionByArtist, HTTP_METHOD.GET, {});
	}
	// Get CollectionByArtist Using UserId
	static async getCollectionListByUserId(UserId) {
		let params = { UserId };
		return this.service.execute(this.API.getCollectionListByUserId, HTTP_METHOD.GET, { params, isAuthorizedAPI: false });
	}
	// Add CollectionByArtist
	static async addCollectionByArtist(body) {
		return this.service.execute(this.API.addCollectionByArtist, HTTP_METHOD.MULTIPART_POST, { body });
	}
	// Update CollectionByArtist/Admin
	static async updateCollection(body, collectionId) {
		let urlDirectParams = `/${collectionId}`;
		return this.service.execute(this.API.updateCollection, HTTP_METHOD.MULTIPART_PUT, { body, urlDirectParams });
	}
	// Delete CollectionByArtist/Admin
	static async deleteCollection(collectionId) {
		let urlDirectParams = `/${collectionId}`;
		return this.service.execute(this.API.deleteCollection, HTTP_METHOD.DELETE, { urlDirectParams });
	}
	// Filtering Products for Collections
	static async filterMyProduct(searchData) {
		let body = { ...searchData };
		return this.service.execute(this.API.filterMyProduct, HTTP_METHOD.POST, { body });
	}

	//Get Collection By Id
	static async getCollection(SlugId) {
		let params = { SlugId };
		return this.service.execute(this.API.getCollectionById, HTTP_METHOD.GET, { params });
	}

	//Delete Collection Item
	static async deleteCollectionItem(collectionId, collectionItemId) {
		let urlDirectParams = `/${collectionId}`;
		let params = { collectionItemId };
		return this.service.execute(this.API.deleteItemFromCollection, HTTP_METHOD.PUT, { urlDirectParams, params });
	}

	//Get All Collections
	static async getAllCollections(pageNum, pageSize) {
		let params = { pageNum, pageSize };
		return this.service.execute(this.API.getAllCollections, HTTP_METHOD.GET, { params });
	}
}
