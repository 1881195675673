import React, { useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

export default function PayNow(intentId) {
	const stripe = useStripe();
	const elements = useElements();
	useEffect(() => {
		stripe.confirmPayNowPayment(intentId, {}).then(function ({ error, paymentIntent }) {
			console.log("🚀 ~ file: PayNow.js ~ line 12 ~ ).then ~ paymentIntent", paymentIntent);
			console.log("🚀 ~ file: PayNow.js ~ line 12 ~ ).then ~ error", error);
			if (error) {
				// Inform the customer that there was an error.
			} else if (paymentIntent.status === "succeeded") {
				// Inform the customer that the payment was successful
			} else if (paymentIntent.status === "requires_action") {
				// Inform the customer that the payment did not go through
			}
		});
	}, []);

	return (
		<div>
			<h1>Haiia</h1>
		</div>
	);
}
