import { ImgNextGen } from "common/NextGenImage";
import styled from "styled-components";

export const ImageForSliderWrapper = styled.div`
	cursor: pointer;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const NormalDiv = styled.div`
	height: 210px;
`;
export const NormalDivSearch = styled.div`
	height: 120px;
`;
export const NormalImage = styled(ImgNextGen)`
	height: 190px;
	width: 190px;
	border-radius:100px;
	filter: grayscale(100%);
	&:hover {
		filter: none;
	}
`;
export const NormalImageSearch = styled(ImgNextGen)`
	height: 100px;
	width: 100px;
	border-radius:100px;
	filter: grayscale(100%);
	&:hover {
		filter: none;
	}
`;