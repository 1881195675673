import styled, { css, keyframes } from "styled-components";
import Image from "common/Image";

const FlipHorizontalBottomKeyframe = keyframes`
    0%{transform:rotateX(-90deg)}100%{transform:rotateX(0)};
`;

const FlipHorizontalBottom = css`
	& {
		animation: ${FlipHorizontalBottomKeyframe} 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	}
`;

const ArtworkCardImageWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content:center;
	background: 0% 0% no-repeat padding-box padding-box rgb(246, 246, 246);
	padding: 15px;
`;

const ArtworkCardImagePlaceholder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 160px;
	width: 15rem;
	background-color: #eaeaea;
	border-radius: 4px;
	img {
		width: 5rem;
		height: 5rem;
	}
`;

const ArtworkCardImage = styled(Image)`
	position: relative;
	height: 305px;
	max-width: 385px;
	cursor: pointer;

	@media (max-width: 576px) {
		max-width: 100%;
	}
`;
const ArtworkCardImageSearch = styled(Image)`
	position: relative;
	height: 205px;
	max-width: 285px;
	cursor: pointer;
	object-fit: contain;

	@media (max-width: 576px) {
		max-width: 100%;
	}
`;


const ArtworkCardOffer = styled.div`
	position: absolute;
	right: 3px;
	top: 3px;
	background-color: #ff0000;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	padding: 1px 7px;
	border-radius: 2px;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
`;

const ArtworkCardActions = styled.div`
	position: absolute;
	display: none;
	height: 26px;
	align-items: center;
	background-color: #fff;
	border-radius: 2rem;
	right: 8px;
	bottom: 8px;
	padding: 0 5px;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
	img {
		width: 33px !important;
		padding: 0 8.5px;
		border-right: 1px solid #bfbfbf;
		cursor: pointer;
		&:last-of-type {
			border: none;
		}
	}
`;

const ArtwordCardTitle = styled.p`
	font-size: 15px !important;
	font-weight: 800 !important;
	color:#000000;
	width: ${props => {
		return props.customWidth ? props.customWidth : "70%";
	}};
	margin-top: 6px;
	margin-bottom: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
const ArtworkCardFooterPrice = styled.div`
display: flex;
justify-content: space-between;
height: 30px;
/* justify-content: flex-start; */

`;
const ArtworkCardFooter = styled.div`
	display: flex;
	justify-content: space-between;
	height: 30px;
    align-items: center;

`;
const ArtworkTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 30px;
	/* width: 155px; */
    align-items: center;

`;
export const Available = styled.p`
	font-size: 12px;
	color: #000;
	font-weight: 500;
	margin: 0;
	img {
		margin-right: 5px;
		width: 16px;
	}
`;

const ArtworkCardArtist = styled.div`
	display: flex;
	align-items: center;
	width:100%;
	justify-content: inherit;
	img {
		width: 13px !important;
		height: 13px;
		margin-right: 3px;
		position: relative;
		left: -1.5px;
	}
	p {
		font-size: 14px!important;
		color: black;
		font-weight: 200;
		margin-bottom: 0;
		${FlipHorizontalBottom}
		overflow: hidden;
		letter-spacing: 0px;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 180px;
		min-width: 100px;
		width: 10rem;
		font-weight: normal!important;
		font-family:'Lato'!important;



	}
`;

const ProfileIcon = styled.img`
	object-fit: unset;
`;

const ArtworkCardPrice = styled.p`
	font-size: 13px;
	color: #000;
	font-weight: 700;
	margin-bottom: 0;
	margin-left: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100px;
`;

const FollowButtonWrapper = styled.div`
	display:none;
`;
const FollowButton = styled.button`
	/* display: none; */
	border: none;
	background: none;
	/* font-size: 12px; */
	color: #0071D4;
	/* box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45); */
	font: normal normal 200 13px/59px Cormorant Garamond;
	border-radius: 10px;
	padding: 3px auto;
	cursor: pointer;
	outline: none;
	justify-content: center;
	.anticon {
		margin-right: 5px;
	}
`;

const ArtworkCardWrapper = styled.div`
	max-width: 385px;
	cursor: default;
	margin: ${props => props.margin || 0};
	&:hover {
		${ArtwordCardTitle} {
			color: #d2502d;
		}
		${ArtworkCardActions} {
			display: flex;
			${FlipHorizontalBottom}
		}
		/* ${ArtworkCardArtist} {
			p {
				display: ${props => (props.hideNameOnHover ? "none" : "inline")};
			}
		} */
		${FollowButtonWrapper}{
			display: flex;
			justify-content: flex-start;
			width: 200px;
			height:100%;
			max-width: 180px;
			min-width: 100px;
		}
		${FollowButton} {
			display: flex;
			align-items: center;
			${FlipHorizontalBottom}
		}
	}
	@media screen and (max-width: 917px){
	  width:330px;
	}
`;
const ArtwordCardDiscount = styled.p`
	font-size: 14px !important;
    font-weight: 400 !important;
	color:#FF0000;
	
	margin-left:15px;
	margin-bottom: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align:right;
`;


export {
	ArtworkCardWrapper,
	ArtworkCardImageWrapper,
	ArtworkCardImagePlaceholder,
	ArtworkCardImage,
	ArtworkCardOffer,
	ArtworkCardActions,
	ArtwordCardTitle,
	ArtworkCardFooter,
	ArtworkCardArtist,
	ArtworkCardPrice,
	FollowButtonWrapper,
	FollowButton,
	ProfileIcon,
	ArtworkCardFooterPrice,
	ArtwordCardDiscount,
	ArtworkCardImageSearch,
	ArtworkTitleContainer
};
