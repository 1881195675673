import { useDispatch, useSelector } from "react-redux";
import {
	createProductAction,
	onSaveDetailsToReducer,
	setReducerStates,
	getCategoryPropertyAction,
	onUpdateImagesAndVideos,
} from "./../actions/AddProductAction";
import { getArtistListByUserId } from "../actions/GalleryAction";

const useAddProductContainer = () => {
	// Save data to reducer
	const onSaveDataToReducer = (type, data, isMobile, hitCreateProductAPI = false) => {
		dispatch(onSaveDetailsToReducer(type, data, isMobile, hitCreateProductAPI));
	};

	// Get Category Properties
	const getCategoryProperty = (data) => {
		dispatch(getCategoryPropertyAction(data));
	};

	// Add Product []
	const createAddProduct = (body) => {
		dispatch(createProductAction(body));
	};

	// Get artists by gallery
	const getArtistsOfGallery = (UserId) => {
		dispatch(getArtistListByUserId("", "", UserId));
	};

	// Upload images for Mobile
	const uploadProductImage = (body, response, isUpdate = false) => {
		dispatch(onUpdateImagesAndVideos(body, response, isUpdate));
	};

	// Clear states
	const clearStates = () => {
		dispatch(setReducerStates("CLEAR_ADD_PRODUCT_REDUCER_STATES", null));
	};

	// Reducer states
	const {
		// Basic
		isBasicSaved,
		categoryArray,
		productName,
		artistId,
		tagsArray,
		tags,
		description,
		oneLineDescription,
		editionPublisher,
		stock,
		productType,

		// Primary
		height,
		width,
		depth,
		medium,
		isLimitedEdition,
		materialsUsed,
		yearofCreation,
		weight,
		originalArtworkId,
		packageHeight,
		packageWidth,
		packageDepth,
		panel,
		framed,

		// Pricing and stock
		isPSSaved,
		price,
		isHidePrice,
		stockManagement,
		currency,
		autoApproveQuote,
		approvePercentage,
		autoRejectQuote,
		rejectPercentage,
		//SET_ARTIST_DETAILS
		style,
		subject,
		isAbstract,
		seriesName,
		isArtistSaved,
		conditionReport,
		signature,
		// Images and Videos
		isIVSaved,
		imagesArray,
		// roomImageArray,
		displayPictureArray,

		// Features
		isFeaturesSaved,
		productProperties,
		isLogisticsSaved,

		// Signing
		isSigningSaved,
		ownerEthAddress,
		blockchainPrivateKey,
		createProductLoader,
		errorToast,
		successToast,
		productResponse,
	} = useSelector((state) => state.addProductManagement);
	const dispatch = useDispatch();

	return {
		onSaveDataToReducer,

		// Basic
		isBasicSaved,
		categoryArray,
		productName,
		artistId,
		tagsArray,
		tags,
		description,
		oneLineDescription,
		editionPublisher,
		productType,
		// Primary
		height,
		width,
		depth,
		medium,
		isLimitedEdition,
		materialsUsed,
		yearofCreation,
		weight,
		originalArtworkId,
		packageHeight,
		packageWidth,
		packageDepth,
		panel,
		framed,
		//SET_ARTIST_DETAILS
		style,
		subject,
		isAbstract,
		seriesName,
		conditionReport,
		signature,
		isArtistSaved,
		// Pricing and stock
		isPSSaved,
		price,
		isHidePrice,
		stockManagement,
		stock,
		currency,
		autoApproveQuote,
		approvePercentage,
		autoRejectQuote,
		rejectPercentage,

		// Images and Videos
		isIVSaved,
		imagesArray,
		// roomImageArray,
		displayPictureArray,

		// Features
		isFeaturesSaved,
		productProperties,
		isLogisticsSaved,

		// Signing
		isSigningSaved,
		ownerEthAddress,
		blockchainPrivateKey,

		getArtistsOfGallery,
		getCategoryProperty,
		createAddProduct,
		clearStates,
		uploadProductImage,

		createProductLoader,
		errorToast,
		successToast,
		productResponse,
	};
};

export default useAddProductContainer;
