import { ArtistFollowService } from "../services/ArtistFollowService";
import * as types from "./ActionsTypes";

// ========================================== Follow Artist ========================================== //
export const followArtist = followerData => {
	return {
		actionType: types.FOLLOW_USER_REQUEST,
		callAPI: () => ArtistFollowService.followArtist(followerData),
		stateObject: "followArtist",
		isTransient: true
	};
};

// ========================================== Get Following List ========================================== //
export const getMyFollowingListAction = () => {
	return {
		actionType: types.GET_FOLLOWING_LIST_REQUEST,
		callAPI: () => ArtistFollowService.getMyFollowingUserList(),
		stateObject: "followingList"
	};
};

// ========================================== Get Following List by userId ========================================== //
export const getFollowingList = userId => {
	return {
		actionType: types.GET_FOLLOWING_LIST_REQUEST,
		callAPI: () => ArtistFollowService.getFollowingList(userId),
		stateObject: "followingList"
	};
};


// ========================================== Get Followers List ========================================== //
export const getMyFollowersListAction = () => {
	return {
		actionType: types.GET_FOLLOWERS_LIST_REQUEST,
		callAPI: () => ArtistFollowService.getMyFollowersList(),
		stateObject: "followersList"
	};
};

// ========================================== Get Followers List  by userId ========================================== //
export const getFollowersList = userId => {
	return {
		actionType: types.GET_FOLLOWERS_LIST_REQUEST,
		callAPI: () => ArtistFollowService.getFollowersList(userId),
		stateObject: "followersList"
	};
};

// ========================================== Unfollow Artist ========================================== //
export const unfollowArtist = artistId => {
	return {
		actionType: types.UNFOLLOW_USER_REQUEST,
		callAPI: () => ArtistFollowService.unfollowArtist(artistId),
		stateObject: "unfollowArtist",
		isTransient: true
	};
};
// ========================================== Get My Following Artist List ========================================== //
export const getMyArtistFollowing = () => {
	return {
		actionType: types.GET_MY_ARTIST_FOLLOWING_REQUEST,
		callAPI: () => ArtistFollowService.getMyArtistFollowing(),
		stateObject: "myArtistFollowing"
	};
};
